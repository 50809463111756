import React, {useEffect, useState} from 'react';

import URLSearchParams from '@ungap/url-search-params';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';

import {Button, LoadingIndicator} from '@edume/magnificent';

import addFormState from '../../../hocs/addFormState';
import {isValidEmail} from '../../../utils/validation';
import TrialView from '../../../views/TrialView';

import styles from './styles.scss';

const CheckEmailPage = ({
  setEmail,
  setResourceType,
  setResourceKey,
  setIsBeekeeper,
  checkTrialUserEmail,
  emailError,
  location,
  intl,
  history,
}) => {
  const [emailClientError, setEmailClientError] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const email = params.get('email');
    const resourceType = params.get('resource-type');
    const resource = params.get('resource');
    const isBeekeeper = params.get('is-beekeeper');

    if (resource) {
      setResourceType(resourceType);
      setResourceKey(resource);
    }

    if (isBeekeeper) {
      setIsBeekeeper(isBeekeeper === 'true');
    }

    if (email && isValidEmail(email)) {
      setEmail(email);
      checkTrialUserEmail();
    } else {
      setEmailClientError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBack = () => {
    history.push('/trial');
  };

  if (emailError || emailClientError) {
    const errorText = intl.formatMessage({
      id: `Error.${emailError || 'email_invalid'}`,
    });
    const backButtonText = intl.formatMessage({id: 'Button.back'});

    return (
      <TrialView>
        <div
          styleName='error-container'
          style={{flex: 1, alignItems: 'center', alignContent: 'center'}}
        >
          <div>{errorText}</div>
          <Button onClick={onBack} type='secondary' noMarginLeft>
            {backButtonText}
          </Button>
        </div>
      </TrialView>
    );
  }

  return (
    <LoadingIndicator
      indicator='hexagons'
      size='large'
      containerStyle='centerAbsolute'
    />
  );
};

CheckEmailPage.propTypes = {
  emailError: PropTypes.string,
  setEmail: PropTypes.func.isRequired,
  setResourceType: PropTypes.func.isRequired,
  setResourceKey: PropTypes.func.isRequired,
  setIsBeekeeper: PropTypes.func.isRequired,
  checkTrialUserEmail: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default addFormState(
  withRouter(
    injectIntl(CSSModules(CheckEmailPage, styles, {allowMultiple: true}))
  )
);
