import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';

import {Button} from '@edume/magnificent';

import colourStyles from '../../../resources/styles/colours.module.scss';
import styles from './styles.scss';

const horizontalPositionMapper = {
  left: 'flex-start',
  right: 'flex-end',
  center: 'center',
};

const verticalPositionMapper = {
  top: 'flex-start',
  bottom: 'flex-end',
  center: 'center',
};

const LoginDisplay = ({
  config = {
    backgroundColor: colourStyles.brandPrimaryDark,
    contentHorizontalPosition: 'left',
    contentVerticalPosition: 'bottom',
    textAlignment: 'left',
    textColor: 'white',
    textWrap: '50%',
  },
  children,
}) => (
  <div styleName='login-container'>
    <div
      styleName='login-display'
      style={{
        backgroundColor: config.backgroundColor,
        backgroundImage: `url(${config.foregroundImageUrl}), url(${config.backgroundImageUrl})`,
        justifyContent:
          horizontalPositionMapper[config.contentHorizontalPosition],
        alignItems: verticalPositionMapper[config.contentVerticalPosition],
        textAlign: config.textAlignment,
      }}
    >
      <div style={{color: config.textColor, width: config.textWrap}}>
        <h2 styleName='title'>{config.title}</h2>
        <p styleName='description'>{config.description}</p>
        {config.buttonText && config.buttonUrl && (
          <Button
            type='secondary'
            onClick={() => {
              window.open(config.buttonUrl, '_blank');
            }}
            noMarginLeft
          >
            {config.buttonText}
          </Button>
        )}
      </div>
    </div>
    <div styleName='login-form'>{children}</div>
  </div>
);

LoginDisplay.propTypes = {
  config: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    backgroundImageUrl: PropTypes.string,
    foregroundImageUrl: PropTypes.string,
    backgroundColor: PropTypes.string,
    buttonText: PropTypes.string,
    buttonUrl: PropTypes.string,
    textColor: PropTypes.string.isRequired,
    textWrap: PropTypes.oneOf(['25%', '50%', '100%']).isRequired,
    textAlignment: PropTypes.oneOf(['left', 'right', 'center']).isRequired,
    contentHorizontalPosition: PropTypes.oneOf(['left', 'right', 'center'])
      .isRequired,
    contentVerticalPosition: PropTypes.oneOf(['top', 'bottom', 'center'])
      .isRequired,
  }),
  children: PropTypes.node,
};
export default CSSModules(LoginDisplay, styles);
