import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {injectIntl} from 'react-intl';

import {LegacyHeading} from '@edume/magnificent';

import styles from './styles.scss';

const HeaderSettings = ({intl, viewCompanySettings}) => (
  <button
    styleName='settingsContainer'
    data-automation='header-item-settings'
    onClick={viewCompanySettings}
  >
    <div styleName='settings'>
      <img styleName='linkImg' src='/resources/img/24_cog.svg' />
      <LegacyHeading colour='white' size='micro' weight='medium'>
        {intl.formatMessage({
          id: 'Header.settingsLink',
        })}
      </LegacyHeading>
    </div>
  </button>
);

HeaderSettings.propTypes = {
  intl: PropTypes.object.isRequired,
  //from addFormState
  viewCompanySettings: PropTypes.func.isRequired,
};

export default injectIntl(
  CSSModules(HeaderSettings, styles, {allowMultiple: true})
);
