// amplitude login types
export const SEAMLESS_EMAIL = 'seamless-email';
export const SEAMLESS_SMS = 'seamless-sms';
export const SEAMLESS_WHATSAPP = 'seamless-whatsapp';
export const SEAMLESS_BRAZE = 'seamless-braze';
export const SEAMLESS_ITERABLE = 'seamless-iterable';
export const SEAMLESS_CUSTOM = 'seamless-custom';
export const SEAMLESS_BEEKEEPER = 'seamless-beekeeper';
export const SEAMLESS_BEEKEEPER_NOTIFICATION =
  'seamless-beekeeper-notification';
