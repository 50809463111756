import React, {useContext, useEffect, useRef} from 'react';

import 'ninja-keys';

import {CommandPaletteContext} from './CommandPaletteContext';

import './styles.scss';

const CommandPalette = () => {
  const {hotkeys} = useContext(CommandPaletteContext);
  const ninjaKeys = useRef(null);

  useEffect(() => {
    if (ninjaKeys.current) {
      ninjaKeys.current.data = hotkeys;
    }
  }, [hotkeys]);

  return (
    <ninja-keys
      ref={ninjaKeys}
      noAutoLoadMdIcons
      hideBreadcrumbs
      hotKeysJoinedView
      openHotkey="cmd+'"
    >
      <div slot='footer' />
    </ninja-keys>
  );
};

export default CommandPalette;
