import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {useIntl} from 'react-intl';

import {Heading, SlidePanel} from '@edume/magnificent';

import ContentCreationCard from '../ContentCreationCard';

import styles from './styles.scss';

const ChooseContentType = ({
  onChooseContentType,
  onClose,
  isGuidesInLearningTabEnabled,
  isKnowledgeHubsEnabled,
}) => {
  const intl = useIntl();

  const getTextKey = (key) =>
    intl.formatMessage({id: `Learning.createContent.${key}`});

  /** @param {import('../CreateContentFlow').ContentType} type */
  const renderCard = (type) => (
    <ContentCreationCard
      title={getTextKey(`${type}.title`)}
      description={getTextKey(`${type}.description`)}
      imageUrl={`/resources/img/lesson/template/${type}.png`}
      dataAutomation={`create-${type}`}
      onSelect={() => onChooseContentType(type)}
    />
  );

  return (
    <SlidePanel title={getTextKey('title')} onClose={onClose}>
      <div styleName='wrapper'>
        <Heading as='h4' size='snail' weight='semiBold'>
          {getTextKey('subtitle.content')}
        </Heading>
        <div styleName='card-wrapper'>
          {renderCard('course')}
          {isGuidesInLearningTabEnabled && renderCard('guide')}
        </div>

        <div styleName='section-spacer' />

        {isKnowledgeHubsEnabled && (
          <>
            <Heading as='h4' size='snail' weight='semiBold'>
              {getTextKey('subtitle.organisation')}
            </Heading>
            {renderCard('knowledgeHub')}
          </>
        )}
      </div>
    </SlidePanel>
  );
};

ChooseContentType.propTypes = {
  onChooseContentType: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isGuidesInLearningTabEnabled: PropTypes.bool.isRequired,
  isKnowledgeHubsEnabled: PropTypes.bool.isRequired,
};

export default CSSModules(ChooseContentType, styles);
