import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import AddKnowledgeHub from '../../components/KnowledgeHub/AddKnowledgeHub';
import * as knowledgeHubActions from '../../state/knowledgeHub/knowledgeHubActions';
import * as notificationActions from '../../state/notification/notificationActions';
import {getSelectedGroupId, getTeams} from '../../state/team/teamSelectors';
import * as trackingActions from '../../state/tracking/trackingActions';

import styles from './styles.scss';

const connector = connect(
  (state) => {
    const groupId = getSelectedGroupId(state);
    const company = state.getIn(['auth', 'company']);
    return {
      groupId,
      teams: getTeams(state),
      isSeamlessEnabled: company.hasSeamless,
      knowledgeHubsList: state.getIn(['knowledgeHub', 'knowledgeHubs']).toJS(),
      knowledgeHubsEnabled: company.knowledgeHubsEnabled,
      knowledgeHubId: state.getIn(['knowledgeHub', 'knowledgeHubId']),
    };
  },
  (dispatch) => ({
    ...bindActionCreators(knowledgeHubActions, dispatch),
    ...bindActionCreators(notificationActions, dispatch),
    ...bindActionCreators(trackingActions, dispatch),
  })
);

const KnowledgeHubView = ({
  onClose,
  knowledgeHubId,
  teams,
  addKnowledgeHub,
  groupId,
  isSeamlessEnabled,
}) => (
  <AddKnowledgeHub
    teams={teams}
    addKnowledgeHub={addKnowledgeHub}
    groupId={groupId}
    isSeamlessEnabled={isSeamlessEnabled}
    knowledgeHubId={knowledgeHubId}
    onClose={onClose}
  />
);

KnowledgeHubView.propTypes = {
  onClose: PropTypes.func.isRequired,
  knowledgeHubsEnabled: PropTypes.bool.isRequired,
  knowledgeHubId: PropTypes.number,
  teams: PropTypes.array.isRequired,
  addKnowledgeHub: PropTypes.func.isRequired,
  groupId: PropTypes.number.isRequired,
  isSeamlessEnabled: PropTypes.bool.isRequired,
};

export default injectIntl(
  connector(CSSModules(KnowledgeHubView, styles, {allowMultiple: true}))
);
