import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {FormattedMessage} from 'react-intl';

import {Button} from '@edume/magnificent';

import {COMPANY, GROUP, TEAM} from '../../../../enums/adminLevels';
import {LegacyIntlHeading, MultiLineInfo} from '../../../core';

import styles from './styles.scss';

const SelectRole = ({level, selected, updateRoles, isInvalid, errorText}) => {
  const onToggleRole = (roleName) => {
    const newRoles = {
      ...selected,
      [roleName]: !selected[roleName],
      isLead: false, //selecting any non-lead role removes the lead flag
    };
    updateRoles(newRoles);
  };

  const onToggleLead = () => {
    if (level !== TEAM) {
      const otherRolesFalse = {
        isAnalyst: false,
        isEditor: false,
        isManager: false,
      };
      //always disable other buttons
      updateRoles({isLead: !selected.isLead, ...otherRolesFalse});
    }
  };

  const getButtonType = (roleName) =>
    selected[roleName] ? 'active' : 'secondary';

  const rolePrefix = 'Admin.role';
  const levelPrefix = `Admin.level.${level}`;

  const tooltipItems = [
    {
      headingKey:
        level !== TEAM ? `${rolePrefix}.leads` : `${rolePrefix}.${level}.leads`,
      descriptionKey:
        level !== TEAM
          ? `${rolePrefix}.lead.description`
          : `${rolePrefix}.${level}.lead.description`,
    },
  ];
  if (level !== TEAM) {
    tooltipItems.push({
      headingKey: `${rolePrefix}.managers`,
      descriptionKey: `${rolePrefix}.manager.description`,
    });
    tooltipItems.push({
      headingKey: `${rolePrefix}.editors`,
      descriptionKey: `${rolePrefix}.editor.description`,
    });
    tooltipItems.push({
      headingKey: `${rolePrefix}.analysts`,
      descriptionKey: `${rolePrefix}.analyst.description`,
    });
  }

  return (
    <div>
      <LegacyIntlHeading
        size='mini'
        colour='grey700'
        textKey='Admin.role.header'
        inline
      />
      <MultiLineInfo
        introKey={`${rolePrefix}.intro`}
        items={tooltipItems}
        dataKey='roleInfo'
        place='bottom'
        theme='dark'
      />
      {isInvalid && errorText && (
        <div>
          <LegacyIntlHeading size='mini' colour='red' text={errorText} />
        </div>
      )}

      <div styleName='buttonContainer'>
        <Button
          type={getButtonType('isLead')}
          onClick={() => onToggleLead()}
          dataAutomation='lead-button'
        >
          <FormattedMessage id={`${levelPrefix}.lead`} />
        </Button>
        {level !== TEAM && (
          <React.Fragment>
            <Button
              type={getButtonType('isManager')}
              onClick={() => onToggleRole('isManager')}
              dataAutomation='manager-button'
            >
              <FormattedMessage id={`${levelPrefix}.manager`} />
            </Button>
            <Button
              type={getButtonType('isEditor')}
              onClick={() => onToggleRole('isEditor')}
              dataAutomation='editor-button'
            >
              <FormattedMessage id={`${levelPrefix}.editor`} />
            </Button>
            <Button
              type={getButtonType('isAnalyst')}
              onClick={() => onToggleRole('isAnalyst')}
              dataAutomation='analyst-button'
            >
              <FormattedMessage id={`${levelPrefix}.analyst`} />
            </Button>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

SelectRole.propTypes = {
  level: PropTypes.oneOf([COMPANY, GROUP, TEAM]),
  selected: PropTypes.shape({
    isLead: PropTypes.bool,
    isAnalyst: PropTypes.bool,
    isEditor: PropTypes.bool,
    isManager: PropTypes.bool,
  }).isRequired,
  updateRoles: PropTypes.func.isRequired,
  isInvalid: PropTypes.bool,
  errorText: PropTypes.string,
};

export default CSSModules(SelectRole, styles);
