import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';

import styles from './styles.scss';

const backgroundColours = ['dark', 'light', 'beigey'];

const FocusElement = ({children, background, fullScreen, onClose, zIndex}) => {
  const HEADER_Z_INDEX = 10;

  const overlayZIndex = zIndex + HEADER_Z_INDEX;
  let overlayStyle = background ? 'overlay-' + background : 'overlay';
  overlayStyle += fullScreen ? ' fullScreen' : ' relative';

  return (
    <div>
      <div
        styleName='outer-container'
        style={{zIndex: overlayZIndex + 1}}
        onClick={onClose}
      >
        <div styleName='content'>{children}</div>
      </div>
      <div styleName={overlayStyle} style={{zIndex: overlayZIndex}} />
    </div>
  );
};

FocusElement.propTypes = {
  children: PropTypes.any,
  background: PropTypes.oneOf(backgroundColours),
  onClose: PropTypes.func,
  zIndex: PropTypes.number, //allows stacking FocusElements on top of one another
  fullScreen: PropTypes.bool, //if true, overlay is position: fixed; if false, is position: absolute
};

FocusElement.defaultProps = {
  zIndex: 2,
  fullScreen: true,
  onClose: () => {},
};

export default CSSModules(FocusElement, styles, {allowMultiple: true});
