import {validation} from '@edume/bento';

import commonPasswords from '../../resources/wordlists/common-passwords.json';

export const isValidEmail = validation.isEmail;
export const isValidEmployeeId = (employeeId) =>
  validation.isEmployeeId(employeeId.trim(), 0);

const isCommonPassword = (password) => commonPasswords.includes(btoa(password));

export const isValidPhone = (phone) => {
  const regex = /^\+?\d{9,}$/;
  return regex.test(phone);
};

// for supporting existing admins having paswords with less than 10 characters
// can be dropped when they are forced to reset their passwords
export const isValidLegacyPassword = (password) => {
  const regex = /(.{8,})/;
  return regex.test(password);
};

export const isValidPassword = (password) => {
  const regex = /(.{10,})/;
  return regex.test(password) && !isCommonPassword(password);
};

export const isGroupNameNotEmpty = (name) => name.trim() !== '';

export const isGroupNameNotTooLong = (name) => name.length <= 60;

export const isValidLessonTitle = (lessonTitle) => {
  const regex = /^.+$/;
  return regex.test(lessonTitle.trim());
};

export const isValidModuleTitle = (moduleTitle) => {
  const regex = /^.+$/;
  return regex.test(moduleTitle.trim());
};

export const isValidAssessmentTitle = (assessmentTitle) => {
  const regex = /^.+$/;
  return regex.test(assessmentTitle.trim());
};

export const isValidName = (name) => {
  const regex = /^.{2,100}$/;
  return regex.test(name.trim());
};

export const isValidCompanyName = (companyName) => {
  const regex = /^.{2,40}$/;
  return regex.test(companyName);
};

export const isValidOutcomes = (outcome) => outcome.length > 0;

export const isValidSurveyTitle = (surveyTitle) => {
  const regex = /^.{2,}$/;
  return regex.test(surveyTitle.trim());
};

export const isValidSurveyQuestion = (surveyQuestion) => {
  const regex = /^.{1,80}$/;
  return regex.test(surveyQuestion.trim());
};

export const isValidSurveyOption = (surveyOption) => {
  const regex = /^.{1,60}$/;
  return regex.test(surveyOption.trim());
};

export const isValidEnpsBoundMsg = (boundMsg) => {
  const regex = /^.{1,20}$/;
  return regex.test(boundMsg.trim());
};

export const isValidHexCode = (text) => {
  const regExp = new RegExp(/^#([0-9a-f]{3}){1,2}$/i);
  return regExp.test(text);
};
