import React from 'react';

import CSSModules from 'react-css-modules';

import styles from './styles.module.scss';

type Colour =
  | 'green'
  | 'amber'
  | 'purple'
  | 'blue'
  | 'red'
  | 'yellow'
  | 'grey'
  | 'light-grey'
  | 'brandPrimaryDark';

type BadgeStyle = 'default' | 'solid' | 'outlined';

type BadgeProps = {
  textContent: string;
  colour: Colour;
  badgeStyle?: BadgeStyle;
  compact?: boolean;
  dataAutomation?: string;
  breakText?: boolean;
  dataFor?: string;
  dataTip?: string;
};

const Badge = ({
  textContent,
  colour,
  badgeStyle = 'default',
  compact,
  dataAutomation,
  breakText,
  dataFor,
  dataTip,
}: BadgeProps): JSX.Element => (
  <div
    data-for={dataFor}
    data-tip={dataTip}
    data-automation={dataAutomation}
    styleName={`badge ${colour} ${badgeStyle !== 'default' ? badgeStyle : ''} ${
      compact ? 'compact' : ''
    } ${breakText ? 'break-text' : ''}`}
  >
    {textContent}
  </div>
);
export default CSSModules(Badge, styles, {allowMultiple: true});
