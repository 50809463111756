import React, {useEffect} from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';

import {
  ACCEPTED_LABELS,
  PUBLISHED,
  READY_TO_PUBLISH,
  UNPUBLISHED,
} from '../../enums/courses';
import {injectFeatureFlags} from '../../utils/featureFlags';
import IconCourse from './IconCourse';
import ImageCourse from './ImageCourse';

import styles from './styles.scss';

const Course = ({
  course,
  history,
  searchCourse,
  courseSearchValue,
  setCourseSearchValue,
  setSearchResults,
  groups,
  currentGroupId,
  updateCourseDetails,
  goToContent,
  copyContent,
  intl,
  isResourceLibraryCourse = false,
  isDemoContentCourse = false,
  featureFlags,
  adminLanguage,
  setCopyingCourse,
  setEditingCourse,
  rowIndex,
  setRowHeight,
  isImageCourseCardEnabled,
  copyDemoCourseToCustomer,
}) => {
  const rowRef = React.createRef();

  useEffect(() => {
    setRowHeight(rowIndex, rowRef.current.clientHeight);
  }, [rowIndex, rowRef, setRowHeight]);

  const handleGoToContent = () => {
    if (searchCourse && courseSearchValue) {
      searchCourse(course.id, 'results list');
    }

    goToContent(course);
  };

  const {
    published,
    ready,
    modules,
    courseActivities,
    isTemplate,
    label,
    id: courseId,
  } = course;

  const {priorityCoursesEnabled} = featureFlags;

  const duplicateOptions = [
    {
      title: intl.formatMessage({id: 'Learning.makeACopyLink'}),
      callback: () => handleCopyContent(),
      dataAutomation: 'duplicate-course-link',
    },
  ];

  if (groups.length > 1) {
    duplicateOptions.splice(1, 0, {
      title: intl.formatMessage({id: 'Learning.copyToAnotherGroupLink'}),
      callback: () => setCopyingCourse(course),
      dataAutomation: 'duplicate-course-to-another-group-link',
    });
  }

  const handleCopyContent = async () => {
    await copyContent({
      content: course,
      groupIds: [currentGroupId],
      currentGroupId,
      ...(isResourceLibraryCourse && {copiedFrom: label}),
      shouldGoToContent: isTemplate,
    });
    if (isResourceLibraryCourse) {
      setCopyingCourse(course);
    }
    setCourseSearchValue('');
    setSearchResults(null);
  };

  const handleCopyDemoCourse = async () => {
    await copyDemoCourseToCustomer({courseId, groupId: currentGroupId});
    setCopyingCourse(course);
  };

  const buttonText = intl.formatMessage({id: 'Learning.goToCourseButton'});
  const sampleCourseText = intl.formatMessage({id: 'Learning.sampleCourse'});

  let publishStatus;
  // ESLint doesn't like nested ternaries :(
  if (published) {
    publishStatus = PUBLISHED;
  } else {
    publishStatus = ready ? READY_TO_PUBLISH : UNPUBLISHED;
  }

  const publishStatusText = intl.formatMessage({
    id: `Learning.${publishStatus}`,
  });

  const copyResourceLibraryCourse = async (e) => {
    if (isImageCourseCardEnabled) {
      e.stopPropagation();
    }

    handleCopyContent();
  };

  const firstActivity = courseActivities?.sort(
    (a, b) => a.moduleId - b.moduleId || a.position - b.position
  )?.[0];
  const firstActivityId = firstActivity?.lessonId ?? firstActivity?.surveyId;
  const firstModuleId = firstActivity?.moduleId ?? modules?.[0].id;

  const acceptedLabel = ACCEPTED_LABELS.includes(label);

  const getCardVersion = (props) => {
    if (isImageCourseCardEnabled) {
      return <ImageCourse {...props} />;
    }
    return <IconCourse {...props} />;
  };

  const courseCardComponent = getCardVersion({
    course,
    publishStatus,
    isResourceLibraryCourse,
    isDemoContentCourse,
    publishStatusText,
    priorityCoursesEnabled,
    sampleCourseText,
    acceptedLabel,
    firstModuleId,
    duplicateOptions,
    copyResourceLibraryCourse,
    handleCopyDemoCourse,
    buttonText,
    firstActivityId,
    updateCourseDetails,
    intl,
    adminLanguage,
    enableEditingCourse: setEditingCourse,
    goToCourse: handleGoToContent,
    isTemplate,
    handleCopyCourse: handleCopyContent,
    history,
  });

  return (
    <div
      styleName={`course-wrapper
       ${isImageCourseCardEnabled ? 'centered' : ''}`}
      ref={rowRef}
    >
      {courseCardComponent}
    </div>
  );
};

Course.propTypes = {
  course: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  selectGroup: PropTypes.func.isRequired,
  currentGroupId: PropTypes.number.isRequired,
  goToContent: PropTypes.func.isRequired,
  copyContent: PropTypes.func.isRequired,
  updateCourseDetails: PropTypes.func,
  editCourseInProgress: PropTypes.bool.isRequired,
  hasCourseDuplicationFailed: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  history: PropTypes.object,
  location: PropTypes.object,
  courseSearchValue: PropTypes.string,
  setCourseSearchValue: PropTypes.func.isRequired,
  setSearchResults: PropTypes.func.isRequired,
  searchCourse: PropTypes.func,
  isResourceLibraryCourse: PropTypes.bool,
  isDemoContentCourse: PropTypes.bool,
  featureFlags: PropTypes.object.isRequired,
  createTemplateFromCourse: PropTypes.func,
  adminLanguage: PropTypes.string.isRequired,
  setEditingCourse: PropTypes.func,
  setCopyingCourse: PropTypes.func,
  rowIndex: PropTypes.number,
  setRowHeight: PropTypes.func,
  isImageCourseCardEnabled: PropTypes.bool,
  copyDemoCourseToCustomer: PropTypes.func.isRequired,
};

export default injectIntl(
  injectFeatureFlags(
    withRouter(CSSModules(Course, styles, {allowMultiple: true}))
  )
);
