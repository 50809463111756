import React from 'react';

import CSSModules from 'react-css-modules';
import {useDispatch, useSelector} from 'react-redux';

import {ExternalLinkIcon} from '@edume/magnificent';

import {trackContactSales} from '../../../state/trial/trialActions';
import {showExternalLinkIcon} from '../../../utils/integrations';
import OverviewArticles from '../OverviewArticles';
import OverviewIntro from '../OverviewIntro';
import OverviewPromo from '../OverviewPromo';

import styles from './styles.scss';

const OverviewDashboardTrial = () => {
  const dispatch = useDispatch();
  const accessMethod = useSelector((state) =>
    state.getIn(['auth', 'accessMethod'])
  );

  const withIcon = showExternalLinkIcon(accessMethod);

  const onClick = () => {
    dispatch(trackContactSales('overview dashboard'));

    window.open('https://www.edume.com/book-a-meeting', '_blank');
  };

  return (
    <div styleName='grid' data-automation='overview-dashboard'>
      <div styleName='content'>
        <OverviewIntro />
      </div>
      <div styleName='content reset-padding'>
        <OverviewPromo
          titleKey='Overview.promo.title'
          descriptionKey='Overview.promo.description'
          textPosition='right'
          theme='light'
          backgroundImageUrl='/resources/img/overview/plg-hero.png'
          primaryButtonKey='Overview.promo.button.primary'
          primaryButtonAction={onClick}
          primaryButtonIcon={
            withIcon && <ExternalLinkIcon size='tiny' colour='white' />
          }
        />
      </div>
      <div styleName='side'>
        <OverviewArticles
          withIcon={withIcon}
          breakpoint='regular'
          isTrial={true}
        />
      </div>
    </div>
  );
};

export default CSSModules(OverviewDashboardTrial, styles, {
  allowMultiple: true,
});
