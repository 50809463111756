import React, {Suspense} from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';

import {LoadingIndicator} from '@edume/magnificent';

import Footer from '../../components/Footer';
import OnboardingModal from '../../components/OnboardingModal';
import CookiePopup from '../../containers/CookiePopup';
import HeaderView from '../HeaderView';

import styles from '../styles.scss';

export const AuthenticatedPage = ({
  children,
  noHeader,
  noFooter,
  showOnboarding,
}) => (
  <>
    {!noHeader && <HeaderView />}
    <Suspense
      fallback={
        <LoadingIndicator
          indicator='hexagons'
          size='large'
          containerStyle='centerAbsolute'
        />
      }
    >
      <div styleName='appContent' id='appContent' key='appContent'>
        {children}
      </div>
      {showOnboarding && <OnboardingModal />}
      <CookiePopup />
      {!noFooter && (
        <div styleName='footerContainer'>
          <Footer />
        </div>
      )}
    </Suspense>
  </>
);

AuthenticatedPage.propTypes = {
  children: PropTypes.node.isRequired,
  noHeader: PropTypes.bool,
  noFooter: PropTypes.bool,
  showOnboarding: PropTypes.bool,
};

export default CSSModules(AuthenticatedPage, styles, {allowMultiple: true});
