import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {LoadingIndicator} from '@edume/magnificent';

import {READY_TO_USE} from '../../../enums/courses';
import * as contentSelectors from '../../../state/content/contentSelector';
import {getResourceLibraryCourses} from '../../../state/course/courseActions';
import {HOME} from '../../../tracking/trackingProps';
import {injectFeatureFlags} from '../../../utils/featureFlags';
import {showExternalLinkIcon} from '../../../utils/integrations';
import OverviewArticles from '../OverviewArticles';
import OverviewBanner from '../OverviewBanner';
import OverviewInspiration from '../OverviewInspiration';
import OverviewRecentCourses from '../OverviewRecentCourses';
import StatBlock from '../StatBlock';

import styles from './styles.scss';

const OverviewDashboard = ({
  lessonsCompleted,
  activeUsersMonth,
  cacheDate,
  adminRoles,
  accessMethod,
  isTrial,
  loading,
  selectedGroupId,
  featureFlags,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {resourceLibraryTabEnabled} = featureFlags;

  const [loadingResourceLibraryCourses, setLoadingResourceLibraryCourses] =
    useState(false);

  const resourceLibraryCoursesLoaded = useSelector((state) =>
    state.getIn(['course', 'resourceLibraryCoursesLoaded'])
  );
  const courses = useSelector((state) => contentSelectors.getContents(state));
  const coursesLoaded = useSelector((state) =>
    state.getIn(['course', 'coursesLoaded'])
  );
  const resourceLibraryCourses = useSelector((state) =>
    state.getIn(['course', 'resourceLibraryCourses'])
  ).toJS();

  useEffect(() => {
    if (!loadingResourceLibraryCourses && !resourceLibraryCoursesLoaded) {
      setLoadingResourceLibraryCourses(true);
      dispatch(getResourceLibraryCourses());
    }
    if (resourceLibraryCoursesLoaded) {
      setLoadingResourceLibraryCourses(false);
    }
  }, [dispatch, loadingResourceLibraryCourses, resourceLibraryCoursesLoaded]);

  const resourceLibraryCoursesToShow = resourceLibraryCourses.filter(
    (c) => c.label === READY_TO_USE
  );
  const isResourceLibrarySectionVisible =
    resourceLibraryTabEnabled &&
    resourceLibraryCoursesLoaded &&
    resourceLibraryCoursesToShow.length > 0;
  const withIcon = showExternalLinkIcon(accessMethod);
  const createdCourses = courses.filter((c) => !c.isTemplate && !c.isSample);
  const unpublishedCourses = createdCourses.filter((c) => !c.published);
  const orderedCourses = unpublishedCourses.sort((a, b) => {
    if (a.updatedAt > b.updatedAt) {
      return -1;
    } else if (a.updatedAt < b.updatedAt) {
      return 1;
    } else {
      return 0;
    }
  });

  const isStatVisible = (stat) => {
    const permissions = adminRoles.isLead || adminRoles.isAnalyst;
    return permissions && stat > 0;
  };

  const goToReporting = () => {
    history.push('/reports' + location.search, HOME); // Source required by Amplitude
  };

  const isDataLoaded = coursesLoaded && !loading;

  if (!isDataLoaded) {
    return (
      <LoadingIndicator
        indicator='hexagons'
        containerStyle='centerAbsolute'
        size='large'
      />
    );
  }

  return (
    <div styleName='grid' data-automation='overview-dashboard'>
      <div styleName='content'>
        <OverviewBanner hasContent={createdCourses.length > 0} />
      </div>
      {orderedCourses.length > 0 && (
        <div styleName='content'>
          <OverviewRecentCourses courses={orderedCourses} />
        </div>
      )}
      {isResourceLibrarySectionVisible && (
        <div styleName='content'>
          <OverviewInspiration
            resourceLibraryCourses={resourceLibraryCoursesToShow}
            groupId={selectedGroupId}
          />
        </div>
      )}
      <div styleName='side'>
        <div styleName='stat-blocks'>
          <StatBlock
            statValue={activeUsersMonth}
            statKey='Overview.activeUsers.stat'
            showButton={isStatVisible(activeUsersMonth)}
            buttonKey='Overview.activeUsers.button'
            cacheDate={cacheDate}
            onClick={goToReporting}
          />
          <StatBlock
            statValue={lessonsCompleted}
            statKey='Overview.lessonsCompleted.stat'
            showButton={isStatVisible(lessonsCompleted)}
            buttonKey='Overview.lessonsCompleted.button'
            cacheDate={cacheDate}
            onClick={goToReporting}
          />
        </div>
        <OverviewArticles
          withIcon={withIcon}
          isTrial={isTrial}
          breakpoint='wide'
        />
      </div>
    </div>
  );
};

OverviewDashboard.propTypes = {
  lessonsCompleted: PropTypes.number.isRequired,
  activeUsersMonth: PropTypes.number.isRequired,
  cacheDate: PropTypes.string,
  adminRoles: PropTypes.object.isRequired,
  isTrial: PropTypes.bool.isRequired,
  accessMethod: PropTypes.string,
  loading: PropTypes.bool,
  selectedGroupId: PropTypes.number,
  featureFlags: PropTypes.object.isRequired,
};

export default injectFeatureFlags(
  CSSModules(OverviewDashboard, styles, {
    allowMultiple: true,
  })
);
