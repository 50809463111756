import React, {useState} from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {injectIntl} from 'react-intl';

import {HintBox, Input, Link, SelectableItem} from '@edume/magnificent';

import {integrationDisplayNames} from '../../../enums/integrations';
import {getTeamIconFromId} from '../../../utils/smartTeams';
import {LegacyIntlHeading} from '../../core';

import styles from './styles.scss';

// eslint-disable-next-line complexity
const SelectTeamsList = ({
  intl,
  teams,
  titleKey,
  setSelectedTeamIds,
  selectedTeamIds,
  hintKey,
  showUserCount,
  showIntegrationIcons,
  hideSearchBox,
  compact,
  truncateTeamNames,
  fullHeight,
  showSelectAll,
}) => {
  const [teamSearch, setTeamSearch] = useState('');

  const lower = (str) => str.toLowerCase();

  const filteredTeams = teams.filter(({name}) =>
    lower(name).includes(lower(teamSearch))
  );
  const filterTeamsIds = filteredTeams.map(({id}) => id);

  const toggleTeamSelection = (id) => {
    const isSelected = selectedTeamIds.includes(id);
    const updatedIds = isSelected
      ? selectedTeamIds.filter((teamId) => teamId !== id)
      : [...selectedTeamIds, id];

    setSelectedTeamIds(updatedIds);
  };

  const allSelected = [...filterTeamsIds].every((x) =>
    selectedTeamIds.includes(x)
  );

  const toggleSelectAll = () => {
    const updatedIds = allSelected
      ? selectedTeamIds.filter((id) => !filterTeamsIds.includes(id))
      : [...new Set([...selectedTeamIds, ...filterTeamsIds])];
    setSelectedTeamIds(updatedIds);
  };

  const getSecondaryText = (
    externalIntegrationType,
    numUsers,
    rightContent,
    id
  ) => {
    const withIntegrationIcon = showIntegrationIcons && externalIntegrationType;

    const withUserCount = showUserCount && !withIntegrationIcon;

    const integrationIcon = (
      <img
        src={`/resources/img/${externalIntegrationType}.svg`}
        styleName='integration-icon'
        alt={`${integrationDisplayNames[externalIntegrationType]} icon`}
      />
    );

    const userCountWithIcon = (
      <div styleName='team-icon-user-count'>
        <span styleName='people-icon'>
          {getTeamIconFromId(id, filteredTeams)}
        </span>
        <span styleName='num-users'>{`${numUsers}`}</span>
      </div>
    );

    return (
      <div styleName='secondary-content-container'>
        <div styleName='secondary-content'>
          {withIntegrationIcon && integrationIcon}
          {withUserCount && userCountWithIcon}
          {rightContent && (
            <span styleName='optional-content'>{rightContent}</span>
          )}
        </div>
      </div>
    );
  };

  const teamRows = filteredTeams.map(
    (
      {name, id, numUsers, externalIntegrationType, disabled, rightContent},
      index
    ) => (
      <SelectableItem
        key={index}
        id={index}
        text={name}
        onClick={() => toggleTeamSelection(id)}
        selected={selectedTeamIds?.includes(id)}
        size='single'
        dataAutomation='team-option'
        secondaryText={getSecondaryText(
          externalIntegrationType,
          numUsers,
          rightContent,
          id
        )}
        disabled={disabled}
        truncateText={truncateTeamNames}
      />
    )
  );

  return (
    <div styleName={classNames('team-selection', {fullHeight})}>
      {titleKey && (
        <LegacyIntlHeading size='micro' weight='medium' textKey={titleKey} />
      )}
      {hintKey && (
        <div styleName='hint-warning'>
          <HintBox
            type='warning'
            text={intl.formatMessage({
              id: hintKey,
            })}
          />
        </div>
      )}
      {!hideSearchBox && (
        <div styleName='team-search'>
          <Input
            type='search'
            value={teamSearch}
            onChangeValue={(v) => setTeamSearch(v)}
            includeBottomMargin={false}
            removeSpellcheck
            validationStyles='hide'
            searchClearText={intl.formatMessage({
              id: 'SelectGroup.search.clearText',
            })}
            onClearInput={() => setTeamSearch('')}
            dataAutomation='team-search-input'
            clearInputDataAutomation='clear-search-button'
            clearInputType='icon'
            placeholder={intl.formatMessage({
              id: 'KnowledgeHub.searchPlaceholder',
            })}
          />
        </div>
      )}

      {showSelectAll && teams.length !== 0 && (
        <div styleName='select-all-toggle'>
          <Link
            linkSize='snail'
            text={intl.formatMessage({
              id: `Link.${allSelected ? 'un' : ''}selectAll`,
            })}
            onClick={toggleSelectAll}
          />
        </div>
      )}

      {filteredTeams.length === 0 ? (
        <LegacyIntlHeading
          textKey='TeamsSelect.search.noResults'
          size='micro'
        />
      ) : (
        <div styleName={`team-list ${compact ? 'compact' : ''}`}>
          {teamRows}
        </div>
      )}
    </div>
  );
};
SelectTeamsList.propTypes = {
  intl: PropTypes.object.isRequired,
  titleKey: PropTypes.string,
  teams: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSelectedTeamIds: PropTypes.func,
  selectedTeamIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  hintKey: PropTypes.string,
  showUserCount: PropTypes.bool,
  showIntegrationIcons: PropTypes.bool,
  hideSearchBox: PropTypes.bool,
  compact: PropTypes.bool,
  truncateTeamNames: PropTypes.bool,
  fullHeight: PropTypes.bool,
  showSelectAll: PropTypes.bool,
};

export default injectIntl(
  CSSModules(SelectTeamsList, styles, {allowMultiple: true})
);
