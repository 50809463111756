import React, {Fragment} from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {useIntl} from 'react-intl';
import {useSelector} from 'react-redux';

import {auth} from '@edume/bento';
import {Heading, LegacyHeading} from '@edume/magnificent';

import {
  getGroupAccessType,
  getSelectedGroup,
} from '../../../state/team/teamSelectors';
import {getKnowledgeHubLink} from '../../../utils/links';
import {CopyLink, Info} from '../../core';
import QRCodeWithDownload from '../../QRCodeWithDownload';

import styles from '../styles.scss';

const HubLinks = ({
  teamIds,
  knowledgeHubId,
  knowledgeHubName,
  isSeamlessEnabled,
  areHubLinksDisabled,
  titleKey,
  children,
}) => {
  const intl = useIntl();

  const group = useSelector((state) => getSelectedGroup(state));
  const company = useSelector((state) => state.getIn(['auth', 'company']));
  const groupAccessType = useSelector((state) => getGroupAccessType(state));

  const linkArgs = {knowledgeHubId, groupOrTeamIds: teamIds};
  const otpLink =
    groupAccessType === auth.OTP
      ? getKnowledgeHubLink({
          type: auth.OTP,
          knowledgeHubId,
          groupOrTeamIds: group.get('id'),
        })
      : null;

  const hubLinks = [
    {
      type: 'seamless',
      link: getKnowledgeHubLink({type: 'seamless', ...linkArgs}),
      visible: isSeamlessEnabled,
    },
    {
      type: auth.SSU,
      link: getKnowledgeHubLink({type: auth.SSU, ...linkArgs}),
      visible: groupAccessType === auth.SSU,
    },
    {
      type: auth.SSO,
      link: getKnowledgeHubLink({type: auth.SSU, ...linkArgs}),
      visible: groupAccessType === auth.SSO,
    },
    {
      type: auth.OTP,
      link: otpLink,
      visible: groupAccessType === auth.OTP,
    },
  ];

  const renderLinks = hubLinks.map(({type, link, visible}) => {
    const linkHeader = intl.formatMessage({
      id: `Link.type.${type}.knowledgeHubTitle`,
    });

    if (visible) {
      return (
        <Fragment key={type}>
          <div styleName='link-heading'>
            <Heading as='h3' size='ant' weight='semiBold'>
              {linkHeader}
            </Heading>
            <Info
              tooltipKey={`Link.type.${type}.description`}
              dataKey={`kh-link-${type}`}
              iconType='?'
              iconLeft
              place='bottom'
              theme='dark'
            />
          </div>
          <CopyLink
            inputDataAutomation={`knowledge-hub-${type}-link-url`}
            copyButtonDataAutomation={`copy-knowledge-hub-${type}-link-button`}
            link={link}
            disabled={areHubLinksDisabled}
          />
        </Fragment>
      );
    }
    return null;
  });

  const renderOtpQrCode = groupAccessType === auth.OTP && (
    <div styleName='qr-code-container'>
      <QRCodeWithDownload
        qrCodeValue={otpLink}
        contentTitle={knowledgeHubName}
        teamName={group.get('name')}
        logo={company.darkLogoUrl}
      />
    </div>
  );

  return (
    <>
      {titleKey && (
        <div styleName='secondary-text'>
          <LegacyHeading size='micro'>
            {intl.formatMessage({id: titleKey})}
          </LegacyHeading>
        </div>
      )}
      {children}
      <div>{renderLinks}</div>
      {renderOtpQrCode}
    </>
  );
};

HubLinks.propTypes = {
  teamIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  knowledgeHubId: PropTypes.number.isRequired,
  knowledgeHubName: PropTypes.string.isRequired,
  isSeamlessEnabled: PropTypes.bool,
  areHubLinksDisabled: PropTypes.bool,
  titleKey: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default CSSModules(HubLinks, styles);
