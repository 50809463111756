import React, {ReactElement, ReactNode} from 'react';

import {HeadingTag} from './Heading/index';

export const textWeights = [
  'regular',
  'medium',
  'semiBold',
  'bold',
  'extraBold',
] as const;
export type TextWeights = (typeof textWeights)[number];

export const textTransforms = [
  'uppercase',
  'lowercase',
  'capitalize',
  'none',
] as const;
export type TextTransforms = (typeof textTransforms)[number];

export const textColours = [
  'red',
  'white',
  'blue',
  'green',
  'textPrimary',
  'textSecondary',
  'primary',
  'purple300',
  'purple700',
  'grey050',
  'grey300',
  'grey700',
  'grey900',
  'blue300',
  'blue500',
  'blue700',
  'inherit',
] as const;
export type TextColours = (typeof textColours)[number];

export const renderText = ({
  children,
  tag,
  styleName,
  maxLines,
  id,
  style,
  dataAutomation,
}: {
  children: ReactNode;
  tag: HeadingTag | 'span' | 'p';
  styleName: string;
  maxLines?: number;
  id?: string;
  style?: React.CSSProperties;
  dataAutomation?: string;
}) => {
  const Tag = tag;

  const newStyle = {...style};
  if (maxLines) {
    newStyle.WebkitBoxOrient = 'vertical';
    newStyle.WebkitLineClamp = maxLines;
    newStyle.display = '-webkit-box';
    newStyle.overflow = 'hidden';
  }

  const Component = (
    <Tag
      styleName={styleName}
      data-automation={dataAutomation}
      id={id}
      style={newStyle}
    >
      {children}
    </Tag>
  );

  const addWrapper = (component: ReactElement) =>
    maxLines ? <div>{component}</div> : component;

  return addWrapper(Component);
};
