import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {injectIntl} from 'react-intl';

import {ExternalLinkIcon, LegacyHeading} from '@edume/magnificent';

import {showExternalLinkIcon} from '../../../../utils/integrations';

import styles from './styles.scss';

const HeaderHelpCentre = ({viewHelpCenter, intl, accessMethod}) => (
  <button
    styleName='settingsContainer'
    onClick={() => {
      viewHelpCenter();
      window.open('https://help.edume.com/', '_blank');
    }}
  >
    <div styleName='helpCenter'>
      <img
        styleName='linkImg'
        src='/resources/img/24_question_mark_circle_filled.svg'
      />
      <LegacyHeading colour='white' size='micro' weight='medium'>
        {intl.formatMessage({
          id: 'Header.helpCentreLink',
        })}
      </LegacyHeading>
      {showExternalLinkIcon(accessMethod) && (
        <div styleName='external-link-container'>
          <ExternalLinkIcon size='tiny' colour='black' />
        </div>
      )}
    </div>
  </button>
);

HeaderHelpCentre.propTypes = {
  viewHelpCenter: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  accessMethod: PropTypes.string,
};

export default injectIntl(
  CSSModules(HeaderHelpCentre, styles, {allowMultiple: true})
);
