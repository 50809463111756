import React, {useState} from 'react';

import CSSModules from 'react-css-modules';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {Button} from '@edume/magnificent';

import {MS_TEAMS} from '../../../enums/integrations';
import {updateUser} from '../../../state/auth/authActions';
import {completeTrialFormStepOne} from '../../../state/trial/trialActions';
import CompanyName from '../CompanyName';
import FormLabel from '../FormLabel';
import Password from '../Password';
import PreferredEmail from '../PreferredEmail';
import UserName from '../UserName';

import styles from './styles.scss';

const FormStepOne = () => {
  const dispatch = useDispatch();

  const firstName = useSelector((state) => state.getIn(['auth', 'firstName']));
  const onSaveFirstName = (name) => dispatch(updateUser({firstName: name}));

  const lastName = useSelector((state) => state.getIn(['auth', 'lastName']));
  const onSaveLastName = (name) => dispatch(updateUser({lastName: name}));

  const companyName = useSelector((state) =>
    state.getIn(['auth', 'company'])
  )?.officialName;

  const isMsTeams =
    useSelector((state) => state.getIn(['auth', 'accessMethod'])) === MS_TEAMS;

  const isBeekeeper = useSelector((state) =>
    state.getIn(['trial', 'isBeekeeper'])
  );

  const initialState = {
    firstName: !!firstName,
    lastName: !!lastName,
    password: isMsTeams,
    companyName: !!companyName,
    preferredEmail: true,
  };

  const [isFormValid, setIsFormValid] = useState(initialState);
  const [isForcingValidation, setIsForcingValidation] = useState(false);

  /** @param {keyof typeof initialState} field */
  const updateValidity = (field) => (status) =>
    setIsFormValid((prevState) => ({...prevState, [field]: status}));

  const history = useHistory();
  const onSubmit = () => {
    const isButtonLive = Object.values(isFormValid).every((value) => value);

    if (isBeekeeper) {
      dispatch(completeTrialFormStepOne());
      history.push('/settings/integrations/beekeeper');
    } else if (isButtonLive) {
      dispatch(completeTrialFormStepOne());
      history.push('/settings/branding/company-branding');
    } else {
      // show validation for all fields
      setIsForcingValidation(true);
    }
  };

  const intl = useIntl();
  const formatKey = (id) => intl.formatMessage({id});
  const firstNamePlaceholder = formatKey('Trial.firstName.placeholder');
  const lastNamePlaceholder = formatKey('Trial.lastName.placeholder');
  const buttonText = formatKey('Trial.submitButton');

  const renderPasswordOrEmail = () =>
    isMsTeams ? (
      <div>
        <FormLabel type='preferredEmail' />
        <PreferredEmail setIsFieldValid={updateValidity('preferredEmail')} />
      </div>
    ) : (
      <div>
        <FormLabel type='password' />
        <Password
          setIsFieldValid={updateValidity('password')}
          isForcingValidation={isForcingValidation}
        />
      </div>
    );

  return (
    <div styleName='wrapper'>
      <div styleName='input-wrapper'>
        <div>
          <FormLabel type='firstName' />
          <UserName
            value={firstName}
            placeholder={firstNamePlaceholder}
            onSave={onSaveFirstName}
            setIsFieldValid={updateValidity('firstName')}
            isForcingValidation={isForcingValidation}
            dataAutomation='trial-first-name-input'
          />
        </div>
        <div>
          <FormLabel type='lastName' />
          <UserName
            value={lastName}
            placeholder={lastNamePlaceholder}
            onSave={onSaveLastName}
            setIsFieldValid={updateValidity('lastName')}
            isForcingValidation={isForcingValidation}
            dataAutomation='trial-last-name-input'
          />
        </div>
        <div>
          <FormLabel type='company' />
          <CompanyName
            value={companyName}
            setIsFieldValid={updateValidity('companyName')}
            isForcingValidation={isForcingValidation}
            isMsTeams={isMsTeams}
          />
        </div>
        {renderPasswordOrEmail()}
      </div>
      <div styleName='submit-button'>
        <Button
          type='primary'
          dataAutomation='trial-next-button'
          onClick={onSubmit}
          noMarginTop
          noMarginLeft
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
};
export default CSSModules(FormStepOne, styles, {allowMultiple: true});
