import {auth} from '@edume/bento';

// note these are only primitive mockeries of selectors, as described in the redux docs:
//  http://redux.js.org/docs/recipes/ComputingDerivedData.html
// but they do the trick, for now

const getGroupById = (groups, id) =>
  groups.find((group) => group.get('id') === id);

export const getSelectedGroup = (state) => {
  const groups = state.getIn(['team', 'groups']);
  const selectedGroupId = state.getIn(['team', 'groupId']);
  return getGroupById(groups, selectedGroupId);
};

export const getSelectedGroupId = (state) => state.getIn(['team', 'groupId']);

export const getTeams = (state) => {
  const selectedGroup = getSelectedGroup(state);
  return selectedGroup ? selectedGroup.get('children').toJS() : [];
};

export const getSelectedTeamId = (state) => state.getIn(['team', 'teamId']);

export const getSelectedTeam = (state) => {
  const selectedId = getSelectedTeamId(state);
  return getTeams(state).find((team) => team.id === selectedId);
};

export const getIntegratedTeams = (state) => {
  const integrations = state.getIn(['team', 'integrations']).toJS();

  return integrations.flatMap(({type, linkedTeams}) =>
    linkedTeams.map((team) => ({
      ...team,
      integrationType: type,
    }))
  );
};

/**
 * @param {any} state
 * @returns {typeof auth.OTP |typeof  auth.SSO | typeof auth.SSU | null}
 */
export const getGroupAccessType = (state) => {
  const selectedGroup = getSelectedGroup(state);
  return selectedGroup
    ? getSelectedGroup(state).getIn(['accessMethod', 'type'])
    : null;
};

export const getIsSsuSsoEnabled = (groupAccessType) =>
  groupAccessType === auth.SSO || groupAccessType === auth.SSU;
