import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {useIntl} from 'react-intl';
import AutoSizer from 'react-virtualized-auto-sizer';
import {FixedSizeList} from 'react-window';

import {Input, SlidePanel, Text} from '@edume/magnificent';

import AdminContentCard from '../../core/AdminContentCard';
import {getItemStyle} from '../../KnowledgeHub/KnowledgeHubContentList';

import AdminContentCardStyles from '../../core/AdminContentCard/styles.scss';
import styles from './styles.scss';

const ROW_MARGIN = 16;
const CARD_ITEM_HEIGHT = +AdminContentCardStyles.cardHeight + ROW_MARGIN;

const ContentListSlidePanel = ({
  contentList,
  assignedContentIds,
  addContent,
  removeContent,
  dataAutomation,
  setShowContentListSlidePanel,
}) => {
  const intl = useIntl();
  const [searchContentValue, setSearchContentValue] = React.useState('');

  const filteredContentList = contentList.filter((content) =>
    content.title.toLowerCase().includes(searchContentValue.toLowerCase())
  );

  const renderContentList = () => {
    if (filteredContentList.length < 1 && searchContentValue) {
      return (
        <Text size='m'>
          {intl.formatMessage({
            id: 'KnowledgeHub.editPanel.search.noResults',
          })}
        </Text>
      );
    }

    return (
      <div
        styleName='content-list-inner-container'
        data-automation={dataAutomation}
      >
        <AutoSizer defaultHeight={CARD_ITEM_HEIGHT} defaultWidth={500}>
          {({width, height}) => (
            <FixedSizeList
              itemCount={filteredContentList.length}
              itemSize={CARD_ITEM_HEIGHT}
              width={width}
              height={height}
              style={{
                scrollbarGutter: 'stable',
                paddingBottom: 20,
              }}
            >
              {({index, style}) => (
                <div key={index} style={getItemStyle({style})}>
                  <AdminContentCard
                    key={filteredContentList[index].id}
                    content={{
                      ...filteredContentList[index],
                      isAssignedContent: !!assignedContentIds.find(
                        (contentId) =>
                          contentId === filteredContentList[index].id
                      ),
                    }}
                    addContent={addContent}
                    removeContent={removeContent}
                  />
                </div>
              )}
            </FixedSizeList>
          )}
        </AutoSizer>
      </div>
    );
  };

  return (
    <SlidePanel
      title={intl.formatMessage({id: 'Learning.collections.manageContent'})}
      onClose={() => setShowContentListSlidePanel(false)}
      overflowScroll={false}
    >
      <div styleName='search-input'>
        <Input
          type='search'
          placeholder={intl.formatMessage({
            id: 'KnowledgeHub.editPanel.searchContentPlaceholder',
          })}
          value={searchContentValue}
          onChangeValue={setSearchContentValue}
          onClearInput={() => setSearchContentValue('')}
          clearInputType='icon'
          dataAutomation='content-search-input'
          autoFocus
        />
      </div>
      {renderContentList()}
    </SlidePanel>
  );
};

ContentListSlidePanel.propTypes = {
  contentList: PropTypes.array.isRequired,
  assignedContentIds: PropTypes.array.isRequired,
  dataAutomation: PropTypes.string,
  addContent: PropTypes.func,
  removeContent: PropTypes.func,
  setShowContentListSlidePanel: PropTypes.func.isRequired,
};

export default CSSModules(ContentListSlidePanel, styles);
