import React, {useState} from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';

import {Button, Input, Well} from '@edume/magnificent';

import FormWrapper from '../../../containers/FormWrapper';
import {StyledModal} from '../../../containers/StyledModals';
import {COMPANY, GROUP, TEAM} from '../../../enums/adminLevels';
import addFormState from '../../../hocs/addFormState';
import useValidation from '../../../hooks/useValidation';
import {Dialog, FocusElement, LegacyIntlHeading} from '../../core';
import SelectRole from './SelectRole';

import styles from './styles.scss';

const connector = connect((state) => ({
  editAdminInProgress: state.getIn(['admin', 'editAdminInProgress']),
}));

const AddEditAdminOld = ({
  selectedAdmin,
  onSave,
  onClose,
  deleteAdmin,
  groupId,
  level,
  hideRoleSelection,
  intl,
  editAdminInProgress,
  formError,
  resetFormError,
  onShowDeleteModal,
  onHideDeleteModal,
}) => {
  const [adminProperties, setAdminProperties] = useState(() => {
    const admin = selectedAdmin || {};
    const {
      id = null,
      activated = false,
      firstName = '',
      lastName = '',
      email = '',
    } = admin;
    return {id, activated, firstName, lastName, email};
  });

  const [
    isFirstNameValid,
    isFirstNameValidDisplay,
    unsetForceFirstNameValidDisplay,
  ] = useValidation(adminProperties.firstName, 'name');
  const [
    isLastNameValid,
    isLastNameValidDisplay,
    unsetForceLastNameValidDisplay,
  ] = useValidation(adminProperties.lastName, 'name');
  const [isEmailValid, isEmailValidDisplay, unsetForceEmailValidDisplay] =
    useValidation(adminProperties.email, 'email');

  const unsetForceValidForm = () => {
    unsetForceFirstNameValidDisplay();
    unsetForceLastNameValidDisplay();
    unsetForceEmailValidDisplay();
  };

  const [roles, setRoles] = useState(() => {
    const admin = selectedAdmin || {};
    const {isAnalyst = false, isEditor = false, isManager = false} = admin;
    const isLead = (isAnalyst && isEditor && isManager) || level === TEAM;
    return {
      isLead,
      isAnalyst: isAnalyst && !isLead,
      isEditor: isEditor && !isLead,
      isManager: isManager && !isLead,
    };
  });

  const [showDeleteAdminDialog, setShowDeleteAdminDialog] = useState(false);
  const [hasNoRoleSelected, setHasNoRoleSelected] = useState(false);

  const updateAdminProperty = (property, value) => {
    if (property === 'email') {
      resetFormError();
    }
    setAdminProperties((admin) => ({...admin, [property]: value}));
  };

  const updateRoles = (newRoles, hasNoneSelected) => {
    setRoles(newRoles);
    setHasNoRoleSelected(hasNoneSelected);
  };

  const toggleDeleteAdminDialog = () => {
    setShowDeleteAdminDialog((wasShowing) => {
      if (wasShowing) {
        onHideDeleteModal();
      } else {
        onShowDeleteModal();
      }
      return !wasShowing;
    });
  };

  const deleteAdminAndClose = () => {
    setShowDeleteAdminDialog(false);
    deleteAdmin(selectedAdmin);
    onHideDeleteModal();
    onClose();
  };

  const isSelectingNoRoles = () => {
    const {isLead, isAnalyst, isEditor, isManager} = roles;
    return (
      !(isLead || isAnalyst || isEditor || isManager) && !hideRoleSelection
    );
  };

  // eslint-disable-next-line complexity
  const isFormInvalid = () => {
    const {email, firstName, lastName} = adminProperties;
    const invalid = !isFirstNameValid || !isLastNameValid || !isEmailValid;
    const empty = email === '' || firstName === '' || lastName === '';
    const noRole = isSelectingNoRoles();
    return (
      formError !== null || invalid || empty || noRole || editAdminInProgress
    );
  };

  const submit = () => {
    const {id, firstName, lastName, email} = adminProperties;
    let finalRoles = _.omit(roles, 'isLead');
    if (roles.isLead || hideRoleSelection) {
      finalRoles = {isAnalyst: true, isEditor: true, isManager: true};
    }

    const admin = {
      firstName,
      email,
      ...finalRoles,
    };
    if (lastName !== '') {
      admin.lastName = lastName;
    }
    if (level !== COMPANY && groupId && !id) {
      admin.groupId = groupId;
    }
    updateRoles(roles, isSelectingNoRoles());
    unsetForceValidForm();
    if (!isFormInvalid()) {
      onSave(admin, id);
    }
  };

  const {activated, firstName, lastName, email} = adminProperties;

  const intlKey = selectedAdmin ? 'Admin.edit.' : 'Admin.add.';
  const addHeaderKey = intlKey + level + 'AdminHeader';
  const firstNamePlaceholder = intl.formatMessage({
    id: intlKey + 'firstNamePlaceholder',
  });
  const firstNameFormatError = intl.formatMessage({
    id: intlKey + 'firstNameFormatError',
  });
  const lastNamePlaceholder = intl.formatMessage({
    id: intlKey + 'lastNamePlaceholder',
  });
  const lastNameFormatError = intl.formatMessage({
    id: intlKey + 'lastNameFormatError',
  });
  const emailPlaceholder = intl.formatMessage({
    id: intlKey + 'emailPlaceholder',
  });
  const emailFormatError = intl.formatMessage({
    id: intlKey + 'emailFormatError',
  });
  const hasNoRoleSelectedError = intl.formatMessage({
    id: intlKey + 'selectRoleMissing',
  });

  const body = () => (
    <Well type='active' wellKey='addAdmin' onClick={() => {}}>
      <LegacyIntlHeading size='mini' colour='grey700' textKey={addHeaderKey} />
      <div styleName='inlineContainer'>
        <div styleName='inputContainer'>
          <Input
            type='text'
            value={firstName}
            inputKey='adminFirst'
            onChangeValue={(name) => updateAdminProperty('firstName', name)}
            onEnterKey={submit}
            errorText={firstNameFormatError}
            placeholder={firstNamePlaceholder}
            isInvalid={!isFirstNameValidDisplay}
            includeBottomMargin={false}
            width='fullWidth'
            dataAutomation='first-name-input'
          />
        </div>
        <div styleName='inputContainer'>
          <Input
            type='text'
            value={lastName}
            inputKey='adminLast'
            onChangeValue={(name) => updateAdminProperty('lastName', name)}
            onEnterKey={submit}
            errorText={lastNameFormatError}
            placeholder={lastNamePlaceholder}
            isInvalid={!isLastNameValidDisplay}
            includeBottomMargin={false}
            width='fullWidth'
            dataAutomation='last-name-input'
          />
        </div>
      </div>

      <div styleName='inputContainer'>
        <Input
          type='email'
          value={email}
          inputKey='adminEmail'
          onChangeValue={(e) => updateAdminProperty('email', e)}
          onEnterKey={submit}
          errorText={emailFormatError}
          placeholder={emailPlaceholder}
          isInvalid={!isEmailValidDisplay || formError === 'email_in_use'}
          includeBottomMargin={false}
          width='fullWidth'
          dataAutomation='email-input'
        />
        {!activated && (
          <span styleName='emailCaption'>
            <FormattedMessage id={intlKey + 'emailCaption'} />
          </span>
        )}
      </div>

      {!hideRoleSelection && (
        <SelectRole
          level={level}
          selected={roles}
          updateRoles={updateRoles}
          isInvalid={hasNoRoleSelected}
          errorText={hasNoRoleSelectedError}
        />
      )}
      <div styleName='buttonContainer'>
        {selectedAdmin && (
          <div styleName='buttonsLeft'>
            <Button
              type='danger'
              onClick={toggleDeleteAdminDialog}
              noMarginLeft={true}
            >
              <FormattedMessage id='Admin.delete.btn' />
            </Button>
          </div>
        )}
        <div styleName='buttonsRight'>
          <Button type='secondary' onClick={onClose}>
            <FormattedMessage id={intlKey + 'cancel'} />
          </Button>
          <Button
            type='primary'
            onClick={submit}
            dataAutomation='create-admin-button'
          >
            <FormattedMessage id={intlKey + 'createButton'} />
          </Button>
        </div>
      </div>
      {selectedAdmin && showDeleteAdminDialog && (
        <Dialog
          titleKey='Admin.delete.title'
          titleValue={{name: selectedAdmin.name}}
          descriptionKey='Admin.delete.desc'
          hintBoxKey='Admin.delete.wellWarning'
          onCancel={toggleDeleteAdminDialog}
          onConfirm={deleteAdminAndClose}
          cancelKey='Button.cancel'
          confirmKey='Admin.delete.btn'
        />
      )}
    </Well>
  );
  return (
    <FormWrapper onClose={onClose}>
      <FocusElement fullScreen={false} background='light' zIndex={3}>
        <StyledModal content={{body: CSSModules(body, styles)()}} />
      </FocusElement>
    </FormWrapper>
  );
};

AddEditAdminOld.propTypes = {
  selectedAdmin: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  deleteAdmin: PropTypes.func,
  groupId: PropTypes.number,
  level: PropTypes.oneOf([COMPANY, GROUP, TEAM]).isRequired,
  hideRoleSelection: PropTypes.bool.isRequired,
  formError: PropTypes.string,
  resetFormError: PropTypes.func.isRequired,
  onShowDeleteModal: PropTypes.func,
  onHideDeleteModal: PropTypes.func,
  //from wrappers
  intl: PropTypes.object.isRequired,
  editAdminInProgress: PropTypes.bool.isRequired,
};

export default injectIntl(addFormState(connector(AddEditAdminOld)));
