import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {withRouter} from 'react-router-dom';

import {ExternalLinkIcon, LoadingIndicator} from '@edume/magnificent';

import {trackingActionTypes} from '../../../state/tracking/trackingActions';
import {HOME} from '../../../tracking/trackingProps';
import {showExternalLinkIcon} from '../../../utils/integrations';
import PromoBlock from '../PromoBlock';
import StatBlock from '../StatBlock';

import styles from './styles.scss';

const OverviewGrid = ({
  loaded,
  lessonsCompleted,
  activeUsersMonth,
  adminRoles,
  track,
  history,
  location,
  cacheDate,
  accessMethod,
}) => {
  const goToReporting = () => {
    history.push('/reports' + location.search, HOME); // Source required by Amplitude
  };

  const getStatsContent = () => {
    const canSeeStats = (stat) => {
      const permissions = adminRoles.isLead || adminRoles.isAnalyst;
      return permissions && stat > 0;
    };

    return (
      <div styleName='statContainer'>
        <StatBlock
          statValue={activeUsersMonth}
          statKey='Overview.activeUsers.stat'
          showButton={canSeeStats(activeUsersMonth)}
          buttonKey='Overview.activeUsers.button'
          cacheDate={cacheDate}
          onClick={goToReporting}
        />
        <StatBlock
          statValue={lessonsCompleted}
          statKey='Overview.lessonsCompleted.stat'
          showButton={canSeeStats(lessonsCompleted)}
          buttonKey='Overview.lessonsCompleted.button'
          cacheDate={cacheDate}
          onClick={goToReporting}
        />
        <PromoBlock
          iconName='promo-guide'
          headingKey='Overview.promo.heading'
          bodyKey='Overview.promo.body'
          buttonKey='Overview.promo.button'
          onClick={() => {
            window.open('https://help.edume.com/inspiration-library', '_blank');
            track(trackingActionTypes.VIEWED_CONTENT_CREATION_GUIDE);
          }}
          buttonIcon={
            showExternalLinkIcon(accessMethod) ? (
              <ExternalLinkIcon size='tiny' colour='brandPrimary' />
            ) : null
          }
        />
      </div>
    );
  };

  if (!loaded) {
    return (
      <LoadingIndicator
        indicator='hexagons'
        size='large'
        containerStyle='centerAbsolute'
      />
    );
  }

  return (
    <div styleName='gridWrapper'>
      <div styleName='columnContainer'>{getStatsContent()}</div>
    </div>
  );
};

OverviewGrid.propTypes = {
  loaded: PropTypes.bool.isRequired,
  lessonsCompleted: PropTypes.number.isRequired,
  activeUsersMonth: PropTypes.number.isRequired,
  cacheDate: PropTypes.string,

  adminRoles: PropTypes.object.isRequired,
  track: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  accessMethod: PropTypes.string,
};

export default withRouter(
  CSSModules(OverviewGrid, styles, {allowMultiple: true})
);
