import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {injectIntl} from 'react-intl';

import {LegacyIntlHeading} from '../..';
import {StyledModal} from '../../../../containers/StyledModals';
import {formatRed} from '../../../../utils/messageFormatter';
import MarkdownHelpBox from './MarkdownHelpBox';

import styles from './styles.scss';

const MarkdownHelpModal = ({onClose, showHyperlinks, intl}) => {
  const introText = intl.formatMessage({id: 'Markdown.modal.intro'});
  const boldSource = intl.formatMessage({id: 'Markdown.modal.boldSource'});
  const italicSource = intl.formatMessage({id: 'Markdown.modal.italicSource'});
  const bulletedListSource = intl.formatMessage({
    id: 'Markdown.modal.bulletedListSource',
  });
  const numberedListSource = intl.formatMessage({
    id: 'Markdown.modal.numberedListSource',
  });
  const linksSource = intl.formatMessage({id: 'Markdown.modal.linksSource'});
  const lineBreakText = intl.formatMessage({id: 'Markdown.modal.lineBreakTip'});
  const emojiText = intl.formatMessage({id: 'Markdown.modal.emojiTip'});

  const modalBody = () => (
    <div styleName='modalBody'>
      <p>{formatRed(introText)}</p>
      <LegacyIntlHeading textKey='Markdown.modal.bold' size='mini-light' />
      <MarkdownHelpBox markdown={boldSource} showSource={true} />
      <LegacyIntlHeading textKey='Markdown.modal.italic' size='mini-light' />
      <MarkdownHelpBox markdown={italicSource} showSource={true} />
      <LegacyIntlHeading
        textKey='Markdown.modal.bulletedList'
        size='mini-light'
      />
      <MarkdownHelpBox markdown={bulletedListSource} showSource={true} />
      <LegacyIntlHeading
        textKey='Markdown.modal.numberedList'
        size='mini-light'
      />
      <MarkdownHelpBox markdown={numberedListSource} showSource={true} />
      {showHyperlinks ? (
        <div>
          <LegacyIntlHeading textKey='Markdown.modal.links' size='mini-light' />
          <MarkdownHelpBox markdown={linksSource} showSource={true} />
        </div>
      ) : null}
      <LegacyIntlHeading textKey='Markdown.modal.tips' size='mini-light' />
      <MarkdownHelpBox
        markdown={`${lineBreakText}\n\n&nbsp;\n\n${emojiText}`}
      />
    </div>
  );

  const content = {
    title: intl.formatMessage({id: 'Markdown.modal.title'}),
    body: CSSModules(modalBody, styles, {allowMultiple: true})(),
  };

  return (
    <StyledModal
      content={content}
      onClose={onClose}
      defaultCloseText={intl.formatMessage({id: 'Button.close'})}
    />
  );
};

MarkdownHelpModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  showHyperlinks: PropTypes.bool,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(MarkdownHelpModal);
