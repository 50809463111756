import {push} from 'connected-react-router';

import * as contentAssignmentTypes from '../../enums/contentAssignment';
import * as contentTypes from '../../enums/contentTypes';
import {VIEWED_PREVIEW_LINK} from '../course/courseActionTypes';
import * as knowledgeHubActionCreators from '../knowledgeHub/knowledgeHubActionCreators';
import {
  ADD_CONTENT_TO_KNOWLEDGE_HUB_SECTION,
  CLEAN_KNOWLEDGE_HUB_SECTION_CONTENT,
  REMOVE_CONTENT_FROM_KNOWLEDGE_HUB_SECTION,
  REORDER_KNOWLEDGE_HUB_SECTION_CONTENT,
  SET_KNOWLEDGE_HUB_ID,
  TOGGLE_KNOWLEDGE_HUB_NOTIFICATIONS_SLIDEPANEL,
} from '../knowledgeHub/knowledgeHubActionTypes';
import * as notificationActions from '../notification/notificationActions';
import {getSelectedGroup} from '../team/teamSelectors';
import {getSectionById} from './knowledgeHubSelector';

export const addKnowledgeHub =
  (groupId, teamIds, name, showsExternalContent) => (dispatch, getState) => {
    const state = getState();
    const existingHubs = state.getIn(['knowledgeHub', 'knowledgeHubs']).toJS();

    const knowledgeHubsManagementEnabled = state.getIn([
      'featureFlags',
      'customerFlags',
    ]).knowledgeHubsManagementEnabled;

    const arrayMatches = (arr1, arr2) =>
      arr1.length === arr2.length && arr1.every((item) => arr2.includes(item));

    const hubMatchingConfig = existingHubs.find(
      (kh) =>
        kh.showsExternalContent === showsExternalContent &&
        arrayMatches(kh.teams, teamIds)
    );

    const hubMatchingName = existingHubs.find(
      (kh) => kh.name.toLowerCase() === name.toLowerCase()
    );

    const hubMatchingBoth =
      hubMatchingConfig?.id === hubMatchingName?.id ? hubMatchingConfig : null;

    if (hubMatchingBoth) {
      return dispatch({
        type: SET_KNOWLEDGE_HUB_ID,
        payload: hubMatchingBoth.id,
      });
    }

    if (hubMatchingName) {
      return notificationActions.createErrorNotification(
        'KnowledgeHub.nameInputError'
      )(dispatch);
    }

    let actionPromise;

    if (hubMatchingConfig && !knowledgeHubsManagementEnabled) {
      actionPromise = dispatch(
        knowledgeHubActionCreators.editKnowledgeHub(
          hubMatchingConfig.id,
          name,
          showsExternalContent
        )
      );
    } else {
      const contentAssignment = knowledgeHubsManagementEnabled
        ? contentAssignmentTypes.MANUAL
        : contentAssignmentTypes.TEAMS;

      actionPromise = dispatch(
        knowledgeHubActionCreators.addKnowledgeHub(
          groupId,
          teamIds,
          name,
          showsExternalContent,
          contentAssignment
        )
      );
    }

    return actionPromise
      .then(({payload}) => {
        const messageKey = 'Learning.createKnowledgeHubSuccess';
        notificationActions.createSuccessNotification(messageKey)(dispatch);
        const {id, contentAssignment} = payload.data;
        if (contentAssignment === contentAssignmentTypes.MANUAL) {
          dispatch(push(`learning/kh/${id}`));
        }
      })
      .catch((error) => {
        notificationActions.createErrorNotificationFromResponse(error)(
          dispatch
        );
      });
  };

export const storeKnowledgeHubPreviewToken =
  (knowledgeHubId, token) => (dispatch) =>
    dispatch(
      knowledgeHubActionCreators.storeKnowledgeHubPreviewToken(
        knowledgeHubId,
        token
      )
    )
      .then(
        dispatch({
          type: VIEWED_PREVIEW_LINK,
          payload: {
            id: knowledgeHubId,
            type: 'knowledge hub',
          },
        })
      )
      .catch((error) => {
        notificationActions.createErrorNotificationFromResponse(error)(
          dispatch
        );
      });

export const loadKnowledgeHubs = () => (dispatch, getState) => {
  const selectedGroup = getSelectedGroup(getState());
  if (selectedGroup) {
    dispatch(
      knowledgeHubActionCreators.getGroupKnowledgeHubs(selectedGroup.get('id'))
    ).catch((error) => {
      notificationActions.createErrorNotificationFromResponse(error)(dispatch);
    });
  }
};

export const clearKnowledgeHubId = () => (dispatch) => {
  dispatch({
    type: SET_KNOWLEDGE_HUB_ID,
    payload: null,
  });
};

export const loadKnowledgeHubContent = (knowledgeHubId) => (dispatch) =>
  dispatch(
    knowledgeHubActionCreators.getKnowledgeHubContent(knowledgeHubId)
  ).catch((error) => {
    notificationActions.createErrorNotificationFromResponse(error)(dispatch);
  });

const cleanKnowledgeHubContent = (sectionId) => (dispatch, getState) => {
  const state = getState();
  const coursesLoaded = state.getIn(['course', 'coursesLoaded']);
  const guidesDsEnabled = state.getIn([
    'featureFlags',
    'customerFlags',
  ]).guidesDsEnabled;
  const guidesLoaded = state.getIn(['guide', 'guidesLoaded']);
  const contentLoaded = coursesLoaded && (guidesLoaded || !guidesDsEnabled);
  if (!contentLoaded) {
    return;
  }
  const getId = (content) => content.get('id');
  const courses = state.getIn(['course', 'courses']).map(getId);
  const guides = state.getIn(['guide', 'guides']).map(getId);
  const {content} = getSectionById(state, sectionId);

  const cleanedContent = content.filter(({id, contentType}) =>
    (contentType === contentTypes.GUIDE ? guides : courses).includes(id)
  );

  dispatch({
    type: CLEAN_KNOWLEDGE_HUB_SECTION_CONTENT,
    payload: {
      cleanedContent,
      sectionId,
    },
  });
};

const debounce = (func, wait) => {
  let timeout;

  return (...args) => {
    const delayedFunc = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(delayedFunc, wait);
  };
};

const updateKnowledgeHubSectionContent =
  ({sectionId}) =>
  (dispatch, getState) => {
    const state = getState();
    const {content, hasLastSaveFailed, knowledgeHubId} = getSectionById(
      state,
      sectionId
    );

    return dispatch(
      knowledgeHubActionCreators.updateKnowledgeHubSectionContent(
        sectionId,
        content
      )
    ).catch((error) => {
      dispatch(notificationActions.createErrorNotification('Error.generic'));

      if (error.response.data.errorCode === 'content_not_assigned_to_group') {
        dispatch(cleanKnowledgeHubContent(sectionId));
      }

      if (hasLastSaveFailed) {
        dispatch(loadKnowledgeHubContent(knowledgeHubId));
      }
    });
  };

const SAVE_DEBOUNCE_MS = 500;

const innerDebouncedUpdateKnowledgeHubSectionContentFunc = debounce(
  (dispatch, args) => dispatch(updateKnowledgeHubSectionContent(args)),
  SAVE_DEBOUNCE_MS
);
const debouncedUpdateKnowledgeHubSectionContent =
  (...args) =>
  (dispatch) =>
    innerDebouncedUpdateKnowledgeHubSectionContentFunc(dispatch, ...args);

const updateContentAndSave =
  ({sectionId, action}) =>
  (dispatch) => {
    dispatch(action);

    return dispatch(debouncedUpdateKnowledgeHubSectionContent({sectionId}));
  };

export const addContentToKnowledgeHubSection =
  (sectionId, {id, contentType}, position = 0) =>
  (dispatch) =>
    dispatch(
      updateContentAndSave({
        sectionId,
        action: {
          type: ADD_CONTENT_TO_KNOWLEDGE_HUB_SECTION,
          payload: {sectionId, content: {id, contentType}, position},
        },
      })
    );

export const removeContentFromKnowledgeHubSection =
  (sectionId, {id, contentType}) =>
  (dispatch) =>
    dispatch(
      updateContentAndSave({
        sectionId,
        action: {
          type: REMOVE_CONTENT_FROM_KNOWLEDGE_HUB_SECTION,
          payload: {sectionId, content: {id, contentType}},
        },
      })
    );

export const reorderKnowledgeHubSectionContent =
  (sectionId, oldIndex, newIndex) => (dispatch) =>
    dispatch(
      updateContentAndSave({
        sectionId,
        action: {
          type: REORDER_KNOWLEDGE_HUB_SECTION_CONTENT,
          payload: {sectionId, oldIndex, newIndex},
        },
      })
    );

export const deleteKnowledgeHub = (knowledgeHubId) => (dispatch) =>
  dispatch(knowledgeHubActionCreators.deleteKnowledgeHub(knowledgeHubId))
    .then(() => {
      const messageKey = 'KnowledgeHub.deleteKnowledgeHubSuccess';
      notificationActions.createSuccessNotification(messageKey)(dispatch);
    })
    .catch((error) => {
      notificationActions.createErrorNotificationFromResponse(error)(dispatch);
    });

export const addKnowledgeHubSection = (knowledgeHubId, title) => (dispatch) =>
  dispatch(
    knowledgeHubActionCreators.addKnowledgeHubSection(knowledgeHubId, title)
  ).catch((error) => {
    notificationActions.createErrorNotificationFromResponse(error)(dispatch);
  });

export const editKnowledgeHubSection =
  (knowledgeHubSectionId, title) => (dispatch) =>
    dispatch(
      knowledgeHubActionCreators.editKnowledgeHubSection(
        knowledgeHubSectionId,
        title
      )
    ).catch((error) => {
      notificationActions.createErrorNotificationFromResponse(error)(dispatch);
    });

export const updateTeamsInKnowledgeHubSection =
  (knowledgeHubSectionId, updatedTeamIds) => (dispatch) => {
    dispatch(
      knowledgeHubActionCreators.updateTeamsInKnowledgeHubSection(
        knowledgeHubSectionId,
        updatedTeamIds
      )
    ).catch(() => {
      dispatch(notificationActions.createErrorNotification('Error.generic'));
    });
  };

export const reorderKnowledgeHubSections =
  (knowledgeHubId, oldSectionIds, newSectionIds) => (dispatch) =>
    dispatch(
      knowledgeHubActionCreators.reorderKnowledgeHubSections(
        knowledgeHubId,
        oldSectionIds,
        newSectionIds
      )
    ).catch(() => {
      dispatch(notificationActions.createErrorNotification('Error.generic'));
    });

export const deleteKnowledgeHubSection =
  (knowledgeHubSectionId) => (dispatch) =>
    dispatch(
      knowledgeHubActionCreators.deleteKnowledgeHubSection(
        knowledgeHubSectionId
      )
    ).catch((error) => {
      notificationActions.createErrorNotificationFromResponse(error)(dispatch);
    });

export const toggleKnowledgeHubNotificationsSlidePanel =
  (payload) => (dispatch) =>
    dispatch({
      type: TOGGLE_KNOWLEDGE_HUB_NOTIFICATIONS_SLIDEPANEL,
      payload,
    });
