import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {useSelector} from 'react-redux';
import requiredIf from 'react-required-if';
import {withRouter} from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import {
  Button,
  ExternalLinkIcon,
  IconButton,
  PreviewIcon,
} from '@edume/magnificent';

import {
  COURSE,
  GUIDE,
  KNOWLEDGE_HUB,
  LESSON,
} from '../../../enums/contentTypes';
import {getInlinePreviewUrl, getPreviewLink} from '../../../utils/contentUtil';
import {showExternalLinkIcon} from '../../../utils/integrations';
import LegacyIntlHeading from '../LegacyIntlHeading';

import styles from './styles.scss';

const PreviewLinkButton = ({
  activityId,
  moduleId,
  disabled,
  courseId,
  knowledgeHubId,
  contentType = LESSON,
  guideId,
  guidePreviewToken,
  isIconOnly,
  isAssessment,
  showBorder = false,
  isLightTheme,
  previewInlineProps,
  intl,
  history,
  location,
}) => {
  const accessMethod = useSelector((state) =>
    state.getIn(['auth', 'accessMethod'])
  );
  const openPreviewLink = async (e) => {
    e.stopPropagation();
    if (previewInlineProps) {
      history.push({
        pathname: getInlinePreviewUrl(contentType, courseId || guideId),
        search: location.search,
        state: previewInlineProps.source,
      });
    } else {
      const previewLink = await getPreviewLink({
        contentType,
        courseId,
        moduleId,
        activityId,
        knowledgeHubId,
        guideId,
        guidePreviewToken,
      });
      window.open(previewLink, '_blank');
    }
  };

  const getFullTextKey = (key, hasAssessmentKey = false) => {
    if (isAssessment && hasAssessmentKey) {
      return `${key}.assessment`;
    }
    return `${key}.${contentType}`;
  };

  const commonProps = {
    icon: <PreviewIcon />,
    isLightTheme,
  };

  return isIconOnly ? (
    <IconButton
      {...commonProps}
      showBorder={showBorder}
      handleClick={openPreviewLink}
      tooltipProps={{
        text: intl.formatMessage({
          id: getFullTextKey('Authoring.label.previewLink'),
        }),
        backgroundColor: 'black',
        effect: 'solid',
        place: 'bottom',
      }}
      dataAutomation='preview-link-icon-button'
    />
  ) : (
    <div
      styleName={`generateLink${contentType === KNOWLEDGE_HUB ? '' : ' right'}`}
    >
      <span>
        <span
          data-tip
          data-for='previewTip'
          styleName={showExternalLinkIcon(accessMethod) ? 'with-link-icon' : ''}
        >
          <Button
            {...commonProps}
            type='secondary'
            disabled={disabled}
            onClick={openPreviewLink}
            noMarginTop
            noMarginLeft
            dataAutomation='preview-link-button'
          >
            <LegacyIntlHeading
              textKey={getFullTextKey('Authoring.label.previewLink', true)}
              weight='medium'
              size='micro'
            />
            {showExternalLinkIcon(accessMethod) && (
              <div styleName='icon-container'>
                <ExternalLinkIcon size='tiny' colour='brandPrimary' />
              </div>
            )}
          </Button>
        </span>
        {disabled ? (
          <ReactTooltip
            id='previewTip'
            place='bottom'
            className='overlay-tooltip arrow-bottom'
          >
            <LegacyIntlHeading
              textKey={getFullTextKey('Authoring.tooltip.previewLinkNotReady')}
              weight='medium'
              size='mini'
              colour='white'
            />
          </ReactTooltip>
        ) : null}
      </span>
    </div>
  );
};

PreviewLinkButton.propTypes = {
  activityId: requiredIf(
    PropTypes.number,
    (props) => props.contentType === LESSON
  ),
  moduleId: requiredIf(
    PropTypes.number,
    (props) => !props.disabled && [COURSE, LESSON].includes(props.contentType)
  ),
  contentType: PropTypes.oneOf([LESSON, COURSE, KNOWLEDGE_HUB, GUIDE]),
  isIconOnly: PropTypes.bool,
  isAssessment: PropTypes.bool,
  showBorder: PropTypes.bool,
  courseId: requiredIf(
    PropTypes.number,
    (props) => props.contentType === COURSE
  ),
  knowledgeHubId: requiredIf(
    PropTypes.number,
    (props) => props.contentType === KNOWLEDGE_HUB
  ),
  guideId: requiredIf(PropTypes.number, (props) => props.contentType === GUIDE),
  guidePreviewToken: requiredIf(
    PropTypes.string,
    (props) => props.contentType === GUIDE
  ),
  disabled: PropTypes.bool,
  isLightTheme: PropTypes.bool,
  previewInlineProps: PropTypes.object,
  intl: requiredIf(PropTypes.object, (props) => props.isIconOnly),
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(
  CSSModules(PreviewLinkButton, styles, {allowMultiple: true})
);
