import {fromJS} from 'immutable';

import {trials} from '@edume/bento';

import {MS_TEAMS} from '../../enums/integrations';
import {GENERATE_FOUNTAIN_KEYS_SUCCESS} from '../settings/settingsActionTypes';
import {
  ACTIVATE_USER,
  ACTIVATE_USER_FAIL,
  ACTIVATE_USER_SUCCESS,
  CHANGED_CREDENTIALS,
  CHECK_EMAIL_FAIL,
  CHECK_EMAIL_SUCCESS,
  CLOSE_ONBOARDING,
  CONTACT_INPUT_CHANGED,
  DELETED_USER,
  DISMISS_HINT_SUCCESS,
  ENABLE_ONBOARDING,
  EXPIRED_TOKEN_USED,
  INTEGRATION_CREATED,
  INTEGRATION_DELETED,
  INTEGRATION_UPDATED,
  INVALID_TOKEN_USED,
  INVITE_NOT_FOUND,
  LOAD_HINTS_SUCCESS,
  LOAD_INVITE_SUCCESS,
  LOGGED_IN,
  LOGGED_IN_MS_TEAMS,
  LOGGED_OUT,
  LOGIN_FAIL,
  LOGIN_FAILED_NOT_ADMIN,
  OLD_TOKEN_VERSION,
  RECORD_CREATE_COURSE,
  RECORD_INVITE_ADMIN,
  RESET_ENTERED_EMAIL,
  RESET_LOGIN_FORM,
  RESET_PASSWORD_DETAILS_REQUEST,
  RESET_PASSWORD_DETAILS_SUCCESS,
  RESET_PASSWORD_ERROR,
  SEND_PASSWORD_RESET_ERROR,
  START_LOGIN_FROM_COOKIE,
  UNDISMISS_HINT_SUCCESS,
  UPDATE_CUSTOMER,
  UPDATE_LANGUAGE,
  UPDATE_USER,
} from './authActionTypes';

export const initialState = fromJS({
  id: null,
  isAdmin: false,
  isAuthenticated: false,
  activationInProgress: false,
  authValidating: false,
  authValidated: false,
  activated: false,
  enteredEmail: false,
  enteredFailedLogin: false,
  failedLoginReason: '',
  invalidTokenLoaded: false,
  alreadyActivatedTokenLoaded: false,
  loggedInAs: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  company: {
    name: '',
    logoUrl: '',
    colorAccent: '#ff0000',
    colorPrimary: '#ff0000',
    colorPrimaryDark: '#ff0000',
    isTrial: false,
    trialEnd: null,
  },
  permissions: fromJS({}),
  showOnboarding: false,
  language: null,
  hidden: false,
  hasCreatedCourse: false,
  hasInvitedAdmins: false,
  hasSignedInAsLearner: false,
  resetContactInput: '',
  resetPasswordError: null,
  isInvalidInput: false,
  sendResetError: null,
  isValidResetUser: false,
  isLoadingResetUser: false,
  hints: [],
  isCompanyOwner: false,
  authType: null,
  ssoConfig: null,
  integrations: [],
  accessMethod: sessionStorage.getItem('accessMethod') || '',
  learnerGroups: [],
});

const updateUserDetails = (data, state) => {
  const {
    id,
    name,
    firstName,
    lastName,
    email,
    phone,
    employeeId,
    hidden,
    hasCreatedCourse,
    hasInvitedAdmins,
    hasSignedInAsLearner,
    isCompanyOwner,
    activationDate,
    createdAt,
  } = data;

  return state
    .set('id', id)
    .set('isAdmin', true)
    .set('isAuthenticated', true)
    .set('authValidating', false)
    .set('authValidated', true)
    .set('activated', true)
    .set('enteredFailedLogin', false)
    .set('loggedInAs', name)
    .set('firstName', firstName)
    .set('lastName', lastName)
    .set('email', email)
    .set('phone', phone)
    .set('employeeId', employeeId)
    .set('hidden', hidden)
    .set('hasCreatedCourse', hasCreatedCourse)
    .set('hasInvitedAdmins', hasInvitedAdmins)
    .set('hasSignedInAsLearner', hasSignedInAsLearner)
    .set('isCompanyOwner', isCompanyOwner)
    .set('activationDate', activationDate)
    .set('createdAt', createdAt);
};

const logOut = () => initialState.set('authValidated', true);

const failLogin = (prevState, reason) =>
  logOut()
    .set('enteredFailedLogin', !reason.includes('token'))
    .set('failedLoginReason', reason)
    // Preserve position in login form
    .set('email', prevState.get('email'))
    .set('enteredEmail', !reason.includes('token'))
    .set('authType', prevState.get('authType'));

export const reducer = (state = initialState, action) => {
  let updated = activationReducer(state, action);
  updated = authReducer(updated, action);
  updated = languageReducer(updated, action);
  return updated;
};

const languageReducer = (state, action) => {
  switch (action.type) {
    case LOGGED_IN: {
      return state.set('language', action.payload.language);
    }
    case UPDATE_LANGUAGE: {
      //set optimistically, don't wait for backend confirmation
      return state.set('language', action.meta.languageCode);
    }
    case RESET_PASSWORD_DETAILS_SUCCESS: {
      return state.set('language', action.payload.data.language);
    }
    default: {
      return state;
    }
  }
};

// eslint-disable-next-line complexity
const activationReducer = (state, action) => {
  //note that some stuff (permissions etc) is NOT sent when activating; this handles everything else
  const loadSharedStateFromAuthAction = () =>
    state
      .set('isAuthenticated', true)
      .set('authValidated', true)
      .set('authValidating', false)
      .set('enteredFailedLogin', false)
      .set('id', action.payload.data.id)
      .set('isAdmin', action.payload.data.isAdmin)
      .set('activated', action.payload.data.activated)
      .set('loggedInAs', action.payload.data.name)
      .set('firstName', action.payload.data.firstName)
      .set('lastName', action.payload.data.lastName)
      .set('email', action.payload.data.email)
      .set('hidden', action.payload.data.hidden)
      .set('activationDate', action.payload.data.activationDate)
      .set('createdAt', action.payload.data.createdAt);

  switch (action.type) {
    case LOAD_INVITE_SUCCESS: {
      return loadSharedStateFromAuthAction()
        .set('language', action.payload.data.language)
        .set('company', action.payload.data.company)
        .set('permissions', fromJS(action.payload.data.permissions))
        .set('language', action.payload.data.language)
        .set('hidden', action.payload.data.hidden)
        .set('hasCreatedCourse', action.payload.hasCreatedCourse)
        .set('hasInvitedAdmins', action.payload.hasInvitedAdmins)
        .set('hasSignedInAsLearner', action.payload.hasSignedInAsLearner)
        .set('ssoConfig', action.payload.data.ssoConfig);
    }
    case INVITE_NOT_FOUND: {
      const errorCode = action.payload;
      if (errorCode === 'user_already_activated') {
        return state.set('alreadyActivatedTokenLoaded', true);
      } else {
        return state.set('invalidTokenLoaded', true);
      }
    }
    case ACTIVATE_USER: {
      return state.set('activationInProgress', true);
    }
    case ACTIVATE_USER_SUCCESS: {
      const newState = state
        .set('activationInProgress', false)
        .set('integrations', action.payload.data?.integrations || []);

      const isTrial = state.get('company').isTrial;
      if (!isTrial) {
        return newState.set('showOnboarding', true);
      } else {
        return newState;
      }
    }
    case ACTIVATE_USER_FAIL: {
      return state.set('activationInProgress', false);
    }
    case CLOSE_ONBOARDING: {
      return state.set('showOnboarding', false);
    }
    case ENABLE_ONBOARDING: {
      return state.set('showOnboarding', true);
    }
    default: {
      return state;
    }
  }
};

// eslint-disable-next-line complexity, max-statements
const authReducer = (state, action) => {
  switch (action.type) {
    case RESET_LOGIN_FORM:
      return initialState;
    case START_LOGIN_FROM_COOKIE: {
      return state
        .set('isAuthenticated', false)
        .set('isAdmin', true)
        .set('activated', true)
        .set('authValidating', true)
        .set('authValidated', false);
    }
    case CHECK_EMAIL_SUCCESS: {
      const {
        authType = null,
        id = null,
        ssoConfig = null,
      } = action.payload.data;

      return state
        .set('authType', authType)
        .set('ssoConfig', ssoConfig)
        .set('id', id)
        .set('enteredFailedLogin', false)
        .set('failedLoginReason', '')
        .set('enteredEmail', true);
    }
    case CHECK_EMAIL_FAIL: {
      const {validation, errorCode} = action.payload.error.response.data;
      const errorReason =
        validation || errorCode ? 'Login.emailError' : 'Login.loginFailedError';

      return state
        .set('enteredFailedLogin', true)
        .set('failedLoginReason', errorReason);
    }
    case LOGGED_IN: {
      const {company, integrations, permissions, learnerGroups} =
        action.payload;
      const isPlaceholderTrialAccount =
        company.isTrial && company.name.includes(trials.NEW_TRIAL_STRING);
      document.title = `${
        isPlaceholderTrialAccount ? 'Trial' : company.name
      } - eduMe Control Panel`;

      const newState = updateUserDetails(action.payload, state);

      return newState
        .set('company', company)
        .set('integrations', integrations)
        .set('permissions', fromJS(permissions))
        .set('learnerGroups', fromJS(learnerGroups));
    }
    case LOGGED_OUT: {
      document.title = 'eduMe Control Panel';
      if (window.hj) {
        //If hotjar enabled, report that we logged out so data does not get mixed
        window.hj('stateChange', '/logout');
      }
      return logOut();
    }

    case LOGIN_FAIL: {
      if (action.payload.error.response.data.validation) {
        return failLogin(state, 'Login.loginFailedMsg');
      }

      const errorCode = action.payload.error.response.data.errorCode;
      const failReasons = {
        password_too_short: 'Login.loginFailedShortPassword', // check with tom and david
        invalid_credentials: 'Login.loginFailedMsg',
      };
      return failLogin(
        state,
        failReasons[errorCode] || 'Login.loginFailedError'
      );
    }
    case INVALID_TOKEN_USED: {
      return failLogin(state, 'Error.invalid_token');
    }
    case EXPIRED_TOKEN_USED: {
      return failLogin(state, 'Error.expired_token');
    }
    case OLD_TOKEN_VERSION: {
      return failLogin(state, 'Error.expired_token');
    }
    case DELETED_USER: {
      return failLogin(state, 'Error.deleted_user');
    }
    case LOGIN_FAILED_NOT_ADMIN: {
      return failLogin(state, 'Login.loginFailedNotAdmin');
    }
    case CHANGED_CREDENTIALS: {
      return state.set('enteredFailedLogin', false);
    }
    case CONTACT_INPUT_CHANGED:
      return state
        .set('resetContactInput', action.payload.input)
        .set('isInvalidInput', action.payload.isInvalid)
        .set('sendResetError', null)
        .set('enteredFailedLogin', false);
    case SEND_PASSWORD_RESET_ERROR:
      return state.set('sendResetError', action.payload);
    case RESET_PASSWORD_DETAILS_REQUEST:
      return state.set('isLoadingResetUser', true);
    case RESET_PASSWORD_ERROR:
      return state
        .set('resetPasswordError', action.payload)
        .set('isLoadingResetUser', false);
    case RESET_PASSWORD_DETAILS_SUCCESS:
      return state
        .set('isValidResetUser', true)
        .set('isLoadingResetUser', false);
    case RECORD_CREATE_COURSE:
      return state.set('hasCreatedCourse', true);
    case RECORD_INVITE_ADMIN:
      return state.set('hasInvitedAdmins', true);
    case LOAD_HINTS_SUCCESS:
    case DISMISS_HINT_SUCCESS:
    case UNDISMISS_HINT_SUCCESS:
      return state.set('hints', action.payload.data);
    case RESET_ENTERED_EMAIL:
      return state.set('enteredEmail', false);
    case INTEGRATION_CREATED: {
      const integration = {
        ...action.payload,
        credentials: {
          enabled: action.payload.credentials.enabled,
        },
      };
      state.get('integrations').push(integration);
      return state;
    }
    case GENERATE_FOUNTAIN_KEYS_SUCCESS: {
      const integration = {
        ...action.payload.data,
        credentials: {},
      };
      const integrations = state.get('integrations');

      if (integrations) {
        state.get('integrations').push(integration);
      }
      return state;
    }
    case INTEGRATION_UPDATED: {
      const updatedIntegration = {
        ...action.payload,
        credentials: {
          enabled: action.payload.credentials.enabled,
        },
      };
      const integrationIndexToUpdate = state
        .get('integrations')
        .findIndex((integration) => integration.id === action.payload.id);
      const newIntegrationsArray = state.get('integrations');
      newIntegrationsArray.splice(integrationIndexToUpdate, 1);
      newIntegrationsArray.push(updatedIntegration);
      return state.set('integrations', newIntegrationsArray);
    }

    case INTEGRATION_DELETED: {
      const {id} = action.payload;
      const integrationIndexToRemove = state
        .get('integrations')
        .findIndex((integration) => integration.id === id);
      const newIntegrationsArray = state.get('integrations');
      newIntegrationsArray.splice(integrationIndexToRemove, 1);
      return state.set('integrations', newIntegrationsArray);
    }
    case LOGGED_IN_MS_TEAMS:
      return state.set('accessMethod', MS_TEAMS);

    case `${UPDATE_CUSTOMER}_SUCCESS`: {
      const company = state.get('company');

      // The response from this endpoint does not include feature flags, so only update the changed values,
      // rather than override the entire company object
      const updates = Object.entries(action.meta.updates);
      if (updates) {
        for (const [key, value] of updates) {
          company[key] = value;
        }
      }
      return state.set('company', company);
    }

    case `${UPDATE_USER}_SUCCESS`:
      return updateUserDetails(action.payload.data, state);

    default: {
      return state;
    }
  }
};
