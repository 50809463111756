import {push} from 'connected-react-router';

import {trials} from '@edume/bento';

import {
  TRIAL_EMAIL_STORAGE_KEY,
  TRIAL_IS_BEEKEEPER,
  TRIAL_RESOURCE_KEY_STORAGE_KEY,
  TRIAL_RESOURCE_TYPE_STORAGE_KEY,
} from '../../enums/trial';
import * as authActions from '../auth/authActions';
import * as formActions from '../form/formActions';
import * as notificationActions from '../notification/notificationActions';
import {track, trackingActionTypes} from '../tracking/trackingActions';
import * as trialActionCreators from './trialActionCreators';
import {
  COMPLETE_TRIAL_FORM_STEP_ONE,
  COMPLETE_TRIAL_FORM_STEP_TWO,
  SET_COMPANY_NAME,
  SET_EMAIL,
  SET_ESTIMATED_LEARNERS,
  SET_FIRST_NAME,
  SET_INDUSTRY,
  SET_INTEGRATIONS_USED,
  SET_IS_BEEKEEPER,
  SET_IS_TRIAL_SIGNUP,
  SET_LAST_NAME,
  SET_RESOURCE_KEY,
  SET_RESOURCE_TYPE,
  VIEWED_ACCOUNT_UPGRADE,
} from './trialActionTypes';

export const startTrial = () => async (dispatch, getState) => {
  const state = getState();
  const TEMPORARY_NAME = trials.generateNewTrialString();

  const companyName = TEMPORARY_NAME;
  const password = TEMPORARY_NAME;
  const email = state.getIn(['trial', 'email']);
  const resourceType = state.getIn(['trial', 'resourceType']);
  const resourceKey = state.getIn(['trial', 'resourceKey']);
  const integrationsUsed = state.getIn(['trial', 'integrationsUsed']);

  try {
    const {payload} = await dispatch(
      trialActionCreators.startTrial({
        companyName,
        email,
        password,
        resourceType,
        resourceKey,
        integrationsUsed,
      })
    );
    dispatch(authActions.loginFromResponse(payload));
    dispatch(setIsTrialSignup(true));

    sessionStorage.removeItem(TRIAL_EMAIL_STORAGE_KEY);
    sessionStorage.removeItem(TRIAL_RESOURCE_TYPE_STORAGE_KEY);
    sessionStorage.removeItem(TRIAL_RESOURCE_KEY_STORAGE_KEY);
  } catch (error) {
    notificationActions.createErrorNotificationFromResponse(error)(dispatch);
  }
};

export const checkTrialUserEmail = () => async (dispatch, getState) => {
  const email = getState().getIn(['trial', 'email']);

  try {
    await dispatch(trialActionCreators.checkTrialUserEmail(email));
    dispatch(formActions.closeForm());

    await dispatch(startTrial());

    dispatch(track(trackingActionTypes.GO_TO_TRIAL_FORM_COMPANY, {email}));
    dispatch(push('/'));
  } catch (error) {
    // only show notifications for server errors, as most are shown under the input
    const quietErrors = [
      'business_email_required',
      'email_in_use',
      'email_is_already_trial_admin',
    ];
    if (
      error.response &&
      quietErrors.indexOf(error.response.data.errorCode) === -1
    ) {
      notificationActions.createErrorNotificationFromResponse(error)(dispatch);
    } else {
      formActions.addFormErrorFromResponse(error)(dispatch);
    }
  }
};

export const checkAndUpdateCompanyName =
  (name) => async (dispatch, getState) => {
    const hasNameChanged =
      name !== getState().getIn(['auth', 'company'])?.officialName;
    if (!hasNameChanged) {
      return true;
    }

    try {
      const {payload} = await dispatch(
        trialActionCreators.checkCompanyName(name)
      );
      if (payload.data) {
        await dispatch(authActions.updateCustomer({officialName: name}));
        return true;
      }
      return false;
    } catch (error) {
      dispatch(notificationActions.createErrorNotificationFromResponse(error));
      return false;
    }
  };

export const checkCompanyName = (name) => async (dispatch) => {
  try {
    await dispatch(trialActionCreators.checkCompanyName(name));
  } catch (error) {
    dispatch(notificationActions.createErrorNotificationFromResponse(error));
  }
};

export const setEmail = (email) => (dispatch) => {
  dispatch({type: SET_EMAIL, payload: {email}});
  sessionStorage.setItem(TRIAL_EMAIL_STORAGE_KEY, email);
};

export const setResourceKey = (key) => (dispatch) => {
  dispatch({type: SET_RESOURCE_KEY, payload: {key}});
  sessionStorage.setItem(TRIAL_RESOURCE_KEY_STORAGE_KEY, key);
};

export const setResourceType = (resourceType) => (dispatch) => {
  dispatch({type: SET_RESOURCE_TYPE, payload: {resourceType}});
  sessionStorage.setItem(TRIAL_RESOURCE_TYPE_STORAGE_KEY, resourceType);
};

export const setIsBeekeeper = (isBeekeeper) => (dispatch) => {
  dispatch({type: SET_IS_BEEKEEPER, payload: {isBeekeeper}});
  sessionStorage.setItem(TRIAL_IS_BEEKEEPER, isBeekeeper);
};

export const setCompanyName = (companyName) => (dispatch) => {
  dispatch({type: SET_COMPANY_NAME, payload: {companyName}});
};

export const setEstimatedLearners = (estimatedLearners) => (dispatch) => {
  dispatch({type: SET_ESTIMATED_LEARNERS, payload: {estimatedLearners}});
  dispatch(authActions.updateCustomer({estimatedLearners}));
};

export const setFirstName = (firstName) => (dispatch) => {
  dispatch({type: SET_FIRST_NAME, payload: {firstName}});
};

export const setLastName = (lastName) => (dispatch) => {
  dispatch({type: SET_LAST_NAME, payload: {lastName}});
};

export const setIndustry = (industry) => (dispatch) => {
  dispatch({type: SET_INDUSTRY, payload: {industry}});
};

export const setIntegrationsUsed = (integrations) => (dispatch) => {
  dispatch({type: SET_INTEGRATIONS_USED, payload: {integrations}});
};

export const setPreferredEmail = (preferredEmail) => async (dispatch) => {
  await dispatch(
    trialActionCreators.setPreferredEmail({
      preferredEmail,
    })
  );
};

export const goToDeathstar = () => (dispatch) => dispatch(push('/'));

export const toggleIsTrialSignup = () => (dispatch, getState) => {
  const isTrialSignup = getState().getIn(['trial', 'isTrialSignup']);
  dispatch(setIsTrialSignup(!isTrialSignup));
};

export const setIsTrialSignup = (isTrue) => (dispatch) =>
  dispatch({type: SET_IS_TRIAL_SIGNUP, payload: {isTrue}});

export const trackContactSales = (source) => (dispatch) =>
  dispatch({
    type: VIEWED_ACCOUNT_UPGRADE,
    payload: {source},
  });

export const completeTrialFormStepOne = () => (dispatch) =>
  dispatch({type: COMPLETE_TRIAL_FORM_STEP_ONE});

export const completeTrialFormStepTwo = () => (dispatch) =>
  dispatch({type: COMPLETE_TRIAL_FORM_STEP_TWO});
