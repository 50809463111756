import React, {Fragment, useEffect, useState} from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {useIntl} from 'react-intl';

import {accessibility} from '@edume/bento';
import {Button, CrossIcon, LegacyHeading} from '@edume/magnificent';

import {Hint, LegacyIntlHeading} from '../';
import useToggle from '../../../hooks/useToggle';

import styles from './styles.scss';

// eslint-disable-next-line complexity
const Tabs = ({
  tabs,
  mobileMenuTitleKey,
  selectedCourseTab = null,
  fullWidth = false,
  isTabsVisible = true,
  isMobileMenuOnly = false,
}) => {
  useEffect(() => {
    if (selectedCourseTab) {
      setActiveTab(selectedTab);
    }
  }, [selectedCourseTab, selectedTab]);

  const intl = useIntl();

  const selectedTab = tabs.findIndex((tab) => tab.selected);

  const [activeTab, setActiveTab] = useState(() => {
    if (selectedTab >= 0) {
      return selectedTab;
    }
    return 0;
  });
  const [dismissedHints, setDismissedHints] = useState([]);
  const [showMobileMenu, toggleMobileMenu, setShowMobileMenu] = useToggle();

  const dismissHint = (hint) => {
    setDismissedHints((prev) => [...prev, hint]);
  };

  const getHint = (tab, isMobile) => (
    <div styleName='hint'>
      <Hint
        hintId={tab.hint}
        buttonKey='gotcha'
        icon='question'
        trianglePosition={isMobile ? 'bl' : 'br'}
        dismissHint={() => dismissHint(tab.hint)}
        visible={!dismissedHints.includes(tab.hint)}
      />
    </div>
  );

  const getActiveTab = () => {
    const tab = tabs[activeTab];

    const renderIcon = () => {
      if (tab.icon) {
        return <img styleName='tab-icon' src={tab.icon} />;
      }

      if (tab.iconComponent) {
        return tab.iconComponent;
      }

      return null;
    };

    return (
      <Fragment>
        <button
          styleName={`menu-toggle${showMobileMenu ? ' active' : ''}`}
          onClick={toggleMobileMenu}
          data-automation={tab.dataAutomation}
          aria-label={intl.formatMessage({
            id: `Learning.tabs.${showMobileMenu ? 'hide' : 'show'}`,
          })}
        >
          <img src='/resources/img/24_drop_arrow_black.svg' />
        </button>
        <div>
          <div styleName='tab-heading'>
            {renderIcon()}
            {tab.titleContent ? (
              tab.titleContent
            ) : (
              <LegacyHeading size='tinier' weight='medium'>
                {tab.title}
              </LegacyHeading>
            )}
          </div>
        </div>
      </Fragment>
    );
  };

  const handleTabChange = (tab, i) => {
    if (tab.onClick) {
      tab.onClick();
    }
    setActiveTab(i);
    setShowMobileMenu(false);
  };

  const getAriaChecked = (isMobile, i, active) =>
    isMobile ? i === active : null;

  const getTabs = (isMobile = false) =>
    tabs.map((tab, i) => (
      <li
        key={i}
        styleName={classNames(
          'tab',
          {active: i === activeTab},
          {disabled: tab.disabled}
        )}
        onClick={() => handleTabChange(tab, i)}
        onKeyDown={(e) => {
          accessibility.accessibleClick(e, () => handleTabChange(tab, i));
        }}
        role='menuitemradio'
        tabIndex={0}
        aria-checked={getAriaChecked(isMobile, i, activeTab)}
      >
        <div>
          <div styleName='tab-heading'>
            {tab.icon && <img styleName='tab-icon' src={tab.icon} />}
            {!tab.icon && tab.iconComponent}
            <LegacyHeading
              size='tinier'
              weight='medium'
              dataAutomation={tab.dataAutomation}
            >
              {tab.title}
            </LegacyHeading>
          </div>
          {tab.hint && getHint(tab, isMobile)}
        </div>
      </li>
    ));

  return (
    <div styleName={isMobileMenuOnly ? 'mobile-menu-only' : ''}>
      {isTabsVisible && (
        <Fragment>
          <div
            styleName={`tab-bar${showMobileMenu ? ' mobile-menu-active' : ''}`}
          >
            {!isMobileMenuOnly && (
              <ul
                styleName={`desktop-tabs ${fullWidth ? 'full-width' : ''}`}
                data-automation='desktop-tabs'
                role='menu'
                aria-label={intl.formatMessage({id: 'Learning.tabs.label'})}
              >
                {getTabs()}
              </ul>
            )}
            <div styleName='mobile-tab'>{getActiveTab()}</div>
          </div>
          <div styleName={`mobile-menu${showMobileMenu ? ' active' : ''}`}>
            {mobileMenuTitleKey && (
              <LegacyIntlHeading
                size='mini-light'
                weight='medium'
                textKey={mobileMenuTitleKey}
              />
            )}
            <Button
              type='thirdary'
              onClick={toggleMobileMenu}
              noMarginTop
              icon={<CrossIcon />}
              tabIndex={!showMobileMenu ? -1 : 0}
              aria-label={intl.formatMessage({id: 'Learning.tabs.hide'})}
            />
            {showMobileMenu && (
              <ul
                role='menu'
                styleName='mobile-menu-list'
                aria-label={intl.formatMessage({id: 'Learning.tabs.label'})}
              >
                {getTabs(true)}
              </ul>
            )}
          </div>
        </Fragment>
      )}

      {!isMobileMenuOnly && (
        <div styleName={`tab-container ${fullWidth ? 'full-width' : ''}`}>
          {tabs[activeTab].content}
        </div>
      )}
    </div>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      titleContent: PropTypes.element,
      icon: PropTypes.string,
      iconComponent: PropTypes.element,
      selected: PropTypes.bool,
      content: PropTypes.element,
      hint: PropTypes.string,
      onClick: PropTypes.func,
      dataAutomation: PropTypes.string,
      disabled: PropTypes.bool,
    })
  ).isRequired,
  mobileMenuTitleKey: PropTypes.string,
  selectedCourseTab: PropTypes.string,
  fullWidth: PropTypes.bool,
  isTabsVisible: PropTypes.bool,
  isMobileMenuOnly: PropTypes.bool,
};

export default CSSModules(Tabs, styles, {allowMultiple: true});
