import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {injectIntl} from 'react-intl';

import {Input} from '@edume/magnificent';

import DeleteFieldIcon from './DeleteFieldIcon';

import styles from './styles.scss';

const ListInput = ({
  value,
  onChangeValue,
  placeholder,
  isInvalid,
  errorText,
  errorKey,
  title,
  validationStyles,
  includeBottomMargin,
  type,
  countText,
  deleteEnabled,
  onDelete,
  deleteTooltipKey,
  disabledTooltipKey,
  intl,
  dataAutomation,
  readyStatusDataAutomation,
  ...props
}) => {
  const error =
    errorText || (errorKey ? intl.formatMessage({id: errorKey}) : null);

  return (
    <div styleName='listInputWrapper'>
      <Input
        type={type || 'text'}
        value={value}
        isInvalid={isInvalid}
        onChangeValue={onChangeValue}
        placeholder={placeholder}
        errorText={error}
        title={title}
        validationStyles={validationStyles}
        includeBottomMargin={includeBottomMargin}
        countText={countText}
        dataAutomation={dataAutomation}
        readyStatusDataAutomation={readyStatusDataAutomation}
        {...props}
      />

      <div
        styleName={`deleteWrapper ${title ? 'hasTitle' : ''} ${
          props.rows === 2 ? 'shift-down' : ''
        }`}
        data-automation={`delete-${dataAutomation}`}
      >
        <DeleteFieldIcon
          fieldHasValue={value.length > 0}
          disabled={!deleteEnabled}
          onClick={onDelete}
          tooltipKey={deleteTooltipKey}
          disabledTooltipKey={disabledTooltipKey}
        />
      </div>
    </div>
  );
};

ListInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  isInvalid: PropTypes.bool.isRequired,
  errorText: PropTypes.string,
  errorKey: PropTypes.string,
  title: PropTypes.string,
  validationStyles: PropTypes.string,
  includeBottomMargin: PropTypes.bool,
  type: PropTypes.string,
  countText: PropTypes.string,
  deleteEnabled: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  deleteTooltipKey: PropTypes.string.isRequired,
  disabledTooltipKey: PropTypes.string.isRequired,
  rows: PropTypes.number,
  intl: PropTypes.object.isRequired,
  dataAutomation: PropTypes.string,
  readyStatusDataAutomation: PropTypes.string,
};

export default injectIntl(CSSModules(ListInput, styles, {allowMultiple: true}));
