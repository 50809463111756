import React from 'react';

import PropTypes from 'prop-types';
import {Droppable} from 'react-beautiful-dnd';
import CSSModules from 'react-css-modules';
import AutoSizer from 'react-virtualized-auto-sizer';
import {FixedSizeList} from 'react-window';

import AdminContentCard from '../../core/AdminContentCard';
import {ASSIGNED_CONTENT_DROPPABLE_ID} from '../KnowledgeHubAssignedContent';
import Row from './Row';

import AdminContentCardStyles from '../../core/AdminContentCard/styles.scss';
import styles from './styles.scss';

const ROW_MARGIN = 16;
const CARD_ITEM_HEIGHT = +AdminContentCardStyles.cardHeight + ROW_MARGIN;
const COLUMN_PADDING = 2;

export const getItemStyle = ({provided = {}, style}) => {
  const combined = {
    ...style,
    ...provided.draggableProps?.style,
  };

  const withSpacing = {
    ...combined,
    top: combined.top + ROW_MARGIN,
    height: combined.height - ROW_MARGIN,
    left: combined.left + COLUMN_PADDING,
    width: `calc(${combined.width} - ${COLUMN_PADDING * 2}px)`,
  };
  return withSpacing;
};

const getListStyle = (width, list, droppableId) => {
  if (droppableId === ASSIGNED_CONTENT_DROPPABLE_ID) {
    const paddingX = width > 500 ? (width - 500) / 2 : 0;
    return {
      padding:
        list.length === 0 ? `${ROW_MARGIN}px ${paddingX}px 20px` : '0 0 20px 0',
      scrollbarGutter: 'stable both-edges',
    };
  }
  return {
    scrollbarGutter: 'stable',
    paddingBottom: '20px',
  };
};

const KnowledgeHubContentList = ({
  contentList,
  droppableId,
  isDropDisabled,
  addContent,
  removeContent,
  dataAutomation,
}) => (
  <div styleName='content-list-container' data-automation={dataAutomation}>
    <Droppable
      droppableId={droppableId}
      mode='virtual'
      isDropDisabled={isDropDisabled}
      renderClone={(provided, {isDragging}, rubric) => (
        <AdminContentCard
          provided={provided}
          content={contentList[rubric.source.index]}
          isDragging={isDragging}
        />
      )}
    >
      {(provided, snapshot) => (
        <AutoSizer defaultHeight={CARD_ITEM_HEIGHT} defaultWidth={500}>
          {({width, height}) => (
            <FixedSizeList
              itemCount={
                snapshot.isUsingPlaceholder
                  ? contentList.length + 1
                  : contentList.length
              }
              itemSize={CARD_ITEM_HEIGHT}
              width={width}
              height={height}
              style={getListStyle(width, contentList, droppableId)}
              outerRef={provided.innerRef}
              itemData={{
                contentList,
                droppableId,
                addContent,
                removeContent,
              }}
            >
              {Row}
            </FixedSizeList>
          )}
        </AutoSizer>
      )}
    </Droppable>
  </div>
);

KnowledgeHubContentList.propTypes = {
  contentList: PropTypes.array.isRequired,
  droppableId: PropTypes.string.isRequired,
  dataAutomation: PropTypes.string,
  isDropDisabled: PropTypes.bool,
  addContent: PropTypes.func,
  removeContent: PropTypes.func,
};

export default CSSModules(KnowledgeHubContentList, styles);
