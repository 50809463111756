import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {injectIntl} from 'react-intl';
import ReactMarkdown from 'react-markdown';

import {formatRed} from '../../../../../utils/messageFormatter';

import styles from './styles.scss';

const MarkdownHelpBox = ({markdown, showSource, intl}) => (
  <div styleName='markdownHelpBox'>
    {showSource && <div styleName='source'>{formatRed(markdown)}</div>}
    <div styleName={'preview' + (showSource ? '' : ' pad-top')}>
      {showSource && (
        <span>{intl.formatMessage({id: 'Markdown.modal.preview'})}</span>
      )}
      <ReactMarkdown source={markdown} />
    </div>
  </div>
);

MarkdownHelpBox.propTypes = {
  markdown: PropTypes.string.isRequired,
  showSource: PropTypes.bool,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(
  CSSModules(MarkdownHelpBox, styles, {allowMultiple: true})
);
