import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';

import Hint from '../../../../containers/Hint';
import {EDUME} from '../../../../enums/integrations';

import styles from '../styles.scss';

const MultiSourceTabs = ({
  teamSources,
  selectedIdsBySource,
  selectedSourceIndex,
  setSelectedSourceIndex,
  showHint,
  renderTabContents,
}) => {
  const getTabStyles = (index) => {
    let positionClass = '';
    let styleName = 'tab';

    if (index === 0) {
      positionClass = 'first';
    } else if (index === teamSources.length - 1) {
      positionClass = 'last';
      styleName += ' last-tab';
    } else {
      styleName += ' middle-tab';
    }

    return {positionClass, styleName};
  };

  const renderSelectedTeamsCount = (selectedIndex) =>
    selectedIdsBySource[selectedIndex]?.length > 0
      ? ` (${selectedIdsBySource[selectedIndex].length})`
      : '';

  const renderAlertIcon = (index) =>
    index === selectedSourceIndex ? (
      <img styleName={'alert-icon'} src='/resources/img/24_alert_white.svg' />
    ) : (
      <img styleName={'alert-icon'} src='/resources/img/24_alert_red.svg' />
    );

  const renderAdditionalTabInformation = (source, index) =>
    source.errorCode ? renderAlertIcon(index) : renderSelectedTeamsCount(index);

  return (
    <>
      <Tabs
        selectedIndex={selectedSourceIndex}
        onSelect={(index) => setSelectedSourceIndex(index)}
      >
        <TabList styleName='tabs-container'>
          {teamSources.map((source, index) => {
            const {positionClass} = getTabStyles(index);
            return (
              <Tab
                tabIndex={'0'}
                data-automation={`${source.type}-tab`}
                className={[`react-tabs__tab ${positionClass}`]}
                key={index}
              >
                {source.type !== EDUME && (
                  <img src={`/resources/img/${source.type}.svg`} />
                )}
                {`${source.title} `}
                {renderAdditionalTabInformation(source, index)}
              </Tab>
            );
          })}
        </TabList>

        {teamSources.map((source, index) => (
          <TabPanel key={index}>{renderTabContents(source, index)}</TabPanel>
        ))}

        {showHint ? (
          <div styleName='hint'>
            <Hint
              hintId='ASSIGN_TEAMS'
              buttonKey='gotcha'
              icon='question'
              trianglePosition='br'
            />
          </div>
        ) : null}
      </Tabs>
    </>
  );
};

MultiSourceTabs.propTypes = {
  teamSources: PropTypes.array.isRequired,
  selectedIdsBySource: PropTypes.array.isRequired,
  selectedSourceIndex: PropTypes.number.isRequired,
  setSelectedSourceIndex: PropTypes.func.isRequired,
  showHint: PropTypes.bool.isRequired,
  renderTabContents: PropTypes.func.isRequired,
};

export default CSSModules(MultiSourceTabs, styles, {allowMultiple: true});
