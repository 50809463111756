import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {bindActionCreators} from 'redux';

import {
  OverviewDashboard,
  OverviewDashboardTrial,
  OverviewGrid,
  OverviewHeader,
} from '../components/overview';
import {TEAM} from '../enums/adminLevels';
import EdumePropTypes from '../proptypes';
import * as authActions from '../state/auth/authActions';
import {getAdminLevel, getAdminRoles} from '../state/auth/authSelector';
import * as overviewActions from '../state/overview/overviewActions';
import * as trackingActions from '../state/tracking/trackingActions';
import * as trialActions from '../state/trial/trialActions';
import {injectFeatureFlags} from '../utils/featureFlags';

import styles from './styles.scss';

const connector = connect(
  (state) => {
    const adminLevel = getAdminLevel(state);
    const adminRoles = getAdminRoles(state);
    const {isTrial} = state.getIn(['auth', 'company']);

    const isTrialSignup = state.getIn(['trial', 'isTrialSignup']);
    const selectedGroupId = state.getIn(['team', 'groupId']);
    const firstName = state.getIn(['auth', 'firstName']);
    const lastName = state.getIn(['auth', 'lastName']);
    const company = state.getIn(['auth', 'company']);
    const email = state.getIn(['auth', 'email']);

    const loaded = state.getIn(['overview', 'loaded']);
    const loading = state.getIn(['overview', 'loading']);
    const lessonsCompleted = state.getIn(['overview', 'lessonsCompleted']);
    const activeUsersMonth = state.getIn(['overview', 'activeUsersMonth']);
    const cacheDate = state.getIn(['overview', 'cacheUpdatedAt']) ?? null;
    const accessMethod = state.getIn(['auth', 'accessMethod']);

    return {
      firstName,
      lastName,
      company,
      selectedGroupId,
      adminLevel,
      adminRoles,
      isTrial,
      loaded,
      loading,
      lessonsCompleted,
      activeUsersMonth,
      cacheDate,
      isTrialSignup,
      email,
      accessMethod,
    };
  },
  (dispatch) => ({
    ...bindActionCreators(overviewActions, dispatch),
    ...bindActionCreators(trackingActions, dispatch),
    ...bindActionCreators(authActions, dispatch),
    ...bindActionCreators(trialActions, dispatch),
  })
);

const OverviewView = ({
  adminLevel,
  adminRoles,
  firstName,
  isTrial,
  isTrialSignup,
  selectedGroupId,
  loaded,
  loading,
  lessonsCompleted,
  activeUsersMonth,
  loadOverviewStats,
  track,
  cacheDate,
  accessMethod,
  featureFlags,
}) => {
  const {dashingDashboardsEnabled} = featureFlags;
  const [prevGroupId, setPrevGroupId] = useState(null);

  useEffect(() => {
    if (
      !isTrial &&
      selectedGroupId !== prevGroupId &&
      selectedGroupId !== null &&
      !loaded &&
      !loading
    ) {
      setPrevGroupId(selectedGroupId);
      loadOverviewStats(selectedGroupId);
    }
  }, [
    loadOverviewStats,
    loaded,
    loading,
    prevGroupId,
    selectedGroupId,
    isTrial,
  ]);

  const getContent = () => {
    if (isTrial) {
      return <OverviewDashboardTrial />;
    }

    const isDashingDashboardVisible =
      dashingDashboardsEnabled &&
      adminLevel !== TEAM &&
      (adminRoles.isLead || adminRoles.isEditor);

    if (isDashingDashboardVisible) {
      return (
        <OverviewDashboard
          lessonsCompleted={lessonsCompleted}
          activeUsersMonth={activeUsersMonth}
          cacheDate={cacheDate}
          adminRoles={adminRoles}
          selectedGroupId={selectedGroupId}
          isTrial={isTrial}
          accessMethod={accessMethod}
          loading={loading}
        />
      );
    }

    return (
      <OverviewGrid
        adminRoles={adminRoles}
        loaded={loaded}
        lessonsCompleted={lessonsCompleted}
        activeUsersMonth={activeUsersMonth}
        cacheDate={cacheDate}
        track={track}
        accessMethod={accessMethod}
      />
    );
  };

  if (adminLevel === TEAM) {
    return (
      <Redirect
        to={{
          pathname: '/messages',
        }}
      />
    );
  }

  return (
    <div>
      <OverviewHeader
        adminLevel={adminLevel}
        adminRoles={adminRoles}
        firstName={firstName}
        isTrialSignup={isTrialSignup}
        standardStyle={!isTrial}
      />
      {getContent()}
    </div>
  );
};

OverviewView.propTypes = {
  //state
  adminLevel: EdumePropTypes.adminLevel.isRequired,
  adminRoles: PropTypes.object.isRequired,
  firstName: PropTypes.string.isRequired,
  isTrial: PropTypes.bool,
  isTrialSignup: PropTypes.bool,
  selectedGroupId: PropTypes.number,
  loaded: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  lessonsCompleted: PropTypes.number.isRequired,
  activeUsersMonth: PropTypes.number.isRequired,
  cacheDate: PropTypes.string,
  accessMethod: PropTypes.string,
  featureFlags: PropTypes.object.isRequired,

  //actions
  loadOverviewStats: PropTypes.func.isRequired,
  track: PropTypes.func.isRequired,
};

export default connector(injectFeatureFlags(CSSModules(OverviewView, styles)));
