/* eslint-disable complexity */
import React, {lazy, Suspense, useEffect, useMemo} from 'react';

import snippet from '@segment/snippet';
import {ErrorBoundary} from '@sentry/react';
import {ConnectedRouter} from 'connected-react-router/immutable';
import 'ninja-keys';
import CSSModules from 'react-css-modules';
import {DragDropContextProvider} from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import withScrolling from 'react-dnd-scrollzone';
import {connect, Provider} from 'react-redux';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';

import {componentImport, multivariantTests} from '@edume/bento';
import {LoadingIndicator} from '@edume/magnificent';

import CommandPalette from './components/CommandPalette';
import Login from './components/Login';
import LoginDisplay from './components/LoginDisplay';
import NotificationArea from './components/NotificationArea';
import config from './config';
import CookiePopup from './containers/CookiePopup';
import CheckEmailPage from './containers/Trial/CheckEmailPage';
import {MS_TEAMS} from './enums/integrations';
import useBreakpoint from './hooks/useBreakpoint';
import * as authActions from './state/auth/authActions';
import * as loginPageConfigActions from './state/loginPageConfig/loginPageConfigActions';
import * as multivariantTestActions from './state/multivariantTests/multivariantTestActions';
import {PLG2} from './state/multivariantTests/multivariantTestNames';
import {history as browserHistory, store} from './state/store';
import * as teamActions from './state/team/teamActions';
import * as trackingActions from './state/tracking/trackingActions';
import * as trialActions from './state/trial/trialActions';
import {addDevActionToggle} from './utils/dev';
import getPageBackground from './utils/getpageBackground';
import ErrorBoundaryFallback from './views/ErrorBoundaryFallback';
import NoMatch from './views/NoMatch';
import OverviewView from './views/OverviewView';
// components part of the main bundle
import AuthenticatedRoute, {
  createAuthenticatedPage,
} from './views/routing/AuthenticatedRoute';
import ScrollToTop from './views/ScrollToTop';

import styles from './views/styles.scss';

// components loaded lazily and code split in different bundles
const lazyWithRetry = componentImport.lazyRetryBuilder(lazy);
const ActivateView = lazyWithRetry(() => import('./views/ActivateView'));
const TrialView = lazyWithRetry(() => import('./views/TrialView'));
const LanguageView = lazyWithRetry(() => import('./views/LanguageView'));
const UnsubscribeView = lazyWithRetry(() => import('./views/UnsubscribeView'));
const SAMLRedirectView = lazyWithRetry(() =>
  import('./views/SAMLRedirectView')
);
const SSORedirectView = lazyWithRetry(() => import('./views/SSORedirectView'));
const PeopleAndTeamsView = lazyWithRetry(() =>
  import('./views/PeopleAndTeamsView')
);
const SmartTeamAddEditView = lazyWithRetry(() =>
  import('./views/peopleAndTeams/SmartTeamAddEditView')
);
const SurveysView = lazyWithRetry(() => import('./views/SurveysView'));
const FeedView = lazyWithRetry(() => import('./views/FeedView'));
const NoGroupsView = lazyWithRetry(() => import('./views/NoGroupsView'));
const OnboardingView = lazyWithRetry(() => import('./views/OnboardingView'));
const MsTeamsView = lazyWithRetry(() => import('./views/MsTeamsView'));
const MsTeamsError = lazyWithRetry(() =>
  import('./views/MsTeamsView/MsTeamsError')
);
const CourseView = lazyWithRetry(() => import('./views/courses/CourseView'));
const LearningTabView = lazyWithRetry(() =>
  import('./views/courses/LearningTabView')
);
const GuideView = lazyWithRetry(() => import('./views/guides/GuideView'));
const GuideAddEditView = lazyWithRetry(() =>
  import('./views/guides/GuideAddEditView')
);
const GuidePreview = lazyWithRetry(() => import('./views/guides/GuidePreview'));
const GuideListView = lazyWithRetry(() =>
  import('./views/guides/GuideListView')
);
const LessonCanvasView = lazyWithRetry(() =>
  import('./views/courses/LessonCanvasView')
);
const KnowledgeHubView = lazyWithRetry(() =>
  import('./views/KnowledgeHubs/KnowledgeHubView')
);
const CompletionScreenView = lazyWithRetry(() =>
  import('./views/courses/CompletionScreenView')
);
const ReportsHighlightsView = lazyWithRetry(() =>
  import('./views/reporting/ReportsHighlightsView')
);
const CourseComparisonView = lazyWithRetry(() =>
  import('./views/reporting/CourseComparisonView')
);
const ReportsCourseView = lazyWithRetry(() =>
  import('./views/reporting/ReportsCourseView')
);
const LessonAnalyticsView = lazyWithRetry(() =>
  import('./views/reporting/LessonAnalyticsView')
);
const TeamView = lazyWithRetry(() =>
  import('./views/reporting/ReportsTeamView')
);
const SettingsView = lazyWithRetry(() => import('./views/SettingsView'));
const ForgotPasswordContactView = lazyWithRetry(() =>
  import('./views/ForgotPassword/ForgotPasswordContactView')
);
const ForgotPasswordCompleteView = lazyWithRetry(() =>
  import('./views/ForgotPassword/ForgotPasswordCompleteView')
);
const ResetPasswordView = lazyWithRetry(() =>
  import('./views/ForgotPassword/ResetPasswordView')
);
const CommandPaletteView = lazyWithRetry(() =>
  import('./views/CommandPaletteView')
);

const ScrollingComponent = CSSModules(withScrolling('div'), styles, {
  allowMultiple: true,
});

const UNAUTHENTICATED_ROUTES = [
  '/activate',
  '/unsubscribe',
  '/samlRedirect',
  '/trial',
  '/trial/check-email',
  '/forgotPassword',
  '/forgotPassword/complete',
  '/resetPassword',
  '/msTeams',
  '/ssoRedirect',
];

const routeConnector = connect(
  (state) => ({
    isAuthenticated: state.getIn(['auth', 'isAuthenticated']),
    showOnboarding: state.getIn(['auth', 'showOnboarding']),
    authValidated: state.getIn(['auth', 'authValidated']),
    authValidating: state.getIn(['auth', 'authValidating']),
    initialTeamsLoading: state.getIn(['team', 'initialTeamsLoading']),
    initialTeamsLoaded: state.getIn(['team', 'initialTeamsLoaded']),
    enteredFailedLogin: state.getIn(['auth', 'enteredFailedLogin']),
    failedLoginReason: state.getIn(['auth', 'failedLoginReason']),
    authType: state.getIn(['auth', 'authType']),
    ssoConfig: state.getIn(['auth', 'ssoConfig']),
    userId: state.getIn(['auth', 'id']),
    userEmail: state.getIn(['auth', 'email']),
    enteredEmail: state.getIn(['auth', 'enteredEmail']),
    groupId: state.getIn(['team', 'groupId']),
    userCountLoaded: state.getIn(['team', 'userCountLoaded']),
    accessMethod: state.getIn(['auth', 'accessMethod']),
    loginPageConfigLoaded: state.getIn(['loginPageConfig', 'hasLoaded']),
    loginPageConfig: state.getIn(['loginPageConfig', 'data']),
    isTrial: state.getIn(['auth', 'company']).isTrial,
    plan: state.get('plan'),
  }),
  (dispatch) => ({
    ...bindActionCreators(authActions, dispatch),
    ...bindActionCreators(teamActions, dispatch),
    ...bindActionCreators(multivariantTestActions, dispatch),
    ...bindActionCreators(trackingActions, dispatch),
    ...bindActionCreators(loginPageConfigActions, dispatch),
    ...bindActionCreators(trialActions, dispatch),
  })
);

const Routes = withRouter(
  routeConnector((props) => {
    const {
      isAuthenticated,
      checkEmail,
      login,
      onChangeCredentials,
      resetLoginForm,
      enteredFailedLogin,
      failedLoginReason,
      location,
      authType,
      ssoConfig,
      userId,
      userEmail,
      enteredEmail,
      history,
      loginWithLocalStorage,
      initialTeamsLoading,
      initialTeamsLoaded,
      authValidated,
      authValidating,
      loadHints,
      loadTeams,
      getLoginPageConfig,
      loginPageConfig,
      loginPageConfigLoaded,
      groupId,
      setVariantOverride,
      clearVariantOverride,
      onChangeContactInput,
      userCountLoaded,
      logViewportSizeChange,
      accessMethod,
      isTrial,
      plan,
      setTestVariant,
      toggleIsTrialSignup,
      showOnboarding,
    } = props;

    const propsForRoutes = {
      showOnboarding,
      plan,
    };

    const urlParams = useMemo(
      () => new URLSearchParams(location.search),
      [location]
    );

    const {isSmallScreen} = useBreakpoint();

    useEffect(() => {
      // We use the timeout to limit the number of actions dispatched as the window is resized
      let timeoutId;
      const resizeListener = () => {
        clearTimeout(timeoutId);
        // eslint-disable-next-line max-nested-callbacks
        timeoutId = setTimeout(() => {
          if (isAuthenticated) {
            logViewportSizeChange();
          }
        }, 1000);
      };

      window.addEventListener('resize', resizeListener);

      return () => window.removeEventListener('resize', resizeListener);
    }, [isAuthenticated, logViewportSizeChange]);
    useEffect(() => {
      if (
        !isAuthenticated &&
        !authValidated &&
        !authValidating &&
        !UNAUTHENTICATED_ROUTES.includes(location.pathname)
      ) {
        loginWithLocalStorage();
      }
    }, [
      isAuthenticated,
      loginWithLocalStorage,
      authValidated,
      authValidating,
      location,
    ]);

    useEffect(() => {
      const groupQueryParam = urlParams.get('g');

      if (!groupQueryParam && groupId) {
        // ensure URL always has a group query param
        history.push({
          pathname: location.pathname,
          search: `?g=${groupId}`,
        });
      } else if (
        groupQueryParam &&
        groupId &&
        Number(groupQueryParam) !== Number(groupId)
      ) {
        // ensure group ID is correct & exists in state
        history.push({
          pathname: location.pathname,
          search: `?g=${groupId}`,
        });
      }
    }, [history, location, groupId, urlParams]);

    useEffect(() => {
      if (
        !initialTeamsLoading &&
        !initialTeamsLoaded &&
        authValidated &&
        isAuthenticated &&
        !UNAUTHENTICATED_ROUTES.includes(location.pathname)
      ) {
        loadTeams({
          initialLoad: true,
          includeUserCount: false,
          fetchExternalTeams: true,
          otherTabsRequireUpdate: false,
        });
        loadHints();
      }
      if (!userCountLoaded && initialTeamsLoaded && !initialTeamsLoading) {
        loadTeams({
          initialLoad: false,
          includeUserCount: true,
          fetchExternalTeams: false,
          otherTabsRequireUpdate: true,
        });
      }
    }, [
      initialTeamsLoaded,
      initialTeamsLoading,
      authValidated,
      isAuthenticated,
      loadHints,
      loadTeams,
      location,
      userCountLoaded,
    ]);

    useEffect(() => {
      if (!loginPageConfigLoaded) {
        getLoginPageConfig();
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      const testVariantId = urlParams.get('variantId');

      if (testVariantId) {
        setVariantOverride(+testVariantId);
      } else {
        clearVariantOverride();
      }
    }, [setVariantOverride, clearVariantOverride, urlParams]);

    useEffect(() => {
      if (window && window.Appcues) {
        window.Appcues.page();
      }
    }, [location.pathname]);

    useEffect(() => {
      const eventListener = addDevActionToggle(toggleIsTrialSignup);

      return () => {
        if (eventListener) {
          window.removeEventListener(
            eventListener.event,
            eventListener.listener
          );
        }
      };
    }, [toggleIsTrialSignup]);

    // Only load Segment snippet if user is not internal
    useEffect(() => {
      const isInternalUser =
        isAuthenticated && userEmail.includes('@edume.com');

      let script;
      if (authValidated && isAuthenticated && !isInternalUser) {
        script = document.createElement('script');
        script.text = snippet.min({apiKey: config.segmentApiKey});
        document.body.appendChild(script);
      }

      return () => {
        if (script) {
          document.body.removeChild(script);
        }
      };
    }, [isAuthenticated, userEmail, authValidated, authValidating]);

    const showLoginPage =
      !isAuthenticated &&
      !UNAUTHENTICATED_ROUTES.includes(location.pathname) &&
      authValidated;

    const isLoading =
      !UNAUTHENTICATED_ROUTES.includes(location.pathname) &&
      (authValidating ||
        initialTeamsLoading ||
        !authValidated ||
        !initialTeamsLoaded) &&
      !showLoginPage;

    if (isLoading) {
      return createAuthenticatedPage(
        LoadingIndicator,
        {
          indicator: 'hexagons',
          size: 'large',
          containerStyle: 'centerAbsolute',
        },
        props,
        !isAuthenticated,
        !isAuthenticated
      );
    }

    const selectLoginPageConfig = (options) => {
      const numberOfOptions = options.length;
      const date = new Date(Date.now());
      const optionIndex = date.getMinutes() % numberOfOptions;
      return options[optionIndex];
    };

    if (showLoginPage) {
      return (
        <div>
          <LoginDisplay config={selectLoginPageConfig(loginPageConfig.toJS())}>
            <Login
              checkEmail={checkEmail}
              loginWithEmail={login}
              onChangeCredentials={onChangeCredentials}
              resetForm={resetLoginForm}
              enteredFailedLogin={enteredFailedLogin}
              failedLoginReason={failedLoginReason}
              pathname={location.pathname}
              authType={authType}
              ssoConfig={ssoConfig}
              userId={userId}
              onForgotPasswordClick={() => history.push('/forgotPassword')}
              enteredEmail={enteredEmail}
              onChangeContactInput={onChangeContactInput}
            />
          </LoginDisplay>
          <CookiePopup />
        </div>
      );
    }

    const isMsTeamsUserOnSmallScreen =
      accessMethod === MS_TEAMS && isSmallScreen;
    if (isMsTeamsUserOnSmallScreen) {
      return <MsTeamsError />;
    }

    const background = getPageBackground(location.pathname);

    if (isTrial) {
      const variantId = multivariantTests.getTestVariant(userId, 4);
      const variantGroup = String.fromCharCode(65 + variantId); // convert variantId to capital letter starting at A
      setTestVariant(PLG2, variantGroup);
    }

    return (
      <ScrollingComponent styleName='outerContainer' style={{background}}>
        <Switch>
          {/* Unauthenticated routes */}
          <Route exact path='/activate' component={ActivateView} />
          <Route exact path='/unsubscribe' component={UnsubscribeView} />
          <Route exact path='/samlRedirect' component={SAMLRedirectView} />
          <Route exact path='/ssoRedirect' component={SSORedirectView} />
          <Route exact path='/trial' component={TrialView} />
          <Route exact path='/trial/check-email' component={CheckEmailPage} />
          <Route
            exact
            path='/forgotPassword'
            component={ForgotPasswordContactView}
          />
          <Route
            exact
            path='/forgotPassword/complete'
            component={ForgotPasswordCompleteView}
          />
          <Route exact path='/resetPassword' component={ResetPasswordView} />
          <Route exact path='/msTeams' component={MsTeamsView} />

          {/* Authenticated routes */}
          <AuthenticatedRoute
            exact
            path='/'
            component={OverviewView}
            props={propsForRoutes}
          />
          <AuthenticatedRoute
            exact
            path='/learning'
            component={LearningTabView}
            props={propsForRoutes}
          />
          <AuthenticatedRoute
            exact
            path='/learning/course/:courseId'
            component={CourseView}
            props={propsForRoutes}
            noHeader
          />
          <AuthenticatedRoute
            exact
            path='/learning/kh/:knowledgeHubId'
            component={KnowledgeHubView}
            props={propsForRoutes}
            noHeader
            noFooter
          />
          <AuthenticatedRoute
            exact
            path='/learning/course/:courseId/lesson/:lessonKey'
            component={LessonCanvasView}
            props={propsForRoutes}
            noHeader
          />
          <AuthenticatedRoute
            exact
            path='/learning/course/:courseId/completionScreen'
            component={CompletionScreenView}
            props={propsForRoutes}
            noHeader
          />
          <AuthenticatedRoute
            exact
            path='/guides'
            component={GuideListView}
            props={propsForRoutes}
          />
          <AuthenticatedRoute
            exact
            path='/guides/new'
            component={GuideAddEditView}
            props={propsForRoutes}
            noHeader
            noFooter
          />
          <AuthenticatedRoute
            exact
            path='/guides/:guideId'
            component={GuideView}
            props={propsForRoutes}
            noHeader
          />
          <AuthenticatedRoute
            exact
            path='/guides/:guideId/edit'
            component={GuideAddEditView}
            props={propsForRoutes}
            noHeader
            noFooter
          />
          <AuthenticatedRoute
            exact
            path='/guides/:guideId/preview'
            component={GuidePreview}
            props={propsForRoutes}
            noHeader
            noFooter
          />
          <AuthenticatedRoute
            exact
            path='/reports'
            component={ReportsHighlightsView}
            props={propsForRoutes}
            extraRouteProps={{tabIndex: 0}}
          />
          <AuthenticatedRoute
            exact
            path='/reports/teams'
            component={ReportsHighlightsView}
            props={propsForRoutes}
            extraRouteProps={{tabIndex: 1}}
          />
          <AuthenticatedRoute
            exact
            path='/reports/course/:courseId/lesson/:lessonKey'
            component={LessonAnalyticsView}
            props={propsForRoutes}
          />
          <AuthenticatedRoute
            exact
            path='/reports/course/:courseId'
            component={ReportsCourseView}
            props={propsForRoutes}
          />
          <AuthenticatedRoute
            exact
            path='/reports/team/:teamId'
            component={TeamView}
            props={propsForRoutes}
            noHeader
          />
          <AuthenticatedRoute
            exact
            path='/reports/compare-courses'
            component={CourseComparisonView}
            props={propsForRoutes}
            noHeader
          />
          <AuthenticatedRoute
            exact
            path='/messages'
            component={FeedView}
            props={propsForRoutes}
          />
          <AuthenticatedRoute
            exact
            path='/no-groups'
            component={NoGroupsView}
            props={propsForRoutes}
          />
          <AuthenticatedRoute
            exact
            path='/people'
            component={PeopleAndTeamsView}
            props={propsForRoutes}
          />
          <AuthenticatedRoute
            exact
            path='/people/new-smart-team'
            component={SmartTeamAddEditView}
            props={propsForRoutes}
            noHeader
          />
          <AuthenticatedRoute
            exact
            path='/people/smart-teams/:smartTeamId'
            component={SmartTeamAddEditView}
            props={propsForRoutes}
            noHeader
          />
          <AuthenticatedRoute
            exact
            path='/onboarding'
            component={OnboardingView}
            props={propsForRoutes}
          />
          <AuthenticatedRoute
            exact
            path='/surveys'
            component={SurveysView}
            props={propsForRoutes}
          />
          <AuthenticatedRoute
            path='/settings'
            component={SettingsView}
            props={propsForRoutes}
          />

          {/* Redirects */}
          <Redirect from='/people-and-teams' to='/people' />
          <Redirect from='/updates' to='/messages' />
          <Redirect from='/content/*' to='/learning' />
          <Redirect from='/reporting/*' to='/reports' />

          <Route component={NoMatch} />
        </Switch>
        <div styleName='notificationsContainer'>
          <NotificationArea />
        </div>
      </ScrollingComponent>
    );
  })
);
const App = () => (
  <Provider store={store}>
    <LanguageView>
      <ErrorBoundary fallback={ErrorBoundaryFallback}>
        <ConnectedRouter history={browserHistory}>
          <Suspense
            fallback={
              <LoadingIndicator
                indicator='hexagons'
                size='large'
                containerStyle='centerAbsolute'
              />
            }
          >
            <ScrollToTop>
              <DragDropContextProvider backend={HTML5Backend}>
                <CommandPaletteView>
                  <CommandPalette />
                  <Routes />
                </CommandPaletteView>
              </DragDropContextProvider>
            </ScrollToTop>
          </Suspense>
        </ConnectedRouter>
      </ErrorBoundary>
    </LanguageView>
  </Provider>
);

export default App;
