import React, {useState} from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {FormattedMessage, injectIntl} from 'react-intl';
import {connect} from 'react-redux';

import {Button, Input} from '@edume/magnificent';

import FormWrapper from '../../../containers/FormWrapper';
import {StyledModal} from '../../../containers/StyledModals';
import addFormState from '../../../hocs/addFormState';
import useValidation from '../../../hooks/useValidation';
import {
  isGroupNameNotEmpty,
  isGroupNameNotTooLong,
} from '../../../utils/validation';
import {FocusElement, LegacyIntlHeading} from '../../core';

import styles from './styles.scss';

const connector = connect((state) => ({
  editTeamInProgress: state.getIn(['team', 'editTeamInProgress']),
}));

// eslint-disable-next-line complexity
const EditTeamOrGroup = ({
  originalName,
  onSave,
  onCancel,
  editType,
  isGroup,
  isModal,
  editTeamInProgress,
  formError,
  resetFormError,
  intl,
}) => {
  const [newName, setName] = useState(originalName);

  const [nameNotEmpty, isNameNotEmptyDisplay, unsetForceValidDisplay] =
    useValidation(newName, isGroupNameNotEmpty);

  const [nameNotTooLong, isNameNotTooLongDisplay] = useValidation(
    newName,
    isGroupNameNotTooLong
  );

  const isNameValid = nameNotEmpty && nameNotTooLong;

  const updateName = (name) => {
    if (formError) {
      resetFormError();
    }
    setName(name);
  };

  const getIntlKeys = () => {
    if (isGroup) {
      return {
        primaryButtonKey: 'Groups.createGroupConfirm',
        placeholderKey: 'Groups.groupNamePlaceholder',
      };
    } else if (editType === 'add') {
      return {
        primaryButtonKey: 'Teams.createTeamConfirm',
        placeholderKey: 'Teams.teamNamePlaceholder',
      };
    } else {
      return {
        primaryButtonKey: 'Teams.editTeamConfirm',
        placeholderKey: 'Teams.teamNamePlaceholder',
      };
    }
  };

  const onSubmit = () => {
    if (formError) {
      resetFormError();
    }
    unsetForceValidDisplay();
    if (isNameValid) {
      onSave(newName);
    }
  };

  const {primaryButtonKey, placeholderKey} = getIntlKeys();
  const placeholder = intl.formatMessage({id: placeholderKey});

  const nameErrors = [
    'child_group_name_already_exists',
    'group_name_already_exists',
  ];
  const userFormError = formError && nameErrors.includes(formError);

  const isInvalid =
    userFormError || !isNameNotEmptyDisplay || !isNameNotTooLongDisplay;

  const primaryButtonProps = {
    type: 'primary',
    onClick: () => onSubmit(newName),
    disabled: editTeamInProgress,
    dataAutomation: 'confirm-create-team-or-group-button',
  };

  const secondaryButtonProps = {
    type: 'secondary',
    onClick: onCancel,
    dataAutomation: 'cancel-create-team-or-group-button',
  };

  let errorMessageId;

  if (userFormError) {
    errorMessageId = `Error.${formError}`;
  } else if (!nameNotTooLong) {
    errorMessageId = isGroup
      ? 'Groups.nameTooLongError'
      : 'Teams.nameTooLongError';
  } else if (!nameNotEmpty) {
    errorMessageId = isGroup
      ? 'Groups.emptyGroupError'
      : 'Teams.emptyTeamError';
  }

  const errorText =
    (errorMessageId && intl.formatMessage({id: errorMessageId})) || ' ';

  const body = () => (
    <div
      styleName={`${isGroup ? 'addGroupContainer' : 'addTeamContainer'} ${
        isModal ? '' : 'hasBorder'
      }`}
    >
      {!isModal && (
        <div styleName='headingsContainer'>
          {isGroup ? (
            <LegacyIntlHeading
              size='small'
              textKey='Groups.createGroupModalTitle'
            />
          ) : (
            <LegacyIntlHeading
              size='mini'
              colour='grey700'
              textKey='Teams.nameTeam'
            />
          )}
        </div>
      )}
      <div styleName={isGroup ? 'blockContainer' : 'inlineContainer'}>
        <div styleName='inputContainer'>
          <Input
            type='text'
            value={newName}
            onChangeValue={updateName}
            errorText={errorText}
            inputKey='editTeamName'
            onEnterKey={() => onSubmit(newName)}
            placeholder={placeholder}
            autoFocus
            isInvalid={isInvalid}
            includeBottomMargin={false}
            width='fullWidth'
            dataAutomation='team-or-group-name-input'
          />
        </div>
        {!isModal && buttons()}
      </div>
    </div>
  );

  const buttons = () => (
    <div>
      <span styleName='buttonContainer'>
        <Button {...primaryButtonProps} noMarginTop>
          <FormattedMessage id={primaryButtonKey} />
        </Button>
      </span>
      <span styleName='buttonContainer'>
        <Button {...secondaryButtonProps} noMarginTop>
          <FormattedMessage id='Button.cancel' />
        </Button>
      </span>
    </div>
  );

  if (isModal) {
    const content = {
      title: intl.formatMessage({
        id: isGroup ? 'Groups.createGroupModalTitle' : 'Teams.nameTeam',
      }),
      body: CSSModules(body, styles)(),
      buttons: [
        {
          ...secondaryButtonProps,
          text: intl.formatMessage({id: 'Button.cancel'}),
        },
        {
          ...primaryButtonProps,
          text: intl.formatMessage({id: primaryButtonKey}),
        },
      ],
    };
    return (
      <FormWrapper onClose={onCancel}>
        <StyledModal onClose={onCancel} content={content} />
      </FormWrapper>
    );
  } else {
    return (
      <FormWrapper onClose={onCancel}>
        <FocusElement background='light'>{body()}</FocusElement>
      </FormWrapper>
    );
  }
};

EditTeamOrGroup.propTypes = {
  //own props
  originalName: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  editType: PropTypes.oneOf(['add', 'edit']).isRequired,
  isGroup: PropTypes.bool, //if true, shows longer form; if false, shows inline form.

  isModal: PropTypes.bool, //if false, appears inline, greying out surrounding content

  //from wrappers
  editTeamInProgress: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  formError: PropTypes.string,
  resetFormError: PropTypes.func.isRequired,
};

EditTeamOrGroup.defaultProps = {
  originalName: '',
  isModal: true,
};

export default injectIntl(
  addFormState(
    connector(CSSModules(EditTeamOrGroup, styles, {allowMultiple: true}))
  )
);
