import React from 'react';

import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import xorWith from 'lodash/xorWith';

import {smartTeamsEnums} from '@edume/bento';
import {PeopleIcon, SmartTeamIcon} from '@edume/magnificent';

export const getTeamIcon = (
  isSmartTeam,
  color = 'grey700',
  size = 'xsmall'
) => {
  if (isSmartTeam) {
    return <SmartTeamIcon colour={color} size={size} />;
  }
  return <PeopleIcon colour={color} size={size} />;
};

/**
 * Filters out smart teams from a list of teams
 * @param {[]} teams
 */
export const filterToStaticTeams = (teams) =>
  teams.filter(({isSmartTeam}) => !isSmartTeam);

/**
 * Filters out static teams from a list of teams
 * @param {[]} teams
 */
export const filterToSmartTeams = (teams) =>
  teams.filter(({isSmartTeam}) => isSmartTeam);

export const teamsContainsSmartTeam = (smartTeamId, teams) =>
  !!teams.find((team) => team.id === smartTeamId && team.isSmartTeam);

export const getTeamIconFromId = (teamId, teams) =>
  getTeamIcon(teamsContainsSmartTeam(teamId, teams));

/**
 * returns both smart and static teams if {@param smartTeamsEnabled} is true, else only static teams
 * @param {[]} teams
 * @param {boolean} smartTeamsEnabled
 */
export const filterSmartTeams = (teams, smartTeamsEnabled) => {
  if (smartTeamsEnabled) {
    return teams;
  }
  return filterToStaticTeams(teams);
};

export const getTimePeriodLabel = ({type, value, intl}) => {
  const intlMessage = (id, values) => intl.formatMessage({id}, values);
  if (!type) {
    return null;
  }
  return intlMessage(`Teams.createSmartTeam.timePeriod.${type}`, {
    value,
  });
};

export const getTimePeriodKey = ({elapsedTimeType, elapsedTimeValue, intl}) => {
  const intlMessage = (id) => intl.formatMessage({id});
  const timePeriodLabel = getTimePeriodLabel({
    type: elapsedTimeType,
    value: elapsedTimeValue,
    intl,
  });
  const allTimeKey = intlMessage('Teams.createSmartTeam.condition.anytime');

  return elapsedTimeValue
    ? `${intlMessage(
        'Teams.createSmartTeam.condition.after'
      )} ${elapsedTimeValue} ${timePeriodLabel.toLowerCase()}`
    : allTimeKey;
};

const logos = {
  adp: '/resources/img/logo/adp.svg',
  beekeeper: '/resources/img/logo/beekeeper.svg',
  msTeams: '/resources/img/logo/msTeams.svg',
  paycor: '/resources/img/logo/paycor.svg',
  salesforce: '/resources/img/logo/salesforce.svg',
  sap: '/resources/img/logo/sap.svg',
  speakap: '/resources/img/logo/speakap.svg',
  workday: '/resources/img/logo/workday.svg',
  dayforce: '/resources/img/logo/dayforce.svg',
  [smartTeamsEnums.TAG_TYPE_EDUME_ACTION]: '/resources/img/logo/edume.svg',
};

/**
 * @param {string} [type]
 * @returns {string | undefined}
 */
export const getTagIcon = (type) => {
  const integrationName = getIntegrationNameFromType(type);
  return logos[integrationName];
};

export const getIntegrationNameFromType = (type = '') => {
  if (type === smartTeamsEnums.TAG_TYPE_EDUME_ACTION) {
    return smartTeamsEnums.TAG_TYPE_EDUME_ACTION;
  }

  return type.includes('.') ? type.split('.')[0] : '';
};
const addTagCriteria = ({id}) => ({
  table: smartTeamsEnums.RULE_TABLES.TAGS,
  where: [
    {
      op: smartTeamsEnums.RULE_CRITERION_OPERATORS.EQUALS,
      column: smartTeamsEnums.RULE_ID_COLUMNS.TAGS,
      value: id,
    },
  ],
  user_id_column: smartTeamsEnums.RULE_ID_COLUMNS.USERS,
});

const addCourseCriteria = ({
  id,
  startDate,
  endDate,
  elapsedTimeType,
  elapsedTimeValue,
  score,
}) => {
  const whereArray = [];

  if (startDate) {
    whereArray.push({
      op: smartTeamsEnums.RULE_CRITERION_OPERATORS.AFTER,
      column: smartTeamsEnums.RULE_COLUMN_NAMES.COURSE_COMPLETION_DATE,
      value: startDate,
    });
  }

  if (endDate) {
    whereArray.push({
      op: smartTeamsEnums.RULE_CRITERION_OPERATORS.BEFORE,
      column: smartTeamsEnums.RULE_COLUMN_NAMES.COURSE_COMPLETION_DATE,
      value: endDate,
    });
  }

  if (elapsedTimeValue) {
    whereArray.push({
      op: smartTeamsEnums.RULE_CRITERION_OPERATORS.AFTER_ELAPSED_TIME,
      column: smartTeamsEnums.RULE_COLUMN_NAMES.COURSE_COMPLETION_DATE,
      value: [elapsedTimeValue, elapsedTimeType],
    });
  }

  if (score) {
    whereArray.push({
      op: smartTeamsEnums.RULE_CRITERION_OPERATORS.SCORED_BELOW,
      column: smartTeamsEnums.RULE_COLUMN_NAMES.COURSE_LESSON_SCORES,
      value: score,
    });
  }

  whereArray.push({
    op: smartTeamsEnums.RULE_CRITERION_OPERATORS.EQUALS,
    column: smartTeamsEnums.RULE_ID_COLUMNS.COURSES,
    value: id,
  });

  return {
    table: smartTeamsEnums.RULE_TABLES.COURSE_COMPLETION,
    where: whereArray,
    user_id_column: smartTeamsEnums.RULE_ID_COLUMNS.USERS,
  };
};

const addGuideCriteria = ({
  id,
  startDate,
  endDate,
  elapsedTimeType,
  elapsedTimeValue,
}) => {
  const whereArray = [];

  if (startDate) {
    whereArray.push({
      op: smartTeamsEnums.RULE_CRITERION_OPERATORS.AFTER,
      column: smartTeamsEnums.RULE_COLUMN_NAMES.GUIDE_COMPLETION_DATE,
      value: startDate,
    });
  }

  if (endDate) {
    whereArray.push({
      op: smartTeamsEnums.RULE_CRITERION_OPERATORS.BEFORE,
      column: smartTeamsEnums.RULE_COLUMN_NAMES.GUIDE_COMPLETION_DATE,
      value: endDate,
    });
  }

  if (elapsedTimeValue) {
    whereArray.push({
      op: smartTeamsEnums.RULE_CRITERION_OPERATORS.AFTER_ELAPSED_TIME,
      column: smartTeamsEnums.RULE_COLUMN_NAMES.GUIDE_COMPLETION_DATE,
      value: [elapsedTimeValue, elapsedTimeType],
    });
  }

  whereArray.push({
    op: smartTeamsEnums.RULE_CRITERION_OPERATORS.EQUALS,
    column: smartTeamsEnums.RULE_ID_COLUMNS.GUIDES,
    value: id,
  });

  return {
    table: smartTeamsEnums.RULE_TABLES.GUIDE_COMPLETION,
    where: whereArray,
    user_id_column: smartTeamsEnums.RULE_ID_COLUMNS.USERS,
  };
};

export const generateRulesPayload = (rules, operator) => {
  const criteria = rules.map((rule) => {
    if (rule.type === smartTeamsEnums.RULE_TYPE_TAG) {
      return addTagCriteria(rule);
    }
    if (rule.type === smartTeamsEnums.ACTION_TYPE_COURSE) {
      return addCourseCriteria({
        ...rule,
        elapsedTimeType: rule.value?.elapsedTimeType,
        elapsedTimeValue: rule.value?.elapsedTimeValue,
        score: rule.value.score,
      });
    }
    if (rule.type === smartTeamsEnums.ACTION_TYPE_GUIDE) {
      return addGuideCriteria({
        ...rule,
        elapsedTimeType: rule.value?.elapsedTimeType,
        elapsedTimeValue: rule.value?.elapsedTimeValue,
      });
    }
    return null;
  });

  return {
    op: operator.value,
    criteria,
  };
};

const getContentId = (criterion, ruleColumn) =>
  get(
    criterion.where.find((w) => w.column === ruleColumn),
    'value'
  );

const createRawRule = ({
  contentId,
  isCourseCompletionEvent,
  content,
  intlMessage,
}) => ({
  id: contentId,
  type: isCourseCompletionEvent
    ? smartTeamsEnums.ACTION_TYPE_COURSE
    : smartTeamsEnums.ACTION_TYPE_GUIDE,
  title: content?.title,
  label: isCourseCompletionEvent
    ? intlMessage('Teams.createSmartTeam.edumeActions.completedCourse')
    : intlMessage('Teams.createSmartTeam.edumeActions.completedGuide'),
  subtype: isCourseCompletionEvent
    ? smartTeamsEnums.ACTION_SUBTYPE_COMPLETED_COURSE
    : smartTeamsEnums.ACTION_SUBTYPE_COMPLETED_GUIDE,
});

// the rules are stored in a different format in the backend
// we need to get the raw rules to display the rules in the UI
// eslint-disable-next-line complexity
export const getRawRules = (existingRules, tags, courses, guides, intl) => {
  const intlMessage = (id) => intl.formatMessage({id});
  let rawRules = [];

  for (const criterion of existingRules.criteria) {
    const isCourseCompletionEvent =
      criterion.table === smartTeamsEnums.RULE_TABLES.COURSE_COMPLETION;
    const isGuideCompletionEvent =
      criterion.table === smartTeamsEnums.RULE_TABLES.GUIDE_COMPLETION;
    if (criterion.table === smartTeamsEnums.RULE_TABLES.TAGS) {
      const tagId = get(criterion, 'where[0].value');
      const tag = tags?.find((t) => t.id === tagId);
      const tagType = tag?.tagType;
      const label = tag?.title;
      const pendingRule = {
        id: tagId,
        type: smartTeamsEnums.RULE_TYPE_TAG,
        label,
        tagType,
      };

      rawRules.push(pendingRule);
    }
    if (isCourseCompletionEvent || isGuideCompletionEvent) {
      const contentId = isCourseCompletionEvent
        ? getContentId(criterion, smartTeamsEnums.RULE_ID_COLUMNS.COURSES)
        : getContentId(criterion, smartTeamsEnums.RULE_ID_COLUMNS.GUIDES);

      const content = isCourseCompletionEvent
        ? courses?.find((c) => c.id === contentId)
        : guides?.find((g) => g.id === contentId);

      let rawRule = createRawRule({
        contentId,
        isCourseCompletionEvent,
        content,
        intlMessage,
      });

      for (const where of criterion.where) {
        switch (where.op) {
          case smartTeamsEnums.RULE_CRITERION_OPERATORS.AFTER:
            rawRule = {
              ...rawRule,
              startDate: new Date(where.value),
              value: {
                elapsedTimeValue: '',
                elapsedTimeType: smartTeamsEnums.DAYS,
              },
            };
            break;

          case smartTeamsEnums.RULE_CRITERION_OPERATORS.AFTER_ELAPSED_TIME:
            rawRule = {
              ...rawRule,
              value: {
                elapsedTimeValue: where.value[0],
                elapsedTimeType: where.value[1],
              },
            };
            break;

          case smartTeamsEnums.RULE_CRITERION_OPERATORS.SCORED_BELOW:
            rawRule = {
              ...rawRule,
              value: {
                ...rawRule.value,
                score: where.value,
              },
            };
            break;
          default:
            break;
        }
      }
      rawRules.push(rawRule);
    }
  }
  return rawRules;
};

export const getTagIdFromRule = (rule) => {
  const tagIdColumn = rule.where.find(
    (where) => where.column === smartTeamsEnums.RULE_ID_COLUMNS.TAGS
  );
  return tagIdColumn?.value;
};

export const getCourseIdFromRule = (rule) => {
  const courseIdColumn = rule.where.find(
    (where) => where.column === smartTeamsEnums.RULE_ID_COLUMNS.COURSES
  );
  return courseIdColumn?.value;
};

export const getGuideIdFromRule = (rule) => {
  const guideIdColumn = rule.where.find(
    (where) => where.column === smartTeamsEnums.RULE_ID_COLUMNS.GUIDES
  );
  return guideIdColumn?.value;
};

export const getElapsedTimeTypeAndValueFromRule = (rule) => {
  const elapsedTimeColumn = rule.where.find(
    (where) =>
      where.op === smartTeamsEnums.RULE_CRITERION_OPERATORS.AFTER_ELAPSED_TIME
  );
  return {
    elapsedTimeValue: elapsedTimeColumn?.value[0],
    elapsedTimeType: elapsedTimeColumn?.value[1],
  };
};

export const getScoreFromRule = (rule) => {
  const scoreColumn = rule.where.find(
    (where) =>
      where.op === smartTeamsEnums.RULE_CRITERION_OPERATORS.SCORED_BELOW
  );
  return scoreColumn?.value;
};

/**
 * @param {string} operator
 * @returns {object}
 * @throws {Error}
 */
export const getExistingOperator = (operator) => {
  switch (operator) {
    case smartTeamsEnums.AND_OPERATOR.value:
      return smartTeamsEnums.AND_OPERATOR;
    case smartTeamsEnums.OR_OPERATOR.value:
      return smartTeamsEnums.OR_OPERATOR;
    default:
      throw new Error(`Unknown operator: ${operator}`);
  }
};

export const getRuleTypes = (rules) => {
  const tagRule = rules.find(
    (rule) => rule.type === smartTeamsEnums.RULE_TYPE_TAG
  );
  const eventRule = rules.find(
    (rule) =>
      rule.type === smartTeamsEnums.ACTION_TYPE_COURSE ||
      rule.type === smartTeamsEnums.ACTION_TYPE_GUIDE
  );

  const ruleTypes = {
    tag: false,
    event: false,
  };
  if (tagRule) {
    ruleTypes.tag = true;
  }
  if (eventRule) {
    ruleTypes.event = true;
  }

  return ruleTypes;
};

/**
 * @param {Array} existingRules
 * @param {Array} newRules
 * @param {object} existingOperator
 * @param {object} newOperator
 * @returns {boolean}
 */
export const checkIfExistingRulesOrOperatorChanged = (
  existingRules,
  newRules,
  existingOperator,
  newOperator
) => {
  const cleanedNewRules = newRules.map((rule) => omitBy(rule, isUndefined));
  const cleanedExistingRules = existingRules.map((rule) => {
    if (
      rule.type === smartTeamsEnums.RULE_TYPE_TAG &&
      isUndefined(rule.label) &&
      isUndefined(rule.tagType)
    ) {
      return omitBy(rule, isUndefined);
    }
    return rule;
  });

  const hasOperatorChanged = existingOperator.value !== newOperator.value;
  const existingRulesChanged = !isEqual(cleanedNewRules, cleanedExistingRules);

  const {tag: haveTagsChanged, event: haveEventsChanged} = getRuleTypes(
    xorWith(cleanedNewRules, cleanedExistingRules, isEqual)
  );

  return {
    existingRulesChanged,
    hasOperatorChanged,
    haveTagsChanged,
    haveEventsChanged,
  };
};

/**
 * @param {string} existingName
 * @param {string} newName
 * @returns {boolean}
 */
export const checkIfExistingTeamNameHasChanged = (existingName, newName) =>
  newName !== existingName;
