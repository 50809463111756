import {addMeta, buildRequest} from '../stateUtil';
import {
  ADD_COURSE,
  ADD_COURSE_TO_DEMO_CONTENT,
  ADD_MODULE,
  ASSIGN_COURSE_TO_GROUPS,
  CHANGE_ORDER,
  COPY_DEMO_COURSE_TO_CUSTOMER,
  CREATE_TEMPLATE_FROM_COURSE,
  DELETE_COURSE,
  DELETE_MODULE,
  DELETE_TRANSLATION,
  EDIT_COURSE_COMPLETION,
  EDIT_MODULE,
  EXPORT_COURSE_TO_GSHEET,
  GET_BAMBOO_TRAININGS,
  IMPORT_COURSE_FROM_GSHEET,
  LINK_EXTERNAL_TEAMS,
  LINK_INTEGRATED_COURSE,
  LOAD_COURSE,
  LOAD_DEMO_CONTENT_COURSES,
  LOAD_RESOURCE_LIBRARY_COURSES,
  PUBLISH_COURSE,
  PUBLISH_LESSON,
  REASSIGN_COURSE,
  REMOVE_COURSE_FROM_DEMO_CONTENT,
  STORE_COURSE_PREVIEW_TOKEN,
  TRANSLATE_COURSE,
  UNLINK_INTEGRATED_COURSE,
  UNPUBLISH_COURSE,
  UPDATE_COURSE,
  UPDATE_COURSE_HIDDEN_TITLE_SLIDE,
  UPDATE_COURSE_PRIORITY,
  UPDATE_DEFAULT_LANGUAGE,
  UPDATE_INTEGRATED_COURSE,
} from './courseActionTypes';

export const addCourse = (courseContent, groupId, meta) =>
  addMeta(
    buildRequest(
      ADD_COURSE,
      'POST',
      `/groups/${groupId}/courses`,
      courseContent
    ),
    {...meta}
  );

export const getCourse = (courseKey, language) => {
  const query = language ? `?language=${language}` : '';
  return buildRequest(LOAD_COURSE, 'GET', `/courses/${courseKey}${query}`);
};

export const updateTeamsInCourse = (
  courseKey,
  teamIdsToAdd,
  teamIdsToRemove
) => {
  const content = {
    groupIdsToAdd: teamIdsToAdd,
    groupIdsToRemove: teamIdsToRemove,
  };
  return buildRequest(
    ASSIGN_COURSE_TO_GROUPS,
    'POST',
    `/course/${courseKey}/assignGroups`,
    content
  );
};

export const updateExternalTeams = (parentGroupId, externalTeamIntegrations) =>
  buildRequest(
    LINK_EXTERNAL_TEAMS,
    'POST',
    `/group/${parentGroupId}/integrations`,
    externalTeamIntegrations
  );

export const deleteCourse = (courseKey) =>
  addMeta(buildRequest(DELETE_COURSE, 'DELETE', `/courses/${courseKey}`), {
    courseKey,
  });

export const updateCourse = (
  courseKey,
  course,
  earlyUpdate = false,
  previousDetails,
  lessons
) =>
  addMeta(
    buildRequest(UPDATE_COURSE, 'PUT', `/courses/${courseKey}`, {
      ...course,
      lessons,
    }),
    {courseKey, course, earlyUpdate, previousDetails}
  );

export const updateDefaultLanguage = (
  courseId,
  defaultLanguage,
  previousDefaultLanguage
) =>
  addMeta(
    buildRequest(
      UPDATE_DEFAULT_LANGUAGE,
      'PUT',
      `/courses/${courseId}/defaultLanguage`,
      {
        defaultLanguage,
      }
    ),
    {courseId, defaultLanguage, previousDefaultLanguage}
  );

export const deleteTranslation = (courseId, language) =>
  addMeta(
    buildRequest(
      DELETE_TRANSLATION,
      'DELETE',
      `/courses/${courseId}/translate?language=${language}`
    ),
    {courseId, language}
  );

export const translateCourse = (courseId, language) =>
  addMeta(
    buildRequest(TRANSLATE_COURSE, 'POST', `/courses/${courseId}/translate`, {
      language,
    }),
    {courseId, language}
  );

export const updateCourseHideTitleSlide = (courseKey, hideTitleSlide) =>
  addMeta(
    buildRequest(
      UPDATE_COURSE_HIDDEN_TITLE_SLIDE,
      'PUT',
      `/courses/${courseKey}/setTitleSlide`,
      {hideTitleSlide}
    ),
    {courseKey, hideTitleSlide}
  );

export const publishCourse = (courseKey) =>
  addMeta(
    buildRequest(PUBLISH_COURSE, 'PUT', `/courses/${courseKey}/publish`),
    {courseKey}
  );

export const unpublishCourse = (courseKey) =>
  addMeta(
    buildRequest(UNPUBLISH_COURSE, 'DELETE', `/courses/${courseKey}/publish`),
    {courseKey}
  );

export const changeModuleActivityOrder = (
  courseId,
  targetModuleId,
  lessonId,
  surveyId,
  targetPosition
) => {
  const activityId = lessonId ? {lessonId} : {surveyId};
  return addMeta(
    buildRequest(CHANGE_ORDER, 'POST', '/module/changeActivityOrder', {
      targetModuleId,
      targetPosition,
      ...activityId,
    }),
    {courseId}
  );
};

export const publishLesson = (courseKey, lessonKey) =>
  addMeta(
    buildRequest(PUBLISH_LESSON, 'PUT', `/lessons/${lessonKey}/publish`),
    {courseKey, lessonKey}
  );

export const addModule = (courseId, title) =>
  buildRequest(ADD_MODULE, 'POST', '/module', {courseId, title});

export const editModule = (moduleId, title) =>
  buildRequest(EDIT_MODULE, 'PUT', `/module/${moduleId}`, {title});

export const deleteModule = (moduleId) =>
  buildRequest(DELETE_MODULE, 'DELETE', `/module/${moduleId}`);

export const reassignUsersToCourse = (groupId, courseId, usersCsv) => {
  let payload = new FormData();
  payload.append('usersCsv', usersCsv);
  payload.append('groupId', groupId);
  return buildRequest(
    REASSIGN_COURSE,
    'POST',
    `/course/${courseId}/retake`,
    payload
  );
};

export const getBambooTrainings = (courseKey) =>
  buildRequest(
    GET_BAMBOO_TRAININGS,
    'GET',
    `/course/${courseKey}/integrations/bamboo/trainings`
  );

export const linkIntegrationToCourse = (courseKey, payload) =>
  buildRequest(
    LINK_INTEGRATED_COURSE,
    'POST',
    `/course/${courseKey}/integrations`,
    payload
  );

export const unlinkIntegrationFromCourse = (courseKey, integrationType) =>
  buildRequest(
    UNLINK_INTEGRATED_COURSE,
    'DELETE',
    `/course/${courseKey}/integrations/${integrationType}`
  );

export const updateCourseIntegration = (
  courseKey,
  courseIntegrationId,
  payload
) =>
  buildRequest(
    UPDATE_INTEGRATED_COURSE,
    'PUT',
    `/course/${courseKey}/integrations/${courseIntegrationId}`,
    payload
  );

export const editCourseEndScreen = (
  courseCompletionFields,
  courseKey,
  language,
  meta
) => {
  const query = language ? `?language=${language}` : '';
  return addMeta(
    buildRequest(
      EDIT_COURSE_COMPLETION,
      'PUT',
      `/courses/completionScreen/${courseKey}${query}`,
      {...courseCompletionFields}
    ),
    {...meta, courseKey}
  );
};

export const exportCourseToGSheet = (courseId) =>
  addMeta(
    buildRequest(EXPORT_COURSE_TO_GSHEET, 'POST', 'lang/exportToGSheets', {
      courseId,
    }),
    {courseId}
  );

export const importCourseFromGSheet = (courseId) =>
  addMeta(
    buildRequest(IMPORT_COURSE_FROM_GSHEET, 'POST', `lang/importFromGSheets`, {
      courseId,
    }),
    {courseId}
  );

export const storeCoursePreviewToken = (courseId, previewToken) =>
  buildRequest(
    STORE_COURSE_PREVIEW_TOKEN,
    'POST',
    `/courses/preview/${courseId}`,
    {previewToken}
  );

export const updateCoursePriority = (courseKey, priority) =>
  buildRequest(
    UPDATE_COURSE_PRIORITY,
    'PUT',
    `/courses/${courseKey}/setPriority`,
    {priority}
  );

export const getResourceLibraryCourses = () =>
  buildRequest(
    LOAD_RESOURCE_LIBRARY_COURSES,
    'GET',
    `/courses/resource-library`
  );

export const getDemoContentCourses = () =>
  buildRequest(LOAD_DEMO_CONTENT_COURSES, 'GET', `/courses/demo-content`);

export const addCourseToDemoContent = (courseId) =>
  buildRequest(
    ADD_COURSE_TO_DEMO_CONTENT,
    'POST',
    `/course/${courseId}/resourceType`,
    {resourceType: 'demo'}
  );

export const removeCourseFromDemoContent = (courseId) =>
  buildRequest(
    REMOVE_COURSE_FROM_DEMO_CONTENT,
    'DELETE',
    `/course/${courseId}/resourceType`,
    {resourceType: 'demo'}
  );

export const createTemplateFromCourse = (courseKey, groupId, courseTitle) =>
  addMeta(
    buildRequest(
      CREATE_TEMPLATE_FROM_COURSE,
      'POST',
      `/course/${courseKey}/createTemplateFromCourse`,
      {
        groupId,
        courseTitle,
      }
    ),
    {courseKey, groupId}
  );

export const copyDemoCourseToCustomer = (courseId, groupIdCopyTo) =>
  addMeta(
    buildRequest(
      COPY_DEMO_COURSE_TO_CUSTOMER,
      'POST',
      `/course/${courseId}/copyDemoCourseToCustomer`,
      {
        groupIdCopyTo,
      }
    ),
    {courseId, groupIdCopyTo}
  );
