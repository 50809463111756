import {z} from 'zod';

import {
  AND_OPERATOR,
  OR_OPERATOR,
  RULE_CRITERION_OPERATORS,
  RULE_TABLES,
} from './smartTeamsEnums';

type Rule = {
  op: 'and' | 'or';
  criteria: (Rule | Criteria)[];
};
type Criteria = z.infer<typeof CriteriaParser>;

const WhereParser = z
  .object({
    column: z.string(),
    op: z.enum([
      RULE_CRITERION_OPERATORS.EQUALS,
      RULE_CRITERION_OPERATORS.CONTAINS,
      RULE_CRITERION_OPERATORS.GREATER_THAN,
      RULE_CRITERION_OPERATORS.LESS_THAN,
      RULE_CRITERION_OPERATORS.BEFORE,
      RULE_CRITERION_OPERATORS.AFTER,
      RULE_CRITERION_OPERATORS.AFTER_ELAPSED_TIME,
      RULE_CRITERION_OPERATORS.SCORED_BELOW,
    ]),
    value: z.union([
      z.string(),
      z.number(),
      z.date(),
      z.tuple([z.string(), z.string()]),
    ]),
  })
  .strict();

const CriteriaParser = z
  .object({
    table: z.enum([
      RULE_TABLES.TAGS,
      RULE_TABLES.COURSE_COMPLETION,
      RULE_TABLES.GUIDE_COMPLETION,
      RULE_TABLES.USER_GROUPS,
      RULE_TABLES.USERS,
    ]),
    where: z.array(WhereParser),
    user_id_column: z.string(),
  })
  .strict();

const RuleParser: z.ZodType<Rule> = z.late
  .object(() => ({
    op: z.enum([AND_OPERATOR.value, OR_OPERATOR.value]),
    criteria: z.array(z.union([RuleParser, CriteriaParser])),
  }))
  .strict();

export const validateSegment = (rules: unknown, debug = false) => {
  const result = RuleParser.safeParse(rules);
  if (debug) {
    console.log(result);
  }
  return result.success;
};
