import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {useIntl} from 'react-intl';

import {Button, EmailIcon, Input, Link} from '@edume/magnificent';

import {LegacyIntlHeading} from '../../core';

import styles from '../styles.scss';

const LoginForm = ({
  inputType,
  emailValue,
  passwordValue = null,
  isValidEmailDisplay,
  isValidPasswordDisplay = null,
  updateEmailValue,
  updatePasswordValue,
  onEmailSubmit = () => null,
  onPasswordSubmit = () => null,
  onBackClick,
  history,
  onChangeContactInput,
}) => {
  const intl = useIntl();

  const submit = (event) => {
    event.preventDefault();
    if (inputType === 'email') {
      onEmailSubmit();
    } else {
      onPasswordSubmit();
    }
  };

  const emailPlaceholderText = intl.formatMessage({
    id: 'Login.emailPlaceholder',
  });

  const invalidEmailText = intl.formatMessage({id: 'Login.emailInvalid'});
  const invalidPasswordText = intl.formatMessage({id: 'Password.errorText'});
  const successText = intl.formatMessage({id: 'Password.successText'});
  const showText = intl.formatMessage({id: 'Password.show'});
  const hideText = intl.formatMessage({id: 'Password.hide'});

  const buttonKey = inputType === 'email' ? 'Button.continue' : 'Button.signIn';
  const buttonText = intl.formatMessage({id: buttonKey});
  const backButtonText = intl.formatMessage({id: 'Button.back'});
  const forgotPasswordMsg = intl.formatMessage({id: 'Login.troubleSigningIn'});

  return (
    <form styleName='login-form' noValidate onSubmit={submit}>
      <LegacyIntlHeading
        size='small'
        weight='medium'
        textKey='Login.signInHeader'
      />
      {inputType === 'email' ? (
        <div styleName='input'>
          <Input
            dataAutomation={'email-input'}
            type={'email'}
            value={emailValue}
            onChangeValue={updateEmailValue}
            inputKey={'loginemail'}
            errorText={invalidEmailText}
            isInvalid={!isValidEmailDisplay}
            placeholder={emailPlaceholderText}
            autoFocus={inputType === 'email'}
            includeBottomMargin={false}
            title={intl.formatMessage({
              id: 'Login.emailHeader',
            })}
          />
        </div>
      ) : (
        <div>
          <LegacyIntlHeading
            size='micro'
            weight='medium'
            textKey='Login.emailHeader'
          />
          <div styleName='email-text'>
            <EmailIcon size='xsmall' />
            <div
              styleName='email-or-phone'
              dangerouslySetInnerHTML={{
                __html: emailValue
                  ?.replaceAll('@', '&#8203;@')
                  .replaceAll('.', '&#8203;.'),
              }}
            />
          </div>
        </div>
      )}
      {inputType === 'password' && (
        <div styleName='input'>
          <Input
            dataAutomation={'password-input'}
            type={'password'}
            value={passwordValue}
            onChangeValue={updatePasswordValue}
            inputKey={'loginpassword'}
            errorText={invalidPasswordText}
            successText={successText}
            isInvalid={!isValidPasswordDisplay}
            autoFocus={inputType === 'password'}
            enableVisibilityToggle={true}
            showText={showText}
            hideText={hideText}
            includeBottomMargin={false}
            title={intl.formatMessage({
              id: 'Login.passwordHeader',
            })}
          />
        </div>
      )}
      <div styleName='buttons'>
        <Button
          dataAutomation={`${inputType}-button`}
          type='primary'
          submit
          fullWidth={true}
        >
          {buttonText}
        </Button>
        {inputType !== 'email' && (
          <Button type='secondary' onClick={onBackClick} fullWidth>
            {backButtonText}
          </Button>
        )}
      </div>
      {inputType === 'password' && (
        <div styleName='linkContainer'>
          <Link
            text={forgotPasswordMsg}
            linkSize='snail'
            linkColour='primary'
            onClick={() => {
              history.push({
                pathname: '/forgotPassword',
                state: emailValue,
              });
              onChangeContactInput(emailValue);
            }}
            dataAutomation='forgot-password-link'
          />
        </div>
      )}
    </form>
  );
};

LoginForm.propTypes = {
  inputType: PropTypes.oneOf(['email', 'password']).isRequired,
  emailValue: PropTypes.string,
  passwordValue: PropTypes.string,
  isValidEmailDisplay: PropTypes.bool.isRequired,
  isValidPasswordDisplay: PropTypes.bool.isRequired,
  updateEmailValue: PropTypes.func.isRequired,
  updatePasswordValue: PropTypes.func.isRequired,
  onEmailSubmit: PropTypes.func,
  onPasswordSubmit: PropTypes.func,
  onBackClick: PropTypes.func.isRequired,
  history: PropTypes.object,
  onChangeContactInput: PropTypes.func.isRequired,
};

export default CSSModules(LoginForm, styles);
