import zlib from 'zlib';

import {ssoUtil} from '@edume/bento';

import config from '../config';

export const generateAuthRequest = (userId) => {
  const encodedUserId = btoa(String(userId));
  return ssoUtil.generateAuthRequest(zlib, config.samlAcsUrl, encodedUserId);
};
