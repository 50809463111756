//Configurable StatsInfoWell, mainly specifying which border radii to include
import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';

import styles from './styles.scss';

const StatsInfoWell = ({
  children,
  omitTopRadius,
  omitBottomRadius,
  backgroundColour,
}) => {
  const topRadiusStyle = omitTopRadius ? '' : 'topRadius';
  const bottomRadiusStyle = omitBottomRadius ? '' : 'bottomRadius';
  const styleNames = `well ${topRadiusStyle} ${bottomRadiusStyle} ${backgroundColour}`;

  return <div styleName={styleNames}>{children}</div>;
};

StatsInfoWell.propTypes = {
  children: PropTypes.any,
  backgroundColour: PropTypes.oneOf(['white', 'grey']),
  omitTopRadius: PropTypes.bool,
  omitBottomRadius: PropTypes.bool,
};

StatsInfoWell.defaultProps = {
  backgroundColour: 'white',
};

export default CSSModules(StatsInfoWell, styles, {allowMultiple: true});
