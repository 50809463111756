import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {injectIntl} from 'react-intl';

import Hint from '../../../containers/Hint';
import {StyledMultiSelectModal} from '../../../containers/StyledModals';

import styles from './styles.scss';

const SelectTeamsDialog = ({
  save,
  cancel,
  teams,
  seedSelectedTeamIds,
  headingKey,
  buttonKey,
  allowZeroTeams,
  showSelectAll,
  canClose,
  showHint,
  additionalTopContent,
  selectAllPosition,
  alternateShading,
  intl,
}) => {
  const items = teams.map((team) => ({
    id: team.id,
    text: team.name,
    dataAutomation: 'team-option',
  }));
  const headingText = intl.formatMessage({id: headingKey});
  const buttonText = intl.formatMessage({id: buttonKey});
  const selectAllText = showSelectAll
    ? intl.formatMessage({id: 'Updates.selectAllTeams'})
    : intl.formatMessage({id: 'Link.selectAll'});
  const unselectAllText = intl.formatMessage({id: 'Link.unselectAll'});

  const getAdditionalContent = () => (
    <div styleName='hint'>
      <Hint
        hintId='ASSIGN_TEAMS'
        buttonKey='gotcha'
        icon='question'
        trianglePosition='br'
      />
    </div>
  );

  return (
    <StyledMultiSelectModal
      save={save}
      cancel={cancel}
      items={items}
      seedSelected={seedSelectedTeamIds}
      headingText={headingText}
      buttonText={buttonText}
      selectAllText={selectAllText}
      unselectAllText={unselectAllText}
      allowNone={allowZeroTeams}
      showSelectAllItem={showSelectAll}
      selectAllPosition={selectAllPosition}
      canClose={canClose}
      additionalTopContent={additionalTopContent}
      additionalBottomContent={
        showHint ? CSSModules(getAdditionalContent, styles)() : null
      }
      alternateShading={alternateShading}
      buttonDataAutomation='confirm-button'
    />
  );
};

SelectTeamsDialog.propTypes = {
  save: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  teams: PropTypes.array.isRequired,
  seedSelectedTeamIds: PropTypes.array,
  headingKey: PropTypes.string.isRequired,
  buttonKey: PropTypes.string.isRequired,
  allowZeroTeams: PropTypes.bool,
  showSelectAll: PropTypes.bool,
  canClose: PropTypes.bool,
  showHint: PropTypes.bool,
  additionalTopContent: PropTypes.element,
  selectAllPosition: PropTypes.string,
  alternateShading: PropTypes.bool,
  intl: PropTypes.object.isRequired,
};

SelectTeamsDialog.defaultProps = {
  allowZeroTeams: true,
};
export default injectIntl(SelectTeamsDialog);
