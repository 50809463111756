import {media} from '@edume/bento';

import {DEFAULT_END_ICON} from '../../utils/lesson';

/*eslint-disable*/
const conversationalExample = {
  en: {
    type: 'lesson',
    version: 3.1,
    params: {
      title: 'Introduction to Workforce Success',
      previewImageUrl:
        'https://edume-res.cloudinary.com/image/upload/v1593612638/control_panel_media/yrabvr28resxn02ni5fp.png',
      intro: {
        greeting: "What's happening? 🤔",
        description:
          'The way people work is changing rapidly. This is driven by three seismic changes happening at the same time: \n\n📲80% of the global workforce is deskless\n\n👩‍💻75% of the workforce will be made up of millennials by 2025\n\n🌐The way we consume information and communicate has fundamentally changed with the advent of the mobile phone.',
        welcomeImageUrl:
          'https://edume-res.cloudinary.com/image/upload/v1593615967/control_panel_media/x633mb8ljlvh4jfoj1lm.png',
      },
      activities: [
        {
          type: 'text',
          params: {
            title: 'What is Workforce Success?',
            description:
              'Workforce Success is ensuring that your **workforce is empowered with the knowledge they require to be successful in their job.** It is the sustained empowerment of individuals that drives companies’ collective success. 💪',
          },
        },
        {
          type: 'interactive',
          params: {
            video: {
              url: 'v1594031169/control_panel_media/vrtsvo5iupumc0igvf9i.mp4',
              source: 'cloudinary',
            },
            background: '#0D003A',
            interaction: {
              type: 'standaloneQuiz',
              params: {
                prompt: 'Select ALL the statements that are TRUE',
                quizType: 'multipleChoice',
                questions: [
                  {
                    question:
                      'People and companies achieve Workforce Success by...',
                    options: [
                      {
                        tip: '3-5 minute lessons in short courses boost engagement and knowledge retention! 🤓',
                        text: 'delivering information in bite-size chunks',
                        isCorrect: true,
                      },
                      {
                        text: 'providing relevant information at the appropriate time',
                        isCorrect: true,
                      },
                      {
                        text: 'empowering your workforce with a voice',
                        isCorrect: true,
                      },
                      {
                        tip: 'Actually, you need to deliver regular learning opportunities where your workforce spend most of their time, their mobile phones! 🤳',
                        text: 'providing face-to-face training only twice a year',
                        isCorrect: false,
                      },
                    ],
                  },
                ],
                shuffleAnswers: true,
              },
            },
            taskBoxText:
              'Before you watch, think about the think about the approach you’re using to **train**, **inform** and **engage** your people. \nHow do you adapt to the changing environment to ensure that your workforce is equipped to **deliver their best?** 📈',
            taskBoxTitle: 'Video 🍿',
          },
        },
        {
          type: 'text',
          params: {
            title: "That's awesome!  🎉🎉",
            description:
              "Let's summarise how you can **empower your workforce to be successful.**\n\n✅ Reach people where they are, their **mobile phones**.\n✅ Give your people with the right information at the right time as part of a **continuous learning journey**.\n✅ Provide access to short-form content using **microlearning**.\n✅ Collect **feedback** by giving people a voice.",
          },
        },
        {
          type: 'interactive',
          params: {
            background: '#0D003A',
            interaction: {
              type: 'imageQuiz',
              params: {
                urls: [
                  'https://edume-res.cloudinary.com/image/upload/v1593680706/control_panel_media/ipsjactpeuj83zfq6ste.png',
                  'https://edume-res.cloudinary.com/image/upload/v1593681542/control_panel_media/iuh1e7nr3c0myx0ra4aj.png',
                  'https://edume-res.cloudinary.com/image/upload/v1593682503/control_panel_media/mi2gu67g0guzlzkhz3b2.png',
                ],
                prompt: 'Complete the sentences.',
                quizType: 'singleChoice',
                questions: [
                  {
                    key: 'getting_started_with_microlearning9097_start_with_your_what_are_their_needs_and_what_s_the_learning_context_7724',
                    options: [
                      {
                        text: 'become innovators and industry leaders',
                        isCorrect: true,
                      },
                      {
                        tip: "It's quite the opposite. Engaged employees are more loyal and productive.💥 ",
                        text: 'experience high churn',
                        isCorrect: false,
                      },
                      {
                        tip: 'Workforce Success can increase sales productivity by up to 20%. 📈',
                        text: 'see profitability decrease',
                        isCorrect: false,
                      },
                    ],
                    question:
                      'Achieving Workforce Success means companies ____.',
                  },
                  {
                    question:
                      'Continually upskilling your workforce ____, and by extension, company profitability.',
                    options: [
                      {
                        text: 'improves engagement',
                        isCorrect: true,
                      },
                      {
                        tip: 'In fact, companies with successful workforce experience 37% higher productivity, 17% higher profitability. 🤑',
                        text: 'reduces engagement',
                        isCorrect: false,
                      },
                      {
                        tip: 'Nope! An engaged, empowered workforce inspires loyalty and productivity. 🙌',
                        text: 'endangers loyalty',
                        isCorrect: false,
                      },
                    ],
                  },
                  {
                    question:
                      'Companies that ____ will future-proof themselves for sustainable, long-term success. ',
                    options: [
                      {
                        text: 'employ continuous learning',
                        isCorrect: true,
                      },
                      {
                        tip: 'The world has changed and companies must change with it. 🌍',
                        text: 'use traditional training methods',
                        isCorrect: false,
                      },
                      {
                        tip: "Technology has altered peoples' preferences for how and when they learn. 📱",
                        text: "don't use mobile-based learning",
                        isCorrect: false,
                      },
                    ],
                  },
                ],
                thumbnailUrls: [
                  'https://edume-res.cloudinary.com/image/upload/v1593680706/control_panel_media/ipsjactpeuj83zfq6ste.png',
                  'https://edume-res.cloudinary.com/image/upload/v1593681542/control_panel_media/iuh1e7nr3c0myx0ra4aj.png',
                  'https://edume-res.cloudinary.com/image/upload/v1593682503/control_panel_media/mi2gu67g0guzlzkhz3b2.png',
                ],
                shuffleAnswers: true,
              },
            },
            taskBoxText:
              'Keep going to find out what the benefits are of having a successful workforce. 🚀',
            taskBoxTitle: 'Quiz💡',
          },
        },
        {
          type: 'text',
          params: {
            title: 'How eduMe can help you achieve Workforce Success 🏆',
            description:
              '✅ Create continuous learning that’s **fun & rewarding.**\n✅ Enable your workforce to **stay informed** and up to date.\n✅ Give your workforce a voice and enabling **informed decisions.**\n✅ Provide powerful analytics to gather **valuable insight.**',
          },
        },
      ],
      learningOutcomes: {
        cheer: 'Good to go? 🚀',
        title: "Let's find out...",
        outcomes: [
          '...what Workforce Success is',
          '...how to achieve Workforce Success',
          '...what the benefits of Workforce Success are',
        ],
      },
      end: {
        title: 'Lesson complete',
        subtitle: 'Finish all the activities to complete this course.',
        icon: {
          url: DEFAULT_END_ICON,
        },
        button: {
          text: 'Next',
        },
      },
    },
  },
  es: {
    type: 'lesson',
    version: 3.1,
    params: {
      title: 'Introduction to Workforce Success',
      previewImageUrl:
        'https://edume-res.cloudinary.com/image/upload/v1593612638/control_panel_media/yrabvr28resxn02ni5fp.png',
      intro: {
        greeting: "What's happening? 🤔",
        description:
          'The way people work is changing rapidly. This is driven by three seismic changes happening at the same time: \n\n📲80% of the global workforce is deskless\n\n👩‍💻75% of the workforce will be made up of millennials by 2025\n\n🌐The way we consume information and communicate has fundamentally changed with the advent of the mobile phone.',
        welcomeImageUrl:
          'https://edume-res.cloudinary.com/image/upload/v1593615967/control_panel_media/x633mb8ljlvh4jfoj1lm.png',
      },
      activities: [
        {
          type: 'text',
          params: {
            title: 'What is Workforce Success?',
            description:
              'Workforce Success is ensuring that your **workforce is empowered with the knowledge they require to be successful in their job.** It is the sustained empowerment of individuals that drives companies’ collective success. 💪',
          },
        },
        {
          type: 'interactive',
          params: {
            video: {
              url: 'v1594031169/control_panel_media/vrtsvo5iupumc0igvf9i.mp4',
              source: 'cloudinary',
            },
            background: '#0D003A',
            interaction: {
              type: 'standaloneQuiz',
              params: {
                prompt: 'Select ALL the statements that are TRUE',
                quizType: 'multipleChoice',
                questions: [
                  {
                    question:
                      'People and companies achieve Workforce Success by...',
                    options: [
                      {
                        tip: '3-5 minute lessons in short courses boost engagement and knowledge retention! 🤓',
                        text: 'delivering information in bite-size chunks',
                        isCorrect: true,
                      },
                      {
                        text: 'providing relevant information at the appropriate time',
                        isCorrect: true,
                      },
                      {
                        text: 'empowering your workforce with a voice',
                        isCorrect: true,
                      },
                      {
                        tip: 'Actually, you need to deliver regular learning opportunities where your workforce spend most of their time, their mobile phones! 🤳',
                        text: 'providing face-to-face training only twice a year',
                        isCorrect: false,
                      },
                    ],
                  },
                ],
                shuffleAnswers: true,
              },
            },
            taskBoxText:
              'Before you watch, think about the think about the approach you’re using to **train**, **inform** and **engage** your people. \nHow do you adapt to the changing environment to ensure that your workforce is equipped to **deliver their best?** 📈',
            taskBoxTitle: 'Video 🍿',
          },
        },
        {
          type: 'text',
          params: {
            title: "That's awesome!  🎉🎉",
            description:
              "Let's summarise how you can **empower your workforce to be successful.**\n\n✅ Reach people where they are, their **mobile phones**.\n✅ Give your people with the right information at the right time as part of a **continuous learning journey**.\n✅ Provide access to short-form content using **microlearning**.\n✅ Collect **feedback** by giving people a voice.",
          },
        },
        {
          type: 'interactive',
          params: {
            background: '#0D003A',
            interaction: {
              type: 'imageQuiz',
              params: {
                urls: [
                  'https://edume-res.cloudinary.com/image/upload/v1593680706/control_panel_media/ipsjactpeuj83zfq6ste.png',
                  'https://edume-res.cloudinary.com/image/upload/v1593681542/control_panel_media/iuh1e7nr3c0myx0ra4aj.png',
                  'https://edume-res.cloudinary.com/image/upload/v1593682503/control_panel_media/mi2gu67g0guzlzkhz3b2.png',
                ],
                thumbnailUrls: [
                  'https://edume-res.cloudinary.com/image/upload/v1593680706/control_panel_media/ipsjactpeuj83zfq6ste.png',
                  'https://edume-res.cloudinary.com/image/upload/v1593681542/control_panel_media/iuh1e7nr3c0myx0ra4aj.png',
                  'https://edume-res.cloudinary.com/image/upload/v1593682503/control_panel_media/mi2gu67g0guzlzkhz3b2.png',
                ],
                prompt: 'Complete the sentences.',
                quizType: 'singleChoice',
                questions: [
                  {
                    key: 'getting_started_with_microlearning9097_start_with_your_what_are_their_needs_and_what_s_the_learning_context_7724',
                    options: [
                      {
                        text: 'become innovators and industry leaders',
                        isCorrect: true,
                      },
                      {
                        tip: "It's quite the opposite. Engaged employees are more loyal and productive.💥 ",
                        text: 'experience high churn',
                        isCorrect: false,
                      },
                      {
                        tip: 'Workforce Success can increase sales productivity by up to 20%. 📈',
                        text: 'see profitability decrease',
                        isCorrect: false,
                      },
                    ],
                    question:
                      'Achieving Workforce Success means companies ____.',
                  },
                  {
                    question:
                      'Continually upskilling your workforce ____, and by extension, company profitability.',
                    options: [
                      {
                        text: 'improves engagement',
                        isCorrect: true,
                      },
                      {
                        tip: 'In fact, companies with successful workforce experience 37% higher productivity, 17% higher profitability. 🤑',
                        text: 'reduces engagement',
                        isCorrect: false,
                      },
                      {
                        tip: 'Nope! An engaged, empowered workforce inspires loyalty and productivity. 🙌',
                        text: 'endangers loyalty',
                        isCorrect: false,
                      },
                    ],
                  },
                  {
                    question:
                      'Companies that ____ will future-proof themselves for sustainable, long-term success. ',
                    options: [
                      {
                        text: 'employ continuous learning',
                        isCorrect: true,
                      },
                      {
                        tip: 'The world has changed and companies must change with it. 🌍',
                        text: 'use traditional training methods',
                        isCorrect: false,
                      },
                      {
                        tip: "Technology has altered peoples' preferences for how and when they learn. 📱",
                        text: "don't use mobile-based learning",
                        isCorrect: false,
                      },
                    ],
                  },
                ],
                shuffleAnswers: true,
              },
            },
            taskBoxText:
              'Keep going to find out what the benefits are of having a successful workforce. 🚀',
            taskBoxTitle: 'Quiz💡',
          },
        },
        {
          type: 'text',
          params: {
            title: 'How eduMe can help you achieve Workforce Success 🏆',
            description:
              '✅ Create continuous learning that’s **fun & rewarding.**\n✅ Enable your workforce to **stay informed** and up to date.\n✅ Give your workforce a voice and enabling **informed decisions.**\n✅ Provide powerful analytics to gather **valuable insight.**',
          },
        },
      ],
      learningOutcomes: {
        cheer: 'Good to go? 🚀',
        title: "Let's find out...",
        outcomes: [
          '...what Workforce Success is',
          '...how to achieve Workforce Success',
          '...what the benefits of Workforce Success are',
        ],
      },
      end: {
        title: 'Lesson complete',
        subtitle: 'Finish all the activities to complete this course.',
        icon: {
          url: DEFAULT_END_ICON,
        },
        button: {
          text: 'Next',
        },
      },
    },
  },
  pt: {
    type: 'lesson',
    version: 3.1,
    params: {
      title: 'Introduction to Workforce Success',
      previewImageUrl:
        'https://edume-res.cloudinary.com/image/upload/v1593612638/control_panel_media/yrabvr28resxn02ni5fp.png',
      intro: {
        greeting: "What's happening? 🤔",
        description:
          'The way people work is changing rapidly. This is driven by three seismic changes happening at the same time: \n\n📲80% of the global workforce is deskless\n\n👩‍💻75% of the workforce will be made up of millennials by 2025\n\n🌐The way we consume information and communicate has fundamentally changed with the advent of the mobile phone.',
        welcomeImageUrl:
          'https://edume-res.cloudinary.com/image/upload/v1593615967/control_panel_media/x633mb8ljlvh4jfoj1lm.png',
      },
      activities: [
        {
          type: 'text',
          params: {
            title: 'What is Workforce Success?',
            description:
              'Workforce Success is ensuring that your **workforce is empowered with the knowledge they require to be successful in their job.** It is the sustained empowerment of individuals that drives companies’ collective success. 💪',
          },
        },
        {
          type: 'interactive',
          params: {
            video: {
              url: 'v1594031169/control_panel_media/vrtsvo5iupumc0igvf9i.mp4',
              source: 'cloudinary',
            },
            background: '#0D003A',
            interaction: {
              type: 'standaloneQuiz',
              params: {
                prompt: 'Select ALL the statements that are TRUE',
                quizType: 'multipleChoice',
                questions: [
                  {
                    question:
                      'People and companies achieve Workforce Success by...',
                    options: [
                      {
                        tip: '3-5 minute lessons in short courses boost engagement and knowledge retention! 🤓',
                        text: 'delivering information in bite-size chunks',
                        isCorrect: true,
                      },
                      {
                        text: 'providing relevant information at the appropriate time',
                        isCorrect: true,
                      },
                      {
                        text: 'empowering your workforce with a voice',
                        isCorrect: true,
                      },
                      {
                        tip: 'Actually, you need to deliver regular learning opportunities where your workforce spend most of their time, their mobile phones! 🤳',
                        text: 'providing face-to-face training only twice a year',
                        isCorrect: false,
                      },
                    ],
                  },
                ],
                shuffleAnswers: true,
              },
            },
            taskBoxText:
              'Before you watch, think about the think about the approach you’re using to **train**, **inform** and **engage** your people. \nHow do you adapt to the changing environment to ensure that your workforce is equipped to **deliver their best?** 📈',
            taskBoxTitle: 'Video 🍿',
          },
        },
        {
          type: 'text',
          params: {
            title: "That's awesome!  🎉🎉",
            description:
              "Let's summarise how you can **empower your workforce to be successful.**\n\n✅ Reach people where they are, their **mobile phones**.\n✅ Give your people with the right information at the right time as part of a **continuous learning journey**.\n✅ Provide access to short-form content using **microlearning**.\n✅ Collect **feedback** by giving people a voice.",
          },
        },
        {
          type: 'interactive',
          params: {
            background: '#0D003A',
            interaction: {
              type: 'imageQuiz',
              params: {
                urls: [
                  'https://edume-res.cloudinary.com/image/upload/v1593680706/control_panel_media/ipsjactpeuj83zfq6ste.png',
                  'https://edume-res.cloudinary.com/image/upload/v1593681542/control_panel_media/iuh1e7nr3c0myx0ra4aj.png',
                  'https://edume-res.cloudinary.com/image/upload/v1593682503/control_panel_media/mi2gu67g0guzlzkhz3b2.png',
                ],
                thumbnailUrls: [
                  'https://edume-res.cloudinary.com/image/upload/v1593680706/control_panel_media/ipsjactpeuj83zfq6ste.png',
                  'https://edume-res.cloudinary.com/image/upload/v1593681542/control_panel_media/iuh1e7nr3c0myx0ra4aj.png',
                  'https://edume-res.cloudinary.com/image/upload/v1593682503/control_panel_media/mi2gu67g0guzlzkhz3b2.png',
                ],
                prompt: 'Complete the sentences.',
                quizType: 'singleChoice',
                questions: [
                  {
                    key: 'getting_started_with_microlearning9097_start_with_your_what_are_their_needs_and_what_s_the_learning_context_7724',
                    options: [
                      {
                        text: 'become innovators and industry leaders',
                        isCorrect: true,
                      },
                      {
                        tip: "It's quite the opposite. Engaged employees are more loyal and productive.💥 ",
                        text: 'experience high churn',
                        isCorrect: false,
                      },
                      {
                        tip: 'Workforce Success can increase sales productivity by up to 20%. 📈',
                        text: 'see profitability decrease',
                        isCorrect: false,
                      },
                    ],
                    question:
                      'Achieving Workforce Success means companies ____.',
                  },
                  {
                    question:
                      'Continually upskilling your workforce ____, and by extension, company profitability.',
                    options: [
                      {
                        text: 'improves engagement',
                        isCorrect: true,
                      },
                      {
                        tip: 'In fact, companies with successful workforce experience 37% higher productivity, 17% higher profitability. 🤑',
                        text: 'reduces engagement',
                        isCorrect: false,
                      },
                      {
                        tip: 'Nope! An engaged, empowered workforce inspires loyalty and productivity. 🙌',
                        text: 'endangers loyalty',
                        isCorrect: false,
                      },
                    ],
                  },
                  {
                    question:
                      'Companies that ____ will future-proof themselves for sustainable, long-term success. ',
                    options: [
                      {
                        text: 'employ continuous learning',
                        isCorrect: true,
                      },
                      {
                        tip: 'The world has changed and companies must change with it. 🌍',
                        text: 'use traditional training methods',
                        isCorrect: false,
                      },
                      {
                        tip: "Technology has altered peoples' preferences for how and when they learn. 📱",
                        text: "don't use mobile-based learning",
                        isCorrect: false,
                      },
                    ],
                  },
                ],
                shuffleAnswers: true,
              },
            },
            taskBoxText:
              'Keep going to find out what the benefits are of having a successful workforce. 🚀',
            taskBoxTitle: 'Quiz💡',
          },
        },
        {
          type: 'text',
          params: {
            title: 'How eduMe can help you achieve Workforce Success 🏆',
            description:
              '✅ Create continuous learning that’s **fun & rewarding.**\n✅ Enable your workforce to **stay informed** and up to date.\n✅ Give your workforce a voice and enabling **informed decisions.**\n✅ Provide powerful analytics to gather **valuable insight.**',
          },
        },
      ],
      learningOutcomes: {
        cheer: 'Good to go? 🚀',
        title: "Let's find out...",
        outcomes: [
          '...what Workforce Success is',
          '...how to achieve Workforce Success',
          '...what the benefits of Workforce Success are',
        ],
      },
      end: {
        title: 'Lesson complete',
        subtitle: 'Finish all the activities to complete this course.',
        icon: {
          url: DEFAULT_END_ICON,
        },
        button: {
          text: 'Next',
        },
      },
    },
  },
  fr: {
    type: 'lesson',
    version: 3.1,
    params: {
      title: 'Introduction to Workforce Success',
      previewImageUrl:
        'https://edume-res.cloudinary.com/image/upload/v1593612638/control_panel_media/yrabvr28resxn02ni5fp.png',
      intro: {
        greeting: "What's happening? 🤔",
        description:
          'The way people work is changing rapidly. This is driven by three seismic changes happening at the same time: \n\n📲80% of the global workforce is deskless\n\n👩‍💻75% of the workforce will be made up of millennials by 2025\n\n🌐The way we consume information and communicate has fundamentally changed with the advent of the mobile phone.',
        welcomeImageUrl:
          'https://edume-res.cloudinary.com/image/upload/v1593615967/control_panel_media/x633mb8ljlvh4jfoj1lm.png',
      },
      activities: [
        {
          type: 'text',
          params: {
            title: 'What is Workforce Success?',
            description:
              'Workforce Success is ensuring that your **workforce is empowered with the knowledge they require to be successful in their job.** It is the sustained empowerment of individuals that drives companies’ collective success. 💪',
          },
        },
        {
          type: 'interactive',
          params: {
            video: {
              url: 'v1594031169/control_panel_media/vrtsvo5iupumc0igvf9i.mp4',
              source: 'cloudinary',
            },
            background: '#0D003A',
            interaction: {
              type: 'standaloneQuiz',
              params: {
                prompt: 'Select ALL the statements that are TRUE',
                quizType: 'multipleChoice',
                questions: [
                  {
                    question:
                      'People and companies achieve Workforce Success by...',
                    options: [
                      {
                        tip: '3-5 minute lessons in short courses boost engagement and knowledge retention! 🤓',
                        text: 'delivering information in bite-size chunks',
                        isCorrect: true,
                      },
                      {
                        text: 'providing relevant information at the appropriate time',
                        isCorrect: true,
                      },
                      {
                        text: 'empowering your workforce with a voice',
                        isCorrect: true,
                      },
                      {
                        tip: 'Actually, you need to deliver regular learning opportunities where your workforce spend most of their time, their mobile phones! 🤳',
                        text: 'providing face-to-face training only twice a year',
                        isCorrect: false,
                      },
                    ],
                  },
                ],
                shuffleAnswers: true,
              },
            },
            taskBoxText:
              'Before you watch, think about the think about the approach you’re using to **train**, **inform** and **engage** your people. \nHow do you adapt to the changing environment to ensure that your workforce is equipped to **deliver their best?** 📈',
            taskBoxTitle: 'Video 🍿',
          },
        },
        {
          type: 'text',
          params: {
            title: "That's awesome!  🎉🎉",
            description:
              "Let's summarise how you can **empower your workforce to be successful.**\n\n✅ Reach people where they are, their **mobile phones**.\n✅ Give your people with the right information at the right time as part of a **continuous learning journey**.\n✅ Provide access to short-form content using **microlearning**.\n✅ Collect **feedback** by giving people a voice.",
          },
        },
        {
          type: 'interactive',
          params: {
            background: '#0D003A',
            interaction: {
              type: 'imageQuiz',
              params: {
                urls: [
                  'https://edume-res.cloudinary.com/image/upload/v1593680706/control_panel_media/ipsjactpeuj83zfq6ste.png',
                  'https://edume-res.cloudinary.com/image/upload/v1593681542/control_panel_media/iuh1e7nr3c0myx0ra4aj.png',
                  'https://edume-res.cloudinary.com/image/upload/v1593682503/control_panel_media/mi2gu67g0guzlzkhz3b2.png',
                ],
                thumbnailUrls: [
                  'https://edume-res.cloudinary.com/image/upload/v1593680706/control_panel_media/ipsjactpeuj83zfq6ste.png',
                  'https://edume-res.cloudinary.com/image/upload/v1593681542/control_panel_media/iuh1e7nr3c0myx0ra4aj.png',
                  'https://edume-res.cloudinary.com/image/upload/v1593682503/control_panel_media/mi2gu67g0guzlzkhz3b2.png',
                ],
                prompt: 'Complete the sentences.',
                quizType: 'singleChoice',
                questions: [
                  {
                    key: 'getting_started_with_microlearning9097_start_with_your_what_are_their_needs_and_what_s_the_learning_context_7724',
                    options: [
                      {
                        text: 'become innovators and industry leaders',
                        isCorrect: true,
                      },
                      {
                        tip: "It's quite the opposite. Engaged employees are more loyal and productive.💥 ",
                        text: 'experience high churn',
                        isCorrect: false,
                      },
                      {
                        tip: 'Workforce Success can increase sales productivity by up to 20%. 📈',
                        text: 'see profitability decrease',
                        isCorrect: false,
                      },
                    ],
                    question:
                      'Achieving Workforce Success means companies ____.',
                  },
                  {
                    question:
                      'Continually upskilling your workforce ____, and by extension, company profitability.',
                    options: [
                      {
                        text: 'improves engagement',
                        isCorrect: true,
                      },
                      {
                        tip: 'In fact, companies with successful workforce experience 37% higher productivity, 17% higher profitability. 🤑',
                        text: 'reduces engagement',
                        isCorrect: false,
                      },
                      {
                        tip: 'Nope! An engaged, empowered workforce inspires loyalty and productivity. 🙌',
                        text: 'endangers loyalty',
                        isCorrect: false,
                      },
                    ],
                  },
                  {
                    question:
                      'Companies that ____ will future-proof themselves for sustainable, long-term success. ',
                    options: [
                      {
                        text: 'employ continuous learning',
                        isCorrect: true,
                      },
                      {
                        tip: 'The world has changed and companies must change with it. 🌍',
                        text: 'use traditional training methods',
                        isCorrect: false,
                      },
                      {
                        tip: "Technology has altered peoples' preferences for how and when they learn. 📱",
                        text: "don't use mobile-based learning",
                        isCorrect: false,
                      },
                    ],
                  },
                ],
                shuffleAnswers: true,
              },
            },
            taskBoxText:
              'Keep going to find out what the benefits are of having a successful workforce. 🚀',
            taskBoxTitle: 'Quiz💡',
          },
        },
        {
          type: 'text',
          params: {
            title: 'How eduMe can help you achieve Workforce Success 🏆',
            description:
              '✅ Create continuous learning that’s **fun & rewarding.**\n✅ Enable your workforce to **stay informed** and up to date.\n✅ Give your workforce a voice and enabling **informed decisions.**\n✅ Provide powerful analytics to gather **valuable insight.**',
          },
        },
      ],
      learningOutcomes: {
        cheer: 'Good to go? 🚀',
        title: "Let's find out...",
        outcomes: [
          '...what Workforce Success is',
          '...how to achieve Workforce Success',
          '...what the benefits of Workforce Success are',
        ],
      },
      end: {
        title: 'Lesson complete',
        subtitle: 'Finish all the activities to complete this course.',
        icon: {
          url: DEFAULT_END_ICON,
        },
        button: {
          text: 'Next',
        },
      },
    },
  },
  de: {
    type: 'lesson',
    version: 3.1,
    params: {
      title: 'Introduction to Workforce Success',
      previewImageUrl:
        'https://edume-res.cloudinary.com/image/upload/v1593612638/control_panel_media/yrabvr28resxn02ni5fp.png',
      intro: {
        greeting: "What's happening? 🤔",
        description:
          'The way people work is changing rapidly. This is driven by three seismic changes happening at the same time: \n\n📲80% of the global workforce is deskless\n\n👩‍💻75% of the workforce will be made up of millennials by 2025\n\n🌐The way we consume information and communicate has fundamentally changed with the advent of the mobile phone.',
        welcomeImageUrl:
          'https://edume-res.cloudinary.com/image/upload/v1593615967/control_panel_media/x633mb8ljlvh4jfoj1lm.png',
      },
      activities: [
        {
          type: 'text',
          params: {
            title: 'What is Workforce Success?',
            description:
              'Workforce Success is ensuring that your **workforce is empowered with the knowledge they require to be successful in their job.** It is the sustained empowerment of individuals that drives companies’ collective success. 💪',
          },
        },
        {
          type: 'interactive',
          params: {
            video: {
              url: 'v1594031169/control_panel_media/vrtsvo5iupumc0igvf9i.mp4',
              source: 'cloudinary',
            },
            background: '#0D003A',
            interaction: {
              type: 'standaloneQuiz',
              params: {
                prompt: 'Select ALL the statements that are TRUE',
                quizType: 'multipleChoice',
                questions: [
                  {
                    question:
                      'People and companies achieve Workforce Success by...',
                    options: [
                      {
                        tip: '3-5 minute lessons in short courses boost engagement and knowledge retention! 🤓',
                        text: 'delivering information in bite-size chunks',
                        isCorrect: true,
                      },
                      {
                        text: 'providing relevant information at the appropriate time',
                        isCorrect: true,
                      },
                      {
                        text: 'empowering your workforce with a voice',
                        isCorrect: true,
                      },
                      {
                        tip: 'Actually, you need to deliver regular learning opportunities where your workforce spend most of their time, their mobile phones! 🤳',
                        text: 'providing face-to-face training only twice a year',
                        isCorrect: false,
                      },
                    ],
                  },
                ],
                shuffleAnswers: true,
              },
            },
            taskBoxText:
              'Before you watch, think about the think about the approach you’re using to **train**, **inform** and **engage** your people. \nHow do you adapt to the changing environment to ensure that your workforce is equipped to **deliver their best?** 📈',
            taskBoxTitle: 'Video 🍿',
          },
        },
        {
          type: 'text',
          params: {
            title: "That's awesome!  🎉🎉",
            description:
              "Let's summarise how you can **empower your workforce to be successful.**\n\n✅ Reach people where they are, their **mobile phones**.\n✅ Give your people with the right information at the right time as part of a **continuous learning journey**.\n✅ Provide access to short-form content using **microlearning**.\n✅ Collect **feedback** by giving people a voice.",
          },
        },
        {
          type: 'interactive',
          params: {
            background: '#0D003A',
            interaction: {
              type: 'imageQuiz',
              params: {
                urls: [
                  'https://edume-res.cloudinary.com/image/upload/v1593680706/control_panel_media/ipsjactpeuj83zfq6ste.png',
                  'https://edume-res.cloudinary.com/image/upload/v1593681542/control_panel_media/iuh1e7nr3c0myx0ra4aj.png',
                  'https://edume-res.cloudinary.com/image/upload/v1593682503/control_panel_media/mi2gu67g0guzlzkhz3b2.png',
                ],
                prompt: 'Complete the sentences.',
                quizType: 'singleChoice',
                questions: [
                  {
                    key: 'getting_started_with_microlearning9097_start_with_your_what_are_their_needs_and_what_s_the_learning_context_7724',
                    options: [
                      {
                        text: 'become innovators and industry leaders',
                        isCorrect: true,
                      },
                      {
                        tip: "It's quite the opposite. Engaged employees are more loyal and productive.💥 ",
                        text: 'experience high churn',
                        isCorrect: false,
                      },
                      {
                        tip: 'Workforce Success can increase sales productivity by up to 20%. 📈',
                        text: 'see profitability decrease',
                        isCorrect: false,
                      },
                    ],
                    question:
                      'Achieving Workforce Success means companies ____.',
                  },
                  {
                    question:
                      'Continually upskilling your workforce ____, and by extension, company profitability.',
                    options: [
                      {
                        text: 'improves engagement',
                        isCorrect: true,
                      },
                      {
                        tip: 'In fact, companies with successful workforce experience 37% higher productivity, 17% higher profitability. 🤑',
                        text: 'reduces engagement',
                        isCorrect: false,
                      },
                      {
                        tip: 'Nope! An engaged, empowered workforce inspires loyalty and productivity. 🙌',
                        text: 'endangers loyalty',
                        isCorrect: false,
                      },
                    ],
                  },
                  {
                    question:
                      'Companies that ____ will future-proof themselves for sustainable, long-term success. ',
                    options: [
                      {
                        text: 'employ continuous learning',
                        isCorrect: true,
                      },
                      {
                        tip: 'The world has changed and companies must change with it. 🌍',
                        text: 'use traditional training methods',
                        isCorrect: false,
                      },
                      {
                        tip: "Technology has altered peoples' preferences for how and when they learn. 📱",
                        text: "don't use mobile-based learning",
                        isCorrect: false,
                      },
                    ],
                  },
                ],
                thumbnailUrls: [
                  'https://edume-res.cloudinary.com/image/upload/v1593680706/control_panel_media/ipsjactpeuj83zfq6ste.png',
                  'https://edume-res.cloudinary.com/image/upload/v1593681542/control_panel_media/iuh1e7nr3c0myx0ra4aj.png',
                  'https://edume-res.cloudinary.com/image/upload/v1593682503/control_panel_media/mi2gu67g0guzlzkhz3b2.png',
                ],
                shuffleAnswers: true,
              },
            },
            taskBoxText:
              'Keep going to find out what the benefits are of having a successful workforce. 🚀',
            taskBoxTitle: 'Quiz💡',
          },
        },
        {
          type: 'text',
          params: {
            title: 'How eduMe can help you achieve Workforce Success 🏆',
            description:
              '✅ Create continuous learning that’s **fun & rewarding.**\n✅ Enable your workforce to **stay informed** and up to date.\n✅ Give your workforce a voice and enabling **informed decisions.**\n✅ Provide powerful analytics to gather **valuable insight.**',
          },
        },
      ],
      learningOutcomes: {
        cheer: 'Good to go? 🚀',
        title: "Let's find out...",
        outcomes: [
          '...what Workforce Success is',
          '...how to achieve Workforce Success',
          '...what the benefits of Workforce Success are',
        ],
      },
      end: {
        title: 'Lesson complete',
        subtitle: 'Finish all the activities to complete this course.',
        icon: {
          url: DEFAULT_END_ICON,
        },
        button: {
          text: 'Next',
        },
      },
    },
  },
};

const conversationalBlank = {
  type: 'lesson',
  version: 3.1,
  params: {
    intro: {
      greeting: '',
      description: '',
      welcomeImageUrl: '',
    },
    learningOutcomes: {
      title: '',
      outcomes: [''],
      image: null,
      cheer: '',
      background: '#FFFFFF',
    },
    activities: [],
    end: {
      title: 'Lesson complete',
      subtitle: 'Finish all the activities to complete this course.',
      icon: {
        url: DEFAULT_END_ICON,
      },
      button: {
        text: 'Next',
      },
    },
  },
};

const slideshowExample = {
  en: {
    type: 'lesson',
    version: 4,
    params: {
      endOfActivityCustomTextOptions: true,
      title: 'The basics: how to structure a lesson',
      previewImageUrl: `https://d13dt4xa7zzqg.cloudfront.net/image/upload/x_100,y_70,w_800,h_400,c_crop/w_800,h_400,c_pad/${media.DEFAULT_CLOUDINARY_METADATA}v1607436843/edume_image_library/checklist.png`,
      intro: {
        image: null,
        button: {
          text: 'Tell me more! 🤔',
        },
        background: '#57D6C8',
        fontColour: '#1E1E34',
        isLightButtonStyle: true,
        title: 'Welcome! 🤗 ',
        description:
          'Creating an eduMe lesson is a little like cooking. You could go ahead based on instinct and still come out with something good. 👩🏻‍🍳\n\nOr you could follow a **tried and tested** recipe to create something great! ✨',
      },
      learningOutcomes: {
        image: null,
        background: '#57D6C8',
        fontColour: '#1E1E34',
        isLightButtonStyle: true,
        cheer: "Ready? Let's go! 🚀",
        title: "In just 5 minutes, you'll know...",
        outcomes: [
          'How to structure your lesson',
          'Why learning outcomes matter',
          'What to include in a recap page',
        ],
      },
      activities: [
        {
          type: 'text',
          params: {
            image: null,
            background: '#FFFFFF',
            fontColour: '#1E1E34',
            isLightButtonStyle: false,
            title: 'How to structure your lesson',
            description:
              'We think great lessons include the following:\n\n* A welcome or introduction page\n* Learning outcomes\n* Media (images/videos)\n* A quiz\n* A recap\n\nAround 5 minutes per lesson is ideal for the best chance of content retention. ',
          },
        },
        {
          type: 'text',
          params: {
            image: {
              width: 6406,
              height: 5125,
              position: 'top',
              url: `https://d13dt4xa7zzqg.cloudfront.net/image/upload/x_813,y_60,w_6406,h_5125,c_crop/w_6406,h_5125,c_pad/${media.DEFAULT_CLOUDINARY_METADATA}v1663324277/control_panel_media/aabe28a7xfcfevrntq6u.jpg`,
            },
            background: '#57D6C8',
            fontColour: '#1E1E34',
            isLightButtonStyle: true,
            title: 'Why do learning outcomes matter?',
            description:
              'Learning outcomes or objectives are what all lessons should be **structured around**.\n\nThey are the **core concepts** that will support content creators to include only **relevant information** and design appropriate assessments. ✅\n\nThey also support learners in **information processing** by distilling lots of information into key points. 🎯',
          },
        },
        {
          type: 'text',
          params: {
            image: null,
            background: '#FFFFFF',
            fontColour: '#1E1E34',
            isLightButtonStyle: false,
            title: 'How to write good learning outcomes',
            description:
              'Learning outcomes should be **specific and measurable**, related to skills/knowledge learners will gain. They can be framed as "by the end of this lesson, you will be able to…"\n\n**Everything** in a lesson should be related to one or more of the outcomes. If not, it is extraneous information that can be removed. ❌\n\nFor a 5-minute lesson, we would expect to see **no more than 3 learning outcomes**. ',
          },
        },
        {
          type: 'text',
          params: {
            image: {
              width: 4098,
              height: 3279,
              position: 'top',
              url: `https://d13dt4xa7zzqg.cloudfront.net/image/upload/x_1884,y_669,w_4099,h_3279,c_crop/w_4098,h_3279,c_pad/${media.DEFAULT_CLOUDINARY_METADATA}v1663324377/control_panel_media/rnyuudxo2ki4tysesowf.jpg`,
            },
            background: '#57D6C8',
            fontColour: '#1E1E34',
            isLightButtonStyle: true,
            title: "Don't forget a recap page!",
            description:
              "The recap page is the opportunity to reiterate the key points of the lesson - **repetition is key** for content retention! It should state what learners should now be able to do and outline any next steps. ⏩\n\nAim for learners to be able to 'tick off' this list and be **confident** they know what it means. ✔️ \n",
          },
        },
        {
          type: 'interactive',
          params: {
            background: '#0D003A',
            fontColour: '#FFFFFF',
            interaction: {
              type: 'imageQuiz',
              params: {
                urls: ['', '', ''],
                quizType: 'singleChoice',
                questions: [
                  {
                    options: [
                      {
                        isCorrect: true,
                        tip: "That's it! 5 minutes is ideal for the best chance of knowledge retention",
                        text: '5 minutes ',
                      },
                      {
                        isCorrect: false,
                        tip: "Not quite! 5 minutes is a good length to hold learners' attention.",
                        text: '10 minutes including a video',
                      },
                      {
                        isCorrect: false,
                        tip: "Not quite! 5 minutes is a good length to hold learners' attention.",
                        text: '3 minutes per learning outcomes',
                      },
                    ],
                    hasVideoFeedback: false,
                    question: "What's the ideal maximum length of a lesson?",
                  },
                  {
                    options: [
                      {
                        isCorrect: true,
                        tip: "That's it! 2-3 learning outcomes is ideal.",
                        text: '2-3 for a 5 minute lesson',
                      },
                      {
                        isCorrect: false,
                        tip: 'Not quite! 2-3 learning outcomes is ideal.',
                        text: '1',
                      },
                      {
                        isCorrect: false,
                        tip: 'Not quite! 2-3 learning outcomes is ideal.',
                        text: 'No more than 5',
                      },
                    ],
                    hasVideoFeedback: false,
                    question:
                      'How many learning outcomes are suggested for a 5-minute lesson?',
                  },
                  {
                    options: [
                      {
                        isCorrect: true,
                        tip: "That's it! A recap should repeat the key points and takeaways.",
                        text: 'reiterate the key points of the lesson.',
                      },
                      {
                        isCorrect: false,
                        tip: 'Not quite! The recap should reiterate content of the lesson rather than new information.',
                        text: 'introduce the topic of the next lesson.  ',
                      },
                      {
                        isCorrect: false,
                        tip: "Not quite! The recap should reiterate content of the lesson but doesn't need to be an exact copy.",
                        text: 'be a direct copy of your learning outcomes page.',
                      },
                    ],
                    hasVideoFeedback: false,
                    question: 'Your recap should...',
                  },
                ],
                thumbnailUrls: ['', '', ''],
                progressButton: {
                  text: 'Submit',
                },
                shuffleAnswers: true,
                prompt: 'Choose the correct answer',
              },
            },
            taskBoxButton: {
              text: 'Take the quiz',
            },
            isLightButtonStyle: true,
            taskBoxText: "Time to put what you've read to the test!",
            taskBoxTitle: 'Quiz',
          },
        },
        {
          type: 'text',
          params: {
            image: {
              width: 4518,
              height: 3615,
              position: 'top',
              url: `https://d13dt4xa7zzqg.cloudfront.net/image/upload/x_107,y_106,w_4519,h_3615,c_crop/w_4518,h_3615,c_pad/${media.DEFAULT_CLOUDINARY_METADATA}v1663324674/control_panel_media/wbbtoacopdfqkhdkvp0y.jpg`,
            },
            background: '#57D6C8',
            fontColour: '#1E1E34',
            isLightButtonStyle: true,
            title: 'Now you know...',
            description:
              '* To include an intro, learning outcomes, media, a quiz and a recap\n\n* Your content should **stem from your learning outcomes**, 3 is ideal\n\n* Lessons should be around 5 minutes in length\n\nGive it a go! 👍👍',
          },
        },
      ],
      end: {
        icon: {
          url: DEFAULT_END_ICON,
        },
        button: {
          text: 'Next',
        },
        background: '#57D6C8',
        fontColour: '#1E1E34',
        isLightButtonStyle: false,
        title: 'Congratulations!',
        subtitle: "You've completed your first lesson!",
      },
    },
  },
  es: {
    type: 'lesson',
    version: 4,
    params: {
      endOfActivityCustomTextOptions: true,
      title: 'The basics: how to structure a lesson',
      previewImageUrl: `https://d13dt4xa7zzqg.cloudfront.net/image/upload/x_100,y_70,w_800,h_400,c_crop/w_800,h_400,c_pad/${media.DEFAULT_CLOUDINARY_METADATA}v1607436843/edume_image_library/checklist.png`,
      intro: {
        image: null,
        button: {
          text: 'Tell me more! 🤔',
        },
        background: '#57D6C8',
        fontColour: '#1E1E34',
        isLightButtonStyle: true,
        title: 'Welcome! 🤗 ',
        description:
          'Creating an eduMe lesson is a little like cooking. You could go ahead based on instinct and still come out with something good. 👩🏻‍🍳\n\nOr you could follow a **tried and tested** recipe to create something great! ✨',
      },
      learningOutcomes: {
        image: null,
        background: '#57D6C8',
        fontColour: '#1E1E34',
        isLightButtonStyle: true,
        cheer: "Ready? Let's go! 🚀",
        title: "In just 5 minutes, you'll know...",
        outcomes: [
          'How to structure your lesson',
          'Why learning outcomes matter',
          'What to include in a recap page',
        ],
      },
      activities: [
        {
          type: 'text',
          params: {
            image: null,
            background: '#FFFFFF',
            fontColour: '#1E1E34',
            isLightButtonStyle: false,
            title: 'How to structure your lesson',
            description:
              'We think great lessons include the following:\n\n* A welcome or introduction page\n* Learning outcomes\n* Media (images/videos)\n* A quiz\n* A recap\n\nAround 5 minutes per lesson is ideal for the best chance of content retention. ',
          },
        },
        {
          type: 'text',
          params: {
            image: {
              width: 6406,
              height: 5125,
              position: 'top',
              url: `https://d13dt4xa7zzqg.cloudfront.net/image/upload/x_813,y_60,w_6406,h_5125,c_crop/w_6406,h_5125,c_pad/${media.DEFAULT_CLOUDINARY_METADATA}v1663324277/control_panel_media/aabe28a7xfcfevrntq6u.jpg`,
            },
            background: '#57D6C8',
            fontColour: '#1E1E34',
            isLightButtonStyle: true,
            title: 'Why do learning outcomes matter?',
            description:
              'Learning outcomes or objectives are what all lessons should be **structured around**.\n\nThey are the **core concepts** that will support content creators to include only **relevant information** and design appropriate assessments. ✅\n\nThey also support learners in **information processing** by distilling lots of information into key points. 🎯',
          },
        },
        {
          type: 'text',
          params: {
            image: null,
            background: '#FFFFFF',
            fontColour: '#1E1E34',
            isLightButtonStyle: false,
            title: 'How to write good learning outcomes',
            description:
              'Learning outcomes should be **specific and measurable**, related to skills/knowledge learners will gain. They can be framed as "by the end of this lesson, you will be able to…"\n\n**Everything** in a lesson should be related to one or more of the outcomes. If not, it is extraneous information that can be removed. ❌\n\nFor a 5-minute lesson, we would expect to see **no more than 3 learning outcomes**. ',
          },
        },
        {
          type: 'text',
          params: {
            image: {
              width: 4098,
              height: 3279,
              position: 'top',
              url: `https://d13dt4xa7zzqg.cloudfront.net/image/upload/x_1884,y_669,w_4099,h_3279,c_crop/w_4098,h_3279,c_pad/${media.DEFAULT_CLOUDINARY_METADATA}v1663324377/control_panel_media/rnyuudxo2ki4tysesowf.jpg`,
            },
            background: '#57D6C8',
            fontColour: '#1E1E34',
            isLightButtonStyle: true,
            title: "Don't forget a recap page!",
            description:
              "The recap page is the opportunity to reiterate the key points of the lesson - **repetition is key** for content retention! It should state what learners should now be able to do and outline any next steps. ⏩\n\nAim for learners to be able to 'tick off' this list and be **confident** they know what it means. ✔️ \n",
          },
        },
        {
          type: 'interactive',
          params: {
            background: '#0D003A',
            fontColour: '#FFFFFF',
            interaction: {
              type: 'imageQuiz',
              params: {
                urls: ['', '', ''],
                quizType: 'singleChoice',
                questions: [
                  {
                    options: [
                      {
                        isCorrect: true,
                        tip: "That's it! 5 minutes is ideal for the best chance of knowledge retention",
                        text: '5 minutes ',
                      },
                      {
                        isCorrect: false,
                        tip: "Not quite! 5 minutes is a good length to hold learners' attention.",
                        text: '10 minutes including a video',
                      },
                      {
                        isCorrect: false,
                        tip: "Not quite! 5 minutes is a good length to hold learners' attention.",
                        text: '3 minutes per learning outcomes',
                      },
                    ],
                    hasVideoFeedback: false,
                    question: "What's the ideal maximum length of a lesson?",
                  },
                  {
                    options: [
                      {
                        isCorrect: true,
                        tip: "That's it! 2-3 learning outcomes is ideal.",
                        text: '2-3 for a 5 minute lesson',
                      },
                      {
                        isCorrect: false,
                        tip: 'Not quite! 2-3 learning outcomes is ideal.',
                        text: '1',
                      },
                      {
                        isCorrect: false,
                        tip: 'Not quite! 2-3 learning outcomes is ideal.',
                        text: 'No more than 5',
                      },
                    ],
                    hasVideoFeedback: false,
                    question:
                      'How many learning outcomes are suggested for a 5-minute lesson?',
                  },
                  {
                    options: [
                      {
                        isCorrect: true,
                        tip: "That's it! A recap should repeat the key points and takeaways.",
                        text: 'reiterate the key points of the lesson.',
                      },
                      {
                        isCorrect: false,
                        tip: 'Not quite! The recap should reiterate content of the lesson rather than new information.',
                        text: 'introduce the topic of the next lesson.  ',
                      },
                      {
                        isCorrect: false,
                        tip: "Not quite! The recap should reiterate content of the lesson but doesn't need to be an exact copy.",
                        text: 'be a direct copy of your learning outcomes page.',
                      },
                    ],
                    hasVideoFeedback: false,
                    question: 'Your recap should...',
                  },
                ],
                thumbnailUrls: ['', '', ''],
                progressButton: {
                  text: 'Submit',
                },
                shuffleAnswers: true,
                prompt: 'Choose the correct answer',
              },
            },
            taskBoxButton: {
              text: 'Take the quiz',
            },
            isLightButtonStyle: true,
            taskBoxText: "Time to put what you've read to the test!",
            taskBoxTitle: 'Quiz',
          },
        },
        {
          type: 'text',
          params: {
            image: {
              width: 4518,
              height: 3615,
              position: 'top',
              url: `https://d13dt4xa7zzqg.cloudfront.net/image/upload/x_107,y_106,w_4519,h_3615,c_crop/w_4518,h_3615,c_pad/${media.DEFAULT_CLOUDINARY_METADATA}v1663324674/control_panel_media/wbbtoacopdfqkhdkvp0y.jpg`,
            },
            background: '#57D6C8',
            fontColour: '#1E1E34',
            isLightButtonStyle: true,
            title: 'Now you know...',
            description:
              '* To include an intro, learning outcomes, media, a quiz and a recap\n\n* Your content should **stem from your learning outcomes**, 3 is ideal\n\n* Lessons should be around 5 minutes in length\n\nGive it a go! 👍👍',
          },
        },
      ],
      end: {
        icon: {
          url: DEFAULT_END_ICON,
        },
        button: {
          text: 'Next',
        },
        background: '#57D6C8',
        fontColour: '#1E1E34',
        isLightButtonStyle: false,
        title: 'Congratulations!',
        subtitle: "You've completed your first lesson!",
      },
    },
  },
  pt: {
    type: 'lesson',
    version: 4,
    params: {
      endOfActivityCustomTextOptions: true,
      title: 'The basics: how to structure a lesson',
      previewImageUrl: `https://d13dt4xa7zzqg.cloudfront.net/image/upload/x_100,y_70,w_800,h_400,c_crop/w_800,h_400,c_pad/${media.DEFAULT_CLOUDINARY_METADATA}v1607436843/edume_image_library/checklist.png`,
      intro: {
        image: null,
        button: {
          text: 'Tell me more! 🤔',
        },
        background: '#57D6C8',
        fontColour: '#1E1E34',
        isLightButtonStyle: true,
        title: 'Welcome! 🤗 ',
        description:
          'Creating an eduMe lesson is a little like cooking. You could go ahead based on instinct and still come out with something good. 👩🏻‍🍳\n\nOr you could follow a **tried and tested** recipe to create something great! ✨',
      },
      learningOutcomes: {
        image: null,
        background: '#57D6C8',
        fontColour: '#1E1E34',
        isLightButtonStyle: true,
        cheer: "Ready? Let's go! 🚀",
        title: "In just 5 minutes, you'll know...",
        outcomes: [
          'How to structure your lesson',
          'Why learning outcomes matter',
          'What to include in a recap page',
        ],
      },
      activities: [
        {
          type: 'text',
          params: {
            image: null,
            background: '#FFFFFF',
            fontColour: '#1E1E34',
            isLightButtonStyle: false,
            title: 'How to structure your lesson',
            description:
              'We think great lessons include the following:\n\n* A welcome or introduction page\n* Learning outcomes\n* Media (images/videos)\n* A quiz\n* A recap\n\nAround 5 minutes per lesson is ideal for the best chance of content retention. ',
          },
        },
        {
          type: 'text',
          params: {
            image: {
              width: 6406,
              height: 5125,
              position: 'top',
              url: `https://d13dt4xa7zzqg.cloudfront.net/image/upload/x_813,y_60,w_6406,h_5125,c_crop/w_6406,h_5125,c_pad/${media.DEFAULT_CLOUDINARY_METADATA}v1663324277/control_panel_media/aabe28a7xfcfevrntq6u.jpg`,
            },
            background: '#57D6C8',
            fontColour: '#1E1E34',
            isLightButtonStyle: true,
            title: 'Why do learning outcomes matter?',
            description:
              'Learning outcomes or objectives are what all lessons should be **structured around**.\n\nThey are the **core concepts** that will support content creators to include only **relevant information** and design appropriate assessments. ✅\n\nThey also support learners in **information processing** by distilling lots of information into key points. 🎯',
          },
        },
        {
          type: 'text',
          params: {
            image: null,
            background: '#FFFFFF',
            fontColour: '#1E1E34',
            isLightButtonStyle: false,
            title: 'How to write good learning outcomes',
            description:
              'Learning outcomes should be **specific and measurable**, related to skills/knowledge learners will gain. They can be framed as "by the end of this lesson, you will be able to…"\n\n**Everything** in a lesson should be related to one or more of the outcomes. If not, it is extraneous information that can be removed. ❌\n\nFor a 5-minute lesson, we would expect to see **no more than 3 learning outcomes**. ',
          },
        },
        {
          type: 'text',
          params: {
            image: {
              width: 4098,
              height: 3279,
              position: 'top',
              url: `https://d13dt4xa7zzqg.cloudfront.net/image/upload/x_1884,y_669,w_4099,h_3279,c_crop/w_4098,h_3279,c_pad/${media.DEFAULT_CLOUDINARY_METADATA}v1663324377/control_panel_media/rnyuudxo2ki4tysesowf.jpg`,
            },
            background: '#57D6C8',
            fontColour: '#1E1E34',
            isLightButtonStyle: true,
            title: "Don't forget a recap page!",
            description:
              "The recap page is the opportunity to reiterate the key points of the lesson - **repetition is key** for content retention! It should state what learners should now be able to do and outline any next steps. ⏩\n\nAim for learners to be able to 'tick off' this list and be **confident** they know what it means. ✔️ \n",
          },
        },
        {
          type: 'interactive',
          params: {
            background: '#0D003A',
            fontColour: '#FFFFFF',
            interaction: {
              type: 'imageQuiz',
              params: {
                urls: ['', '', ''],
                quizType: 'singleChoice',
                questions: [
                  {
                    options: [
                      {
                        isCorrect: true,
                        tip: "That's it! 5 minutes is ideal for the best chance of knowledge retention",
                        text: '5 minutes ',
                      },
                      {
                        isCorrect: false,
                        tip: "Not quite! 5 minutes is a good length to hold learners' attention.",
                        text: '10 minutes including a video',
                      },
                      {
                        isCorrect: false,
                        tip: "Not quite! 5 minutes is a good length to hold learners' attention.",
                        text: '3 minutes per learning outcomes',
                      },
                    ],
                    hasVideoFeedback: false,
                    question: "What's the ideal maximum length of a lesson?",
                  },
                  {
                    options: [
                      {
                        isCorrect: true,
                        tip: "That's it! 2-3 learning outcomes is ideal.",
                        text: '2-3 for a 5 minute lesson',
                      },
                      {
                        isCorrect: false,
                        tip: 'Not quite! 2-3 learning outcomes is ideal.',
                        text: '1',
                      },
                      {
                        isCorrect: false,
                        tip: 'Not quite! 2-3 learning outcomes is ideal.',
                        text: 'No more than 5',
                      },
                    ],
                    hasVideoFeedback: false,
                    question:
                      'How many learning outcomes are suggested for a 5-minute lesson?',
                  },
                  {
                    options: [
                      {
                        isCorrect: true,
                        tip: "That's it! A recap should repeat the key points and takeaways.",
                        text: 'reiterate the key points of the lesson.',
                      },
                      {
                        isCorrect: false,
                        tip: 'Not quite! The recap should reiterate content of the lesson rather than new information.',
                        text: 'introduce the topic of the next lesson.  ',
                      },
                      {
                        isCorrect: false,
                        tip: "Not quite! The recap should reiterate content of the lesson but doesn't need to be an exact copy.",
                        text: 'be a direct copy of your learning outcomes page.',
                      },
                    ],
                    hasVideoFeedback: false,
                    question: 'Your recap should...',
                  },
                ],
                thumbnailUrls: ['', '', ''],
                progressButton: {
                  text: 'Submit',
                },
                shuffleAnswers: true,
                prompt: 'Choose the correct answer',
              },
            },
            taskBoxButton: {
              text: 'Take the quiz',
            },
            isLightButtonStyle: true,
            taskBoxText: "Time to put what you've read to the test!",
            taskBoxTitle: 'Quiz',
          },
        },
        {
          type: 'text',
          params: {
            image: {
              width: 4518,
              height: 3615,
              position: 'top',
              url: `https://d13dt4xa7zzqg.cloudfront.net/image/upload/x_107,y_106,w_4519,h_3615,c_crop/w_4518,h_3615,c_pad/${media.DEFAULT_CLOUDINARY_METADATA}v1663324674/control_panel_media/wbbtoacopdfqkhdkvp0y.jpg`,
            },
            background: '#57D6C8',
            fontColour: '#1E1E34',
            isLightButtonStyle: true,
            title: 'Now you know...',
            description:
              '* To include an intro, learning outcomes, media, a quiz and a recap\n\n* Your content should **stem from your learning outcomes**, 3 is ideal\n\n* Lessons should be around 5 minutes in length\n\nGive it a go! 👍👍',
          },
        },
      ],
      end: {
        icon: {
          url: DEFAULT_END_ICON,
        },
        button: {
          text: 'Next',
        },
        background: '#57D6C8',
        fontColour: '#1E1E34',
        isLightButtonStyle: false,
        title: 'Congratulations!',
        subtitle: "You've completed your first lesson!",
      },
    },
  },
  fr: {
    type: 'lesson',
    version: 4,
    params: {
      endOfActivityCustomTextOptions: true,
      title: 'The basics: how to structure a lesson',
      previewImageUrl: `https://d13dt4xa7zzqg.cloudfront.net/image/upload/x_100,y_70,w_800,h_400,c_crop/w_800,h_400,c_pad/${media.DEFAULT_CLOUDINARY_METADATA}v1607436843/edume_image_library/checklist.png`,
      intro: {
        image: null,
        button: {
          text: 'Tell me more! 🤔',
        },
        background: '#57D6C8',
        fontColour: '#1E1E34',
        isLightButtonStyle: true,
        title: 'Welcome! 🤗 ',
        description:
          'Creating an eduMe lesson is a little like cooking. You could go ahead based on instinct and still come out with something good. 👩🏻‍🍳\n\nOr you could follow a **tried and tested** recipe to create something great! ✨',
      },
      learningOutcomes: {
        image: null,
        background: '#57D6C8',
        fontColour: '#1E1E34',
        isLightButtonStyle: true,
        cheer: "Ready? Let's go! 🚀",
        title: "In just 5 minutes, you'll know...",
        outcomes: [
          'How to structure your lesson',
          'Why learning outcomes matter',
          'What to include in a recap page',
        ],
      },
      activities: [
        {
          type: 'text',
          params: {
            image: null,
            background: '#FFFFFF',
            fontColour: '#1E1E34',
            isLightButtonStyle: false,
            title: 'How to structure your lesson',
            description:
              'We think great lessons include the following:\n\n* A welcome or introduction page\n* Learning outcomes\n* Media (images/videos)\n* A quiz\n* A recap\n\nAround 5 minutes per lesson is ideal for the best chance of content retention. ',
          },
        },
        {
          type: 'text',
          params: {
            image: {
              width: 6406,
              height: 5125,
              position: 'top',
              url: `https://d13dt4xa7zzqg.cloudfront.net/image/upload/x_813,y_60,w_6406,h_5125,c_crop/w_6406,h_5125,c_pad/${media.DEFAULT_CLOUDINARY_METADATA}v1663324277/control_panel_media/aabe28a7xfcfevrntq6u.jpg`,
            },
            background: '#57D6C8',
            fontColour: '#1E1E34',
            isLightButtonStyle: true,
            title: 'Why do learning outcomes matter?',
            description:
              'Learning outcomes or objectives are what all lessons should be **structured around**.\n\nThey are the **core concepts** that will support content creators to include only **relevant information** and design appropriate assessments. ✅\n\nThey also support learners in **information processing** by distilling lots of information into key points. 🎯',
          },
        },
        {
          type: 'text',
          params: {
            image: null,
            background: '#FFFFFF',
            fontColour: '#1E1E34',
            isLightButtonStyle: false,
            title: 'How to write good learning outcomes',
            description:
              'Learning outcomes should be **specific and measurable**, related to skills/knowledge learners will gain. They can be framed as "by the end of this lesson, you will be able to…"\n\n**Everything** in a lesson should be related to one or more of the outcomes. If not, it is extraneous information that can be removed. ❌\n\nFor a 5-minute lesson, we would expect to see **no more than 3 learning outcomes**. ',
          },
        },
        {
          type: 'text',
          params: {
            image: {
              width: 4098,
              height: 3279,
              position: 'top',
              url: `https://d13dt4xa7zzqg.cloudfront.net/image/upload/x_1884,y_669,w_4099,h_3279,c_crop/w_4098,h_3279,c_pad/${media.DEFAULT_CLOUDINARY_METADATA}v1663324377/control_panel_media/rnyuudxo2ki4tysesowf.jpg`,
            },
            background: '#57D6C8',
            fontColour: '#1E1E34',
            isLightButtonStyle: true,
            title: "Don't forget a recap page!",
            description:
              "The recap page is the opportunity to reiterate the key points of the lesson - **repetition is key** for content retention! It should state what learners should now be able to do and outline any next steps. ⏩\n\nAim for learners to be able to 'tick off' this list and be **confident** they know what it means. ✔️ \n",
          },
        },
        {
          type: 'interactive',
          params: {
            background: '#0D003A',
            fontColour: '#FFFFFF',
            interaction: {
              type: 'imageQuiz',
              params: {
                urls: ['', '', ''],
                quizType: 'singleChoice',
                questions: [
                  {
                    options: [
                      {
                        isCorrect: true,
                        tip: "That's it! 5 minutes is ideal for the best chance of knowledge retention",
                        text: '5 minutes ',
                      },
                      {
                        isCorrect: false,
                        tip: "Not quite! 5 minutes is a good length to hold learners' attention.",
                        text: '10 minutes including a video',
                      },
                      {
                        isCorrect: false,
                        tip: "Not quite! 5 minutes is a good length to hold learners' attention.",
                        text: '3 minutes per learning outcomes',
                      },
                    ],
                    hasVideoFeedback: false,
                    question: "What's the ideal maximum length of a lesson?",
                  },
                  {
                    options: [
                      {
                        isCorrect: true,
                        tip: "That's it! 2-3 learning outcomes is ideal.",
                        text: '2-3 for a 5 minute lesson',
                      },
                      {
                        isCorrect: false,
                        tip: 'Not quite! 2-3 learning outcomes is ideal.',
                        text: '1',
                      },
                      {
                        isCorrect: false,
                        tip: 'Not quite! 2-3 learning outcomes is ideal.',
                        text: 'No more than 5',
                      },
                    ],
                    hasVideoFeedback: false,
                    question:
                      'How many learning outcomes are suggested for a 5-minute lesson?',
                  },
                  {
                    options: [
                      {
                        isCorrect: true,
                        tip: "That's it! A recap should repeat the key points and takeaways.",
                        text: 'reiterate the key points of the lesson.',
                      },
                      {
                        isCorrect: false,
                        tip: 'Not quite! The recap should reiterate content of the lesson rather than new information.',
                        text: 'introduce the topic of the next lesson.  ',
                      },
                      {
                        isCorrect: false,
                        tip: "Not quite! The recap should reiterate content of the lesson but doesn't need to be an exact copy.",
                        text: 'be a direct copy of your learning outcomes page.',
                      },
                    ],
                    hasVideoFeedback: false,
                    question: 'Your recap should...',
                  },
                ],
                thumbnailUrls: ['', '', ''],
                progressButton: {
                  text: 'Submit',
                },
                shuffleAnswers: true,
                prompt: 'Choose the correct answer',
              },
            },
            taskBoxButton: {
              text: 'Take the quiz',
            },
            isLightButtonStyle: true,
            taskBoxText: "Time to put what you've read to the test!",
            taskBoxTitle: 'Quiz',
          },
        },
        {
          type: 'text',
          params: {
            image: {
              width: 4518,
              height: 3615,
              position: 'top',
              url: `https://d13dt4xa7zzqg.cloudfront.net/image/upload/x_107,y_106,w_4519,h_3615,c_crop/w_4518,h_3615,c_pad/${media.DEFAULT_CLOUDINARY_METADATA}v1663324674/control_panel_media/wbbtoacopdfqkhdkvp0y.jpg`,
            },
            background: '#57D6C8',
            fontColour: '#1E1E34',
            isLightButtonStyle: true,
            title: 'Now you know...',
            description:
              '* To include an intro, learning outcomes, media, a quiz and a recap\n\n* Your content should **stem from your learning outcomes**, 3 is ideal\n\n* Lessons should be around 5 minutes in length\n\nGive it a go! 👍👍',
          },
        },
      ],
      end: {
        icon: {
          url: DEFAULT_END_ICON,
        },
        button: {
          text: 'Next',
        },
        background: '#57D6C8',
        fontColour: '#1E1E34',
        isLightButtonStyle: false,
        title: 'Congratulations!',
        subtitle: "You've completed your first lesson!",
      },
    },
  },
  de: {
    type: 'lesson',
    version: 4,
    params: {
      endOfActivityCustomTextOptions: true,
      title: 'The basics: how to structure a lesson',
      previewImageUrl: `https://d13dt4xa7zzqg.cloudfront.net/image/upload/x_100,y_70,w_800,h_400,c_crop/w_800,h_400,c_pad/${media.DEFAULT_CLOUDINARY_METADATA}v1607436843/edume_image_library/checklist.png`,
      intro: {
        image: null,
        button: {
          text: 'Tell me more! 🤔',
        },
        background: '#57D6C8',
        fontColour: '#1E1E34',
        isLightButtonStyle: true,
        title: 'Welcome! 🤗 ',
        description:
          'Creating an eduMe lesson is a little like cooking. You could go ahead based on instinct and still come out with something good. 👩🏻‍🍳\n\nOr you could follow a **tried and tested** recipe to create something great! ✨',
      },
      learningOutcomes: {
        image: null,
        background: '#57D6C8',
        fontColour: '#1E1E34',
        isLightButtonStyle: true,
        cheer: "Ready? Let's go! 🚀",
        title: "In just 5 minutes, you'll know...",
        outcomes: [
          'How to structure your lesson',
          'Why learning outcomes matter',
          'What to include in a recap page',
        ],
      },
      activities: [
        {
          type: 'text',
          params: {
            image: null,
            background: '#FFFFFF',
            fontColour: '#1E1E34',
            isLightButtonStyle: false,
            title: 'How to structure your lesson',
            description:
              'We think great lessons include the following:\n\n* A welcome or introduction page\n* Learning outcomes\n* Media (images/videos)\n* A quiz\n* A recap\n\nAround 5 minutes per lesson is ideal for the best chance of content retention. ',
          },
        },
        {
          type: 'text',
          params: {
            image: {
              width: 6406,
              height: 5125,
              position: 'top',
              url: `https://d13dt4xa7zzqg.cloudfront.net/image/upload/x_813,y_60,w_6406,h_5125,c_crop/w_6406,h_5125,c_pad/${media.DEFAULT_CLOUDINARY_METADATA}v1663324277/control_panel_media/aabe28a7xfcfevrntq6u.jpg`,
            },
            background: '#57D6C8',
            fontColour: '#1E1E34',
            isLightButtonStyle: true,
            title: 'Why do learning outcomes matter?',
            description:
              'Learning outcomes or objectives are what all lessons should be **structured around**.\n\nThey are the **core concepts** that will support content creators to include only **relevant information** and design appropriate assessments. ✅\n\nThey also support learners in **information processing** by distilling lots of information into key points. 🎯',
          },
        },
        {
          type: 'text',
          params: {
            image: null,
            background: '#FFFFFF',
            fontColour: '#1E1E34',
            isLightButtonStyle: false,
            title: 'How to write good learning outcomes',
            description:
              'Learning outcomes should be **specific and measurable**, related to skills/knowledge learners will gain. They can be framed as "by the end of this lesson, you will be able to…"\n\n**Everything** in a lesson should be related to one or more of the outcomes. If not, it is extraneous information that can be removed. ❌\n\nFor a 5-minute lesson, we would expect to see **no more than 3 learning outcomes**. ',
          },
        },
        {
          type: 'text',
          params: {
            image: {
              width: 4098,
              height: 3279,
              position: 'top',
              url: `https://d13dt4xa7zzqg.cloudfront.net/image/upload/x_1884,y_669,w_4099,h_3279,c_crop/w_4098,h_3279,c_pad/${media.DEFAULT_CLOUDINARY_METADATA}v1663324377/control_panel_media/rnyuudxo2ki4tysesowf.jpg`,
            },
            background: '#57D6C8',
            fontColour: '#1E1E34',
            isLightButtonStyle: true,
            title: "Don't forget a recap page!",
            description:
              "The recap page is the opportunity to reiterate the key points of the lesson - **repetition is key** for content retention! It should state what learners should now be able to do and outline any next steps. ⏩\n\nAim for learners to be able to 'tick off' this list and be **confident** they know what it means. ✔️ \n",
          },
        },
        {
          type: 'interactive',
          params: {
            background: '#0D003A',
            fontColour: '#FFFFFF',
            interaction: {
              type: 'imageQuiz',
              params: {
                urls: ['', '', ''],
                quizType: 'singleChoice',
                questions: [
                  {
                    options: [
                      {
                        isCorrect: true,
                        tip: "That's it! 5 minutes is ideal for the best chance of knowledge retention",
                        text: '5 minutes ',
                      },
                      {
                        isCorrect: false,
                        tip: "Not quite! 5 minutes is a good length to hold learners' attention.",
                        text: '10 minutes including a video',
                      },
                      {
                        isCorrect: false,
                        tip: "Not quite! 5 minutes is a good length to hold learners' attention.",
                        text: '3 minutes per learning outcomes',
                      },
                    ],
                    hasVideoFeedback: false,
                    question: "What's the ideal maximum length of a lesson?",
                  },
                  {
                    options: [
                      {
                        isCorrect: true,
                        tip: "That's it! 2-3 learning outcomes is ideal.",
                        text: '2-3 for a 5 minute lesson',
                      },
                      {
                        isCorrect: false,
                        tip: 'Not quite! 2-3 learning outcomes is ideal.',
                        text: '1',
                      },
                      {
                        isCorrect: false,
                        tip: 'Not quite! 2-3 learning outcomes is ideal.',
                        text: 'No more than 5',
                      },
                    ],
                    hasVideoFeedback: false,
                    question:
                      'How many learning outcomes are suggested for a 5-minute lesson?',
                  },
                  {
                    options: [
                      {
                        isCorrect: true,
                        tip: "That's it! A recap should repeat the key points and takeaways.",
                        text: 'reiterate the key points of the lesson.',
                      },
                      {
                        isCorrect: false,
                        tip: 'Not quite! The recap should reiterate content of the lesson rather than new information.',
                        text: 'introduce the topic of the next lesson.  ',
                      },
                      {
                        isCorrect: false,
                        tip: "Not quite! The recap should reiterate content of the lesson but doesn't need to be an exact copy.",
                        text: 'be a direct copy of your learning outcomes page.',
                      },
                    ],
                    hasVideoFeedback: false,
                    question: 'Your recap should...',
                  },
                ],
                thumbnailUrls: ['', '', ''],
                progressButton: {
                  text: 'Submit',
                },
                shuffleAnswers: true,
                prompt: 'Choose the correct answer',
              },
            },
            taskBoxButton: {
              text: 'Take the quiz',
            },
            isLightButtonStyle: true,
            taskBoxText: "Time to put what you've read to the test!",
            taskBoxTitle: 'Quiz',
          },
        },
        {
          type: 'text',
          params: {
            image: {
              width: 4518,
              height: 3615,
              position: 'top',
              url: `https://d13dt4xa7zzqg.cloudfront.net/image/upload/x_107,y_106,w_4519,h_3615,c_crop/w_4518,h_3615,c_pad/${media.DEFAULT_CLOUDINARY_METADATA}v1663324674/control_panel_media/wbbtoacopdfqkhdkvp0y.jpg`,
            },
            background: '#57D6C8',
            fontColour: '#1E1E34',
            isLightButtonStyle: true,
            title: 'Now you know...',
            description:
              '* To include an intro, learning outcomes, media, a quiz and a recap\n\n* Your content should **stem from your learning outcomes**, 3 is ideal\n\n* Lessons should be around 5 minutes in length\n\nGive it a go! 👍👍',
          },
        },
      ],
      end: {
        icon: {
          url: DEFAULT_END_ICON,
        },
        button: {
          text: 'Next',
        },
        background: '#57D6C8',
        fontColour: '#1E1E34',
        isLightButtonStyle: false,
        title: 'Congratulations!',
        subtitle: "You've completed your first lesson!",
      },
    },
  },
};

const slideshowBlank = {
  type: 'lesson',
  version: 4,
  params: {
    intro: {
      title: '',
      description: '',
      background: '#FFFFFF',
      image: null,
    },
    learningOutcomes: {
      title: '',
      outcomes: [''],
      image: null,
      cheer: '',
      background: '#FFFFFF',
    },
    activities: [],
    end: {
      title: 'Lesson complete',
      subtitle: 'Finish all the activities to complete this course.',
      icon: {
        url: DEFAULT_END_ICON,
      },
      button: {
        text: 'Next',
      },
      background: '#FFFFFF',
      fontColour: '#1E1E34',
      isLightButtonStyle: false,
    },
  },
};

const assessmentBlank = {
  en: {
    type: 'assessment',
    version: 4,
    params: {
      previewImageUrl:
        'https://edume-res.cloudinary.com/image/upload/v1597932656/template-images/assessment-background.svg',
      intro: {
        title: '',
        description: '',
        background: '#FFFFFF',
        image: null,
      },
      activities: [
        {
          type: 'interactive',
          params: {
            background: '#0D003A',
            taskBoxTitle: 'Assessment',
            taskBoxText:
              'This is a scored assessment, answer carefully and make sure you answer all of the questions.',
            interaction: {
              type: 'standaloneQuiz',
              params: {
                quizType: 'singleChoice',
                prompt: 'Choose the correct answer',
                shuffleAnswers: true,
                questions: [
                  {
                    question: '',
                    options: [
                      {text: '', isCorrect: true},
                      {text: '', isCorrect: false},
                    ],
                  },
                ],
              },
            },
          },
        },
      ],
      results: {
        message: 'Thanks for completing the assessment.',
        passMessage: "Great work, you've passed the assessment.",
        failMessage:
          'Unfortunately you’ve not reached the minimum score. Please try again.',
        background: '#0D003A',
        fontColour: '#FFFFFF',
        isLightButtonStyle: true,
      },
    },
  },
  es: {
    type: 'assessment',
    version: 4,
    params: {
      previewImageUrl:
        'https://edume-res.cloudinary.com/image/upload/v1597932656/template-images/assessment-background.svg',
      intro: {
        title: '',
        description: '',
        background: '#FFFFFF',
        image: null,
      },
      activities: [
        {
          type: 'interactive',
          params: {
            background: '#0D003A',
            taskBoxTitle: 'Evaluación',
            taskBoxText:
              'Esta es una evaluación puntuada. Responde con cuidado y asegúrate de responder todas las preguntas.',
            interaction: {
              type: 'standaloneQuiz',
              params: {
                quizType: 'singleChoice',
                prompt: 'Escoge la respuesta correcta',
                shuffleAnswers: true,
                questions: [
                  {
                    question: '',
                    options: [
                      {text: '', isCorrect: true},
                      {text: '', isCorrect: false},
                    ],
                  },
                ],
              },
            },
          },
        },
      ],
      results: {
        message: 'Gracias por completar la evaluación.',
        passMessage: 'Buen trabajo, aprobaste la evaluación.',
        failMessage:
          'Lamentablemente, no alcanzaste la puntuación mínima. Inténtalo de nuevo.',
      },
    },
  },
  pt: {
    type: 'assessment',
    version: 4,
    params: {
      previewImageUrl:
        'https://edume-res.cloudinary.com/image/upload/v1597932656/template-images/assessment-background.svg',
      intro: {
        title: '',
        description: '',
        background: '#FFFFFF',
        image: null,
      },
      activities: [
        {
          type: 'interactive',
          params: {
            background: '#0D003A',
            taskBoxTitle: 'Avaliação',
            taskBoxText:
              'Esta é uma avaliação pontuada, responda com cuidado e certifique-se de responder a todas as perguntas.',
            interaction: {
              type: 'standaloneQuiz',
              params: {
                quizType: 'singleChoice',
                prompt: 'Selecione a resposta correta',
                shuffleAnswers: true,
                questions: [
                  {
                    question: '',
                    options: [
                      {text: '', isCorrect: true},
                      {text: '', isCorrect: false},
                    ],
                  },
                ],
              },
            },
          },
        },
      ],
      results: {
        message: 'Obrigado por completar a avaliação.',
        passMessage: 'Ótimo trabalho, você passou na avaliação.',
        failMessage:
          'Infelizmente você não atingiu a pontuação mínima. Tente novamente.',
      },
    },
  },
  fr: {
    type: 'assessment',
    version: 4,
    params: {
      previewImageUrl:
        'https://edume-res.cloudinary.com/image/upload/v1597932656/template-images/assessment-background.svg',
      intro: {
        title: '',
        description: '',
        background: '#FFFFFF',
        image: null,
      },
      activities: [
        {
          type: 'interactive',
          params: {
            background: '#0D003A',
            taskBoxTitle: 'Évaluation',
            taskBoxText:
              'Ceci est une évaluation notée, répondez attentivement et pensez à bien répondre à toutes les questions.',
            interaction: {
              type: 'standaloneQuiz',
              params: {
                quizType: 'singleChoice',
                prompt: 'Choisissez la bonne réponse',
                shuffleAnswers: true,
                questions: [
                  {
                    question: '',
                    options: [
                      {text: '', isCorrect: true},
                      {text: '', isCorrect: false},
                    ],
                  },
                ],
              },
            },
          },
        },
      ],
      results: {
        message: "Merci d'avoir fait l'évaluation.",
        passMessage: "Beau travail, vous avez réussi l'évaluation.",
        failMessage:
          "Malheureusement vous n'avez pas atteint la note de passage. Réessayez encore.",
      },
    },
  },
  de: {
    type: 'assessment',
    version: 4,
    params: {
      previewImageUrl:
        'https://edume-res.cloudinary.com/image/upload/v1597932656/template-images/assessment-background.svg',
      intro: {
        title: '',
        description: '',
        background: '#FFFFFF',
        image: null,
      },
      activities: [
        {
          type: 'interactive',
          params: {
            background: '#0D003A',
            taskBoxTitle: 'Prüfung',
            taskBoxText:
              'Dies ist eine Prüfung mit Punkten. Beantworte sorgfältig und achte darauf, dass du alle Fragen beantwortest.',
            interaction: {
              type: 'standaloneQuiz',
              params: {
                quizType: 'singleChoice',
                prompt: 'Wähle die richtige Antwort',
                shuffleAnswers: true,
                questions: [
                  {
                    question: '',
                    options: [
                      {text: '', isCorrect: true},
                      {text: '', isCorrect: false},
                    ],
                  },
                ],
              },
            },
          },
        },
      ],
      results: {
        message: 'Danke, dass du die Prüfung abgeschlossen hast.',
        passMessage: 'Gute Arbeit, du hast die Prüfung bestanden.',
        failMessage:
          'Leider hast du die Mindestpunktzahl nicht erreicht. Bitte versuche es erneut.',
        background: '#0D003A',
        fontColour: '#FFFFFF',
        isLightButtonStyle: true,
      },
    },
  },
};

/*eslint-enable*/
export default {
  conversational: {
    example: conversationalExample,
    blank: conversationalBlank,
  },
  slideshow: {
    example: slideshowExample,
    blank: slideshowBlank,
    ai: slideshowBlank,
  },
  assessment: {
    blank: assessmentBlank,
  },
};
