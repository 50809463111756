import React from 'react';

import PropTypes from 'prop-types';

import {auth} from '@edume/bento';

import MultiLineInfo from '../MultiLineInfo';

const CopyLinkToolTip = ({items = [auth.SSU, auth.SSO, 'seamless']}) => {
  const tooltipItems = items.map((type) => ({
    headingKey: `Link.type.${type}.title`,
    descriptionKey: `Link.type.${type}.description`,
  }));

  return (
    <MultiLineInfo
      dataKey='linksTooltip'
      introKey='Link.title.hint'
      items={tooltipItems}
      size='large'
      place='right'
      theme='dark'
    />
  );
};

CopyLinkToolTip.propTypes = {
  items: PropTypes.array,
};

export default CopyLinkToolTip;
