import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {Link} from 'react-router-dom';

import {LegacyHeading} from '@edume/magnificent';

import styles from './styles.scss';

const SettingsWell = ({
  borderColour,
  children,
  href,
  subtitle,
  subtitle2,
  title,
  dataAutomation,
}) => {
  const stylesNames = ['link'];
  if (borderColour) {
    stylesNames.push(`left-border-${borderColour}`);
  }

  return (
    <Link
      style={{textDecoration: 'none'}}
      to={href}
      data-automation={dataAutomation}
    >
      <div key={title} styleName={stylesNames.join(' ')}>
        <div styleName='text-container'>
          <LegacyHeading size='tinier' weight='medium'>
            {title}
          </LegacyHeading>
          <LegacyHeading size='micro' colour='grey700'>
            {subtitle}
          </LegacyHeading>
          {subtitle2 && (
            <LegacyHeading size='mini' colour='grey700'>
              {subtitle2}
            </LegacyHeading>
          )}
        </div>
        <div styleName='container'>
          {children}
          <img src='/resources/img/24_drop_arrow_dark_purple.svg' alt='icon' />
        </div>
      </div>
    </Link>
  );
};

SettingsWell.propTypes = {
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  subtitle2: PropTypes.string,
  children: PropTypes.any,
  borderColour: PropTypes.string,
  dataAutomation: PropTypes.string,
};

export default CSSModules(SettingsWell, styles, {allowMultiple: true});
