import {errorCodes} from '@edume/bento';

import {ERROR, SUCCESS, WARNING} from '../../utils/notificationTypes';
import {ADD_NOTIFICATION, DELETE_NOTIFICATION} from './notificationActionTypes';

const generateId = Date.now;

export const deleteNotification = (id) => (dispatch) => {
  dispatch({
    type: DELETE_NOTIFICATION,
    payload: id,
  });
};

/**
 * Adds a notification to the Redux store.
 * @param {object} options - The options for adding the notification.
 * @param {number} options.id - The ID of the notification.
 * @param {string} options.messageKey - The key of the message for the notification.
 * @param {object} options.data - The data for the notification.
 * @param {string} options.type - The type of the notification.
 * @param {object} [options.ctaOptions] - The options for the call-to-action button.
 * @param {string} [options.headerText] - The text for the header of the notification.
 * @param {boolean} [options.isAutoDismissed] - Whether the notification is auto-dismissed.
 * @return {() => void}
 */
export const addNotification =
  ({id, messageKey, data, type, ctaOptions, headerText, isAutoDismissed}) =>
  (dispatch) => {
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        id,
        headerText,
        messageKey,
        data,
        type,
        ctaOptions,
        isAutoDismissed,
      },
    });
  };

export const createSuccessNotification =
  (messageKey, data, ctaOptions) => (dispatch) => {
    const id = generateId();
    dispatch(
      addNotification({id, messageKey, data, type: SUCCESS, ctaOptions})
    );
  };

export const createErrorNotification =
  (messageKey, data, ctaOptions) => (dispatch) => {
    const id = generateId();
    dispatch(addNotification({id, messageKey, data, type: ERROR, ctaOptions}));
  };

const createPersistentErrorNotification =
  (messageKey, data, ctaOptions) => (dispatch) => {
    const id = generateId();
    dispatch(
      addNotification({
        id,
        messageKey,
        data,
        type: ERROR,
        ctaOptions,
        isAutoDismissed: false,
      })
    );
  };

export const createErrorNotificationFromResponse =
  (errorResponse, dataKey) => (dispatch) => {
    const genericError = 'Error.generic';
    if (!errorResponse.response) {
      //this is a code error not a server error
      console.error(errorResponse);
      return createErrorNotification(genericError)(dispatch);
    }
    const errorCode = errorResponse.response.data.errorCode;
    const messageKey = errorCode ? `Error.${errorCode}` : genericError;
    if (errorCode === 'invalid_token') {
      return null; //skip notification; the user is redirected
    }
    let data;
    if (dataKey) {
      data = {[dataKey]: errorResponse.response.data[dataKey]};
    }

    if (errorCode === errorCodes.RELOAD_REQUIRED) {
      return dispatch(createPersistentErrorNotification(messageKey, data));
    }

    return dispatch(createErrorNotification(messageKey, data));
  };

export const createInfoNotification =
  ({messageKey, data, ctaOptions, headerText, isAutoDismissed}) =>
  (dispatch) => {
    const id = generateId();
    dispatch(
      addNotification({
        id,
        messageKey,
        data,
        type: WARNING,
        ctaOptions,
        headerText,
        isAutoDismissed,
      })
    );
  };

export const createMaxUsersErrorNotification = (error) => (dispatch) => {
  const errorCode = error.response.data.errorCode;
  if (errorCode !== errorCodes.MAX_USERS_REACHED) {
    return;
  }

  dispatch(createErrorNotification(`Error.${errorCode}`));
};
