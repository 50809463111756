import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';

import {
  ChevronIcon,
  FolderIcon,
  IconButton,
  PlusIcon,
  Text,
} from '@edume/magnificent';

import {toggleSidebarCreationExpansion} from '../../../state/course/courseActions';
import {SIDEBAR_TABS} from '../../../state/course/courseActionTypes';

import styles from '../styles.scss';

export const COLLECTION_CONTAINER_ID = 'collections-scroll-container';

const Sidebar = ({
  creationSidebarItems,
  curationSidebarItems,
  setIsAddEditCollectionModalVisible,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const {isKnowledgeHubsEnabled, activeSidebarItem, isSidebarCreationExpanded} =
    useSelector((state) => {
      const isKhEnabled = state.getIn([
        'featureFlags',
        'customerFlags',
      ]).knowledgeHubsEnabled;
      const activeItem = state.getIn(['course', 'activeSidebarItem']);
      const isSidebarExpanded = state.getIn([
        'course',
        'isSidebarCreationExpanded',
      ]);

      return {
        isKnowledgeHubsEnabled: isKhEnabled,
        activeSidebarItem: activeItem,
        isSidebarCreationExpanded: isSidebarExpanded,
      };
    });

  const hasCoursesLoaded = useSelector((state) =>
    state.getIn(['course', 'coursesLoaded'])
  );

  const toggleCreationItems = () => dispatch(toggleSidebarCreationExpansion());

  const renderItemChildren = (children, isTabbable = true) => (
    <div styleName='collections'>
      <div styleName='collections-heading-section'>
        <div styleName='collections-heading'>
          <Text size='m'>
            {intl.formatMessage({
              id: 'Learning.sidebar.collections',
            })}
          </Text>
        </div>
        <div styleName='collections-heading-add'>
          <IconButton
            icon={<PlusIcon size='small' colour='brandPrimary' />}
            showBorder={false}
            isBgTransparent={true}
            handleClick={() => setIsAddEditCollectionModalVisible('add')}
            tooltipProps={{
              text: intl.formatMessage({id: 'Learning.collections.addTooltip'}),
              effect: 'solid',
              place: 'bottom',
              backgroundColor: 'black',
            }}
            dataAutomation='add-collection-button'
            disabled={!isTabbable}
          />
        </div>
      </div>
      {children.length > 0 && (
        <div
          styleName='collections-list-container'
          id={COLLECTION_CONTAINER_ID}
        >
          {children.map((collection, i) => (
            <button
              key={i}
              styleName={classNames('collection', 'sidebar-button', {
                active: collection.isActive,
              })}
              onClick={collection.onClick}
              tabIndex={isTabbable ? undefined : -1}
              data-automation='collection-tab'
            >
              <FolderIcon size='tiny' />
              <Text
                size='m'
                weight={collection.isActive ? 'medium' : 'regular'}
                maxLines={1}
              >
                {collection.name}
              </Text>
            </button>
          ))}
        </div>
      )}
    </div>
  );

  const renderSectionTitle = (key) => {
    if (!isKnowledgeHubsEnabled) {
      return null;
    }

    return (
      <Text size='m' weight='medium'>
        {intl.formatMessage({
          id: `Learning.sidebar.${key}`,
        })}
      </Text>
    );
  };

  const renderCreationHeader = () => {
    if (!isKnowledgeHubsEnabled) {
      return null;
    }

    return (
      <button
        styleName={classNames('section-header', 'sidebar-button', {
          active:
            !isSidebarCreationExpanded &&
            activeSidebarItem !== SIDEBAR_TABS.KNOWLEDGE_HUBS,
        })}
        onClick={toggleCreationItems}
      >
        {renderSectionTitle('creation')}
        <ChevronIcon
          orientation={isSidebarCreationExpanded ? 'up' : 'down'}
          withTransition
          size='xsmall'
          colour='grey700'
        />
      </button>
    );
  };

  const renderCurationHeader = () => (
    <div styleName='curation-header'>{renderSectionTitle('curation')}</div>
  );

  const renderItem = (
    {isActive, automationTag, name, children, count, onClick},
    isTabbable = true
  ) => {
    if (children) {
      return renderItemChildren(children, isTabbable);
    }

    return (
      <button
        styleName={classNames('sidebar-button', {active: isActive})}
        onClick={onClick}
        data-automation={automationTag}
        tabIndex={isTabbable ? undefined : -1}
      >
        <Text size='m' weight={isActive ? 'medium' : 'regular'}>
          {intl.formatMessage({
            id: `Learning.sidebar.${name}`,
          })}
        </Text>
        {hasCoursesLoaded && (
          <Text
            size='m'
            colour='grey700'
            weight='medium'
            dataAutomation='active-tab-count'
          >
            {count}
          </Text>
        )}
      </button>
    );
  };

  return (
    <div styleName='sidebar'>
      {renderCreationHeader()}
      <div
        aria-hidden={!isSidebarCreationExpanded}
        styleName={classNames('creation', {visible: isSidebarCreationExpanded})}
      >
        <div style={{overflow: 'hidden'}}>
          {creationSidebarItems.map((item) =>
            renderItem(item, isSidebarCreationExpanded)
          )}
        </div>
      </div>

      {isKnowledgeHubsEnabled && (
        <>
          {renderCurationHeader()}
          {curationSidebarItems.map((item) => renderItem(item))}
        </>
      )}
    </div>
  );
};

Sidebar.propTypes = {
  creationSidebarItems: PropTypes.array,
  curationSidebarItems: PropTypes.array,
  setIsAddEditCollectionModalVisible: PropTypes.func.isRequired,
};

export default CSSModules(Sidebar, styles, {allowMultiple: true});
