/* eslint-disable max-params */

/* eslint-disable consistent-return */

/* eslint-disable max-statements */

/* eslint-disable complexity */

/* eslint-disable max-len */
import Promise from 'bluebird';
import {push} from 'connected-react-router';
import {fromJS} from 'immutable';
import _ from 'lodash';

import {fixMarkdown} from '@edume/magnificent';

import {getFileFormatFromString} from '../../utils/string';
import * as authActions from '../auth/authActions';
import {UPDATE_COURSE_TIMESTAMPS} from '../course/courseActionTypes';
import {getCourseById, getCourseByModuleId} from '../course/courseSelector';
import * as formActions from '../form/formActions';
import * as notificationActions from '../notification/notificationActions';
import * as moduleActivityActionCreators from './moduleActivityActionCreators';
import {
  LOAD_LESSONS,
  RESET_MODULE_ACTIVITY,
  START_EDITING_ACTIVITY,
  START_EDITING_END,
  STOP_EDITING_ACTIVITY,
  STOP_EDITING_END,
  UPDATE_ESON,
  VIEWED_PREVIEW_LINK,
} from './moduleActivityActionTypes';

const augmentWithLessonKey = (lessonWithLessonKey) => ({
  id: lessonWithLessonKey[0].data.id,
  eson: lessonWithLessonKey[0].data.eson,
  minutes: lessonWithLessonKey[0].data.minutes,
  position: lessonWithLessonKey[0].data.position,
  key: lessonWithLessonKey[1],
  published: lessonWithLessonKey[0].data.published,
  moduleId: lessonWithLessonKey[0].data.moduleId,
  languages: lessonWithLessonKey[0].data.languages,
});

const dispatchLessonUpdate = (
  getState,
  dispatch,
  lessonKey,
  eson,
  activityIndex,
  metaData
) => {
  const originalLessonEson = getLesson(lessonKey, getState);
  return dispatch(
    moduleActivityActionCreators.updateLesson(
      lessonKey,
      eson,
      getState().getIn(['moduleActivity', 'selectedLanguage']),
      metaData,
      originalLessonEson
    )
  )
    .then((successAction) => {
      dispatch({
        type: UPDATE_ESON,
        payload: {
          lessonKey,
          data: successAction.payload.data.result,
          activityIndex,
        },
      });
      return successAction.payload.data;
    })
    .then(({result, hasDiff}) => {
      const lessons = getState().getIn(['moduleActivity', 'lessons']);
      const lessonIndex = lessons.findIndex(
        (lesson) => lesson.get('key') === lessonKey
      );
      const moduleId = lessons.toJS()[lessonIndex].moduleId;
      dispatch({
        type: UPDATE_COURSE_TIMESTAMPS,
        payload: {
          moduleId,
        },
      });
      if (hasDiff) {
        notificationActions.createInfoNotification({
          headerText: 'Lesson.updateDifferenceWarningTitle',
          messageKey: 'Lesson.updateDifferenceWarning',
          isAutoDismissed: false,
        })(dispatch);
      }

      return {result, hasDiff};
    })
    .catch((error) => {
      notificationActions.createErrorNotificationFromResponse(error)(dispatch);
      throw error;
    });
};

const getLesson = (lessonKey, getState) =>
  getState()
    .getIn(['moduleActivity', 'lessons'])
    .filter((lesson) => lesson.get('key') === lessonKey)
    .getIn([0, 'eson']);

export const updateLessonPreviewImage =
  (lessonKey, previewImageUrl) => (dispatch, getState) => {
    const lesson = getLesson(lessonKey, getState);
    const updated = lesson.setIn(
      ['params', 'previewImageUrl'],
      previewImageUrl
    );
    return dispatchLessonUpdate(getState, dispatch, lessonKey, updated);
  };

export const addIntro = (lessonKey, newEson) => (dispatch, getState) => {
  const meta = [{updateType: 'CREATE', componentType: 'intro'}];
  const lesson = getLesson(lessonKey, getState);
  const updatedEson = lesson.setIn(['params', 'intro'], newEson);
  dispatchLessonUpdate(getState, dispatch, lessonKey, updatedEson, null, meta);
};

export const addLearningOutcomes =
  (lessonKey, newEson) => (dispatch, getState) => {
    const meta = [{updateType: 'CREATE', componentType: 'learningOutcomes'}];
    const lesson = getLesson(lessonKey, getState);
    const updatedEson = lesson.setIn(['params', 'learningOutcomes'], newEson);
    dispatchLessonUpdate(
      getState,
      dispatch,
      lessonKey,
      updatedEson,
      null,
      meta
    );
  };

export const addActivity =
  (lessonKey, index, newEson) => (dispatch, getState) => {
    const lesson = getLesson(lessonKey, getState);
    const activities = lesson.getIn(['params', 'activities']);
    const updatedActivities = activities.insert(index, newEson);
    const updatedEson = lesson.setIn(
      ['params', 'activities'],
      updatedActivities
    );
    const {
      type,
      params: {interaction},
    } = updatedActivities.toJS()[index];
    const componentType =
      type === 'interactive' ? interaction.type : `${type} bubble`;
    const meta = [{updateType: 'CREATE', componentType}];
    meta[0].componentType =
      meta[0].componentType === 'standaloneQuiz'
        ? 'videoQuiz'
        : meta[0].componentType;
    dispatchLessonUpdate(
      getState,
      dispatch,
      lessonKey,
      updatedEson,
      null,
      meta
    );
  };

export const deleteActivity = (lessonKey, index) => (dispatch, getState) => {
  const lesson = getLesson(lessonKey, getState);
  const component = lesson.getIn(['params', 'activities', index]).toJS();
  const {
    type,
    params: {interaction},
  } = component;
  const componentType =
    type === 'interactive' ? interaction.type : `${type} bubble`;
  const meta = [{updateType: 'DELETE', componentType}];
  meta[0].componentType =
    meta[0].componentType === 'standaloneQuiz'
      ? 'videoQuiz'
      : meta[0].componentType;
  const updatedEson = lesson.removeIn(['params', 'activities', index]);
  return dispatchLessonUpdate(
    getState,
    dispatch,
    lessonKey,
    updatedEson,
    null,
    meta
  ).then(() => {
    dispatch(
      notificationActions.createSuccessNotification(
        'LessonCanvas.notifications.deleteSuccess'
      )
    );
  });
};

export const deleteIntroActivity = (lessonKey) => (dispatch, getState) => {
  const meta = [{updateType: 'DELETE', componentType: 'intro'}];
  const updatedEson = getLesson(lessonKey, getState).deleteIn([
    'params',
    'intro',
  ]);
  return dispatchLessonUpdate(
    getState,
    dispatch,
    lessonKey,
    updatedEson,
    null,
    meta
  ).then(() => {
    dispatch(
      notificationActions.createSuccessNotification(
        'LessonCanvas.notifications.deleteSuccess'
      )
    );
  });
};

export const deleteLearningOutcomesActivity =
  (lessonKey) => (dispatch, getState) => {
    const meta = [{updateType: 'DELETE', componentType: 'learningOutcomes'}];
    const updatedEson = getLesson(lessonKey, getState).deleteIn([
      'params',
      'learningOutcomes',
    ]);
    return dispatchLessonUpdate(
      getState,
      dispatch,
      lessonKey,
      updatedEson,
      null,
      meta
    ).then(() => {
      dispatch(
        notificationActions.createSuccessNotification(
          'LessonCanvas.notifications.deleteSuccess'
        )
      );
    });
  };

export const stopEditingActivity = () => (dispatch) => {
  dispatch({type: STOP_EDITING_ACTIVITY});
};

export const updateLearningOutcomes =
  (lessonKey, patch) => (dispatch, getState) => {
    const lesson = getLesson(lessonKey, getState);
    const currentButtonText = lesson.getIn(
      ['params', 'learningOutcomes', 'button', 'text'],
      ''
    );
    const meta = [
      {
        updateType: 'EDIT',
        componentType: 'learningOutcomes',
        hasButtonTextChanged:
          patch.button && currentButtonText !== patch.button.text,
      },
    ];
    const updatedEson = getLesson(lessonKey, getState).setIn(
      ['params', 'learningOutcomes'],
      patch
    );
    return dispatchLessonUpdate(
      getState,
      dispatch,
      lessonKey,
      updatedEson,
      null,
      meta
    );
  };

export const updateIntro =
  (lessonKey, greeting, description, welcomeImageUrl, button) =>
  (dispatch, getState) => {
    const lesson = getLesson(lessonKey, getState);
    const imageUrl = lesson.getIn(['params', 'intro', 'welcomeImageUrl']);
    const currentButtonText = lesson.getIn(
      ['params', 'intro', 'button', 'text'],
      ''
    );

    const meta = [
      {
        updateType: !imageUrl && welcomeImageUrl ? 'UPLOAD' : 'EDIT',
        componentType: 'intro',
        hasButtonTextChanged: button && currentButtonText !== button.text,
        ...(welcomeImageUrl !== imageUrl && {
          mediaFormat: getFileFormatFromString(welcomeImageUrl),
        }),
      },
    ];
    authActions.dismissHint('LESSON_BUBBLES')(dispatch);
    const updatedEson = lesson
      .setIn(['params', 'intro', 'greeting'], greeting)
      .setIn(['params', 'intro', 'description'], fixMarkdown(description))
      .setIn(['params', 'intro', 'welcomeImageUrl'], welcomeImageUrl)
      .setIn(['params', 'intro', 'button'], button)
      .toJS();
    if (!welcomeImageUrl) {
      delete updatedEson.params.intro.welcomeImageUrl;
    }
    return dispatchLessonUpdate(
      getState,
      dispatch,
      lessonKey,
      updatedEson,
      null,
      meta
    );
  };

export const updateTextActivity =
  (lessonKey, index, title, description, button) => (dispatch, getState) => {
    const activityParamsPath = ['params', 'activities', index, 'params'];
    const lesson = getLesson(lessonKey, getState);
    const currentButtonText = lesson.getIn(
      [...activityParamsPath, 'button', 'text'],
      ''
    );
    const meta = [
      {
        updateType: 'EDIT',
        componentType: 'text bubble',
        hasButtonTextChanged: button && currentButtonText !== button.text,
      },
    ];
    const updatedEson = getLesson(lessonKey, getState)
      .setIn([...activityParamsPath, 'title'], title)
      .setIn([...activityParamsPath, 'description'], fixMarkdown(description))
      .setIn([...activityParamsPath, 'button'], button)
      .toJS();
    return dispatchLessonUpdate(
      getState,
      dispatch,
      lessonKey,
      updatedEson,
      null,
      meta
    );
  };

export const updateImageActivity =
  (
    lessonKey,
    index,
    thumbnailUrl,
    url,
    caption,
    width,
    height,
    thumbnailWidth,
    thumbnailHeight,
    button
  ) =>
  (dispatch, getState) => {
    const activityParamsPath = ['params', 'activities', index, 'params'];
    const lesson = getLesson(lessonKey, getState);
    const currentButtonText = lesson.getIn(
      [...activityParamsPath, 'button', 'text'],
      ''
    );
    const currentMediaUrl = lesson.getIn([...activityParamsPath, 'url']);
    const hasImageChanged = url !== currentMediaUrl;
    const meta = [
      {
        updateType: currentMediaUrl || !url ? 'EDIT' : 'UPLOAD',
        componentType: 'image bubble',
        hasButtonTextChanged: button && currentButtonText !== button.text,
        ...(hasImageChanged && {mediaFormat: getFileFormatFromString(url)}),
      },
    ];
    const updatedEson = lesson
      .setIn([...activityParamsPath, 'thumbnailUrl'], thumbnailUrl)
      .setIn([...activityParamsPath, 'url'], url)
      .setIn([...activityParamsPath, 'width'], width)
      .setIn([...activityParamsPath, 'height'], height)
      .setIn([...activityParamsPath, 'thumbnailWidth'], thumbnailWidth)
      .setIn([...activityParamsPath, 'thumbnailHeight'], thumbnailHeight)
      .setIn([...activityParamsPath, 'caption'], fixMarkdown(caption))
      .setIn([...activityParamsPath, 'button'], button)
      .toJS();
    return dispatchLessonUpdate(
      getState,
      dispatch,
      lessonKey,
      updatedEson,
      null,
      meta
    );
  };

export const updateVideoActivity =
  (lessonKey, index, videoUrl, source, caption, button) =>
  (dispatch, getState) => {
    const lesson = getLesson(lessonKey, getState);
    const activityParamsPath = ['params', 'activities', index, 'params'];
    const currentMediaUrl = lesson.getIn([...activityParamsPath, 'url']);
    const currentButtonText = lesson.getIn(
      [...activityParamsPath, 'button', 'text'],
      ''
    );
    const hasVideoChanged = videoUrl !== currentMediaUrl;
    const meta = [
      {
        updateType: currentMediaUrl || !videoUrl ? 'EDIT' : 'UPLOAD',
        componentType: 'video bubble',
        hasButtonTextChanged: button && currentButtonText !== button.text,
        ...(hasVideoChanged && {
          mediaFormat:
            source === 'cloudinary'
              ? getFileFormatFromString(videoUrl)
              : source,
        }),
      },
    ];
    const updatedEson = lesson
      .setIn([...activityParamsPath, 'url'], videoUrl)
      .setIn([...activityParamsPath, 'source'], source)
      .setIn([...activityParamsPath, 'caption'], fixMarkdown(caption))
      .setIn([...activityParamsPath, 'button'], button)
      .toJS();
    return dispatchLessonUpdate(
      getState,
      dispatch,
      lessonKey,
      updatedEson,
      null,
      meta
    );
  };

export const updateQuizType =
  (lessonKey, index, newQuizType) => (dispatch, getState) => {
    const activityParamsPath = ['params', 'activities', index, 'params'];
    const lesson = getLesson(lessonKey, getState);
    const componentType = lesson.getIn([
      ...activityParamsPath,
      'interaction',
      'type',
    ]);
    const meta = [{updateType: 'EDIT', componentType}];
    const updatedEson = lesson
      .setIn([...activityParamsPath, 'quizType'], newQuizType)
      .toJS();
    return dispatchLessonUpdate(
      getState,
      dispatch,
      lessonKey,
      updatedEson,
      null,
      meta
    );
  };

export const startEditingActivity = (activityIndex) => (dispatch) => {
  dispatch({type: START_EDITING_ACTIVITY, payload: activityIndex});
};

export const updateInteractiveActivity =
  (lessonKey, index, params, questionIndex, shouldContinueAuthoring) =>
  (dispatch, getState) => {
    const activityParamsPath = ['params', 'activities', index, 'params'];
    const lesson = getLesson(lessonKey, getState);
    const componentType = lesson.getIn([
      ...activityParamsPath,
      'interaction',
      'type',
    ]);
    const currentParams = lesson
      .getIn([...activityParamsPath, 'interaction', 'params'])
      .toJS();
    const currentTaskBoxText = lesson.getIn(
      [...activityParamsPath, 'taskBoxButton', 'text'],
      ''
    );
    const currentProgressButtonText = lesson.getIn(
      [
        ...activityParamsPath,
        'interaction',
        'params',
        'progressButton',
        'text',
      ],
      ''
    );
    const newParams = params.interaction.params;

    let hasMediaChanged = false;
    let mediaFormat;
    let updateType = 'EDIT';

    if (componentType === 'imageQuiz') {
      if (
        (!currentParams?.url && newParams?.url) ||
        (currentParams?.url && newParams?.urls)
      ) {
        hasMediaChanged = true;
        updateType = 'UPLOAD';
        mediaFormat = newParams.urls
          ? getFileFormatFromString(newParams.urls[questionIndex])
          : getFileFormatFromString(newParams.url);
      }
      if (currentParams?.url && newParams?.url) {
        hasMediaChanged = currentParams.url !== newParams.url;
        if (hasMediaChanged) {
          mediaFormat = getFileFormatFromString(newParams?.url);
        }
      }
      if (currentParams?.urls && newParams?.urls) {
        hasMediaChanged =
          currentParams.urls[questionIndex] !== newParams?.urls[questionIndex];
        if (hasMediaChanged) {
          updateType =
            currentParams.urls.length === newParams.urls.length
              ? 'EDIT'
              : 'UPLOAD';
          mediaFormat = getFileFormatFromString(newParams.urls[questionIndex]);
        }
      }
    } else {
      const {video} = params;
      const currentVideo = lesson.getIn([...activityParamsPath]).toJS().video;
      if (!currentVideo && video) {
        hasMediaChanged = true;
        updateType = 'UPLOAD';
        mediaFormat =
          video.source === 'cloudinary'
            ? getFileFormatFromString(video.url)
            : video.source;
      }
      if (currentVideo && video) {
        hasMediaChanged = currentVideo?.url !== video.url;
        if (hasMediaChanged) {
          mediaFormat =
            video.source === 'cloudinary'
              ? getFileFormatFromString(video.url)
              : video.source;
        }
      }
    }

    const updatedEson = lesson.setIn([...activityParamsPath], fromJS(params));

    if (!hasMediaChanged && shouldContinueAuthoring) {
      const {quizType} = newParams;
      const currentQuestionEson = currentParams?.questions?.[questionIndex];
      const newQuestionEson = newParams?.questions?.[questionIndex];

      const isEsonSame = _.isEqual(
        {
          question: currentQuestionEson?.question,
          options: currentQuestionEson?.options,
        },
        {question: newQuestionEson?.question, options: newQuestionEson?.options}
      );

      if (
        quizType !== 'text' &&
        ((!currentQuestionEson && !newQuestionEson?.question) ||
          (isEsonSame && currentParams.prompt === newParams.prompt))
      ) {
        return;
      }

      if (quizType === 'text') {
        const isOrderingEsonSame = _.isEqual(
          {prompt: currentParams.prompt, answers: currentParams.answers},
          {prompt: newParams.prompt, answers: newParams.answers}
        );

        if (isOrderingEsonSame) {
          return;
        }
        if (!currentParams.prompt) {
          return dispatchLessonUpdate(
            getState,
            dispatch,
            lessonKey,
            updatedEson,
            null,
            [{}]
          );
        }
      }
    }

    let hasButtonTextChanged;
    if (params.taskBoxButton) {
      hasButtonTextChanged = currentTaskBoxText !== params.taskBoxButton.text;
    }

    if (newParams.progressButton && !hasButtonTextChanged) {
      hasButtonTextChanged =
        currentProgressButtonText !== newParams.progressButton.text;
    }

    const meta = [
      {
        updateType,
        componentType,
        hasButtonTextChanged,
        ...(hasMediaChanged && {mediaFormat}),
      },
    ];
    meta[0].componentType =
      meta[0].componentType === 'imageQuiz'
        ? meta[0].componentType
        : 'videoQuiz';

    return dispatchLessonUpdate(
      getState,
      dispatch,
      lessonKey,
      updatedEson,
      null,
      meta
    ).then(() => {
      if (!shouldContinueAuthoring) {
        dispatch({
          type: STOP_EDITING_ACTIVITY,
        });
      }
    });
  };

export const startEditingEnd = () => (dispatch) => {
  dispatch({type: START_EDITING_END});
};

export const stopEditingEnd = () => (dispatch) => {
  dispatch({type: STOP_EDITING_END});
};

export const updateEnd =
  (lessonKey, newEnd, customTextOptions) => (dispatch, getState) => {
    const lesson = getLesson(lessonKey, getState);

    const currentButtonText = lesson.getIn(
      ['params', 'end', 'button', 'text'],
      ''
    );
    const currentIconUrl = lesson.getIn(['params', 'end', 'icon', 'url'], '');

    const {button, icon = {}} = newEnd;

    const meta = [
      {
        updateType: 'EDIT',
        componentType: 'end',
        hasButtonTextChanged: button && currentButtonText !== button.text,
        ...(icon.url !== currentIconUrl && {
          mediaFormat: getFileFormatFromString(icon.url),
        }),
      },
    ];

    const updatedEson = lesson
      .setIn(['params', 'end'], newEnd)
      .setIn(['params', 'endOfActivityCustomTextOptions'], customTextOptions)
      .toJS();

    return dispatchLessonUpdate(
      getState,
      dispatch,
      lessonKey,
      updatedEson,
      null,
      meta
    ).then(() => dispatch({type: STOP_EDITING_END}));
  };

export const updateLessonTitle =
  (lessonKey, newTitle) => (dispatch, getState) => {
    const updatedEson = getLesson(lessonKey, getState)
      .setIn(['params', 'title'], newTitle)
      .toJS();

    return dispatchLessonUpdate(getState, dispatch, lessonKey, updatedEson)
      .then(() => formActions.closeForm()(dispatch))
      .then(() =>
        notificationActions.createSuccessNotification(
          'Learning.updateLessonSuccess'
        )(dispatch)
      );
  };

export const saveLesson = (lessonKey, eson, meta) => (dispatch, getState) =>
  dispatchLessonUpdate(getState, dispatch, lessonKey, eson, null, meta);

export const loadLessons = (lessonKeys, courseId, language) => (dispatch) => {
  Promise.map(lessonKeys, (key) =>
    dispatch(moduleActivityActionCreators.getLesson(key, language))
  )
    .then((actions) => actions.map((action) => action.payload))
    .then((lessons) =>
      dispatch({
        type: LOAD_LESSONS,
        payload: {
          lessons: _.zip(lessons, lessonKeys).map(augmentWithLessonKey),
          courseId,
          language,
        },
      })
    )
    .catch((error) => {
      notificationActions.createErrorNotificationFromResponse(error)(dispatch);
    });
};

export const getCourseSurveys = (courseId, language) => (dispatch) => {
  dispatch(moduleActivityActionCreators.getCourseSurveys(courseId, language));
};

export const addLesson =
  (
    courseId,
    defaultCourseLanguage,
    moduleId,
    templateType,
    eson,
    lessonType,
    fileUploadedType
  ) =>
  (dispatch, getState) => {
    const state = getState();
    const selectedLanguage = state.get('selectedLanguage');
    const course = getCourseById(state, courseId);

    //When we add a lesson we want to add it in the defaultCourseLanguage, so we should change the selectedLanguage if it's not default
    const loadLessonsPromise = async () =>
      selectedLanguage !== defaultCourseLanguage
        ? dispatch(
            loadLessons(course.lessonKeys, courseId, defaultCourseLanguage)
          )
        : null;

    loadLessonsPromise()
      .then(() =>
        dispatch(
          moduleActivityActionCreators.addLesson(
            moduleId,
            templateType,
            eson,
            courseId,
            lessonType,
            fileUploadedType,
            defaultCourseLanguage
          )
        )
      )
      .then((action) => action.payload.data)
      .then((lesson) => {
        dispatch(
          push(`/learning/course/${courseId}/lesson/${lesson.lessonKey}`)
        );
      })
      .then(() => formActions.closeForm()(dispatch))
      .catch((error) => {
        notificationActions.createErrorNotificationFromResponse(error)(
          dispatch
        );
      });
  };

export const copyLessonToCourse =
  (lessonId, courseId, moduleId) => (dispatch) =>
    dispatch(
      moduleActivityActionCreators.copyLessonToCourse(
        lessonId,
        courseId,
        moduleId
      )
    ).catch((error) => {
      console.log('error', error);
      return notificationActions.createErrorNotificationFromResponse(error)(
        dispatch
      );
    });

export const createModuleSurvey =
  (survey, moduleId, courseDefaultLanguage) => async (dispatch) => {
    const messageKey = 'Survey.createSurveySuccess';
    try {
      await dispatch(
        moduleActivityActionCreators.createModuleSurvey(
          moduleId,
          survey,
          courseDefaultLanguage
        )
      );
      notificationActions.createSuccessNotification(messageKey)(dispatch);
    } catch (error) {
      notificationActions.createErrorNotificationFromResponse(error)(dispatch);
    }
  };

export const deleteLesson = (lessonKey, moduleId, position) => (dispatch) =>
  dispatch(
    moduleActivityActionCreators.deleteLesson(lessonKey, moduleId, position)
  ).catch((error) => {
    notificationActions.createErrorNotificationFromResponse(error)(dispatch);
  });

export const deleteModuleSurvey =
  (surveyId, moduleId, position) => (dispatch) => {
    dispatch(
      moduleActivityActionCreators.deleteModuleSurvey(
        surveyId,
        moduleId,
        position
      )
    ).catch((error) =>
      notificationActions.createErrorNotificationFromResponse(error)(dispatch)
    );
  };

export const storeLessonPreviewToken = (lessonId, token) => (dispatch) =>
  dispatch(
    moduleActivityActionCreators.storeLessonPreviewToken(lessonId, token)
  )
    .then(
      dispatch({
        type: VIEWED_PREVIEW_LINK,
        payload: {
          id: lessonId,
          type: 'lesson',
        },
      })
    )
    .catch((error) => {
      notificationActions.createErrorNotificationFromResponse(error)(dispatch);
    });

export const resetModuleActivity = (courseId) => (dispatch) => {
  dispatch({type: RESET_MODULE_ACTIVITY, payload: courseId});
};

export const getEduRating = (lesson) => (dispatch, getState) => {
  const userLanguage = getState().getIn(['auth', 'language']);
  return dispatch(
    moduleActivityActionCreators.getEduRating(lesson, userLanguage)
  );
};

export const generateLessonSuggestionsFromDocument =
  ({courseTitle, file}) =>
  (dispatch) =>
    dispatch(
      moduleActivityActionCreators.generateLessonSuggestionsFromDocument({
        courseTitle,
        file,
      })
    )
      .then((res) => {
        if (res.payload.data.error) {
          notificationActions.createErrorNotificationFromResponse({
            response: res.payload,
          })(dispatch);
        }
        return res.payload.data;
      })
      .catch((error) => {
        notificationActions.createErrorNotificationFromResponse(error)(
          dispatch
        );
      });

export const generateLessonsFromTopics =
  ({topics, moduleId, file, queryFilter}) =>
  (dispatch, getState) => {
    const state = getState();
    const course = getCourseByModuleId(state, moduleId);
    dispatch(
      moduleActivityActionCreators.generateLessonsFromTopics({
        topics,
        moduleId,
        courseId: course.id,
        file,
        queryFilter,
      })
    )
      .then((res) => {
        const existingLessonKeys = course.lessonKeys;
        const generatedLessonKeys = res.payload.data.map(
          (lesson) => lesson.lessonKey
        );
        dispatch(
          loadLessons(
            [...existingLessonKeys, ...generatedLessonKeys],
            course.id,
            course.defaultLanguage
          )
        );
      })
      .catch((error) => {
        notificationActions.createErrorNotificationFromResponse(error)(
          dispatch
        );
      });
  };
