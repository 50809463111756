export const ADD_GROUP = 'ADD_GROUP';
export const ADD_GROUP_FAIL = 'ADD_GROUP_FAIL';
export const ADD_GROUP_SUCCESS = 'ADD_GROUP_SUCCESS';
export const ADD_TEAM = 'ADD_TEAM';
export const ADD_TEAM_FAIL = 'ADD_TEAM_FAIL';
export const ADD_TEAM_SUCCESS = 'ADD_TEAM_SUCCESS';
export const DELETE_GROUP = 'DELETE_GROUP';
export const DELETE_GROUP_FAIL = 'DELETE_GROUP_FAIL';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_TEAM = 'DELETE_TEAM';
export const DELETE_TEAM_SUCCESS = 'DELETE_TEAM_SUCCESS';
export const DELETED_TEAM = 'DELETED_TEAM';
export const DISMISS_CSV_UPLOAD_ERROR = 'DISMISS_CSV_UPLOAD_ERROR';
export const EDIT_GROUP = 'EDIT_GROUP';
export const EDIT_GROUP_FAIL = 'EDIT_GROUP_FAIL';
export const EDIT_GROUP_SUCCESS = 'EDIT_GROUP_SUCCESS';
export const EDIT_TEAM = 'EDIT_TEAM';
export const EDIT_TEAM_FAIL = 'EDIT_TEAM_FAIL';
export const EDIT_TEAM_SUCCESS = 'EDIT_TEAM_SUCCESS';
export const INITIAL_LOAD_TEAMS = 'INITIAL_LOAD_TEAMS';
export const INITIAL_LOAD_TEAMS_SUCCESS = 'INITIAL_LOAD_TEAMS_SUCCESS';
export const LOAD_EXTERNAL_TEAMS = 'LOAD_EXTERNAL_TEAMS';
export const LOAD_EXTERNAL_TEAMS_SUCCESS = 'LOAD_EXTERNAL_TEAMS_SUCCESS';
export const LOAD_TEAMS = 'LOAD_TEAMS';
export const LOAD_TEAMS_SUCCESS = 'LOAD_TEAMS_SUCCESS';
export const SELECT_TEAM = 'SELECT_TEAM';
export const SET_REFRESH_GROUP_ID = 'SET_REFRESH_GROUP_ID';
export const UPLOAD_CSV = 'UPLOAD_CSV';
export const UPLOAD_CSV_FAIL = 'UPLOAD_CSV_FAIL';
export const UPLOAD_CSV_SUCCESS = 'UPLOAD_CSV_SUCCESS';
export const SET_SHOW_ADD_TEAM_MODAL = 'SET_SHOW_ADD_TEAM_MODAL';
export const SEARCH_MS_TEAMS_TEAMS = 'SEARCH_MS_TEAMS_TEAMS';
export const SEARCH_MS_TEAMS_TEAMS_SUCCESS = 'SEARCH_MS_TEAMS_TEAMS_SUCCESS';
export const GET_ADDITIONAL_MS_TEAMS_TEAMS = 'GET_ADDITIONAL_MS_TEAMS_TEAMS';
export const GET_ADDITIONAL_MS_TEAMS_TEAMS_SUCCESS =
  'GET_ADDITIONAL_MS_TEAMS_TEAMS_SUCCESS';
export const GET_ADDITIONAL_MS_TEAMS_TEAMS_FAIL =
  'GET_ADDITIONAL_MS_TEAMS_TEAMS_FAIL';
export const CLICKED_CREATE_TEAM = 'CLICKED_CREATE_TEAM';
export const SELECTED_TEAM_TYPE = 'SELECTED_TEAM_TYPE';
export const VIEWED_SMART_TEAM_DETAILS = 'VIEWED_SMART_TEAM_DETAILS';
export const VIEWED_TEAM_ACTION = 'VIEWED_TEAM_ACTION';
export const SHOW_USERS_IN_TEAM = 'SHOW_USERS_IN_TEAM';
export const CHANGED_STATIC_TEAM_NAME = 'CHANGED_STATIC_TEAM_NAME';
export const EDIT_SMART_TEAM = 'EDIT_SMART_TEAM';
export const SAVED_TEAM_UPDATE = 'SAVED_TEAM_UPDATE';
