import React, {useRef} from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {injectIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {Link} from '@edume/magnificent';

import useFeatureFlag from '../../../hooks/useFeatureFlags';
import {getHasLeadRightsOnGroup} from '../../../utils/permissions';
import {ContentList} from '../../core';
import GenericEmptyScreen from '../../GenericEmptyScreen';
import KnowledgeHubCard from '../../KnowledgeHub/KnowledgeHubCard';

import KnowledgeHubCardStyles from '../../KnowledgeHub/KnowledgeHubCard/styles.scss';
import styles from './styles.scss';

const CARD_ITEM_HEIGHT = +KnowledgeHubCardStyles.cardHeight + 40;
const CARD_ITEM_WIDTH = 380;

const KnowledgeHubList = ({
  intl,
  knowledgeHubs,
  toggleKnowledgeHubModal,
  knowledgeHubCardProps,
  groups,
  groupId,
}) => {
  const listRef = useRef(null);
  const history = useHistory();

  const permissions = useSelector((state) =>
    state.getIn(['auth', 'permissions']).toJS()
  );
  const currentGroup = groups.find((group) => group.id === groupId);
  const hasLeadRightsOnGroup = getHasLeadRightsOnGroup(
    permissions,
    currentGroup
  );

  const knowledgeHubsCustomisationsEnabled = useFeatureFlag(
    'knowledgeHubsCustomisations'
  );

  if (knowledgeHubs.length < 1) {
    return (
      <div styleName='empty-screen'>
        <GenericEmptyScreen
          title={intl.formatMessage({id: 'Learning.noKnowledgeHubs.header'})}
          description={intl.formatMessage({
            id: 'Learning.noKnowledgeHubs.body',
          })}
          onButtonClick={toggleKnowledgeHubModal}
          buttonText={intl.formatMessage({id: 'Learning.noKnowledgeHubs.btn'})}
          buttonDataAutomation='create-knowledge-hub-button'
          imageUrl='/resources/img/24_mortaboard.svg'
          linkTextKey='Learning.noKnowledgeHubs.helpLink'
          linkHref='https://help.edume.com/knowledge-hubs'
          fullWidthImage={false}
          hasPermission
          headerDataAutomation='no-knowledge-hubs-header'
        />
      </div>
    );
  }

  const knowledgeHubCards = knowledgeHubs.map((kh) => (
    <KnowledgeHubCard
      key={kh.id}
      knowledgeHub={kh}
      showLinks
      {...knowledgeHubCardProps}
    />
  ));

  const linkText = intl.formatMessage({id: 'KnowledgeHub.customisationLink'});

  const goToKnowledgeHubCustomisations = () =>
    history.push({
      pathname: '/settings/branding/knowledge-hubs',
      search: history.location.search,
    });

  return (
    <>
      {knowledgeHubsCustomisationsEnabled && hasLeadRightsOnGroup && (
        <div styleName='link-container'>
          <Link text={linkText} onClick={goToKnowledgeHubCustomisations} />
        </div>
      )}

      <ContentList
        contentList={knowledgeHubCards}
        getRowHeight={() => CARD_ITEM_HEIGHT}
        itemWidth={CARD_ITEM_WIDTH}
        ref={listRef}
      />
    </>
  );
};

KnowledgeHubList.propTypes = {
  knowledgeHubs: PropTypes.array.isRequired,
  knowledgeHubCardProps: PropTypes.shape({
    teams: PropTypes.array.isRequired,
    createErrorNotification: PropTypes.func.isRequired,
    trackIdCopied: PropTypes.func.isRequired,
    isSeamlessEnabled: PropTypes.bool.isRequired,
  }).isRequired,
  groups: PropTypes.array.isRequired,
  groupId: PropTypes.number.isRequired,
  toggleKnowledgeHubModal: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(CSSModules(KnowledgeHubList, styles));
