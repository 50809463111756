import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';

import {Heading, Text} from '@edume/magnificent';

import styles from './styles.scss';

const ContentCreationCard = ({
  title,
  description,
  imageUrl,
  isSelected,
  onSelect,
  dataAutomation,
  isCompact = false,
}) => (
  <button
    styleName={isSelected ? 'selected-template-box' : 'template-box'}
    onClick={onSelect}
    data-automation={dataAutomation}
    role='menuitem'
  >
    <img styleName={classNames('image', {compact: isCompact})} src={imageUrl} />
    <div styleName='details'>
      <Heading as='h3' size='hamster' weight='medium'>
        {title}
      </Heading>
      <div styleName='description'>
        <Text size='s'>{description}</Text>
      </div>
    </div>
  </button>
);

ContentCreationCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  dataAutomation: PropTypes.string.isRequired,
  isCompact: PropTypes.bool,
};

export default CSSModules(ContentCreationCard, styles, {allowMultiple: true});
