import React, {useState} from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {injectIntl} from 'react-intl';

import * as contentTypes from '../../../enums/contentTypes';
import {ActivityCard} from '../../core';

import styles from './styles.scss';

const EmptyScreenButtons = ({onClick, intl}) => {
  const [hoveredOption, setHoveredOption] = useState(null);

  return (
    <div styleName='actions-buttons-wrap'>
      <ActivityCard
        option={{
          id: 1,
          activityTitle: intl.formatMessage({
            id: 'Learning.noContents.course.btn.title',
          }),
          activitySubtitle: intl.formatMessage({
            id: 'Learning.noContents.course.btn.description',
          }),
          imageUrl: '/resources/img/lesson/create-a-course-icon.svg',
          hoveredImageUrl:
            '/resources/img/lesson/create-a-course-hover-icon.svg',
          onSelect: () => onClick(contentTypes.COURSE),
          dataAutomation: 'blank-course-option',
        }}
        isHovered={hoveredOption === 1}
        setIsHovered={(value) => {
          if (value) {
            setHoveredOption(1);
          } else {
            setHoveredOption(null);
          }
        }}
        hasBackgroundStyles={false}
        titleSize='m'
        subtitleColour='grey700'
      />
      <span styleName='or-span'>
        <span>
          {intl.formatMessage({
            id: 'Learning.noContents.or',
          })}
        </span>
      </span>
      <ActivityCard
        option={{
          id: 2,
          activityTitle: intl.formatMessage({
            id: 'Learning.noContents.guide.btn.title',
          }),
          activitySubtitle: intl.formatMessage({
            id: 'Learning.noContents.guide.btn.description',
          }),
          imageUrl: '/resources/img/lesson/create-a-guide-icon.svg',
          hoveredImageUrl:
            '/resources/img/lesson/create-a-guide-hover-icon.svg',
          onSelect: () => onClick(contentTypes.GUIDE),

          dataAutomation: 'blank-guide-option',
        }}
        isHovered={hoveredOption === 2}
        setIsHovered={(value) => {
          if (value) {
            setHoveredOption(2);
          } else {
            setHoveredOption(null);
          }
        }}
        hasBackgroundStyles={false}
        titleSize='m'
        subtitleColour='grey700'
      />
    </div>
  );
};

EmptyScreenButtons.propTypes = {
  onClick: PropTypes.func,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(CSSModules(EmptyScreenButtons, styles));
