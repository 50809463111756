import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {useIntl} from 'react-intl';

import {
  Badge,
  Button,
  ContentCard,
  CrossIcon,
  PlusIcon,
  TickIcon,
} from '@edume/magnificent';

import * as contentTypes from '../../../enums/contentTypes';
import {
  PUBLISHED,
  READY_TO_PUBLISH,
  REVIEW,
  UNPUBLISHED,
} from '../../../enums/courses';
import {getContentBadgeColour} from '../../../utils/badges';
import {LegacyIntlHeading} from '../../core';

import colourStyles from '../../../../resources/styles/colours.module.scss';
import styles from './styles.scss';

const SUCCESS_ICON_DURATION = 1000;

// eslint-disable-next-line complexity
const AdminContentCard = ({
  content,
  provided,
  addContent,
  removeContent,
  isDragging,
}) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    let timeoutId;
    if (showSuccess) {
      timeoutId = setTimeout(
        () => setShowSuccess(false),
        SUCCESS_ICON_DURATION
      );
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [showSuccess]);

  const {
    contentType,
    isAssignedContent,
    title,
    courseImageUrl,
    cover,
    numModuleActivities,
    steps,
  } = content;

  const isGuide = contentType === contentTypes.GUIDE;
  const activityCountContent = isGuide ? (
    <LegacyIntlHeading
      size='mini'
      textKey='Guides.stepCount'
      values={{number: steps?.length}}
    />
  ) : (
    <LegacyIntlHeading
      size='mini'
      textKey='Courses.preview.lessonCountCap'
      values={{number: numModuleActivities}}
    />
  );

  const status = (() => {
    if (isGuide ? content.status === PUBLISHED : content.published) {
      return PUBLISHED;
    }
    if (isGuide ? content.status === REVIEW : content.ready) {
      return READY_TO_PUBLISH;
    }
    return UNPUBLISHED;
  })();

  const contentTypeText = intl.formatMessage({
    id: `Learning.contentType.${contentType}`,
  });

  const isUnAssignedOrShowingSuccess =
    isAssignedContent === false || showSuccess;

  const rightPanel = (
    <div styleName='right-panel'>
      {removeContent && !isUnAssignedOrShowingSuccess && (
        <div styleName='remove-button'>
          <Button
            type='custom'
            icon={<CrossIcon size='tiny' />}
            onClick={() => removeContent(content)}
            customColour={colourStyles.grey300}
            customDarkColour={colourStyles.grey700}
            customFontColour={colourStyles.white}
            customHoverFontColour={colourStyles.white}
            dataAutomation='remove-content-button'
          />
        </div>
      )}

      {addContent && isUnAssignedOrShowingSuccess && (
        <div styleName={`add-button${showSuccess ? ' success' : ''}`}>
          <Button
            type={showSuccess ? 'custom' : 'primary'}
            customColour={colourStyles.aqua700}
            customDarkColour={colourStyles.aqua700}
            customFontColour={colourStyles.white}
            customHoverFontColour={colourStyles.white}
            icon={showSuccess ? <TickIcon /> : <PlusIcon />}
            dataAutomation={
              showSuccess ? 'content-added-label' : 'add-content-button'
            }
            onClick={() => {
              setShowSuccess(true);
              addContent(content);
            }}
          />
        </div>
      )}
    </div>
  );

  const badgeColour = getContentBadgeColour(status);

  const cardFooter = (
    <Badge
      colour={badgeColour}
      textContent={intl.formatMessage({id: `Learning.${status}`})}
    />
  );

  return (
    <div
      styleName={`card-container${isDragging ? ' dragging' : ''}`}
      data-automation='content-card'
      {...(provided && provided.draggableProps)}
      {...(provided && provided.dragHandleProps)}
      ref={provided && provided.innerRef}
    >
      <ContentCard
        title={title}
        image={isGuide ? cover.thumbnail : courseImageUrl}
        contentType={contentType}
        contentTypeText={contentTypeText}
        activityCountContent={activityCountContent}
        borderRadius={4}
        cardFooter={cardFooter}
        rightPanel={rightPanel}
        fullHeight={false}
        borders={true}
        selected={isAssignedContent}
      />
    </div>
  );
};

AdminContentCard.propTypes = {
  content: PropTypes.object.isRequired,
  provided: PropTypes.object,
  addContent: PropTypes.func,
  removeContent: PropTypes.func,
  isDragging: PropTypes.bool,
};

export default CSSModules(AdminContentCard, styles, {
  allowMultiple: true,
});
