import React, {useEffect, useState} from 'react';

import {PDFDownloadLink} from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import QRCode from 'qrcode';
import CSSModules from 'react-css-modules';
import {useIntl} from 'react-intl';

import {Button, DownloadIcon, Text} from '@edume/magnificent';

import QRCodePDF from './QRCodePDF';

import styles from './styles.scss';

const QRCodeWithDownload = ({
  qrCodeValue,
  contentTitle,
  teamName,
  logo,
  downloadButtonText,
}) => {
  const [QRCodeDataUrl, setQRCodeDataUrl] = useState(null);
  const intl = useIntl();
  useEffect(() => {
    async function generateQrCodeDataUrl() {
      setQRCodeDataUrl(await QRCode.toDataURL(qrCodeValue));
    }

    if (qrCodeValue) {
      generateQrCodeDataUrl();
    }
  }, [qrCodeValue]);

  if (!QRCodeDataUrl) {
    return (
      <div styleName='qr-code-loading'>
        <Text size='s' weight='bold' inline>
          {intl.formatMessage({id: 'QrCode.generating'})}
        </Text>
      </div>
    );
  }

  return (
    <>
      <div styleName='qr-code-wrapper'>
        <img src={QRCodeDataUrl} />
      </div>
      <div styleName='qr-code-download-btn'>
        <PDFDownloadLink
          fileName={`${contentTitle}_${teamName}.pdf`}
          document={
            <QRCodePDF
              QRCodeDataUrl={QRCodeDataUrl}
              contentTitle={contentTitle}
              teamName={teamName}
              logo={logo}
            />
          }
        >
          {({loading}) => (
            <Button
              noMarginTop={true}
              type='secondary'
              icon={<DownloadIcon size='small' colour='brandPrimary' />}
              disabled={loading}
            >
              {downloadButtonText ??
                intl.formatMessage({id: 'QrCode.download.button'})}
            </Button>
          )}
        </PDFDownloadLink>
      </div>
    </>
  );
};

QRCodeWithDownload.propTypes = {
  qrCodeValue: PropTypes.string,
  contentTitle: PropTypes.string,
  teamName: PropTypes.string,
  logo: PropTypes.string,
  downloadButtonText: PropTypes.string,
};

export default CSSModules(QRCodeWithDownload, styles, {allowMultiple: true});
