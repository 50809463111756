// React-Intl
import I18nIsoCountries from 'i18n-iso-countries';
import deLocale from 'i18n-iso-countries/langs/de.json';
import enLocale from 'i18n-iso-countries/langs/en.json';
import esLocale from 'i18n-iso-countries/langs/es.json';
import frLocale from 'i18n-iso-countries/langs/fr.json';
import ptLocale from 'i18n-iso-countries/langs/pt.json';

import {languageUtil} from '@edume/bento';

import countryCallingCodes from '../../resources/locales/countryCodes';
import {sortObjectsAlphabeticallyByKey} from './sort';

I18nIsoCountries.registerLocale(enLocale);
I18nIsoCountries.registerLocale(esLocale);
I18nIsoCountries.registerLocale(ptLocale);
I18nIsoCountries.registerLocale(frLocale);
I18nIsoCountries.registerLocale(deLocale);

// Load polyfill if browser doesn't support Intl
// this is the node setup from here: https://formatjs.io/guides/runtime-environments/#client
// can almost certainly be improved once we configure per-customer (?) default languages
// e.g. can load only needed locales, only load intl polyfill if required
const areIntlLocalesSupported = require('intl-locales-supported').default;

export const localesMyAppSupports = languageUtil.adminLanguages;

if (global.Intl) {
  // Determine if the built-in `Intl` has the locale data we need.
  if (!areIntlLocalesSupported(localesMyAppSupports)) {
    // `Intl` exists, but it doesn't have the data we need, so load the
    // polyfill and patch the constructors we need with the polyfill's.
    const IntlPolyfill = require('intl');
    Intl.MessageFormat = IntlPolyfill.MessageFormat;
    Intl.RelativeFormat = IntlPolyfill.RelativeFormat;
  }
} else {
  // No `Intl`, so use and load the polyfill.
  global.Intl = require('intl');
}

export const getCountriesWithCallingCode = (language) => {
  const countriesList = I18nIsoCountries.getNames(language);
  const countriesWithCode = [];
  const priorityCountries = [];
  const priorityCountryCodes = ['US', 'GB'];
  Object.keys(countriesList).forEach((countryCode) => {
    const country = {
      code: countryCode,
      name: countriesList[countryCode],
      callingCode: countryCallingCodes[countryCode],
    };
    if (priorityCountryCodes.includes(countryCode)) {
      return priorityCountries.push(country);
    }
    return countriesWithCode.push(country);
  });
  countriesWithCode.sort(sortObjectsAlphabeticallyByKey('name'));

  return [...priorityCountries, ...countriesWithCode];
};

export const getIndexFromCountryCode = (countries, countryCode) => {
  const countryIndex = countries.findIndex(
    (country) => country.code === countryCode
  );
  return countryIndex === -1 ? null : countryIndex;
};

export const getCountryFromPhone = (countries, phone) => {
  const threeNumberCode = phone.substring(0, 3);
  const twoNumberCode = phone.substring(0, 2);
  const oneNumberCode = phone.substring(0, 1);
  let countryIndex = countries.findIndex(
    (country) => country.callingCode === threeNumberCode
  );
  if (countryIndex === -1) {
    countryIndex = countries.findIndex(
      (country) => country.callingCode === twoNumberCode
    );
  }
  if (countryIndex === -1) {
    countryIndex = countries.findIndex(
      (country) => country.callingCode === oneNumberCode
    );
  }
  return countryIndex === -1 ? null : countryIndex;
};
