import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {useIntl} from 'react-intl';
import requiredIf from 'react-required-if';

import {Dropdown, FixedRatioImage, HintBox} from '@edume/magnificent';

import {getPreviewImage} from '../../../utils/fileUpload';
import {
  FIVE_BY_FOUR_CROPPED_IMAGE_ONLY,
  ONE_BY_ONE_CROPPED_IMAGE_ONLY,
  TWO_BY_ONE_CROPPED_IMAGE_ONLY,
  TWO_BY_ONE_CROPPED_IMAGE_WITH_ORIGINAL,
} from '../UploadModal/mediaTypes';
import UploadModal from '../UploadModal/UploadModal';
import useUploadModal from '../UploadModal/useUploadModal';

import colours from '../../../../resources/styles/colours.module.scss';
import styles from './styles.scss';

const grey700 = colours.grey700;

// eslint-disable-next-line complexity
const UploadMediaWithThumbnail = ({
  media,
  type,
  imageType,
  addButtonLocaleKey,
  changeButtonLocaleKey,
  dimOnHover = true,
  imageStyles,
  disabled,
  hintTextKey,
  setMedia,
  dataAutomation,
  onModalOpen = () => {},
  onModalClose = () => {},
  isAddingOrRemovingEnabled = true,
}) => {
  const intl = useIntl();
  const {openUploadModal, ...uploadModalProps} = useUploadModal(
    setMedia,
    onModalOpen,
    onModalClose
  );

  const buttonText = intl.formatMessage({
    id: media.url ? changeButtonLocaleKey : addButtonLocaleKey,
  });
  const dropdownTitle = intl.formatMessage({id: 'UploadModal.dropdownTitle'});
  const uploadText = intl.formatMessage({id: 'UploadModal.dropdownUpload'});
  const embedText = intl.formatMessage({id: 'UploadModal.dropdownEmbed'});

  const thumbnail = getPreviewImage(media.thumbnailUrl || media.url, type);

  const isChangingMediaDisabled =
    disabled || (!isAddingOrRemovingEnabled && !media.url);

  if (!media.url && !isAddingOrRemovingEnabled) {
    return (
      <div styleName='hint-container '>
        <HintBox
          type='info'
          text={intl.formatMessage({id: 'UploadModal.addingDisabledHintText'})}
          icon='info'
        />
      </div>
    );
  }

  return (
    <FixedRatioImage
      url={thumbnail}
      backgroundColor={grey700}
      style={imageStyles}
      dataAutomation={dataAutomation}
    >
      <div
        styleName='fixed-container'
        onClick={
          isChangingMediaDisabled
            ? undefined
            : () => openUploadModal(media, imageType)
        }
      >
        {!isChangingMediaDisabled && (
          <div styleName={`imageMenu${dimOnHover ? ' dimOnHover' : ''}`}>
            {type === 'image' ? (
              <div
                styleName='imageButton'
                data-automation={`${
                  media.url ? 'change-image' : 'add-image'
                }-button`}
              >
                <span styleName='buttonText'>{buttonText}</span>
              </div>
            ) : (
              <Dropdown
                type='secondary'
                defaultText={buttonText}
                textSize='s'
                title={dropdownTitle}
                options={[
                  {
                    title: uploadText,
                    dataAutomation: 'add-video-upload-dropdown-option',
                    callback: () => openUploadModal(media, 'video'),
                  },
                  {
                    title: embedText,
                    dataAutomation: 'add-video-embed-dropdown-option',
                    callback: () => openUploadModal(media, 'youtube'),
                  },
                ]}
                dataAutomation='add-video-dropdown-button'
              />
            )}
          </div>
        )}
        <UploadModal
          hintTextKey={hintTextKey}
          isRemovingEnabled={isAddingOrRemovingEnabled}
          {...uploadModalProps}
        />
      </div>
    </FixedRatioImage>
  );
};

UploadMediaWithThumbnail.propTypes = {
  type: PropTypes.oneOf(['image', 'video']).isRequired,
  imageType: requiredIf(
    PropTypes.oneOf([
      FIVE_BY_FOUR_CROPPED_IMAGE_ONLY,
      TWO_BY_ONE_CROPPED_IMAGE_ONLY,
      ONE_BY_ONE_CROPPED_IMAGE_ONLY,
      TWO_BY_ONE_CROPPED_IMAGE_WITH_ORIGINAL,
    ]),
    (props) => props.type === 'image'
  ),
  setMedia: PropTypes.func.isRequired,
  media: PropTypes.shape({
    url: PropTypes.string,
    thumbnailUrl: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    thumbnailWidth: PropTypes.number,
    thumbnailHeight: PropTypes.number,
    source: requiredIf(PropTypes.string, (props) => props.type === 'video'),
  }),
  addButtonLocaleKey: PropTypes.string.isRequired,
  changeButtonLocaleKey: PropTypes.string.isRequired,
  hintTextKey: PropTypes.string.isRequired,
  dimOnHover: PropTypes.bool,
  onModalOpen: PropTypes.func,
  onModalClose: PropTypes.func,
  imageStyles: PropTypes.object,
  dataAutomation: PropTypes.string,
  disabled: PropTypes.bool,
  isAddingOrRemovingEnabled: PropTypes.bool,
};

export default CSSModules(UploadMediaWithThumbnail, styles, {
  allowMultiple: true,
});
