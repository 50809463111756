import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';

import {setAmplitudeTestProps} from '../../../../state/multivariantTests/multivariantTestActions';
import {PLG2} from '../../../../state/multivariantTests/multivariantTestNames';
import HeaderHelpCentre from './HeaderHelpCentre';
import HeaderSalesCta from './HeaderSalesCta';
import HeaderSettings from './HeaderSettings';

import styles from './styles.scss';

const HeaderItems = ({settingsProps, canSeeHeaderSettings}) => {
  const intl = useIntl();
  const accessMethod = useSelector((state) =>
    state.getIn(['auth', 'accessMethod'])
  );
  const multivariantTestIds = useSelector((state) =>
    state.getIn(['multivariantTests', 'variantIds']).toJS()
  );
  const salesCtaTestVariant = multivariantTestIds.find((id) =>
    id.includes(PLG2)
  );
  const variant = salesCtaTestVariant && salesCtaTestVariant.split('-')[0];
  const buttonText = variant
    ? intl.formatMessage({
        id: `Trial.salesCta.variant${variant}`,
      })
    : '';

  const dispatch = useDispatch();
  if (buttonText) {
    dispatch(setAmplitudeTestProps());
  }

  return (
    <>
      <HeaderHelpCentre {...settingsProps} accessMethod={accessMethod} />
      {canSeeHeaderSettings && <HeaderSettings {...settingsProps} />}
      {buttonText && <HeaderSalesCta text={buttonText} />}
    </>
  );
};

HeaderItems.propTypes = {
  settingsProps: PropTypes.object.isRequired,
  canSeeHeaderSettings: PropTypes.bool.isRequired,
};

export default CSSModules(HeaderItems, styles);
