import React, {Component} from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {injectIntl} from 'react-intl';

import {Link} from '@edume/magnificent';

import MarkdownHelpModal from './MarkdownHelpModal';

import styles from './styles.scss';

class MarkdownInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };
  }

  toggleModal() {
    this.setState((prevState) => {
      if (prevState.showModal) {
        this.props.onHideModal();
      } else {
        this.props.onShowModal();
      }
      return {showModal: !prevState.showModal};
    });
  }

  render() {
    const {children, showHyperlinks, countText, intl} = this.props;
    const {showModal} = this.state;

    const boldHintText = intl.formatMessage({id: 'Markdown.boldHint'});
    const italicHintText = intl.formatMessage({id: 'Markdown.italicHint'});
    const linkText = intl.formatMessage({id: 'Markdown.link'});

    return (
      <div styleName='markdownInput'>
        {children}
        <div styleName='base-info'>
          <span>{countText}</span>
          <div styleName='hint-text'>
            <span>
              <span styleName='bold'>{boldHintText}</span>&nbsp;&nbsp;
              <span styleName='italic'>{italicHintText}</span>
            </span>
            &nbsp;&nbsp;
            <Link text={linkText} onClick={() => this.toggleModal()} />
          </div>
        </div>
        {showModal ? (
          <MarkdownHelpModal
            onClose={() => this.toggleModal()}
            showHyperlinks={showHyperlinks}
          />
        ) : null}
      </div>
    );
  }
}

MarkdownInput.propTypes = {
  children: PropTypes.any.isRequired,
  showHyperlinks: PropTypes.bool,
  countText: PropTypes.string,
  onHideModal: PropTypes.func,
  onShowModal: PropTypes.func,
  intl: PropTypes.object.isRequired,
};

MarkdownInput.defaultProps = {
  onHideModal: () => {},
  onShowModal: () => {},
};

export default injectIntl(
  CSSModules(MarkdownInput, styles, {allowMultiple: true})
);
