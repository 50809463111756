import React, {useState} from 'react';

import URLSearchParams from '@ungap/url-search-params';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {useIntl} from 'react-intl';
import requiredIf from 'react-required-if';
import {withRouter} from 'react-router-dom';

import {auth} from '@edume/bento';
import {SadFilledIcon} from '@edume/magnificent';

import useBreakpoint from '../../hooks/useBreakpoint';
import useValidation from '../../hooks/useValidation';
import SimpleMessageView from '../../views/landing/SimpleMessageView';
import LoginForm from './LoginForm';
import MessageBubbleHeader from './MessageBubbleHeader';
import SSOLoginForm from './SSOLoginForm';

import styles from './styles.scss';

const LARGE_SCREEN_BREAKPOINT = 960;

const Login = ({
  checkEmail,
  loginWithEmail,
  onChangeCredentials,
  resetForm,
  enteredFailedLogin,
  failedLoginReason,
  authType,
  userId,
  ssoConfig,
  pathname,
  history,
  enteredEmail,
  onChangeContactInput,
}) => {
  const intl = useIntl();
  const {isSmallScreen} = useBreakpoint(LARGE_SCREEN_BREAKPOINT);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [ssoError, setSsoError] = useState(() => {
    const params = new URLSearchParams(location.search);
    const errorCode = params.get('error');
    return errorCode || null;
  });

  const [isEmailValid, isEmailValidDisplay, unsetForceEmailValidDisplay] =
    useValidation(email, 'email');
  const [
    isPasswordValid,
    isPasswordValidDisplay,
    unsetForcePasswordValidDisplay,
  ] = useValidation(password, 'password-legacy');

  const updateEmail = (newEmail) => {
    setEmail(newEmail);
    onChangeCredentials();
  };

  const updatePassword = (newPassword) => {
    setPassword(newPassword);
    onChangeCredentials();
  };

  const onResetForm = () => {
    setPassword('');
    resetForm();
  };

  const onCheckEmail = () => {
    unsetForceEmailValidDisplay();
    if (isEmailValid) {
      checkEmail(email);
      setSsoError(null);
    }
  };

  const onLoginWithEmail = () => {
    unsetForcePasswordValidDisplay();
    if (isEmailValid && isPasswordValid) {
      loginWithEmail(email, password, pathname);
    }
  };

  const getErrorKey = () => {
    if (ssoError) {
      return 'Error.ssoGenericError';
    }
    return failedLoginReason || 'Login.loginFailedMsg';
  };

  const loginFailedMsg = intl.formatMessage({id: getErrorKey()});

  return (
    <SimpleMessageView darkBackground={isSmallScreen}>
      <MessageBubbleHeader ssoConfig={ssoConfig} authType={authType} />
      {(enteredFailedLogin || ssoError) && (
        <div styleName='errorMessage'>
          <SadFilledIcon colour='white' />
          <span data-automation='error-message'>{loginFailedMsg}</span>
        </div>
      )}
      {authType === auth.SSO ? (
        <SSOLoginForm
          onBackClick={onResetForm}
          ssoConfig={ssoConfig}
          userId={userId}
        />
      ) : (
        <LoginForm
          inputType={enteredEmail ? 'password' : 'email'}
          emailValue={email}
          passwordValue={password}
          isValidEmailDisplay={isEmailValidDisplay}
          isValidPasswordDisplay={isPasswordValidDisplay}
          updateEmailValue={updateEmail}
          updatePasswordValue={updatePassword}
          onEmailSubmit={onCheckEmail}
          onPasswordSubmit={onLoginWithEmail}
          onBackClick={onResetForm}
          history={history}
          onChangeContactInput={onChangeContactInput}
        />
      )}
    </SimpleMessageView>
  );
};

Login.propTypes = {
  checkEmail: PropTypes.func.isRequired,
  loginWithEmail: PropTypes.func.isRequired,
  onChangeCredentials: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  enteredFailedLogin: PropTypes.bool.isRequired,
  failedLoginReason: PropTypes.string.isRequired,
  authType: PropTypes.string,
  userId: requiredIf(PropTypes.number, (props) => props.authType === auth.SSO),
  ssoConfig: PropTypes.object,
  pathname: PropTypes.string.isRequired,
  history: PropTypes.object,
  enteredEmail: PropTypes.bool,
  onChangeContactInput: PropTypes.func.isRequired,
};

export default withRouter(CSSModules(Login, styles));
