import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {FormattedMessage, injectIntl} from 'react-intl';
import ReactTooltip from 'react-tooltip';

import {
  AsteriskIcon,
  Badge,
  CopyIcon,
  DemoIcon,
  IconButton,
  LanguageIcon,
  LegacyHeading,
  Link,
  QuestionMarkIcon,
} from '@edume/magnificent';

import * as contentTypes from '../../../enums/contentTypes';
import {getContentBadgeColour} from '../../../utils/badges';
import {getPreviewLink} from '../../../utils/contentUtil';
import {checkIsCourseInDemoLibrary} from '../../../utils/courseUtil';
import {injectFeatureFlags} from '../../../utils/featureFlags';
import {PreviewLinkButton} from '../../core';

import styles from './styles.scss';

// eslint-disable-next-line complexity, max-statements
const ImageCourse = ({
  course,
  publishStatus,
  isResourceLibraryCourse,
  publishStatusText,
  priorityCoursesEnabled,
  acceptedLabel,
  firstModuleId,
  duplicateOptions,
  copyResourceLibraryCourse,
  firstActivityId,
  updateCourseDetails,
  intl,
  goToCourse,
  adminLanguage,
  isTemplate,
  handleCopyCourse,
  isDemoContentCourse,
  handleCopyDemoCourse,
  hasNoHoverState = false,
  showCourseLabel = false,
}) => {
  const {
    isTempCoursePlaceholder,
    published,
    ready,
    title,
    isMultiLanguage,
    id: courseId,
    priority,
    updatedAt,
    label,
    numModuleActivities,
    contentType,
  } = course;

  if (isTempCoursePlaceholder) {
    return <div styleName='course-card' />;
  }

  const activityCountText = `${intl.formatMessage(
    {id: `Learning.${contentType}.activityCount`},
    {count: numModuleActivities || course.steps?.length || 0}
  )}`;
  const duplicateButtonTooltipText = intl.formatMessage({
    id: `Learning.${contentType}.duplicate`,
  });

  const lastEdited = `${intl.formatMessage({
    id: 'Learning.courseCard.lastEdited',
  })} ${moment(updatedAt).locale(adminLanguage).fromNow()}`;

  const tempCourseText = intl.formatMessage({id: 'Learning.copyingMsg'});

  const isPriorityCourse = priorityCoursesEnabled && priority === 'priority';
  const isCourseInDemoLibrary = checkIsCourseInDemoLibrary(course);

  const isEditableCourse = !(
    isDemoContentCourse ||
    isResourceLibraryCourse ||
    isTemplate
  );

  const imageUrl =
    course.courseImageUrl || '/resources/img/default_course_image.png';

  const openPreviewLink = async (e) => {
    e.stopPropagation();
    const previewLink = await getPreviewLink({
      contentType: contentTypes.COURSE,
      courseId,
      moduleId: firstModuleId,
      activityId: firstActivityId,
    });
    window.open(previewLink, '_blank');
  };

  const getCardLabel = () => {
    if ((isResourceLibraryCourse || showCourseLabel) && acceptedLabel) {
      return (
        <div styleName='badge-wrapper'>
          <Badge
            textContent={intl.formatMessage({
              id: `Learning.courseLabel.${label}`,
            })}
            colour={getContentBadgeColour(label)}
            compact
            badgeStyle='outlined'
            dataAutomation='course-label'
          />
        </div>
      );
    }

    if (isDemoContentCourse) {
      return (
        <div styleName='badge-wrapper'>
          <Badge
            textContent={intl.formatMessage({
              id: `Learning.courseLabel.demo`,
            })}
            colour={getContentBadgeColour('demo')}
            compact
            dataAutomation='course-label'
          />
        </div>
      );
    }

    if (isTemplate) {
      return (
        <div styleName='badge-wrapper'>
          <Badge
            textContent={intl.formatMessage({
              id: `Learning.courseLabel.template`,
            })}
            colour={getContentBadgeColour('template')}
            compact
            badgeStyle='solid'
            dataAutomation='course-label'
          />
        </div>
      );
    }

    return (
      <div styleName='badge-wrapper'>
        <Badge
          textContent={publishStatusText}
          colour={getContentBadgeColour(publishStatus)}
          compact
          dataAutomation='course-status'
        />
      </div>
    );
  };

  return (
    <div
      styleName={`course-card ${hasNoHoverState ? '' : 'with-hover'}`}
      onClick={
        isResourceLibraryCourse || isDemoContentCourse
          ? openPreviewLink
          : goToCourse
      }
      data-automation='course-card'
    >
      <div styleName='course-image-container'>
        <div styleName='image-header'>
          <div>
            {!isDemoContentCourse && (
              <div styleName='icons'>
                {isPriorityCourse && (
                  <div data-tip data-for={`priority-tip-${course.id}`}>
                    <AsteriskIcon colour='blue700' size='xsmall' />
                    <ReactTooltip
                      id={`priority-tip-${course.id}`}
                      effect='solid'
                      place='left'
                      backgroundColor='black'
                      delayShow={300}
                      className='courseIconTooltip'
                    >
                      {intl.formatMessage({
                        id: 'Learning.courseIcon.tooltip.priority',
                      })}
                    </ReactTooltip>
                  </div>
                )}
                {isMultiLanguage && (
                  <div data-tip data-for={`mls-tip-${course.id}`}>
                    <LanguageIcon colour='blue700' size='xsmall' />
                    <ReactTooltip
                      id={`mls-tip-${course.id}`}
                      effect='solid'
                      place='left'
                      backgroundColor='black'
                      delayShow={300}
                      className='courseIconTooltip'
                    >
                      {intl.formatMessage({
                        id: 'Learning.courseIcon.tooltip.mls',
                      })}
                    </ReactTooltip>
                  </div>
                )}
                {isCourseInDemoLibrary && (
                  <div data-tip data-for={`demo-tip-${course.id}`}>
                    <DemoIcon colour='blue700' size='xsmall' />
                    <ReactTooltip
                      id={`demo-tip-${course.id}`}
                      effect='solid'
                      place='left'
                      backgroundColor='black'
                      delayShow={300}
                      className='courseIconTooltip'
                    >
                      {intl.formatMessage({
                        id: 'Learning.courseIcon.tooltip.demo',
                      })}
                    </ReactTooltip>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <div styleName='image'>
          <img
            src={imageUrl}
            alt='course-image'
            data-automation='course-image'
          />
          <span
            styleName={`card-content-type ${course.contentType || 'course'}`}
          >
            {intl.formatMessage({
              id: `Learning.contentType.${course.contentType || 'course'}`,
            })}
          </span>
        </div>
      </div>

      <div
        styleName={`course-info ${
          isResourceLibraryCourse && !acceptedLabel ? 'noLabel' : ''
        }`}
      >
        <div styleName='course-info-header'>
          {getCardLabel()}

          <LegacyHeading
            size='mini'
            weight='medium'
            dataAutomation='course-activity-count'
          >
            {activityCountText}
          </LegacyHeading>
        </div>

        <div styleName='course-title'>
          <LegacyHeading
            size='tinier'
            weight='medium'
            dataAutomation='course-title'
          >
            {isTempCoursePlaceholder ? tempCourseText : title}
          </LegacyHeading>
        </div>

        <div styleName='course-card-actions'>
          {isEditableCourse && (
            <LegacyHeading
              size='mini'
              colour='grey700'
              dataAutomation='last-edited-time'
            >
              {lastEdited}
            </LegacyHeading>
          )}

          <div
            styleName={`course-options ${
              !isEditableCourse ? 'noLastEdited' : ''
            } ${isDemoContentCourse ? 'demo-container' : ''}`}
          >
            {isDemoContentCourse && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                styleName='demo-course-icon'
              >
                <span
                  data-tip
                  data-for={`demoContentTip-${course.id}`}
                  data-event='click'
                  data-event-off='mouseout'
                >
                  <QuestionMarkIcon size='small' colour='grey300' />
                </span>
                <ReactTooltip
                  id={`demoContentTip-${course.id}`}
                  effect='solid'
                  place='bottom'
                  type='dark'
                  globalEventOff='click'
                  className='demoTooltip'
                >
                  <div>
                    {intl.formatMessage({
                      id: 'Learning.demo.cardTooltip1',
                    })}

                    <div>
                      <FormattedMessage
                        id='Learning.demo.cardTooltip2'
                        values={{
                          accountName: course.customer?.name,
                          accountId: course.customer?.id,
                          b: (text) => <b>{text}</b>,
                        }}
                      />
                    </div>
                    <div>
                      <FormattedMessage
                        id='Learning.demo.cardTooltip3'
                        values={{
                          groupName: course.parentGroup?.name,
                          groupId: course.parentGroup?.id,
                          b: (text) => <b>{text}</b>,
                        }}
                      />
                    </div>
                  </div>
                </ReactTooltip>
              </div>
            )}
            {(ready || published) && (
              <PreviewLinkButton
                courseId={courseId}
                moduleId={firstModuleId}
                activityId={firstActivityId}
                intl={intl}
                contentType={contentType}
                guideId={courseId}
                guidePreviewToken={course.previewToken}
                previewInlineProps={
                  contentType === contentTypes.GUIDE
                    ? {source: '/learning'}
                    : null
                }
                isIconOnly
              />
            )}
            {updateCourseDetails && isEditableCourse && (
              <>
                <IconButton
                  icon={<CopyIcon size='small' colour='brandPrimary' />}
                  tooltipProps={{
                    text: duplicateButtonTooltipText,
                    backgroundColor: 'black',
                    effect: 'solid',
                    place: 'bottom',
                  }}
                  popoverProps={{title: '', options: duplicateOptions}}
                  dataAutomation={`duplicate-${contentType}-button`}
                  isBgTransparent={true}
                  showBorder={false}
                />
              </>
            )}
            {isTemplate && (
              <Link
                dataAutomation='use-template-button'
                text={intl.formatMessage({id: 'Learning.useThisTemplate'})}
                onClick={(e) => {
                  e.stopPropagation();
                  handleCopyCourse();
                }}
              />
            )}
            {isResourceLibraryCourse && (
              <Link
                text={intl.formatMessage({id: 'Learning.copyToMyLibrary'})}
                onClick={copyResourceLibraryCourse}
              />
            )}
            {isDemoContentCourse && (
              <Link
                text={intl.formatMessage({id: 'Learning.copyToMyLibrary'})}
                onClick={(event) => {
                  event.stopPropagation();
                  handleCopyDemoCourse();
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ImageCourse.propTypes = {
  course: PropTypes.object.isRequired,
  publishStatus: PropTypes.string.isRequired,
  isResourceLibraryCourse: PropTypes.bool.isRequired,
  publishStatusText: PropTypes.string.isRequired,
  priorityCoursesEnabled: PropTypes.bool.isRequired,
  acceptedLabel: PropTypes.bool.isRequired,
  firstModuleId: PropTypes.number,
  duplicateOptions: PropTypes.array,
  copyResourceLibraryCourse: PropTypes.func.isRequired,
  firstActivityId: PropTypes.number,
  updateCourseDetails: PropTypes.func,
  intl: PropTypes.object.isRequired,
  goToCourse: PropTypes.func.isRequired,
  adminLanguage: PropTypes.string.isRequired,
  isTemplate: PropTypes.bool,
  handleCopyCourse: PropTypes.func.isRequired,
  isDemoContentCourse: PropTypes.bool,
  handleCopyDemoCourse: PropTypes.func,
  hasNoHoverState: PropTypes.bool,
  showCourseLabel: PropTypes.bool,
};

export default injectIntl(
  injectFeatureFlags(CSSModules(ImageCourse, styles, {allowMultiple: true}))
);
