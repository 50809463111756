import axios from 'axios';

import {videoUtil} from '@edume/bento';

import config from '../config';
import edumeClient from '../edumeClient';

const defaultMediaUrl = '/resources/img/default_course_image.png';

export const CANCELLED_COMPONENT_UNMOUNTED = 'CANCELLED_COMPONENT_UNMOUNTED';
export const CLOUDINARY_VIDEO_URL_PREFIX =
  'https://edume-res.cloudinary.com/video/upload/';

// Checkers if a file is too big, so we can warn/error
export const shouldWarnFileTooBig = (file, type) => {
  let fileSize = file.size / 1048576; // 1024 * 1024
  fileSize = Math.round(fileSize * 10) / 10; // round to 1 decimal place
  return (
    (type === 'video' && fileSize > 40) || (type === 'image' && fileSize > 2)
  );
};

export const shouldErrorFileTooBig = (file, type) => {
  let fileSize = file.size / 1048576; // 1024 * 1024
  fileSize = Math.round(fileSize * 10) / 10; // round to 1 decimal place
  return (
    (type === 'video' && fileSize > 100) || (type === 'image' && fileSize > 10)
  );
};

// https://stackoverflow.com/a/59985954/5915221
export const readURL = (file) =>
  new Promise((res, rej) => {
    const reader = new FileReader();
    reader.onload = (e) => res(e.target.result);
    reader.onerror = (e) => rej(e);
    reader.readAsDataURL(file);
  });

export const getImageDimensions = (url) =>
  new Promise((res, rej) => {
    const image = new Image();
    image.src = url;
    image.onload = () => {
      res({width: image.width, height: image.height});
    };
    image.onerror = (e) => rej(e);
  });

export const handleImageUpload = (file, cancelTokenSource) =>
  handleMediaUpload(file, cancelTokenSource);
export const handleVideoUpload = (file, cancelTokenSource) =>
  handleMediaUpload(file, cancelTokenSource)
    //If its a cloudinary video, strip out the cloudinary part of the URL to match BE response structure (+ strip out width/height, unused)
    .then(({fileName, url}) => ({
      fileName: fileName,
      url: url.split(CLOUDINARY_VIDEO_URL_PREFIX)[1],
    }));

//first get our upload preset from the LFE host server, then upload to cloudinary
const handleMediaUpload = async (file, cancelTokenSource) => {
  const signaturePayload = await edumeClient.get(
    `/cloudinary/signUpload?preset=${config.cloudinaryUploadPreset}`
  );
  const {apiKey, timestamp, signature} = signaturePayload.data;

  let data = new FormData();
  data.append('upload_preset', config.cloudinaryUploadPreset);
  data.append('api_key', apiKey);
  data.append('timestamp', timestamp);
  data.append('signature', signature);
  data.append('resource_type', 'auto');
  data.append('file', file);
  const axiosConfig = cancelTokenSource
    ? {cancelToken: cancelTokenSource.token}
    : {};
  return axios
    .post('https://api.cloudinary.com/v1_1/edume/upload', data, axiosConfig)
    .then((response) => {
      const {secure_url, original_filename, format, width, height} =
        response.data;
      if (secure_url !== '') {
        const uploadFilename = original_filename + '.' + format;
        return {
          fileName: uploadFilename,
          url: secure_url,
          width,
          height,
        };
      } else {
        //original function returned uncleanly if this ever happened, not sure why..
        throw new Error('Invalid image/video returned');
      }
    })
    .catch((e) => {
      if (e.message !== CANCELLED_COMPONENT_UNMOUNTED) {
        // ignore requests cancelled because component doing the upload was unmounted
        console.error(e.message);
      }
      throw e;
    });
};

export const getYouTubeIdFromUrl = videoUtil.getYouTubeIdFromUrl;

export const getPreviewImage = (url, type) => {
  if (!url) {
    return defaultMediaUrl;
  }
  const youtubeId = getYouTubeIdFromUrl(url);
  if (youtubeId) {
    return `https://img.youtube.com/vi/${youtubeId}/hqdefault.jpg`;
  }
  return type === 'image'
    ? url
    : //cloudinary supports changing video extension to jpg to get default thumbnail
      CLOUDINARY_VIDEO_URL_PREFIX + url.replace(/\.[^.]*$/, '.jpg');
};

export const getPrivacyOfYouTubeVideo = async (id) => {
  const apiKey = config.youtubeApiKey;
  const url = `https://www.googleapis.com/youtube/v3/videos?id=${id}&part=status&key=${apiKey}`;
  try {
    const response = await axios.get(url, {
      headers: {Accept: 'application/json'},
    });
    if (response.data && response.data.items.length) {
      const privacy = response.data.items[0].status.privacyStatus;
      return privacy;
    } else {
      throw new Error('Video not found');
    }
  } catch (error) {
    console.error(error);
    throw new Error('Error getting video');
  }
};

export const truncateStringFromEnd = (str, maxLength) => {
  if (str.length <= maxLength) {
    return str;
  }
  return '...' + str.slice(-maxLength);
};

export const truncateVideoURL = (videoUrl, maxLength) => {
  try {
    const url = new URL(videoUrl);
    const {host, pathname, search} = url;
    let name = `${host}${pathname}${search}`;
    name = name.split('www.')[1] ?? name;
    if (name.length <= maxLength) {
      return name;
    }
    return name.substring(0, maxLength) + '...';
  } catch {
    // Create fall back for partial cloudinary url
    return truncateStringFromEnd(videoUrl, maxLength);
  }
};
