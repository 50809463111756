import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {injectIntl} from 'react-intl';
import {withRouter} from 'react-router-dom';

import {Button, Input, LegacyHeading} from '@edume/magnificent';

import {
  CONTENT_FILTERS,
  SIDEBAR_TABS,
} from '../../../state/course/courseActionTypes';
import {sortContentByCreationDate} from '../../../utils/courseUtil';
import {LegacyIntlHeading} from '../../core';

import styles from '../styles.scss';

const LearningTabHeader = ({
  courses,
  intl,
  searchCourse,
  openAddContentPanel,
  clearCourseSearch,
  courseSearchValue,
  setCourseSearchValue,
  searchResults,
  setSearchResults,
  setActiveSidebarItem,
  setActiveContentFilter,
  goToContent,
}) => {
  const searchPlaceholder = intl.formatMessage({
    id: 'SelectCourse.search.placeholder',
  });

  const noSearchesFoundHeading = (
    <LegacyIntlHeading
      size='micro'
      textKey='SelectCourse.search.noResultsMessage'
    />
  );

  const setCourseSearch = (value) => {
    if (!value) {
      clearCourseSearch();
    }
    setCourseSearchValue(value);
  };

  const handleGoToContent = (content, noOfResultsReturned) => {
    // TODO: add content type?
    searchCourse({
      enteredTerm: courseSearchValue,
      noOfResultsReturned,
      searchType: 'dropdown',
      courseId: content.id,
      // contentType: content.contentType
    });

    goToContent(content);
  };

  const getFilteredCoursesbySearch = () => {
    const filteredCoursesTemp = courses.filter(
      (course) =>
        course.title &&
        course.title.toLowerCase().includes(courseSearchValue.toLowerCase())
    );
    const sorted = sortContentByCreationDate(filteredCoursesTemp, 'title');
    return sorted;
  };

  const searchThroughCourses = () => {
    if (!courseSearchValue) {
      clearCourseSearch();
      return null;
    }

    const searchedCourses = getFilteredCoursesbySearch();
    setSearchResults(searchedCourses);
    setActiveSidebarItem(SIDEBAR_TABS.STANDARD_CONTENT);
    setActiveContentFilter(CONTENT_FILTERS.ALL);

    // amplitude event
    searchCourse({
      enteredTerm: courseSearchValue,
      noOfResultsReturned: searchedCourses.length,
    });

    return searchedCourses;
  };

  const renderDropdown = (searchedCourses, noOfResultsReturned) =>
    searchedCourses.map((course) => (
      <div
        styleName='dropdown-option'
        key={course.id}
        onClick={() => handleGoToContent(course, noOfResultsReturned)}
        data-automation='course-search-result'
      >
        <LegacyHeading size='micro'>{course.title}</LegacyHeading>
      </div>
    ));

  const renderNoSearchResults = () => (
    <div styleName='dropdown-option no-results'>{noSearchesFoundHeading}</div>
  );

  const getSearchDropdownResults = () => {
    const searchedCourses = getFilteredCoursesbySearch();
    const results =
      searchedCourses.length !== 0
        ? renderDropdown(searchedCourses.slice(0, 10), searchedCourses.length)
        : renderNoSearchResults();

    return <div styleName='search-dropdown'>{results}</div>;
  };

  return (
    <div styleName='header-actions'>
      <div styleName='course-search'>
        <Input
          type='search'
          value={courseSearchValue}
          onChangeValue={setCourseSearch}
          placeholder={searchPlaceholder}
          includeBottomMargin={false}
          removeSpellcheck
          validationStyles='hide'
          searchClearText={intl.formatMessage({
            id: 'SelectGroup.search.clearText',
          })}
          onEnterKey={searchThroughCourses}
          onClearInput={clearCourseSearch}
          dataAutomation='course-search-input'
          clearInputDataAutomation='clear-search-button'
          clearInputType='icon'
        />
        {courseSearchValue && !searchResults && getSearchDropdownResults()}
      </div>

      <Button
        type='primary'
        dataAutomation='create-button'
        onClick={openAddContentPanel}
        noMarginLeft
        noMarginTop
      >
        {intl.formatMessage({id: 'Button.create'})}
      </Button>
    </div>
  );
};

LearningTabHeader.propTypes = {
  courses: PropTypes.array.isRequired,
  searchCourse: PropTypes.func,
  intl: PropTypes.object.isRequired,
  openAddContentPanel: PropTypes.func.isRequired,
  clearCourseSearch: PropTypes.func.isRequired,
  courseSearchValue: PropTypes.string.isRequired,
  setCourseSearchValue: PropTypes.func.isRequired,
  searchResults: PropTypes.array,
  setSearchResults: PropTypes.func.isRequired,
  setActiveSidebarItem: PropTypes.func.isRequired,
  setActiveContentFilter: PropTypes.func.isRequired,
  goToContent: PropTypes.func.isRequired,
};

LearningTabHeader.defaultProps = {
  showTeamInfo: true,
  openAddTeamOnMount: false,
};

export default injectIntl(
  withRouter(CSSModules(LearningTabHeader, styles, {allowMultiple: true}))
);
