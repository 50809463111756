import React, {useState} from 'react';

import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';
import ReactTooltip from 'react-tooltip';

import {videoUtil} from '@edume/bento';
import {Button, Input} from '@edume/magnificent';

import {StyledModal} from '../../../containers/StyledModals';
import {getPrivacyOfYouTubeVideo} from '../../../utils/fileUpload.js';
import LegacyIntlHeading from '../LegacyIntlHeading';
import MediaContainer from '../MediaContainer';

import styles from './styles.scss';

const EmbedYoutubeLinkModal = ({
  embedUrl,
  onSetEmbedUrl,
  onSaveChanges,
  onRemove,
  onClose,
  isRemovingEnabled,
}) => {
  const intl = useIntl();
  const [error, setError] = useState(false);
  const [isNewUrl, setIsNewUrl] = useState(false);
  const [step, setStep] = useState(() =>
    embedUrl ? 'preview-content' : 'edit-link'
  );
  const videoId = videoUtil.getYouTubeIdFromUrl(embedUrl);

  const checkYouTubeVideo = async () => {
    try {
      const privacy = await getPrivacyOfYouTubeVideo(videoId);
      if (privacy === 'private') {
        throw Error('Saving private YouTube links is not supported');
      }
      setIsNewUrl(true);
      setStep('preview-content');
    } catch (e) {
      setError(true);
    }
  };

  const steps = {
    'edit-link': () => ({
      title: intl.formatMessage({id: 'UploadModal.embedTitle'}),
      body: (
        <div className={styles['embed-container']}>
          <LegacyIntlHeading size='micro' textKey='UploadModal.embedHint' />
          <Input
            type='text'
            value={embedUrl}
            onChangeValue={onSetEmbedUrl}
            placeholder=''
            title={intl.formatMessage({id: 'UploadModal.embedLabel'})}
            isInvalid={!videoId || error}
            errorText={intl.formatMessage({id: 'UploadModal.embedError'})}
            includeBottomMargin={false}
            width='fullWidth'
            dataAutomation='embed-link-input'
          />
        </div>
      ),
      buttons: [
        {
          type: 'secondary',
          onClick: onClose,
          text: intl.formatMessage({id: 'Button.cancel'}),
        },
        {
          type: 'primary',
          disabled: !embedUrl,
          onClick: checkYouTubeVideo,
          text: intl.formatMessage({id: 'UploadModal.embedButton'}),
          dataAutomation: 'check-url-button',
        },
      ],
    }),
    'preview-content': () => ({
      iconUrl: isNewUrl ? '/resources/img/38_tick.svg' : null,
      title: intl.formatMessage({
        id: isNewUrl
          ? 'UploadModal.embedSuccessTitle'
          : 'UploadModal.embedChangeTitle',
      }),
      body: (
        <div className={styles['embed-container']}>
          <MediaContainer type='embed' youtubeId={videoId} />
          <Input
            className={styles['embed-input']}
            title={intl.formatMessage({id: 'UploadModal.embedLabel'})}
            type='text'
            value={embedUrl}
            onChangeValue={() => {}}
            disabled
            placeholder=''
            includeBottomMargin={false}
            width='fullWidth'
            noMarginLeft
            noMarginTop
          />
        </div>
      ),
      buttons: [
        {
          type: 'primary',
          onClick: onSaveChanges,
          text: intl.formatMessage({id: 'Button.okay'}),
          dataAutomation: 'embed-video-done-button',
        },
      ],
      bottomLeftContent: {
        type: 'element',
        element: (
          <>
            <div
              data-tip
              data-for='deleteTip'
              className={styles['delete-button']}
            >
              {isRemovingEnabled && (
                <Button
                  type='danger'
                  onClick={onRemove}
                  noMarginTop
                  noMarginLeft
                  iconUrl='/resources/img/delete_white.svg'
                  darkIconUrl='/resources/img/delete_white.svg'
                  dataAutomation='embed-video-delete-button'
                />
              )}
            </div>
            <ReactTooltip
              id='deleteTip'
              type='dark'
              effect='solid'
              className='overlay-tooltip'
            >
              {intl.formatMessage(
                {id: 'UploadModal.removeTooltip'},
                {type: 'video'}
              )}
            </ReactTooltip>
          </>
        ),
      },
    }),
  };

  return <StyledModal compact onClose={onClose} content={steps[step]()} />;
};

EmbedYoutubeLinkModal.propTypes = {
  embedUrl: PropTypes.string,
  onSetEmbedUrl: PropTypes.func.isRequired,
  onSaveChanges: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isRemovingEnabled: PropTypes.bool,
};

export default EmbedYoutubeLinkModal;
