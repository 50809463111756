export const tabPermissions = {
  overview: {name: 'overview', order: 0},
  learning: {name: 'learning', order: 1},
  guides: {name: 'guides', order: 2},
  messages: {name: 'messages', order: 3},
  onboarding: {name: 'onboarding', order: 4},
  people: {name: 'people', order: 5},
  surveys: {name: 'surveys', order: 6},
  reports: {name: 'reports', order: 7},
};

const superadminRegex = /@edume\.com$/;

export const isEdumeAdmin = (state) => {
  //this could potentially use `hidden`, but it's not in e.g. seed data, so playing it safe
  const email = state.getIn(['auth', 'email']);
  return email.match(superadminRegex);
};

export const ADMIN_LEVELS = {
  COMPANY: 'company',
  GROUP: 'group',
  TEAM: 'team',
  NONE: 'none',
};

export const getAdminLevel = (state) => {
  const adminPermissions = state.getIn(['auth', 'permissions']).toJS();
  if (adminPermissions.company) {
    return ADMIN_LEVELS.COMPANY;
  }
  if (adminPermissions.group) {
    return ADMIN_LEVELS.GROUP;
  }
  if (adminPermissions.team) {
    return ADMIN_LEVELS.TEAM;
  }
  return ADMIN_LEVELS.NONE;
};

const getTabsBasedOnRole = (roles, surveysEnabled) => {
  //stop duplication if multiple roles
  let tabs = new Set([tabPermissions.overview]);
  if (roles.isAnalyst) {
    tabs.add(tabPermissions.reports);
  }
  if (roles.isEditor) {
    tabs
      .add(tabPermissions.learning)
      .add(tabPermissions.guides)
      .add(tabPermissions.onboarding)
      .add(tabPermissions.messages);
    if (surveysEnabled) {
      tabs.add(tabPermissions.surveys);
    }
  }
  if (roles.isManager) {
    tabs
      .add(tabPermissions.people)
      .add(tabPermissions.onboarding)
      .add(tabPermissions.messages);
  }
  //sort so that tabs are in correct order for header
  const sortedTabs = Array.from(tabs).sort((a, b) => a.order - b.order);
  return sortedTabs.map((t) => t.name);
};

export const getAdminRoles = (state) => {
  const adminPermissions = state.getIn(['auth', 'permissions']).toJS();
  const level = getAdminLevel(state);
  if (level === ADMIN_LEVELS.COMPANY) {
    return adminPermissions.company;
  }
  if (level === ADMIN_LEVELS.GROUP && adminPermissions.group.length > 0) {
    //atm adminPermissions are the same for all groups/teams
    return adminPermissions.group[0].roles;
  }
  if (level === ADMIN_LEVELS.TEAM && adminPermissions.team.length > 0) {
    return adminPermissions.team[0].roles;
  }
  return {isAnalyst: false, isEditor: false, isManager: false, isLead: false};
};

export const getVisibleTabs = (state) => {
  const email = state.getIn(['auth', 'email']);
  const hasGroups = state.getIn(['team', 'groups']).toJS().length > 0;
  const groupsLoaded = state.getIn(['team', 'teamsLoaded']);
  const roles = getAdminRoles(state);
  const company = state.getIn(['auth', 'company']);
  const isOnboardingEnabled = state.getIn([
    'featureFlags',
    'customerFlags',
  ]).onboardingEnabled;
  const isGuidesDsEnabled = state.getIn([
    'featureFlags',
    'customerFlags',
  ]).guidesDsEnabled;

  if (groupsLoaded && !hasGroups) {
    return ['no-groups'];
  }

  let tabs = getTabsBasedOnRole(roles, company.surveysEnabled);
  if (!isOnboardingEnabled || !email.match(superadminRegex)) {
    //only super admins can see onboarding
    tabs = tabs.filter((name) => name !== 'onboarding');
  }

  if (company.platform === 'web') {
    // only customers with `platform` set to `web` can see messages
    tabs = tabs.filter((name) => name !== 'messages');
  }

  if (!isGuidesDsEnabled) {
    tabs = tabs.filter((name) => name !== 'guides');
  }

  if (getAdminLevel(state) === ADMIN_LEVELS.TEAM) {
    tabs = tabs.filter(
      (name) =>
        name !== tabPermissions.learning.name &&
        name !== tabPermissions.overview.name &&
        name !== tabPermissions.surveys.name
    );
  }

  return tabs;
};

export const hasDownloadPermissions = (state) => {
  const uberLondonGroupId = 1549;
  const isUberLondonGroup =
    state.getIn(['team', 'groupId']) === uberLondonGroupId;
  return !(isUberLondonGroup && !isEdumeAdmin(state));
};
