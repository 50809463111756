import {fromJS} from 'immutable';

import {SELECT_GROUP} from '../people/peopleActionTypes';
import {
  CREATE_GROUP_SEGMENT,
  CREATE_GROUP_SEGMENT_FAIL,
  CREATE_GROUP_SEGMENT_SUCCESS,
  EDIT_GROUP_SEGMENT,
  EDIT_GROUP_SEGMENT_FAIL,
  EDIT_GROUP_SEGMENT_SUCCESS,
  LOAD_GROUP_SEGMENTS,
  LOAD_GROUP_SEGMENTS_FAIL,
  LOAD_GROUP_SEGMENTS_SUCCESS,
} from './segmentActionTypes';

export const initialState = fromJS({
  loaded: false,
  loading: false,
  segments: [],
});

// eslint-disable-next-line complexity
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_GROUP_SEGMENTS: {
      return state.set('loaded', false).set('loading', true);
    }
    case LOAD_GROUP_SEGMENTS_SUCCESS: {
      return state
        .set('loaded', true)
        .set('loading', false)
        .set('segments', fromJS(action.payload.data));
    }

    case LOAD_GROUP_SEGMENTS_FAIL:
      return state.set('loading', false).set('loaded', false);

    case SELECT_GROUP:
      return initialState;

    case CREATE_GROUP_SEGMENT:
    case EDIT_GROUP_SEGMENT:
      return state.set('loading', true).set('loaded', false);

    case CREATE_GROUP_SEGMENT_FAIL:
    case CREATE_GROUP_SEGMENT_SUCCESS:
    case EDIT_GROUP_SEGMENT_FAIL:
    case EDIT_GROUP_SEGMENT_SUCCESS:
      return state.set('loading', false).set('loaded', true);

    default:
      return state;
  }
};
