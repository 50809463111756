import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {useIntl} from 'react-intl';

import {LegacyHeading} from '@edume/magnificent';

import styles from './styles.scss';

const FormLabel = ({type}) => {
  const intl = useIntl();
  const labelText = intl.formatMessage({id: `Trial.${type}.label`});

  return (
    <div styleName='container'>
      <LegacyHeading size='mini' weight='bold'>
        {labelText}
      </LegacyHeading>
    </div>
  );
};

FormLabel.propTypes = {
  type: PropTypes.oneOf([
    'firstName',
    'lastName',
    'company',
    'industry',
    'integrations',
    'learners',
    'password',
    'preferredEmail',
  ]).isRequired,
};

export default CSSModules(FormLabel, styles);
