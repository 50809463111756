import React, {useEffect, useRef, useState} from 'react';

import {MDXEditor} from '@mdxeditor/editor';
import {headingsPlugin} from '@mdxeditor/editor/plugins/headings';
import {linkPlugin} from '@mdxeditor/editor/plugins/link';
import {listsPlugin} from '@mdxeditor/editor/plugins/lists';
import {quotePlugin} from '@mdxeditor/editor/plugins/quote';
import {thematicBreakPlugin} from '@mdxeditor/editor/plugins/thematic-break';
import {toolbarPlugin} from '@mdxeditor/editor/plugins/toolbar';
import {BoldItalicUnderlineToggles} from '@mdxeditor/editor/plugins/toolbar/components/BoldItalicUnderlineToggles';
import {ListsToggle} from '@mdxeditor/editor/plugins/toolbar/components/ListsToggle';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';

import {Heading, ReadyIcon} from '@edume/magnificent';

import {tabControlPlugin} from './plugins/tabBehaviour';

import colourStyles from '../../../../resources/styles/colours.module.scss';
import styles from './styles.scss';

// Necessary to prevent the markown editor crashing when it sees HTML and code blocks:
// - remove HTML tags except <u> or </u> (which the editor uses for underlined text)
// - remove ``` for code blocks
const sanitizeMarkdown = (markdown) =>
  markdown.replace(/(<([^u>\n]+)>)/g, '').replace(/```/g, '');

// Have to un-escape < because the editor escapes it by default, and otherwise text like `<2` will not get rendered
const desanitizeMarkdown = (markdown) => markdown.replace(/\\</g, '<');

const handleArrowKeyPress = (event) => {
  if ([37, 39].indexOf(event.keyCode) > -1) {
    // Allow users to use left and right to navigate the toolbar radio buttons
    // (stop keypresses propagating to parent editor shortcuts)
    event.stopPropagation();
  }
};

const MarkdownInput = ({
  isInvalid,
  title,
  validationStyles,
  readyStatusDataAutomation,
  optionalText,
  value,
  placeholder,
  onChangeValue,
  onFocus,
  onBlur,
  dataAutomation,
}) => {
  const ref = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  const handleOnChangeValue = (markdown) =>
    onChangeValue(desanitizeMarkdown(markdown));

  const handleFocus = () => {
    setIsFocused(true);
    if (onFocus) {
      onFocus();
    }
  };

  const handleBlur = () => {
    // Adds a space to empty lines to prevent them from collapsing (which is the default Markdown behaviour).
    // Despite appearances, this actually only matches a single empty line (as far as the user can see) because
    // the editor automatically adds an empty line between paragraphs
    const markdownWithPersistentEmptyLines = value.replace(
      /\n\n\n\n/g,
      '\n\n&nbsp;\n\n'
    );
    onChangeValue(markdownWithPersistentEmptyLines);

    setIsFocused(false);
    if (onBlur) {
      onBlur();
    }
  };

  const handleClick = () => ref?.current?.focus(); // enables clicking anywhere on the textarea to focus

  useEffect(() => {
    const textboxElement = document.querySelectorAll('[role="textbox"]');
    textboxElement[0].setAttribute('data-automation', dataAutomation);
  }, [dataAutomation]);

  useEffect(() => {
    if (
      desanitizeMarkdown(ref?.current?.getMarkdown()) !==
      desanitizeMarkdown(value)
    ) {
      ref?.current.setMarkdown(sanitizeMarkdown(value));
    }
  }, [value]);

  return (
    <>
      {title && (
        <div styleName='title-block'>
          <div styleName='title'>
            {validationStyles === 'icon' && (
              <ReadyIcon
                isValid={!isInvalid}
                size='small'
                dataAutomation={readyStatusDataAutomation}
              />
            )}
            <Heading as='h6' size='snail' weight='medium' colour='textPrimary'>
              {title}
            </Heading>
          </div>
          {optionalText && <span styleName='optional'>{optionalText}</span>}
        </div>
      )}
      <div
        onFocus={handleFocus}
        onBlur={handleBlur}
        onClick={handleClick}
        onKeyDown={handleArrowKeyPress}
        style={{
          '--focus': isFocused
            ? colourStyles.brandPrimaryDark
            : colourStyles.grey300,
        }}
      >
        <MDXEditor
          ref={ref}
          markdown={sanitizeMarkdown(value)}
          styleName='markdown-input'
          onChange={handleOnChangeValue}
          placeholder={placeholder}
          plugins={[
            listsPlugin(),
            quotePlugin(),
            linkPlugin(),
            thematicBreakPlugin(),
            headingsPlugin(),
            tabControlPlugin(),
            toolbarPlugin({
              toolbarContents: () => (
                <>
                  <BoldItalicUnderlineToggles />
                  <ListsToggle />
                </>
              ),
            }),
          ]}
        />
      </div>
    </>
  );
};

MarkdownInput.propTypes = {
  isInvalid: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChangeValue: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  dataAutomation: PropTypes.string,
  title: PropTypes.string,
  validationStyles: PropTypes.oneOf(['normal', 'alwaysValid', 'hide', 'icon']),
  readyStatusDataAutomation: PropTypes.string,
  optionalText: PropTypes.string,
};

export default CSSModules(MarkdownInput, styles, {allowMultiple: true});
