import {
  defaultFilterAndSortOptions,
  filterOptions,
  sortOptions,
} from '../../utils/people';
import {addMeta, buildRequest} from '../stateUtil';
import {UPLOAD_CSV} from '../team/teamActionTypes';
import {
  ADD_REMOVE_USER_FROM_TEAMS,
  ADD_USER,
  DELETE_USER,
  EDIT_USER,
  LOAD_USER_DETAILS,
  LOAD_USERS,
  LOAD_USERS_SEARCH,
  REMOVE_USER_FROM_TEAM,
  UPDATE_ADMIN_LEVEL,
} from './peopleActionTypes';

export const addPeople = (peopleCsv, teamIds, parentGroupId, source) => {
  let payload = new FormData();
  payload.append('usersCsv', peopleCsv);
  payload.append('groupIds', teamIds);
  payload.append('parentGroupId', parentGroupId);
  return addMeta(buildRequest(UPLOAD_CSV, 'POST', '/users', payload), {
    source,
    countTeamsAssigned: teamIds.length,
  });
};

export const addAndRemoveUserFromTeams = (
  userId,
  groupIdsToAdd,
  groupIdsToRemove,
  isNewUser = false
) => {
  const content = {
    groupIdsToAdd,
    groupIdsToRemove,
  };
  return addMeta(
    buildRequest(
      ADD_REMOVE_USER_FROM_TEAMS,
      'PUT',
      `/users/${userId}/groups`,
      content
    ),
    {
      isNewUser,
    }
  );
};

export const loadUserDetails = (user) =>
  buildRequest(LOAD_USER_DETAILS, 'GET', `/user/${user}/profile`);

export const addUser = (user, groupId, source = null, teamIds = null) =>
  addMeta(buildRequest(ADD_USER, 'POST', `/group/${groupId}/user`, user), {
    source,
    countTeamsAssigned: teamIds ? teamIds.length : 1,
  });

export const deleteUser = (userId, source) =>
  addMeta(buildRequest(DELETE_USER, 'DELETE', `/users/${userId}`), {
    source,
    userId,
  });

export const editUser = (userId, user, teamId = null, source = null) =>
  addMeta(buildRequest(EDIT_USER, 'PUT', `/users/${userId}`, user), {
    source,
    teamId,
  });

export const removeUserFromTeam = (user, teamId) =>
  addMeta(
    buildRequest(
      REMOVE_USER_FROM_TEAM,
      'DELETE',
      `/group/${teamId}/${user.id}`
    ),
    {userId: user.id, teamId, user}
  );

export const getUsers = (
  groupId,
  pageIndex = 0,
  pageFilters = defaultFilterAndSortOptions,
  query = '',
  silent = false
) => {
  if (query && query.trim() !== '') {
    return searchUsers(groupId, query, silent);
  } else {
    return getUsersPaginated(groupId, pageIndex, pageFilters, silent);
  }
};

const getUsersPaginated = (groupId, pageIndex, pageFilters, silent) => {
  let url = `/users/${groupId}?page=${pageIndex}`;
  const pageRequestOptions = getPageRequestOptions(pageIndex, pageFilters);
  if (typeof pageRequestOptions.admin !== 'undefined') {
    url += `&admin=${pageRequestOptions.admin}`;
  }

  if (typeof pageRequestOptions.activated !== 'undefined') {
    url += `&activated=${pageRequestOptions.activated}`;
  }

  if (typeof pageRequestOptions.sort !== 'undefined') {
    url += `&sort=${pageRequestOptions.sort}`;
  }

  return addMeta(buildRequest(LOAD_USERS, 'GET', url), {
    groupId,
    pageIndex,
    pageFilters,
    silent,
  });
};

const searchUsers = (groupId, query, silent) => {
  let url = `/users/${groupId}/search?query=${encodeURIComponent(query)}`;

  return addMeta(buildRequest(LOAD_USERS_SEARCH, 'GET', url), {
    //reset filter state when searching
    groupId,
    pageIndex: 0,
    pageFilters: defaultFilterAndSortOptions,
    silent,
  });
};

const getPageRequestOptions = (pageIndex, pageFilters) => {
  const {filterOption, sortOption} = pageFilters;
  let pageRequestOptions = {};

  if (filterOption === filterOptions.admin) {
    pageRequestOptions.admin = true;
  } else if (filterOption === filterOptions.nonAdmin) {
    pageRequestOptions.admin = false;
  } else if (filterOption === filterOptions.notActivated) {
    pageRequestOptions.activated = false;
  } else if (filterOption === filterOptions.activated) {
    pageRequestOptions.activated = true;
  }

  if (sortOption === sortOptions.aToZ) {
    pageRequestOptions.sort = 'name';
  }

  return pageRequestOptions;
};

export const updateAdminLevel = (userId, permissions) =>
  buildRequest(UPDATE_ADMIN_LEVEL, 'PATCH', `/users/${userId}/adminLevel`, {
    ...permissions,
  });
