import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {useDispatch, useSelector} from 'react-redux';

import {Button, ExternalLinkIcon} from '@edume/magnificent';

import {trackContactSales} from '../../../../state/trial/trialActions';
import {showExternalLinkIcon} from '../../../../utils/integrations';

import styles from './styles.scss';

const HeaderSalesCta = ({text}) => {
  const dispatch = useDispatch();
  const accessMethod = useSelector((state) =>
    state.getIn(['auth', 'accessMethod'])
  );
  const withIcon = showExternalLinkIcon(accessMethod);
  const onClick = () => {
    dispatch(trackContactSales('header cta'));

    window.open('https://www.edume.com/book-a-meeting', '_blank');
  };

  return (
    <div styleName={`button ${withIcon ? 'with-icon' : ''}`}>
      <Button
        type='primary'
        noMarginTop
        noMarginLeft
        onClick={onClick}
        dataAutomation='sales-cta-button'
      >
        {text}
        {withIcon && (
          <div styleName='icon-container'>
            <ExternalLinkIcon size='tiny' colour='white' />
          </div>
        )}
      </Button>
    </div>
  );
};

HeaderSalesCta.propTypes = {
  text: PropTypes.string.isRequired,
};

export default CSSModules(HeaderSalesCta, styles, {allowMultiple: true});
