import * as adminActions from '../admin/adminActions';
import * as formActions from '../form/formActions';
import * as notificationActions from '../notification/notificationActions';
import * as teamActions from '../team/teamActions';
import {getSelectedGroupId} from '../team/teamSelectors';
import * as peopleActionCreators from './peopleActionCreators';
import {
  RESET_SEARCH,
  RESET_SELECTED_TEAM,
  RESET_SELECTED_USER,
  RESET_USERS_STATE,
  SET_SELECTED_TEAM,
  SET_SELECTED_USER,
  UPDATE_SEARCH_QUERY,
  VIEW_TEAMS_OR_USERS,
} from './peopleActionTypes';

const getUserPageIndex = (groupId, getState) =>
  getState().getIn(['people', 'groupUsers', groupId, 'usersPageIndex']);

const getUserPageFilters = (groupId, getState) =>
  getState().getIn(['people', 'groupUsers', groupId, 'usersPageFilters']);

export const loadUsersInVisibleTeams = (dispatch, getState, options) => {
  const groupId =
    getState().getIn(['people', 'selectedTeamId']) ||
    getState().getIn(['team', 'groupId']);
  const usersPageIndex = getUserPageIndex(groupId, getState);
  const usersPageFilters = getUserPageFilters(groupId, getState);
  loadUsers(
    groupId,
    usersPageIndex,
    usersPageFilters,
    options
  )(dispatch, getState);
};

export const resetSelectedUser = () => ({
  type: RESET_SELECTED_USER,
});

export const setSelectedUser = (userId) => ({
  type: SET_SELECTED_USER,
  payload: {id: userId},
});

export const resetSelectedTeam = () => ({
  type: RESET_SELECTED_TEAM,
});

export const setSelectedTeam = (teamId) => ({
  type: SET_SELECTED_TEAM,
  payload: {id: teamId},
});

export const loadUserDetails = (userId) => (dispatch) =>
  dispatch(peopleActionCreators.loadUserDetails(userId));

export const addPeople =
  (peopleCsv, teamIds, source) => (dispatch, getState) => {
    const groupId = getState().getIn(['team', 'groupId']);

    dispatch(
      peopleActionCreators.addPeople(peopleCsv, teamIds, groupId, source)
    )
      .then(() =>
        teamActions.loadTeams({otherTabsRequireUpdate: false})(
          dispatch,
          getState
        )
      )
      .then(() => {
        loadUsersInVisibleTeams(dispatch, getState, {
          resetState: true,
          resetSearch: true,
        });

        const messageKey = 'People.addPeopleSuccess';
        notificationActions.createSuccessNotification(messageKey)(dispatch);
      });
  };

export const deleteUser = (userId, source) => (dispatch, getState) => {
  dispatch(peopleActionCreators.deleteUser(userId, source))
    .then(() =>
      teamActions.loadTeams({otherTabsRequireUpdate: false})(dispatch, getState)
    )
    .then(() => {
      loadUsersInVisibleTeams(dispatch, getState, {
        resetState: true,
        resetSearch: false,
      });
      const messageKey = 'People.deleteSuccess';
      notificationActions.createSuccessNotification(messageKey)(dispatch);
    })
    .catch((error) =>
      notificationActions.createErrorNotificationFromResponse(error)(dispatch)
    );
};

//accepts group or team id
export const addUser =
  (user, groupId, source, isAddingFirstUser = false) =>
  (dispatch, getState) =>
    dispatch(peopleActionCreators.addUser(user, groupId, source))
      .then(() =>
        isAddingFirstUser
          ? teamActions.loadTeams({otherTabsRequireUpdate: false})(
              dispatch,
              getState
            )
          : null
      )
      .then(() => {
        loadUsersInVisibleTeams(dispatch, getState, {
          resetState: true,
          resetSearch: true,
        });
        const messageKey = 'People.addPersonSuccess';
        notificationActions.createSuccessNotification(messageKey)(dispatch);
      })
      .then(() => formActions.closeForm()(dispatch))
      .catch((error) => {
        dispatch(notificationActions.createMaxUsersErrorNotification(error));
        formActions.addFormErrorFromResponse(error)(dispatch);
      });

export const addUserAndAssignToTeams =
  (user, groupId, teamIds, source) => (dispatch, getState) =>
    dispatch(peopleActionCreators.addUser(user, groupId, source, teamIds))
      .then((successAction) => successAction.payload.data)
      .then((createdUser) =>
        dispatch(
          peopleActionCreators.addAndRemoveUserFromTeams(
            createdUser.id,
            teamIds,
            [],
            true
          )
        )
      )
      .then(() =>
        teamActions.loadTeams({otherTabsRequireUpdate: false})(
          dispatch,
          getState
        )
      )
      .then(() => {
        loadUsersInVisibleTeams(dispatch, getState, {
          resetState: true,
          resetSearch: true,
        });
        const messageKey = 'People.addPersonSuccess';
        notificationActions.createSuccessNotification(messageKey)(dispatch);
      })
      .then(() => formActions.closeForm()(dispatch))
      .catch((error) => {
        dispatch(notificationActions.createMaxUsersErrorNotification(error));
        formActions.addFormErrorFromResponse(error)(dispatch);
      });

export const editUser =
  (userId, diff, teamId, source) => (dispatch, getState) =>
    dispatch(peopleActionCreators.editUser(userId, diff, teamId, source))
      .then(() =>
        teamActions.loadTeams({otherTabsRequireUpdate: false})(
          dispatch,
          getState
        )
      )
      .then(() => {
        loadUsersInVisibleTeams(dispatch, getState, {
          resetState: true,
          resetSearch: false,
        });
        const messageKey = 'People.editSuccess';
        notificationActions.createSuccessNotification(messageKey)(dispatch);
      })
      .then(() => formActions.closeForm()(dispatch))
      .catch((error) => {
        formActions.addFormErrorFromResponse(error)(dispatch);
      });

export const addAndRemoveUserFromTeams =
  (userId, groupIdsToAdd, groupIdsToRemove) => (dispatch, getState) => {
    dispatch(
      peopleActionCreators.addAndRemoveUserFromTeams(
        userId,
        groupIdsToAdd,
        groupIdsToRemove
      )
    )
      .then(() =>
        teamActions.loadTeams({otherTabsRequireUpdate: false})(
          dispatch,
          getState
        )
      )
      .then(() => {
        loadUsersInVisibleTeams(dispatch, getState);
        const messageKey = 'Teams.assignPersonToTeamsSuccess';
        notificationActions.createSuccessNotification(messageKey)(dispatch);
      })
      .catch((error) =>
        notificationActions.createErrorNotificationFromResponse(error)(dispatch)
      );
  };

export const removeUserFromTeam = (user, teamId) => (dispatch, getState) => {
  dispatch(peopleActionCreators.removeUserFromTeam(user, teamId))
    .then(() =>
      teamActions.loadTeams({otherTabsRequireUpdate: false})(dispatch, getState)
    )
    .then(() => {
      loadUsersInVisibleTeams(dispatch, getState);
      const messageKey = 'Teams.personRemovedSuccess';
      notificationActions.createSuccessNotification(messageKey)(dispatch);
    })
    .catch((error) =>
      notificationActions.createErrorNotificationFromResponse(error)(dispatch)
    );
};

export const updateSearchQuery = (newSearch) => ({
  type: UPDATE_SEARCH_QUERY,
  payload: newSearch,
});

export const resetSearch = (shouldLoadUsers) => (dispatch, getState) => {
  const groupId = getState().getIn(['team', 'groupId']);

  dispatch({
    type: RESET_SEARCH,
    payload: {groupId},
  });

  if (shouldLoadUsers) {
    const usersPageFilters = getUserPageFilters(groupId, getState);
    return loadUsers(groupId, 0, usersPageFilters)(dispatch, getState);
  }

  return Promise.resolve();
};

export const loadUsers =
  (groupId, pageIndex, pageFilters, paramOptions = {}) =>
  async (dispatch, getState) => {
    const options = {resetState: false, silent: false, ...paramOptions};
    let searchQuery = getState().getIn(['people', 'searchQuery']);

    try {
      if (options.resetState) {
        dispatch(resetMembersState(groupId));
      }
      if (options.resetSearch) {
        // used when doing something irrelevant to search, e.g. adding a user, as new user wouldn't show up
        searchQuery = '';
        dispatch(resetSearch());
      }
      if (options.resetSelectedTeam) {
        groupId = getState().getIn(['team', 'groupId']); // eslint-disable-line no-param-reassign
        dispatch(resetSelectedTeam());
      }

      return dispatch(
        peopleActionCreators.getUsers(
          groupId,
          pageIndex,
          pageFilters,
          searchQuery,
          options.silent
        )
      );
    } catch (e) {
      console.error('Error.cannotLoadUsers', e);
      return notificationActions.createErrorNotification(
        'Error.cannotLoadUsers'
      );
    }
  };

export const viewTeamsOrUsers = (index) => (dispatch) => {
  dispatch({
    type: VIEW_TEAMS_OR_USERS,
    payload: index,
  });
};

export const resetMembersState = (groupId) => ({
  type: RESET_USERS_STATE,
  payload: {groupId},
});

export const updateAdminLevel =
  (userId, updatedPermissions) => async (dispatch, getState) => {
    try {
      await dispatch(
        peopleActionCreators.updateAdminLevel(userId, updatedPermissions)
      );
      await teamActions.loadTeams({otherTabsRequireUpdate: false})(
        dispatch,
        getState
      );
      const groupId = getSelectedGroupId(getState());
      await adminActions.getGroupAdmins(groupId)(dispatch, getState);
      loadUsersInVisibleTeams(dispatch, getState, {
        resetState: true,
        resetSearch: true,
      });
    } catch (error) {
      notificationActions.createErrorNotificationFromResponse(error)(dispatch);
    }
  };
