import crypto from 'crypto';

import {auth} from '@edume/bento';

import config from '../config';

const baseUrl = () => config.lfeRoot || 'https://learn.edume.com';

/**
 * @typedef {object} AccessLinkArgs
 * @property {number | number[]} groupOrTeamIds
 * @property {number} [moduleId]
 * @property {number} [lessonId]
 * @property {number} [guideId]
 * @property {number} [knowledgeHubId]
 */

/**
 * Create SSU / SSO / OTP link for content
 * @param {AccessLinkArgs & {urlStart: string}} args
 * @returns string
 */
const createContentAccessLink = ({
  urlStart,
  groupOrTeamIds,
  moduleId,
  lessonId,
  guideId,
  knowledgeHubId,
}) => {
  let contentPath = '';
  if (moduleId) {
    contentPath = `/m/${moduleId}`;
  } else if (lessonId) {
    contentPath = `/l/${lessonId}`;
  } else if (guideId) {
    contentPath = `/gd/${guideId}`;
  } else if (knowledgeHubId) {
    contentPath = `/kh/${knowledgeHubId}`;
  }
  return `${urlStart}${contentPath}?g=${groupOrTeamIds}`;
};

/**
 * Create OTP link for content
 * @param {AccessLinkArgs} args
 * @returns string
 */
export const createOtpLink = (args) =>
  createContentAccessLink({urlStart: `${baseUrl()}/otp`, ...args});

/**
 * Create SSU / SSO link for content
 * @param {AccessLinkArgs} args
 * @returns string
 */
export const createSsuSsoLink = (args) =>
  createContentAccessLink({urlStart: `${baseUrl()}/w`, ...args});

/**
 * @param {string} contentType
 * @param {number} contentId
 * @param {number} teamId
 */
export const createContentSeamlessBaseLink = (
  contentType,
  contentId,
  teamId
) => {
  switch (contentType) {
    case 'course':
      return createCourseSeamlessBaseLink(contentId, teamId);
    case 'guide':
      return createGuideSeamlessBaseLink(contentId, teamId);
    default:
      throw Error('contentType not supported');
  }
};

/**
 * @param {number} courseId
 * @param {number} teamId
 */
export const createCourseSeamlessBaseLink = (courseId, teamId) =>
  `${baseUrl()}/e/c/${courseId}?g=${teamId}`;

/**
 * @param {number} moduleId
 * @param {number} teamId
 */
export const createModuleSeamlessBaseLink = (moduleId, teamId) =>
  `${baseUrl()}/e/m/${moduleId}?g=${teamId}`;

/**
 * @param {number} knowledgeHubId
 * @param {number | number[]} teamIds
 */
export const createKnowledgeHubSeamlessBaseLink = (knowledgeHubId, teamIds) =>
  `${baseUrl()}/e/kh/${knowledgeHubId}?g=${teamIds}`;

/**
 * @param {number} guideId
 * @param {number} teamId
 */
export const createGuideSeamlessBaseLink = (guideId, teamId) =>
  `${baseUrl()}/e/gd/${guideId}?g=${teamId}`;

/**
 * @param {number} guideId
 * @param {number} teamId
 */
export const createGuideAnonymousSeamlessBaseLink = (guideId, teamId) =>
  `${baseUrl()}/e/gd/${guideId}?g=${teamId}&anon=true`;

export const generateSeamlessLink = (url, secret) => {
  const generateSignature = crypto
    .createHmac('sha256', secret)
    .update(url)
    .digest('hex');

  return url + '&sig=' + generateSignature;
};

/**
 * @param {object} args
 * @param {typeof auth.SSU | typeof auth.OTP | 'seamless'} args.type
 * @param {number} args.knowledgeHubId
 * @param {number | number[]} args.groupOrTeamIds
 * @returns string
 */
export const getKnowledgeHubLink = ({type, knowledgeHubId, groupOrTeamIds}) => {
  switch (type) {
    case auth.SSU:
      return createSsuSsoLink({knowledgeHubId, groupOrTeamIds});
    case auth.OTP:
      return createOtpLink({knowledgeHubId, groupOrTeamIds});
    case 'seamless':
      return createKnowledgeHubSeamlessBaseLink(knowledgeHubId, groupOrTeamIds);
    default:
      return '';
  }
};
