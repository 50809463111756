import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';

import {ReadyIcon} from '@edume/magnificent';

import IntlHeading from '../IntlHeading';

import styles from './styles.scss';

const HeadingWithIcon = ({
  headingKey,
  ready = false,
  hideIcon = false,
  dataAutomation,
  readyStatusDataAutomation,
  weight = 'medium',
}) => (
  <div styleName='heading-with-icon' data-automation={dataAutomation}>
    {!hideIcon && (
      <ReadyIcon
        isValid={ready}
        size='small'
        dataAutomation={readyStatusDataAutomation}
      />
    )}
    <IntlHeading
      size='snail'
      weight={weight}
      colour='textPrimary'
      textKey={headingKey}
      as='h6'
    />
  </div>
);

HeadingWithIcon.propTypes = {
  headingKey: PropTypes.string.isRequired,
  ready: PropTypes.bool,
  hideIcon: PropTypes.bool,
  dataAutomation: PropTypes.string,
  readyStatusDataAutomation: PropTypes.string,
  weight: PropTypes.oneOf(['regular', 'medium']),
};

export default CSSModules(HeadingWithIcon, styles);
