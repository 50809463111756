import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';

import Footer from '../../../components/Footer';

import styles from './styles.scss';

//TODO probably figure out a better name for this
const SimpleMessageView = ({darkBackground = true, children}) => (
  <div styleName='outerContainer'>
    <div styleName={`background ${darkBackground ? ' dark' : ''}`}>
      <div styleName='contentContainer'>{children}</div>
    </div>
    <Footer darkBackground={darkBackground} />
  </div>
);

SimpleMessageView.propTypes = {
  darkBackground: PropTypes.bool,
  children: PropTypes.any.isRequired,
};

export default CSSModules(SimpleMessageView, styles, {allowMultiple: true});
