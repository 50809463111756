import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {useIntl} from 'react-intl';

import {Button} from '@edume/magnificent';

import styles from './styles.scss';

const ImageLibraryPreview = ({
  image,
  chooseImage,
  onDelete,
  isEduMeLibrary,
}) => {
  const {url, filename, width, height} = image;
  const intl = useIntl();
  const getPreview = (imageUrl) =>
    imageUrl.replace(
      '/image/upload/',
      '/image/upload/w_480,h_480,c_limit,q_50,f_auto/'
    );

  return (
    <div styleName='library-preview-container'>
      <div styleName='overlay'>
        {!isEduMeLibrary && (
          <button styleName='library-delete' onClick={() => onDelete(image.id)}>
            <img src='/resources/img/delete.svg' />
          </button>
        )}
        <Button
          type='secondary'
          onClick={() => chooseImage(image)}
          noMarginTop
          noMarginLeft
          dataAutomation='select-image-button'
        >
          {intl.formatMessage({id: 'ImageLibrary.useImage'})}
        </Button>
      </div>
      <img styleName='library-preview' src={getPreview(url)} alt={filename} />
      <div styleName='library-filename-container'>
        <p styleName='library-filename'>{filename}</p>
        <p styleName='library-file-dimensions'>{`${width}x${height}px`}</p>
      </div>
    </div>
  );
};

ImageLibraryPreview.propTypes = {
  image: PropTypes.object.isRequired,
  chooseImage: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isEduMeLibrary: PropTypes.bool,
};

export default CSSModules(ImageLibraryPreview, styles);
