import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';

import {AiSparklesIcon} from '@edume/magnificent';

import styles from './styles.scss';

const AiSparklesInputButton = ({onClick}) => (
  <div styleName='ai-sparkles-input-button-wrapper' onClick={onClick}>
    <AiSparklesIcon />
  </div>
);

AiSparklesInputButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CSSModules(AiSparklesInputButton, styles);
