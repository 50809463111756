import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {injectIntl} from 'react-intl';

import {Heading, Text} from '@edume/magnificent';

import styles from './styles.scss';

const GuideBanner = ({isCardView = false, isPanelView = false, intl}) => {
  const cardView = (
    <div styleName='guide-banner-card'>
      <div styleName='guide-banner-image'>
        <img
          src='/resources/img/card-banner-img.png'
          srcSet='/resources/img/card-banner-img.png 1x, /resources/img/card-banner-img@2x.png 2x'
        />
      </div>
      <div styleName='guide-banner-text'>
        <Heading
          as='h2'
          colour='textPrimary'
          size='snail'
          textTransform='none'
          weight='bold'
        >
          {intl.formatMessage({
            id: 'Guides.listView.banners.cardView.title',
          })}
        </Heading>
        <Text
          colour='textSecondary'
          inline='true'
          lineHeight='loose'
          size='xs'
          textTransform='none'
          weight='regular'
        >
          {intl.formatMessage({
            id: 'Guides.listView.banners.cardView.description',
          })}
        </Text>
        <ul styleName='guide-banner-app-button'>
          <li>
            <div styleName='guide-banner-app-popover'>
              <Text
                colour='textSecondary'
                inline='false'
                lineHeight='tight'
                size='s'
                textTransform='uppercase'
                weight='regular'
              >
                {intl.formatMessage({
                  id: 'Guides.listView.banners.scanToDownload',
                })}
              </Text>
              <figure>
                <img src='/resources/img/qr_ios_app.svg' />
              </figure>
            </div>
            <a
              href='https://apps.apple.com/tr/app/edume-content-builder/id6443876247'
              target='_blank'
              rel='noreferrer'
            >
              <img src='/resources/img/app-store-icon.svg' />
            </a>
          </li>
          <li>
            <div styleName='guide-banner-app-popover'>
              <Text
                colour='textSecondary'
                inline='false'
                lineHeight='tight'
                size='s'
                textTransform='uppercase'
                weight='regular'
              >
                {intl.formatMessage({
                  id: 'Guides.listView.banners.scanToDownload',
                })}
              </Text>
              <figure>
                <img src='/resources/img/qr_playstore_app.svg' />
              </figure>
            </div>
            <a
              href='https://play.google.com/store/apps/details?id=com.edume.guides'
              target='_blank'
              rel='noreferrer'
            >
              <img src='/resources/img/google-play-icon.svg' />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );

  const listView = (
    <div styleName='guide-banner'>
      <div styleName='guide-banner-text'>
        <Heading
          as='h2'
          colour='textPrimary'
          size='leopard'
          textTransform='none'
          weight='bold'
        >
          {intl.formatMessage({
            id: 'Guides.listView.banners.listView.title',
          })}
        </Heading>
        <Text
          colour='textSecondary'
          inline='true'
          lineHeight='loose'
          size='m'
          textTransform='none'
          weight='regular'
        >
          {intl.formatMessage({
            id: 'Guides.listView.banners.listView.description',
          })}
        </Text>
        <ul styleName='guide-banner-app-button'>
          <li>
            <div styleName='guide-banner-app-popover'>
              <Text
                colour='textSecondary'
                inline='false'
                lineHeight='tight'
                size='s'
                textTransform='uppercase'
                weight='regular'
              >
                {intl.formatMessage({
                  id: 'Guides.listView.banners.scanToDownload',
                })}
              </Text>
              <figure>
                <img src='/resources/img/qr_ios_app.svg' />
              </figure>
            </div>
            <a
              href='https://apps.apple.com/tr/app/edume-content-builder/id6443876247'
              target='_blank'
              rel='noreferrer'
            >
              <img src='/resources/img/app-store-icon.svg' />
            </a>
          </li>
          <li>
            <div styleName='guide-banner-app-popover'>
              <Text
                colour='textSecondary'
                inline='false'
                lineHeight='tight'
                size='s'
                textTransform='uppercase'
                weight='regular'
              >
                {intl.formatMessage({
                  id: 'Guides.listView.banners.scanToDownload',
                })}
              </Text>
              <figure>
                <img src='/resources/img/qr_playstore_app.svg' />
              </figure>
            </div>
            <a
              href='https://play.google.com/store/apps/details?id=com.edume.guides'
              target='_blank'
              rel='noreferrer'
            >
              <img src='/resources/img/google-play-icon.svg' />
            </a>
          </li>
        </ul>
      </div>
      <div styleName='guide-banner-image'>
        <img
          src='/resources/img/horizontal-banner-img.png'
          srcSet='/resources/img/horizontal-banner-img.png 1x, /resources/img/horizontal-banner-img@2x.png 2x'
        />
      </div>
    </div>
  );

  const panelView = (
    <div styleName='guide-banner-panel'>
      <div styleName='guide-banner-text'>
        <Heading
          as='h3'
          colour='textPrimary'
          size='hamster'
          textTransform='none'
          weight='semiBold'
        >
          {intl.formatMessage({
            id: 'Guides.listView.banners.listView.title',
          })}
        </Heading>
        <Text
          colour='textSecondary'
          inline='true'
          lineHeight='loose'
          size='s'
          textTransform='none'
          weight='regular'
        >
          {intl.formatMessage({
            id: 'Guides.listView.banners.listView.description',
          })}
        </Text>
        <ul styleName='guide-banner-app-button'>
          <li>
            <div styleName='guide-banner-app-popover'>
              <Text
                colour='textSecondary'
                inline='false'
                lineHeight='tight'
                size='s'
                textTransform='uppercase'
                weight='regular'
              >
                {intl.formatMessage({
                  id: 'Guides.listView.banners.scanToDownload',
                })}
              </Text>
              <figure>
                <img src='/resources/img/qr_ios_app.svg' />
              </figure>
            </div>
            <a
              href='https://apps.apple.com/tr/app/edume-content-builder/id6443876247'
              target='_blank'
              rel='noreferrer'
            >
              <img src='/resources/img/app-store-icon.svg' />
            </a>
          </li>
          <li>
            <div styleName='guide-banner-app-popover'>
              <Text
                colour='textSecondary'
                inline='false'
                lineHeight='tight'
                size='s'
                textTransform='uppercase'
                weight='regular'
              >
                {intl.formatMessage({
                  id: 'Guides.listView.banners.scanToDownload',
                })}
              </Text>
              <figure>
                <img src='/resources/img/qr_playstore_app.svg' />
              </figure>
            </div>
            <a
              href='https://play.google.com/store/apps/details?id=com.edume.guides'
              target='_blank'
              rel='noreferrer'
            >
              <img src='/resources/img/google-play-icon.svg' />
            </a>
          </li>
        </ul>
      </div>
      <div styleName='guide-banner-image'>
        <img
          src='/resources/img/panel-banner-img.png'
          srcSet='/resources/img/panel-banner-img.png 1x, /resources/img/panel-banner-img@2x.png 2x'
        />
      </div>
    </div>
  );

  if (isPanelView) {
    return panelView;
  } else if (isCardView) {
    return cardView;
  } else {
    return listView;
  }
};

GuideBanner.propTypes = {
  isCardView: PropTypes.bool,
  isPanelView: PropTypes.bool,

  intl: PropTypes.object.isRequired,
};

export default injectIntl(
  CSSModules(GuideBanner, styles, {allowMultiple: true})
);
