import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';

import {LegacyHeading} from '@edume/magnificent';

import styles from './styles.scss';

const BaseHeader = ({
  title,
  rootContent,
  headerLeftContent,
  headerRightContent,
  rightContent,
  nudgeDown,
  hideDetailsOnMobile,
  backButtonContainer,
  titleDataAutomation,
  darkMode,
}) => (
  <div styleName={classNames('titleContainer', {nudgeDown}, {dark: darkMode})}>
    {rootContent}
    <div>
      {backButtonContainer}
      <div
        styleName={classNames('details', {
          'mobile-hide': hideDetailsOnMobile,
        })}
      >
        {headerLeftContent}
        <div styleName='headerContainer'>
          <LegacyHeading
            size={headerRightContent ? 'tinier' : 'tiny'}
            weight='medium'
            colour={darkMode ? 'white' : 'textPrimary'}
            dataAutomation={titleDataAutomation}
          >
            {title}
          </LegacyHeading>
          {headerRightContent}
        </div>
      </div>
      <div styleName='rightContainer'>{rightContent}</div>
    </div>
  </div>
);

BaseHeader.propTypes = {
  title: PropTypes.string.isRequired,
  titleDataAutomation: PropTypes.string,
  rootContent: PropTypes.element,
  headerLeftContent: PropTypes.element,
  headerRightContent: PropTypes.element,
  rightContent: PropTypes.array,
  nudgeDown: PropTypes.bool,
  hideDetailsOnMobile: PropTypes.bool,
  backButtonContainer: PropTypes.node,
  darkMode: PropTypes.bool,
};

export default CSSModules(BaseHeader, styles, {allowMultiple: true});
