import React, {MouseEvent, ReactNode} from 'react';

import classNames from 'classnames';
import CSSModules from 'react-css-modules';

import styles from './styles.module.scss';

type CommonWellProps = {
  children?: ReactNode;
  wellKey?: string;
  selected?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  hasCheckbox?: boolean;
  noMargin?: boolean;
  fullHeight?: boolean;
  leftBorderColor?:
    | 'successPrimary'
    | 'grey'
    | 'finished'
    | 'published'
    | 'unpublished';
  hasArrow?: boolean;
  companyLogoUrl?: string;
  companyPrimaryColor?: string;
  dataAutomation?: string;
  pagination?: boolean;
  compact?: boolean;
  borderRadius?: number;
};

type ConditionalTypeProps =
  | {
      type: 'interactive';
      onClick: () => void;
    }
  | {
      type: 'info' | 'faded' | 'active' | 'interactive course-card';
      onClick?: never;
    };

type WellProps = CommonWellProps & ConditionalTypeProps;

// eslint-disable-next-line complexity
const Well = ({
  type,
  children,
  wellKey,
  selected,
  hasCheckbox,
  onMouseEnter,
  onMouseLeave,
  noMargin,
  fullHeight,
  leftBorderColor,
  onClick,
  hasArrow,
  companyLogoUrl,
  companyPrimaryColor,
  dataAutomation,
  pagination,
  compact,
  borderRadius,
}: WellProps): JSX.Element => {
  // the SVG for hasCheckbox do not exist anymore?!?
  const tickImg = `/resources/img/${selected ? 'tick_on.svg' : 'tick_off.svg'}`;
  const arrowSrc = '/resources/img/24_drop_arrow_right_black.svg';

  const onWellClick = (
    e: MouseEvent<HTMLButtonElement> | MouseEvent<HTMLDivElement>
  ) => {
    e.stopPropagation();
    if (onClick) {
      onClick();
    }
  };

  const renderCompanyLogo = () => (
    <div
      styleName='logo'
      style={{
        backgroundColor: companyPrimaryColor,
        backgroundImage: `url("${companyLogoUrl}")`,
      }}
    />
  );

  const ContainerTag = onClick ? 'button' : 'div';

  return (
    <ContainerTag
      key={wellKey}
      onClick={onWellClick}
      styleName={classNames(type, {
        selected,
        'no-margin': noMargin,
        'full-height': fullHeight,
        [`border-${leftBorderColor}`]: leftBorderColor,
        arrow: hasArrow,
        'with-logo': companyLogoUrl,
        pagination,
        compact,
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-automation={dataAutomation}
      style={{
        ...((borderRadius || borderRadius === 0) && {
          borderRadius: borderRadius,
        }),
      }}
    >
      {companyLogoUrl && renderCompanyLogo()}
      {children}
      {hasCheckbox && <img styleName='checkbox' src={tickImg} />}
      {hasArrow && <img src={arrowSrc} />}
    </ContainerTag>
  );
};

export default CSSModules(Well, styles, {allowMultiple: true});
