import React from 'react';

import PropTypes from 'prop-types';
import {useDropzone} from 'react-dropzone';
import {useIntl} from 'react-intl';
import ReactTooltip from 'react-tooltip';

import {Button} from '@edume/magnificent';

import styles from './styles.scss';

const ToolbarButtons = ({
  type,
  onRemove,
  onReplace,
  goToLibrary,
  isRemovingEnabled = true,
}) => {
  const intl = useIntl();
  const {getRootProps, getInputProps, open} = useDropzone({
    accept: type === 'image' ? 'image/*' : 'video/*',
    multiple: false,
    onDrop: onReplace,
  });

  const removeTooltipText = intl.formatMessage(
    {id: 'UploadModal.removeTooltip'},
    {type}
  );
  const replaceTooltipText = intl.formatMessage(
    {id: 'UploadModal.replaceTooltip'},
    {type}
  );

  return (
    <div style={{display: 'flex'}}>
      {goToLibrary ? (
        <button
          style={{
            position: 'absolute',
            left: 0,
            opacity: 0,
            pointerEvents: 'none',
          }}
        />
      ) : (
        <div
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            opacity: 0,
            pointerEvents: 'none',
          }}
        >
          <div {...getRootProps({className: 'dropzone'})}>
            <input {...getInputProps()} data-automation={`${type}-input`} />
          </div>
        </div>
      )}
      {isRemovingEnabled && (
        <div data-tip data-for='deleteTip' className={styles['delete-button']}>
          <Button
            type='danger'
            onClick={onRemove}
            noMarginTop
            noMarginLeft
            iconUrl='/resources/img/delete_white.svg'
            darkIconUrl='/resources/img/delete_white.svg'
            dataAutomation='modal-delete-button'
          />
        </div>
      )}
      <ReactTooltip
        id='deleteTip'
        type='dark'
        effect='solid'
        className='overlay-tooltip'
      >
        {removeTooltipText}
      </ReactTooltip>
      <div data-tip data-for='uploadTip' className={styles['reupload-button']}>
        <Button
          type='secondary'
          onClick={() => (goToLibrary ? goToLibrary() : open())}
          noMarginTop
          noMarginLeft
          iconUrl='/resources/img/24_upload.svg'
          darkIconUrl='/resources/img/24_upload.svg'
          dataAutomation='modal-upload-button'
        />
      </div>
      <ReactTooltip
        id='uploadTip'
        type='dark'
        effect='solid'
        className='overlay-tooltip'
      >
        {replaceTooltipText}
      </ReactTooltip>
    </div>
  );
};

ToolbarButtons.propTypes = {
  type: PropTypes.oneOf(['image', 'video']).isRequired,
  onRemove: PropTypes.func.isRequired,
  onReplace: PropTypes.func,
  goToLibrary: PropTypes.func,
  isRemovingEnabled: PropTypes.bool,
};

export default ToolbarButtons;
