import React, {Component} from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {injectIntl} from 'react-intl';
import ReactResizeDetector from 'react-resize-detector';

import {Avatar, LegacyHeading, ListPopover} from '@edume/magnificent';

import SelectGroupContainer from '../../../containers/SelectGroup';
import {COMPANY} from '../../../enums/adminLevels';
import EdumePropTypes from '../../../proptypes';
import {languageNameMap} from '../../../utils/language.js';
import {
  getHasLeadRightsOnCompany,
  getHasLeadRightsOnGroup,
} from '../../../utils/permissions';
import HeaderItems from './HeaderItems';
import HeaderLink from './HeaderLink';
import MobileMenu from './MobileMenu';

import styles from './styles.scss';

const fallbackLogo = '/resources/img/logo-edume-grayscale-light.svg';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 115, //standard desktop size
      isProfileVisible: false,
      isCompanyAdminModalVisible: false,
      isMobileMenuVisible: false,
      isLogoutVisible: true,
    };
  }

  componentDidMount() {
    this.setState({
      isLogoutVisible:
        sessionStorage.getItem('isLogoutVisible') === 'false' ? false : true,
    });
  }

  showProfile = () => {
    this.setState({isProfileVisible: true});
  };
  hideProfile = () => {
    this.setState({isProfileVisible: false});
  };
  showCompanyAdminModal = () => {
    this.setState({isCompanyAdminModalVisible: true});
  };
  hideCompanyAdminModal = () => {
    this.setState({isCompanyAdminModalVisible: false});
  };

  isCompanyLead(permissions, level) {
    //only interested in admin lead levels
    return level === COMPANY ? permissions.company.isLead : false;
  }

  onResize = (w, height) => {
    this.setState({height});
  };

  setPageScrolling = (canScroll) => {
    if (canScroll) {
      document.body.classList.remove('no-mobile-scroll');
    } else {
      document.body.classList.add('no-mobile-scroll');
    }
  };

  setMobileMenuVisibility = (isMobileMenuVisible) => {
    this.setState({isMobileMenuVisible});
    this.setPageScrolling(!isMobileMenuVisible);
  };

  renderGroupContainer = () => {
    const emptyContainer = <div styleName='groupContainer mobile-hide' />;

    return !this.props.integrationsLoaded ||
      !this.props.isGroupDropdownVisible ? (
      emptyContainer
    ) : (
      <div styleName='groupContainer mobile-hide'>
        <SelectGroupContainer />
      </div>
    );
  };

  /*TODO there's a minor style rendering issue here - when the profile or settings popover is open,
  you can still hover over the group settings hitbox and activate the style (and even open it).
  this should probably be turned off, as having >1 popover open is weird.
  I think the only way is to assign a higher z-index to whichever container is active, but that would require
  rewriting/duplicating a lot of the popover state that's currently stored in SelectGroup and HeaderSettings
  */
  // eslint-disable-next-line complexity
  render() {
    const {
      permissions,
      loggedInAs,
      email,
      logout,
      addAdmin,
      deleteGroup,
      deleteGroupInProgress,
      logoUrl,
      darkLogoUrl,
      selectedGroup,
      isTrial,
      visibleTabs,
      isEduMeAdmin,
      intl,
      viewHelpCenter,
      loadTeams,
      userCountLoaded,
      viewCompanySettings,
      guidesInLearningTabEnabled,
      setLanguage,
      adminLanguage,
    } = this.props;
    const {
      height,
      isProfileVisible,
      isCompanyAdminModalVisible,
      isMobileMenuVisible,
      isLogoutVisible,
    } = this.state;

    const hasGroups = selectedGroup;
    const isGroupLead = getHasLeadRightsOnGroup(permissions, selectedGroup);
    const isCompanyLead = getHasLeadRightsOnCompany(permissions);
    const canSeeHeaderSettings = !!(isCompanyLead || isGroupLead);

    const keys = {
      '/': 'Header.overview',
      '/learning': 'Header.content',
      '/guides': 'Header.guides',
      '/messages': 'Header.updates',
      '/onboarding': 'Header.onboarding',
      '/people': 'Header.peopleAndTeams',
      '/surveys': 'Header.surveys',
      '/reports': 'Header.reporting',
      '/settings': 'Header.settings',
    };

    const dataAutomationTags = {
      '/': 'home',
      '/learning': 'learning',
      '/guides': 'guides',
      '/messages': 'messages',
      '/onboarding': 'onboarding',
      '/people': 'people',
      '/surveys': 'surveys',
      '/reports': 'reports',
      '/settings': 'settings',
    };

    const icons = {
      '/': '24_home',
      '/learning': '24_mortaboard',
      '/guides': '24_guides_book',
      '/messages': '24_email',
      '/onboarding': '24_meow',
      '/people': '24_profile_2',
      '/surveys': '24_tick',
      '/reports': '24_graph',
      '/settings': '24_cog',
    };

    const settingsProps = {
      permissions,
      isEduMeAdmin,
      isTrial,
      addAdmin,
      autoshowCompanyAdminModal: isCompanyAdminModalVisible,
      hideCompanyAdminModal: this.hideCompanyAdminModal,
      selectedGroup,
      deleteGroup,
      deleteGroupInProgress,
      viewHelpCenter,
      loadTeams,
      userCountLoaded,
      viewCompanySettings,
    };

    // eslint-disable-next-line no-useless-escape
    const pathMatches = /\/[^\/]+/.exec(window.location.pathname);
    const currentPath = pathMatches ? pathMatches[0] : '/';

    let routes = Object.keys(keys);
    if (guidesInLearningTabEnabled) {
      routes = routes.filter((route) => route !== '/guides');
    }

    const links = routes.map((route, i) => (
      <HeaderLink
        onClick={() => this.setMobileMenuVisibility(false)}
        key={i}
        url={route}
        visibleTabs={visibleTabs}
        keys={keys}
        icons={icons}
        currentPath={currentPath}
        dataAutomation={dataAutomationTags[route]}
      />
    ));

    const activePageText = intl.formatMessage({id: keys[currentPath]});
    const activePageIcon = icons[currentPath];

    const languageDropdownOptions = Object.keys(languageNameMap).map(
      (langCode) => ({
        title: languageNameMap[langCode],
        callback: () => setLanguage(langCode),
        dataAutomation: 'language-option',
      })
    );

    let profileOptions = [
      {
        subtitle: intl.formatMessage(
          {id: 'Header.signInAs'},
          {username: loggedInAs}
        ),
      },
      {
        title: intl.formatMessage({id: 'Button.signOut'}),
        callback: logout,
        dataAutomation: 'sign-out-link',
      },
    ];

    if (!isTrial) {
      profileOptions.unshift(
        {
          subtitle: intl.formatMessage({id: 'Header.chooseLanguage'}),
        },
        {
          title: languageNameMap[adminLanguage],
          dataAutomation: 'choose-language',
          nestedPopoverOptions: [
            {
              subtitle: intl.formatMessage({id: 'Header.languages'}),
            },
            ...languageDropdownOptions,
          ],
          nestedSide: 'left',
        }
      );
    }

    return (
      <div styleName='headerSpacer' style={{height}}>
        <header>
          <ReactResizeDetector handleHeight onResize={this.onResize} />

          <div styleName={`topBar${isMobileMenuVisible ? ' active' : ''}`}>
            <div
              styleName='active-hide desktop-hide mobile-toggle'
              onClick={() => this.setMobileMenuVisibility(true)}
            >
              <img
                src='/resources/img/24_drop_arrow_white.svg'
                alt='Show menu'
                styleName='arrow-icon'
              />
              <img
                src={`/resources/img/${activePageIcon}_white.svg`}
                alt='icon'
                styleName='page-icon'
              />
              <LegacyHeading size='micro' colour='white'>
                {activePageText}
              </LegacyHeading>
            </div>
            {isMobileMenuVisible && (
              <MobileMenu
                settingsProps={settingsProps}
                links={links}
                logoUrl={darkLogoUrl}
                active={isMobileMenuVisible}
                hideMenu={() => this.setMobileMenuVisibility(false)}
                email={email}
                iconText={loggedInAs}
                logout={logout}
                isLogoutVisible={isLogoutVisible}
              />
            )}
            <img styleName='logo mobile-hide' src={logoUrl || fallbackLogo} />
            {this.renderGroupContainer()}

            <div styleName={'header-items-container mobile-hide'}>
              <HeaderItems
                settingsProps={settingsProps}
                canSeeHeaderSettings={canSeeHeaderSettings}
              />
            </div>

            {isLogoutVisible && (
              <button
                styleName='profileContainer mobile-hide'
                data-automation='profile-dropdown-link'
                onClick={
                  this.state.isProfileVisible
                    ? this.hideProfile
                    : this.showProfile
                }
              >
                {isProfileVisible && (
                  <div styleName='popoverPositioner'>
                    <ListPopover
                      options={profileOptions}
                      close={this.hideProfile}
                    />
                  </div>
                )}

                <div styleName='profile'>
                  <img src='/resources/img/24_drop_arrow_white.svg' />
                  <div styleName='icon'>
                    <Avatar name={loggedInAs} withBorder />
                  </div>
                </div>
              </button>
            )}
          </div>
          {hasGroups && <nav styleName='tabRow mobile-hide'>{links}</nav>}
        </header>
      </div>
    );
  }
}

Header.propTypes = {
  permissions: EdumePropTypes.permissions.isRequired,
  visibleTabs: PropTypes.array.isRequired,
  loggedInAs: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
  addAdmin: PropTypes.func.isRequired,
  deleteGroup: PropTypes.func.isRequired,
  deleteGroupInProgress: PropTypes.bool.isRequired,
  isTrial: PropTypes.bool.isRequired,
  isEduMeAdmin: PropTypes.bool.isRequired,
  logoUrl: PropTypes.string,
  darkLogoUrl: PropTypes.string,
  selectedGroup: PropTypes.object,
  intl: PropTypes.object.isRequired,
  viewHelpCenter: PropTypes.func.isRequired,
  isGroupDropdownVisible: PropTypes.bool.isRequired,
  integrationsLoaded: PropTypes.bool.isRequired,
  loadTeams: PropTypes.func.isRequired,
  userCountLoaded: PropTypes.bool.isRequired,
  viewCompanySettings: PropTypes.func.isRequired,
  guidesInLearningTabEnabled: PropTypes.bool.isRequired,
  setLanguage: PropTypes.func.isRequired,
  adminLanguage: PropTypes.string.isRequired,
};

export default injectIntl(CSSModules(Header, styles, {allowMultiple: true}));
