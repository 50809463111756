import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import requiredIf from 'react-required-if';

import {ChevronIcon, Link} from '@edume/magnificent';

import useToggle from '../../../hooks/useToggle';

import styles from './styles.scss';

const ExpandableContentBox = ({
  title,
  summary,
  startExpanded = false,
  children,
}) => {
  const [expanded, toggleExpanded] = useToggle(startExpanded);

  return (
    <div styleName='container'>
      {title && (
        <div styleName='title-container'>
          <Link
            onClick={toggleExpanded}
            data-automation='expandable-content-box-title'
            icon={
              <ChevronIcon
                orientation={expanded ? 'up' : 'down'}
                withTransition
                colour='brandPrimary'
                size='tiny'
              />
            }
            text={title}
          />
        </div>
      )}
      {summary && (
        <button onClick={toggleExpanded} styleName='full-width-button'>
          {summary}
          <ChevronIcon
            orientation={expanded ? 'up' : 'down'}
            colour='grey700'
            withTransition
          />
        </button>
      )}
      <div styleName={`expandable-content${expanded ? ' expanded' : ''}`}>
        {children}
      </div>
    </div>
  );
};

ExpandableContentBox.propTypes = {
  title: requiredIf(PropTypes.string, (props) => !props.summary),
  summary: requiredIf(PropTypes.element, (props) => !props.title),
  startExpanded: PropTypes.bool,
  children: PropTypes.element.isRequired,
};

export default CSSModules(ExpandableContentBox, styles, {allowMultiple: true});
