import React, {useState} from 'react';

import PropTypes from 'prop-types';
import {useIntl} from 'react-intl';

import {Link, LoadingIndicator} from '@edume/magnificent';

import LegacyIntlHeading from '../LegacyIntlHeading';

import styles from './styles.scss';

const ImagePreviewer = ({imageUrl, croppedImageUrl, showOriginalImage}) => {
  const intl = useIntl();
  const [showOriginalImageToggle, setShowOriginalImageToggle] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const previewImageTitle = intl.formatMessage({
    id: 'UploadModal.previewImageTitle',
  });
  const originalImageTitle = intl.formatMessage({
    id: 'UploadModal.originalImageTitle',
  });
  const showPreviewButtonText = intl.formatMessage({
    id: 'UploadModal.showPreviewButton',
  });
  const showOriginalButtonText = intl.formatMessage({
    id: 'UploadModal.showOriginalButton',
  });

  return (
    <div>
      {showOriginalImage && (
        <div className={styles['preview-toggle-area']}>
          <LegacyIntlHeading
            size='micro'
            text={
              !showOriginalImageToggle ? previewImageTitle : originalImageTitle
            }
          />
          <Link
            text={
              !showOriginalImageToggle
                ? showOriginalButtonText
                : showPreviewButtonText
            }
            onClick={() => setShowOriginalImageToggle((prev) => !prev)}
          />
        </div>
      )}
      {isLoading && (
        <LoadingIndicator indicator='hexagons' containerStyle='centerFlex' />
      )}
      <img
        className={styles['image-preview']}
        src={showOriginalImageToggle ? imageUrl : croppedImageUrl}
        onLoad={() => setIsLoading(false)}
        onError={() => setIsLoading(false)}
        data-automation='upload-modal-image-preview'
      />
    </div>
  );
};

ImagePreviewer.propTypes = {
  imageUrl: PropTypes.string,
  croppedImageUrl: PropTypes.string.isRequired,
  showOriginalImage: PropTypes.bool.isRequired,
};

export default ImagePreviewer;
