import React from 'react';

import CSSModules from 'react-css-modules';

import Checkbox from '../../Checkbox';

import styles from './styles.module.scss';

type SelectableItemProps = {
  id: number;
  text: string;
  onClick: () => void;
  selected: boolean;
  secondaryText?: string;
  disabled?: boolean;
  dataAutomation?: string;
  truncateText?: boolean;
};

const SelectableItem = ({
  id,
  text,
  onClick,
  selected,
  secondaryText = '',
  disabled = false,
  dataAutomation,
  truncateText = false,
}: SelectableItemProps): JSX.Element => {
  const handleClick: React.MouseEventHandler<HTMLDivElement> = disabled
    ? () => {}
    : () => {
        onClick();
      };

  const handleChange = disabled
    ? () => {}
    : () => {
        onClick();
      };

  return (
    <div
      data-automation={dataAutomation}
      onClick={handleClick}
      styleName={`selectable-item ${selected && !disabled ? ' selected' : ''} ${
        disabled ? 'disabled' : ''
      } ${truncateText ? 'truncated' : ''}`}
    >
      <div styleName='content-container'>
        <Checkbox
          checked={disabled ? false : selected}
          checkboxKey={`select${id}`}
          onToggleCheck={handleChange}
          disabled={disabled}
          label={text}
          isPropagationDisabled
        />
      </div>
      <span styleName={`item-secondary-text`}>{secondaryText}</span>
    </div>
  );
};

export default CSSModules(SelectableItem, styles, {allowMultiple: true});
