import React, {
  ElementType,
  MouseEventHandler,
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';

import AriaModal from 'react-aria-modal';
import CSSModules from 'react-css-modules';

import Button, {ButtonTypes, IconPosition} from '../Button';
import {CrossSquareSolidIcon} from '../icons';
import Link, {IconSizes} from '../Link';
import Heading from '../TextComponents/Heading';
import Text from '../TextComponents/Text';

import styles from './styles.module.scss';

type CommonModalProps = {
  blockHeader?: boolean | 'warning' | 'success';
  cancelFocus?: boolean;
  centerButton?: boolean;
  compact?: boolean;
  darkenUnderlay?: boolean;
  dataAutomation?: string;
  defaultCloseText?: string;
  disableFocusTrap?: boolean;
  disableShadowLine?: boolean;
  forceModalStyle?: boolean;
  mobileKeyboardVisible?: boolean;
  mounted?: boolean;
  noButtons?: boolean;
  onClose: () => void;
  renderTo?: string | HTMLElement;
  reskinClassName?: string;
  size?: 'tiny' | 'small' | 'large';
  stepIndex?: number; //defaults to 0? used if > 1 slide, needs to be controlled
  topMargin?: string;
  underlayClickExits?: boolean;
  fitContentHeightForMobile?: boolean;
  fitContentHeightForDesktop?: boolean;
  closeIcon?: boolean;
  linkDataAutomation?: string;
};

type ContentShape = {
  imageUrl?: string;
  iconUrl?: string;
  icon?: ReactNode;
  title?: ReactNode;
  buttons?: ModalButton[];
  bottomLeftContent?: BottomLeftContent | null;
  sidebarConfig?: SidebarConfig;
  body?: ReactNode;
  isBottomContentHidden?: boolean;
};

export type ModalButton = {
  text: string;
  onClick: MouseEventHandler<HTMLButtonElement> | (() => void);
  type: ButtonTypes;
  disabled?: boolean;
  form?: string;
  submit?: boolean;
  icon?: ReactNode;
  iconUrl?: string;
  iconPosition?: IconPosition;
  dataAutomation?: string;
};

type CommonBottomLeftContentProps = {
  buttonType?: ButtonTypes;
  text: string;
  onClick: (() => MouseEventHandler<HTMLButtonElement>) | (() => void);
  element?: ReactNode;
  icon?: ReactNode;
  dataAutomation?: string;
};

type ConditionalBottomLeftContentProps =
  | {
      type: 'element';
      iconUrl?: never;
      iconPosition?: never;
      iconSize?: never;
    }
  | {
      type: 'button';
      iconUrl?: string;
      iconPosition?: IconPosition;
      iconSize?: never;
    }
  | {
      type: 'link';
      iconUrl?: string;
      iconPosition?: never;
      iconSize?: IconSizes;
    };

export type BottomLeftContent = CommonBottomLeftContentProps &
  ConditionalBottomLeftContentProps;

type SidebarConfig = {
  title: string;
  options: SidebarOption[];
  bottomContent?: SidebarBottomContent;
};

type SidebarOption = {
  text: string;
  onClick: () => MouseEventHandler<HTMLButtonElement>;
  active?: boolean;
  iconUrl?: string;
  dataAutomation?: string;
};

type SidebarBottomContent = {
  text: string;
  linkText: string;
  linkHref: string;
  typeOfLink: string;
};

type ConditionalOnStepChange =
  | {
      onStepChange: (i: number) => void;
      content?: MultiStep<ContentShape>;
    }
  | {
      onStepChange?: never;
      content: Exclude<ContentShape | ContentShape[], MultiStep<ContentShape>>;
    };

type MultiStep<T> = {
  0: T;
  1: T;
} & Array<T>;

type ModalProps = CommonModalProps & ConditionalOnStepChange;

// eslint-disable-next-line max-statements, complexity
const Modal = ({
  blockHeader,
  cancelFocus,
  centerButton,
  compact = true,
  darkenUnderlay = true,
  dataAutomation,
  defaultCloseText = 'Close',
  disableFocusTrap,
  disableShadowLine,
  forceModalStyle,
  mobileKeyboardVisible,
  mounted = true,
  noButtons,
  onClose,
  renderTo,
  reskinClassName = '',
  size,
  stepIndex = 0,
  topMargin,
  underlayClickExits = true,
  fitContentHeightForMobile,
  fitContentHeightForDesktop,
  closeIcon,
  onStepChange,
  content,
  linkDataAutomation,
}: ModalProps): ReactElement => {
  const [showMobileSidebar, setShowMobileSidebar] = useState(false);

  // Needed to handle overflow on ModalComponent
  // All as part of fixing the same iOS Safari scrolling issue as mobileKeyboardVisible
  const [windowWidth, setWindowWidth] = useState(0);
  const resizeListener = () => setWindowWidth(window.innerWidth);
  useEffect(() => {
    setWindowWidth(window.innerWidth);
    window.addEventListener('resize', resizeListener);
    return () => window.removeEventListener('resize', resizeListener);
  }, []);

  const ModalComponentRef = useRef<typeof AriaModal | ElementType>(
    AriaModal.renderTo(renderTo ?? '')
  );
  const ModalComponent: typeof AriaModal | ElementType =
    ModalComponentRef.current;

  const getBottomLeftContent = ({
    type,
    text,
    element,
    onClick,
    buttonType,
    icon,
    iconUrl,
    iconSize,
    iconPosition,
    dataAutomation: buttonDataAutomation,
  }: BottomLeftContent) => {
    switch (type) {
      case 'button':
        return (
          <Button
            onClick={onClick}
            dataAutomation={buttonDataAutomation}
            type={buttonType || 'thirdary'}
            icon={icon}
            iconUrl={iconUrl}
            iconPosition={iconPosition}
            reskinClassName={reskinClassName}
            noMarginLeft
            noMarginTop
          >
            {text}
          </Button>
        );
      case 'link':
        return (
          <Link
            onClick={onClick}
            text={text}
            icon={icon}
            iconLink={iconUrl}
            iconSize={iconSize}
            reskinClassName={reskinClassName}
            dataAutomation={linkDataAutomation}
          />
        );
      case 'element':
        return element;
      default:
        return null;
    }
  };

  const toggleMobileSidebar = () => setShowMobileSidebar(!showMobileSidebar);

  const getSidebar = (config: SidebarConfig) => {
    const {title, options: configOptions, bottomContent} = config;
    const options = configOptions.map(
      (
        {text, onClick, active, iconUrl, dataAutomation: sidebarDataAutomation},
        i
      ) => {
        const onButtonClick = () => {
          setShowMobileSidebar(false);
          onClick();
        };

        return (
          <button
            key={i}
            type='button'
            onClick={onButtonClick}
            styleName={active ? 'active' : ''}
          >
            {iconUrl && <img src={iconUrl} alt='' />}
            <Text
              size='s'
              colour='white'
              dataAutomation={sidebarDataAutomation}
            >
              {text}
            </Text>
          </button>
        );
      }
    );

    const activeOption =
      configOptions.find(({active}) => !!active) || configOptions[0];

    return (
      <div styleName={`sidebar${showMobileSidebar ? ' active' : ''}`}>
        <div styleName='sidebar-title'>
          <Heading as='h4' size='ant' colour='white'>
            {title}
          </Heading>
        </div>
        <button
          type='button'
          onClick={toggleMobileSidebar}
          styleName='mobile-controls'
        >
          <Text size='xl' colour='white' weight='medium'>
            {activeOption.text}
          </Text>
          <img src='/resources/img/24_drop_arrow_white.svg' alt='' />
        </button>
        <div styleName='options'>
          {options}
          {bottomContent?.linkText && (
            <div>
              <p styleName='bottomContentText'>{bottomContent.text}</p>
              <div styleName='bottomContentLink'>
                <Link
                  text={bottomContent.linkText}
                  href={bottomContent.linkHref}
                  linkColour='white'
                  type={bottomContent.typeOfLink}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const steps = Array.isArray(content) ? content : [content];
  const isMultiStep = steps.length > 1;
  const activeStep = steps[stepIndex];

  const {
    title,
    body,
    imageUrl,
    iconUrl,
    icon,
    bottomLeftContent,
    sidebarConfig,
    isBottomContentHidden,
  } = activeStep || {};
  const buttons = activeStep?.buttons || [];

  const bottomButtons = buttons.map((b: ModalButton, index: number) =>
    b.submit ? (
      <Button
        key={index}
        type={b.type}
        noMarginTop
        forceModalStyle={forceModalStyle}
        disabled={b.disabled}
        form={b.form}
        icon={b.icon}
        iconUrl={b.iconUrl}
        iconPosition={b.iconPosition}
        submit={b.submit}
        reskinClassName={reskinClassName}
        dataAutomation={b.dataAutomation}
      >
        {b.text}
      </Button>
    ) : (
      <Button
        key={index}
        type={b.type}
        onClick={b.onClick}
        noMarginTop
        forceModalStyle={forceModalStyle}
        disabled={b.disabled}
        form={b.form}
        icon={b.icon}
        iconUrl={b.iconUrl}
        iconPosition={b.iconPosition}
        reskinClassName={reskinClassName}
        dataAutomation={b.dataAutomation}
      >
        {b.text}
      </Button>
    )
  );

  const dots = Array.from(new Array(steps.length), (_val: void, i: number) => (
    <div
      key={i}
      onClick={() => onStepChange && onStepChange(i)}
      styleName={i === stepIndex ? 'circle current' : 'circle'}
    />
  ));

  const noBottomContent =
    buttons.length === 0 && !bottomLeftContent && !isMultiStep;
  const titleStyles = compact ? 'titleWrapper' : 'titleWrapper uncompact';
  const bodyWrapperStyles = `bodyWrapper ${blockHeader ? 'blockHeader' : ''} ${
    reskinClassName ? reskinClassName : ''
  }`;

  let styleName = 'modal';
  if (size) {
    styleName += ` ${size}`;
  }
  if (!forceModalStyle) {
    styleName += ' mobile-style';
  }
  if (mobileKeyboardVisible) {
    styleName += ' mobile-keyboard-visible';
  } // This prop needed to handle iOS Safari scrolling issue (with more changes below)
  if (sidebarConfig) {
    styleName += ' with-sidebar';
  }
  if (reskinClassName) {
    styleName += ` ${reskinClassName}`;
  }
  if (disableShadowLine) {
    styleName += ' disable-shadow-line';
  }
  if (typeof blockHeader === 'string') {
    styleName += ` ${blockHeader}`;
  }
  if (fitContentHeightForDesktop) {
    styleName += ' fit-desktop-height';
  }

  const forcedButtonContainer = forceModalStyle ? 'forcedButtonContainer' : '';
  const forcedBottomContainer = forceModalStyle ? 'forcedBottomContainer' : '';
  const forcedButtonCenter = centerButton ? 'forcedButtonCenter' : '';

  const hiddenOnMobile =
    noBottomContent || mobileKeyboardVisible ? 'hiddenOnMobile' : '';

  const dialogClass = fitContentHeightForDesktop ? 'modal-fit-height' : '';

  return (
    <ModalComponent
      titleText='Modal' //TODO probably make this configurable via props, especially for intl
      onExit={onClose}
      getApplicationNode={() => document.getElementById('appContent')}
      dialogClass={dialogClass}
      underlayColor={darkenUnderlay ? 'rgba(13,0,58,0.5)' : 'rgba(0,0,0,0)'}
      underlayStyle={{
        WebkitOverflowScrolling: 'auto',
        overflow:
          mobileKeyboardVisible && windowWidth < 599
            ? 'visible'
            : 'hidden auto',
      }}
      verticallyCenter={!topMargin}
      focusDialog={cancelFocus}
      mounted={mounted}
      underlayClickExits={underlayClickExits}
      focusTrapPaused={disableFocusTrap}
    >
      <div styleName={styleName} style={{marginTop: topMargin || 0}}>
        {sidebarConfig && getSidebar(sidebarConfig)}
        <div
          styleName={fitContentHeightForMobile ? 'fit-content' : 'main-content'}
        >
          {imageUrl && <img styleName='image' src={imageUrl} />}
          <div styleName={bodyWrapperStyles}>
            <div styleName='header'>
              {title && (
                <div styleName={titleStyles}>
                  <div styleName='titleWrapperWithIcon'>
                    {iconUrl && <img src={iconUrl} />}
                    {!iconUrl && icon}
                    <Heading
                      as='h2'
                      size='sheep'
                      dataAutomation={`${dataAutomation}-title`}
                      weight={'medium'}
                    >
                      {title}
                    </Heading>
                  </div>
                  {/*if no buttons, show a close button here. intended to be used with no image tho!*/}
                  {closeIcon && (
                    <button
                      data-automation='modal-close-icon-button'
                      type='button'
                      styleName='close-button'
                      onClick={onClose}
                    >
                      <div styleName='close-image'>
                        <CrossSquareSolidIcon
                          size='medium'
                          colour='purple300'
                        />
                      </div>
                      <div styleName='close-image-hover'>
                        <CrossSquareSolidIcon
                          size='medium'
                          colour='brandPrimary'
                        />
                      </div>
                    </button>
                  )}
                  {buttons.length === 0 && !closeIcon && !noButtons && (
                    <Button
                      type='thirdary'
                      dataAutomation='default-modal-close-button'
                      onClick={onClose}
                      reskinClassName={reskinClassName}
                      noMarginTop
                    >
                      {defaultCloseText}
                    </Button>
                  )}
                </div>
              )}
            </div>
            <div styleName='bodyContent'>{body}</div>
          </div>

          {/*show the bottom area as default for spacing reasons; but not on mobile*/}
          {!isBottomContentHidden && (
            <div
              styleName={`bottomContainer ${hiddenOnMobile} ${forcedBottomContainer}`}
            >
              {bottomLeftContent && getBottomLeftContent(bottomLeftContent)}
              {isMultiStep && <div styleName='dots'>{dots}</div>}
              {buttons.length > 0 && (
                <span
                  styleName={`buttonContainer ${forcedButtonContainer} ${forcedButtonCenter}`}
                >
                  {bottomButtons}
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    </ModalComponent>
  );
};

export default CSSModules(Modal, styles, {allowMultiple: true});
