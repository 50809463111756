import React, {useState} from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import AutoSizer from 'react-virtualized-auto-sizer';
import {FixedSizeGrid as Grid} from 'react-window';

import {LoadingIndicator} from '@edume/magnificent';

import {Dialog} from '../../core';
import Cell from './Cell';
import FilePicker from './FilePicker';
import ImageLibraryPreview from './ImageLibraryPreview';

import styles from './styles.scss';

const ImageLibrary = ({
  isLibraryLoading,
  imagesFromLibrary,
  startUpload,
  chooseImage,
  deleteImage,
  setIsDeleteModalOpen,
  isEduMeLibrary,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletingId, setDeletingId] = useState(null);

  const onDelete = (id) => {
    setDeletingId(id);
    setIsDeleteModalOpen(true);
    setShowDeleteModal(true);
  };

  const onCancel = () => {
    setDeletingId(null);
    setIsDeleteModalOpen(false);
    setShowDeleteModal(false);
  };

  const onConfirmDelete = () => {
    deleteImage(deletingId);
    onCancel();
  };

  const filePicker = (
    <div className={styles['library-filepicker-container']} key={0}>
      <FilePicker
        type='image'
        onFilePick={startUpload}
        isUploading={false}
        hintTextKey={''}
      />
    </div>
  );

  const renderImages = imagesFromLibrary.map((image, index) => (
    <ImageLibraryPreview
      image={image}
      chooseImage={chooseImage}
      onDelete={onDelete}
      key={index + 1}
      isEduMeLibrary={isEduMeLibrary}
    />
  ));

  const content = isEduMeLibrary ? renderImages : [filePicker, ...renderImages];

  return isLibraryLoading ? (
    <div styleName='loader-container'>
      <LoadingIndicator indicator='hexagons' containerStyle='centerAbsolute' />
    </div>
  ) : (
    <div styleName='library-container'>
      {showDeleteModal && (
        <Dialog
          titleKey='ImageLibrary.deleteModal.title'
          descriptionKey='ImageLibrary.deleteModal.body'
          hintBoxKey='ImageLibrary.deleteModal.hintBox'
          onCancel={onCancel}
          onConfirm={onConfirmDelete}
          cancelKey='ImageLibrary.deleteModal.cancelButton'
          confirmKey='ImageLibrary.deleteModal.confirmButton'
        />
      )}
      <AutoSizer defaultWidth={498} defaultHeight={537}>
        {({width, height}) => {
          const columnCount = width > 480 ? 2 : 1;
          const rowCount = Math.ceil(content.length / columnCount);
          const cellWidth = width / columnCount;
          const cellHeight = 179;

          return (
            <Grid
              className={styles['grid']}
              width={width + 40}
              height={height}
              columnCount={columnCount}
              columnWidth={cellWidth}
              rowCount={rowCount}
              rowHeight={cellHeight}
              itemData={{content, columnCount}}
            >
              {Cell}
            </Grid>
          );
        }}
      </AutoSizer>
    </div>
  );
};

ImageLibrary.propTypes = {
  isLibraryLoading: PropTypes.bool.isRequired,
  imagesFromLibrary: PropTypes.array.isRequired,
  startUpload: PropTypes.func.isRequired,
  chooseImage: PropTypes.func.isRequired,
  deleteImage: PropTypes.func.isRequired,
  setIsDeleteModalOpen: PropTypes.func.isRequired,
  isEduMeLibrary: PropTypes.bool,
};

export default CSSModules(ImageLibrary, styles);
