import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {injectIntl} from 'react-intl';

import {SelectableItem} from '@edume/magnificent';

import styles from '../../styles.scss';

const SelectableTeamList = ({
  sourceIndex,
  teamsToDisplay,
  toggleSelection,
  isTeamSelected,
  intl,
}) =>
  [...teamsToDisplay].map((t, idx) => {
    const {id, name, disabled, parentGroupName} = t;
    const secondaryText = disabled
      ? intl.formatMessage(
          {id: 'Learning.assignTeams.teamAlreadyLinked'},
          {groupName: parentGroupName}
        )
      : null;

    const isFirstTeam = idx === 0;

    return (
      <div
        key={idx}
        tabIndex={isFirstTeam ? -1 : 1}
        styleName={`${isFirstTeam ? 'no-top-margin' : ''}`}
      >
        <SelectableItem
          id={id}
          key={id}
          disabled={disabled}
          text={name}
          secondaryText={secondaryText}
          onClick={() => toggleSelection(sourceIndex, id)}
          selected={isTeamSelected(id, sourceIndex)}
          size={'single'}
          dataAutomation={'team-option'}
        />
      </div>
    );
  });

SelectableTeamList.propTypes = {
  sourceIndex: PropTypes.number.isRequired,
  teamsToDisplay: PropTypes.array.isRequired,
  toggleSelection: PropTypes.func.isRequired,
  isTeamSelected: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(CSSModules(SelectableTeamList, styles));
