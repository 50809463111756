export const RULE_TABLES = {
  TAGS: 'users_tags',
  COURSE_COMPLETION: 'stats_users_courses',
  GUIDE_COMPLETION: 'guides_events',
  USER_GROUPS: 'users_groups',
  USERS: 'users',
} as const;

export const RULE_CRITERION_OPERATORS = {
  AFTER_ELAPSED_TIME: 'after elapsed time',
  AFTER: 'after',
  BEFORE: 'before',
  EQUALS: 'equals',
  CONTAINS: 'contains',
  GREATER_THAN: 'greater than',
  LESS_THAN: 'less than',
  SCORED_BELOW: 'scored below',
} as const;

export const AND_OPERATOR = {
  translationKey: 'Teams.createSmartTeam.operator.and',
  value: 'and',
} as const;

export const OR_OPERATOR = {
  translationKey: 'Teams.createSmartTeam.operator.or',
  value: 'or',
} as const;

export const RULE_OPERATORS = [AND_OPERATOR, OR_OPERATOR] as const;

export const RULE_COLUMN_NAMES = {
  COURSE_COMPLETION_DATE: 'completion_date',
  GUIDE_COMPLETION_DATE: 'created_at',
  COURSE_LESSON_SCORES: 'lesson_scores',
} as const;

export const DAYS = 'days';

export const WEEKS = 'weeks';

export const MONTHS = 'months';

export const TIME_PERIOD_TYPES = {DAYS, WEEKS, MONTHS} as const;

export const MAX_MATCHED_LEARNERS = 500;

export const RULE_TYPE_TAG = 'tag';
export const RULE_TYPE_ACTION = 'action';

export const ACTION_TYPE_COURSE = 'course';
export const ACTION_SUBTYPE_COMPLETED_COURSE = 'completedCourse';
export const ACTION_TYPE_ASSESSMENT = 'assessment';
export const ACTION_SUBTYPE_COMPLETED_ASSESSMENT = 'completedAssessment';

export const ACTION_TYPE_GUIDE = 'guide';
export const ACTION_SUBTYPE_COMPLETED_GUIDE = 'completedGuide';

export const TAG_TYPE_EDUME_ACTION = 'edumeAction';

export const SET_IS_TAG_RULE = 'SET_IS_TAG_RULE';
export const SET_IS_ACTION_RULE = 'SET_IS_ACTION_RULE';
export const SET_CONTENT_COMPLETED_ANYTIME = 'SET_CONTENT_COMPLETED_ANYTIME';
export const SET_CONTENT_COMPLETED_AFTER_ELAPSED_TIME =
  'SET_CONTENT_COMPLETED_AFTER_ELAPSED_TIME';
export const SET_SCORED_BELOW = 'SET_SCORED_BELOW';
export const SET_SCORED_ANY = 'SET_SCORED_ANY';

export const RULE_ID_COLUMNS = {
  TAGS: 'tag_value_id',
  COURSES: 'course_id',
  GUIDES: 'guide_id',
  USERS: 'user_id',
} as const;
export const STATIC_TEAM = 'staticTeam';
export const SMART_TEAM = 'smartTeam';
export const CONDITION_TYPES = {
  TIME: 'time',
  SCORE: 'score',
};

export const STATIC = 'static';
export const SMART = 'smart';
