import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {injectIntl} from 'react-intl';

import {Button} from '@edume/magnificent';

import {LegacyIntlHeading} from '../../core';
import OverviewBlock from '../OverviewBlock';

import styles from './styles.scss';

const PromoBlock = ({
  iconName,
  headingKey,
  bodyKey,
  buttonKey,
  onClick,
  intl,
  buttonIcon = null,
}) => (
  <OverviewBlock>
    <div styleName='container'>
      <img styleName='icon' src={`/resources/img/overview/${iconName}.svg`} />
      <div>
        <LegacyIntlHeading size='micro' weight='medium' textKey={headingKey} />
        <LegacyIntlHeading size='mini' textKey={bodyKey} />

        <div styleName='button'>
          <Button
            type='thirdary'
            noMarginLeft
            onClick={onClick}
            icon={buttonIcon}
            iconPosition={buttonIcon ? 'end' : null}
          >
            {intl.formatMessage({id: buttonKey})}
          </Button>
        </div>
      </div>
    </div>
  </OverviewBlock>
);

PromoBlock.propTypes = {
  iconName: PropTypes.string.isRequired,
  headingKey: PropTypes.string.isRequired,
  bodyKey: PropTypes.string.isRequired,
  buttonKey: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  buttonIcon: PropTypes.element,
};

export default injectIntl(
  CSSModules(PromoBlock, styles, {allowMultiple: true})
);
