import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {Button, Heading, Text} from '@edume/magnificent';

import {COURSE, GUIDE} from '../../../enums/contentTypes';
import {DASHBOARD} from '../../../enums/overview';
import {setIsUserAddingCourse} from '../../../state/course/courseActions';
import {setIsUserAddingGuide} from '../../../state/guide/guideActions';
import {goToCreateContent} from '../../../state/overview/overviewActions';
import {injectFeatureFlags} from '../../../utils/featureFlags';
import OverviewBlock from '../OverviewBlock';

import styles from './styles.scss';

const OverviewBanner = ({hasContent, featureFlags}) => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const {guidesDsEnabled} = featureFlags;

  let type = COURSE;
  if (hasContent && guidesDsEnabled) {
    const now = new Date();
    type = now.getDate() % 2 === 0 ? GUIDE : COURSE;
  }

  const backgroundImageUrl = `/resources/img/overview/${type}-banner.png`;
  const title = intl.formatMessage({
    id: `Overview.banner.${hasContent ? type : 'empty'}.title`,
  });
  const description = intl.formatMessage({
    id: `Overview.banner.${type}.description`,
  });
  const button = (
    <Text size='s' weight='semiBold'>
      {intl.formatMessage({
        id: `Overview.banner.${type}.button`,
      })}
    </Text>
  );

  let redirectPath = '/learning';
  if (!featureFlags.guidesInLearningTabEnabled && type === GUIDE) {
    redirectPath = '/guides/new';
  }
  const onButtonClick = () => {
    dispatch(goToCreateContent(type));
    if (type === COURSE) {
      dispatch(setIsUserAddingCourse(true));
    } else if (type === GUIDE && featureFlags.guidesInLearningTabEnabled) {
      dispatch(setIsUserAddingGuide(true));
    }
    history.push(redirectPath + location.search, DASHBOARD);
  };

  return (
    <OverviewBlock>
      <div
        styleName={`banner`}
        style={{'--bg-image': `url('${backgroundImageUrl}')`}}
      >
        <div styleName='banner-content'>
          <Heading as='h2' size='leopard' weight='semiBold'>
            {title}
          </Heading>
          <Text size='m' colour='grey700'>
            {description}
          </Text>
          <Button
            type='primary'
            noMarginLeft
            noMarginTop
            onClick={onButtonClick}
            dataAutomation={`create-${type.toLowerCase()}-button`}
          >
            {button}
          </Button>
        </div>
      </div>
    </OverviewBlock>
  );
};

OverviewBanner.propTypes = {
  hasContent: PropTypes.bool.isRequired,
  featureFlags: PropTypes.object.isRequired,
};

export default injectFeatureFlags(
  CSSModules(OverviewBanner, styles, {allowMultiple: true})
);
