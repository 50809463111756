import React, {useState} from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {injectIntl} from 'react-intl';

import {Link, ListPopover} from '@edume/magnificent';

import styles from './styles.scss';

const PopoverLink = ({
  linkText,
  linkTextKey,
  linkIconUrl,
  linkColour,
  linkSize,
  linkIcon,
  linkIconPosition,
  title,
  titleKey,
  options,
  intl,
}) => {
  const [showPopover, setShowPopover] = useState(false);

  const toggleEdit = () => {
    setShowPopover((wasShowing) => !wasShowing);
  };

  const popoverLinkText =
    typeof linkText === 'undefined'
      ? intl.formatMessage({id: linkTextKey})
      : linkText;
  const popoverLinkTitle =
    typeof title === 'undefined' ? intl.formatMessage({id: titleKey}) : title;
  const popover = (
    <ListPopover
      title={popoverLinkTitle}
      options={options}
      close={toggleEdit}
    />
  );
  return (
    <span styleName='linkWrapper'>
      <Link
        text={popoverLinkText}
        onClick={toggleEdit}
        iconLink={linkIconUrl}
        linkColour={linkColour}
        linkSize={linkSize}
        icon={linkIcon}
        iconPosition={linkIconPosition}
      />
      {showPopover && popover}
    </span>
  );
};

PopoverLink.propTypes = {
  linkText: PropTypes.string,
  linkTextKey: PropTypes.string,
  linkIconUrl: PropTypes.string,
  linkColour: PropTypes.string,
  linkSize: PropTypes.string,
  linkIcon: PropTypes.element,
  linkIconPosition: PropTypes.string,
  title: PropTypes.string,
  titleKey: PropTypes.string,
  options: PropTypes.array.isRequired,
  intl: PropTypes.object.isRequired,
};

PopoverLink.defaultProps = {
  linkSize: 'snail',
};

export default injectIntl(CSSModules(PopoverLink, styles));
