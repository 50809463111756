import React from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';

import {Link} from '@edume/magnificent';

import {LegacyIntlHeading} from '../../../core';

import styles from './styles.scss';

const SelectGroupMenu = ({groups, selectGroup, active, onClose}) => {
  const groupNames = groups.map((group) => (
    <Link
      key={group.id}
      text={group.name}
      onClick={() => selectGroup(group.id)}
      linkColour='white'
      linkSize='cat'
    />
  ));

  return (
    <div styleName={`select-group-menu${active ? ' active' : ''}`}>
      <button styleName='back-button' onClick={onClose}>
        <img src='/resources/img/24_left_white.svg' alt='<' />
        <LegacyIntlHeading textKey='Button.back' size='mini' colour='white' />
      </button>
      <LegacyIntlHeading
        textKey='Header.chooseGroup'
        colour='grey300'
        size='mini'
      />
      <div styleName='group-links'>{groupNames}</div>
    </div>
  );
};

SelectGroupMenu.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectGroup: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CSSModules(SelectGroupMenu, styles, {allowMultiple: true});
