import React, {useEffect} from 'react';

import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import {IntlProvider} from 'react-intl';
import {connect} from 'react-redux';
import {useHistory, withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';

import NotificationArea from '../../components/NotificationArea';
import config from '../../config';
import * as contentTypes from '../../enums/contentTypes';
import * as courseActions from '../../state/course/courseActions';
import * as trackingActions from '../../state/tracking/trackingActions';
import {trackingActionTypes} from '../../state/tracking/trackingActions';
import {getLocalisedStringsForLanguage} from '../../utils/language';

import styles from './styles.scss';

const mapStateToProps = (state) => ({
  trialStartSuccess: state.getIn(['trial', 'trialStartSuccess']),
  firstResource: state.getIn(['trial', 'firstResource']),
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(trackingActions, dispatch),
  ...bindActionCreators(courseActions, dispatch),
});

const TrialView = ({
  track,
  trialStartSuccess,
  location,
  children,
  firstResource,
  goToResources,
}) => {
  const {pathname} = location;
  const history = useHistory();
  const messages = getLocalisedStringsForLanguage('en');

  useEffect(() => {
    track(trackingActionTypes.TRIAL_SIGNUP_START);
  }, [track]);

  useEffect(() => {
    if (window.hj) {
      window.hj('stateChange', pathname);
    }

    if (pathname === '/trial') {
      window.location.href = config.trialWelcomeUrl;
    }
  }, [pathname]);

  const redirectToResource = (resource) => {
    const {id, type, lessonKey} = resource;

    switch (type) {
      case contentTypes.COURSE: {
        const resourceContentUrl = `/learning/course/${id}/lesson/${lessonKey}`;
        history.push({pathname: resourceContentUrl});
        break;
      }
      case contentTypes.GUIDE: {
        const resourceContentUrl = `/guides/${id}/edit`;
        history.push({pathname: resourceContentUrl});
        break;
      }
      default:
        goToResources();
        break;
    }
  };

  if (trialStartSuccess) {
    const {isResourceNotFound} = firstResource;

    if (isResourceNotFound) {
      goToResources();
    } else {
      redirectToResource(firstResource);
    }
  }

  return (
    <IntlProvider locale='en' messages={messages}>
      <div styleName={'trial-view'}>
        <div>
          <div id='modalContainer' />
          <div id='nestedModalContainer' />
        </div>
        <NotificationArea />
        <div styleName='view-container'>
          <div styleName='content'>{children}</div>
        </div>
      </div>
    </IntlProvider>
  );
};

TrialView.propTypes = {
  // actions
  track: PropTypes.func.isRequired,
  goToResources: PropTypes.func.isRequired,
  // state
  trialStartSuccess: PropTypes.bool.isRequired,
  firstResource: PropTypes.object,
  // other
  location: PropTypes.object.isRequired,
  children: PropTypes.object,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CSSModules(TrialView, styles, {allowMultiple: true}))
);
